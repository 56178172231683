import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
/**
 * Utilities for chats
 */
const useChatUtils = () => {
  // const userActive = useSelector((state) => state.userReducer);
  const practice = useSelector((state) => state.practicesReducer.practice);
  
  const practitioners = practice && Array.isArray(practice.people) ? practice.people : [];
  
  /**
   * Check if the main user is in practitioner list
   * @returns boolean
   */
  const isPractitioner = (chat) => {
    if (chat.type === 'group' || !chat.main_user) {
      return false;
    } else if (
      practitioners.find((p) => p.auth_user_id === chat.main_user.auth_user_id && p.type === 'doctor')
    ) {
      return true;
    }
    return false;
  };
  /**
   * Check if a user has inactive status o is not practitioner
   * @param {object} chat
   * @returns boolean
   */
  const isActive = (chat) => {
    if (chat.type === 'group' || !chat.main_user) {
      return true;
    }
    if ((chat.is_practitioner && chat.main_user.status !== 'inactive') || (chat.status !== 'inactive')) {
      return true;
    }
    return false;
  };

  return {
    isActive,
    isPractitioner,
  };
};

useChatUtils.propTypes = {
  practice: PropTypes.object.isRequired,
};

export default useChatUtils;
