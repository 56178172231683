import React, { useEffect, useState } from 'react';

import { Button } from 'reactstrap';
import ReactTable from 'react-table';
import Icon from 'components/Icons/Icon';

const DevicesOverview = () => {

  const [data, setData] = useState([]);

  useEffect(() => {
    let newData = data;

    for (let i = 0; i < 20; i++) {
      newData.push({
        status: "In use",
        study: "Palpitation",
        patient: "Mayer",
        shipping: "",
        expiry: "20.02.2021 13:05",
        firstDate: "20.02.2021 14:05",
        actions: (
          <Button
              className="actions-right btn-icon btn-neutral"
              color="success"
              size="md"
              type="button"
            >
            <Icon file="renew.svg" className="renew-icon" />
          </Button>
        )
      });
    }

    setData(newData);

    //eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <ReactTable
        data={data}
        
        columns={[
          {
            Header: 'Status',
            accessor: 'status',
          },
          {
            Header: 'Study',
            accessor: 'study',
          },
          {
            Header: 'Patient',
            accessor: 'patient',
          },
          {
            Header: 'Shipping Date',
            accessor: 'shipping',
          },
          {
            Header: 'Expiry',
            accessor: 'expiry',
          },
          {
            Header: 'First Date Transfer',
            accessor: 'firstDate',
          },
          {
            Header: 'Actions',
            accessor: 'actions',
            sortable: false,
            filterable: false
          }
        ]}
        className="-striped -highlight"
        defaultPageSize={5}
      />

    </React.Fragment>
  );
};

export default DevicesOverview;
