import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useTranslate } from 'react-translate';

const ImageCropModal = (props) => {
  const {
    toggle,
    showImageCrop,
    imageInputId,
    imagePreviewId,
    defaultImage,
    setAvatar,
  } = props;

  /**
   * Crop functionality
   */
  const imgRef = useRef(null);
  const [upImg, setUpImg] = useState(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 30,
    x: 25,
    y: 25,
    aspect: 1 / 1,
    maxWidth: 1500,
    maxHeight: 1500,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const t = useTranslate('image-crop-modal');

  /**
   * Elements
   */
  const imageInput = document.getElementById(imageInputId);
  const imagePreview = document.getElementById(imagePreviewId);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const handleImageCrop = (e) => {
    toggle();
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
      const showPreview = (canvas, crop) => {
        if (!crop || !canvas || !imagePreview) {
          return;
        }
        canvas.toBlob(
          (blob) => {
            const previewUrl = window.URL.createObjectURL(blob);
            imagePreview.src = previewUrl;
            setAvatar(blob);
          },
          'image/png',
          1
        );
      };
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    showPreview(canvas, completedCrop);
    //eslint-disable-next-line
  }, [completedCrop]);

  useEffect(() => {
    if (imageInput) {
      imageInput.addEventListener('change', handleImageCrop);
    }
    return () => {
      if (imageInput) {
        imageInput.removeEventListener('change', handleImageCrop);
      }
    };
  });

  return (
    <>
      <Modal isOpen={showImageCrop} toggle={toggle} size="lg">
        <ModalHeader>{t('ajust-your-image')}</ModalHeader>
        <ModalBody className="text-center">
          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(e) => setCrop(e)}
            onComplete={(e) => setCompletedCrop(e)}
            style={{
              maxWidth: "60vh"
            }}
          />
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                display: 'none',
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0),
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button 
            color="info" 
            onClick={toggle}
            className="mb-3"
          >
            {t('ready')}
          </Button>
          
          <Button
            color="danger"
            className="mb-3 ml-2"
            onClick={() => {
              imageInput.value = null;
              imagePreview.src = defaultImage;
              toggle();
            }}
          >
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ImageCropModal.defaultProps = {
  showImageCrop: false,
};

ImageCropModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  setAvatar: PropTypes.func.isRequired, // Image cropped
  showImageCrop: PropTypes.bool.isRequired, //Show modal
  imageInputId: PropTypes.string.isRequired, //Image input
  imagePreviewId: PropTypes.string.isRequired, // Image preview
  defaultImage: PropTypes.string.isRequired, // Initial image
};

export default ImageCropModal;
