import CONSTANTS from '../CONSTANTS';

const initialState = {
  studies: [],
  page: 0,
  lastPage: 1,
  limit: 10,
  study: {},
  loadingAiReview: false,
  loadingStudy: false,
  loadingStudyList: false,
  showSentModal: false,
  querySearch: "",
}
const kiLabReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    
    case CONSTANTS.LAB_EARLY_SET_LIST: 
      return {...state, studies: action.studies.data}
    case CONSTANTS.LAB_EARLY_SET_STUDY: 
      return {...state, study: action.study}
    case CONSTANTS.LAB_EARLY_SET_QUERY:
      return {...state, querySearch: action.query}
    default:
      return state;
  }
};

export default kiLabReducer;
