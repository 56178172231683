import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { translate, TranslatorProvider } from 'react-translate';
import languages from 'configs/lang';
import PushNotifications from 'components/Notifications/PushNotifications';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
} from 'reactstrap';
import Main2fa from 'components/Users/Main2fa';
import moment from 'moment';
import {
  actionUpdateLang,
  actionUpdateDateFormat,
} from 'store/actions/usersActions';
import FormChangePassword from 'components/Users/FormChangePassword';
import CredentialsForm from 'components/Users/CredentialsForm';
import SessionManagerSection from './SessionManagerSection';
//import AppInformationSection from 'components/Users/AppInformationSection';
import LegalContent from 'views/Components/LegalContent';
import UserDeleteForm from "components/Users/UserDeleteForm";

const Settings = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);
  const { nativeWebView } = useSelector((state) => state.commonReducer);

  const { language, dateFormat } = user;

  const handleLang = (e) => {
    let lang = e.target.value;
    dispatch(actionUpdateLang(lang));
  };

  const handleDateFormat = (e) => {
    let dateFormat = e.target.value;
    dispatch(actionUpdateDateFormat(dateFormat));
  };

  return (
    <>
      <Card>
        <CardBody>
          <Card>
            <CardHeader>
              <CardTitle className="d-flex align-content-center">
                <div className="general-card-title-img text-primary mr-2">
                  <i className="icon-settings1" />
                </div>
                <div className="general-card-title text-primary">
                  {t('title')}
                </div>
              </CardTitle>
            </CardHeader>

            <CardBody>
              <div className="setting-forms">
                <Row>
                  <Col>
                    {/* Change Credentials */}
                    {!user.password_expired && (
                      <>
                        {/* edit formats of date and language */}
                        <h5 className="general-card-subtitle">
                          {t('user-settings')}
                        </h5>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>{t('date-format')}</label>
                              <Input
                                type="select"
                                name="dateFormat"
                                defaultValue={dateFormat}
                                onChange={handleDateFormat}
                              >
                                <option value="DD.MM.YYYY">
                                  DD.MM.YYYY ({moment().format('DD.MM.YYYY')})
                                </option>
                                <option value="MM-DD-YYYY">
                                  MM-DD-YYYY ({moment().format('MM-DD-YYYY')})
                                </option>
                                <option value="DD-MM-YYYY">
                                  DD-MM-YYYY ({moment().format('DD-MM-YYYY')})
                                </option>
                                <option value="YYYY-MM-DD">
                                  YYYY-MM-DD ({moment().format('YYYY-MM-DD')})
                                </option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>{t('language')}</label>
                              <Input
                                type="select"
                                name="date-format"
                                className="disabled"
                                defaultValue={language}
                                onChange={handleLang}
                              >
                                {/* map enabled languages */}
                                {Object.keys(languages).map((val) => {
                                  if (languages[val].abbr !== "DEU") {
                                    return null;
                                  }
                                  return (
                                    <option key={val} value={val}>
                                      {languages[val].name}
                                    </option>
                                  );
                                })}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <SessionManagerSection />
                        <PushNotifications />
                        {/* componente of Two Factor Authentications  */}
                        <Main2fa />
                        {/* edit mail user  */}
                        <CredentialsForm />
                      </>
                    )}

                    {/* Change Password */}
                    <FormChangePassword />

                    {/* Delete account */}
                    {(!user.password_expired) && (
                      <UserDeleteForm />
                    )}

                    {/* Legals */}
                    <div className="setting-forms setting-section">
                      <h5 className="general-card-subtitle">{t('information')}</h5>
                      {/* <div className="container-fluid"> */}
                      <Row className="ml-auto mr-auto justify-content-center">

                          <TranslatorProvider translations={require(`assets/i18n/terms/${language || 'de'}.json`)}>
                            <LegalContent />
                          </TranslatorProvider>
                      </Row>
                      </div>
                    {/* </div> */}

                    {/*nativeWebView && <AppInformationSection />*/}

                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </>
  );
};

export default translate('settings')(Settings);
