//page of messages of ussers
import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-translate';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Row, Col, CardTitle, CardHeader } from 'reactstrap';
import Icon from 'components/Icons/Icon';
import InboxMenu from 'components/Messages/InboxMenu';
import InboxMenuMobile from 'components/Messages/InboxMenuMobile';
import InboxBody from 'components/Messages/View/InboxBody';
import {
  actionSetMessage,
  actionGetMessages,
  actionSetBox,
} from 'store/actions/messagesActions';
import { actionSetUserPracticecDisaffected } from 'store/actions/practicesActions';

const Inbox = () => {
  const t = useTranslate('messages');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);
  const { practice, isDisaffected } = useSelector((state) => state.practicesReducer);
  const [showForm, setShowForm] = useState(false);

  const { mobile } = useSelector((state) => state.commonReducer);
  // const message = useSelector((state) => state.messagesReducer.message);
  useEffect(() => {
      //check if user looged is disaffected in te current practice
    if (user && practice && practice.people) {     
      //get current user
      let res = practice.people.find(us => us.email === user.email);

      //check status
      dispatch(actionSetUserPracticecDisaffected(res && res.isDisaffected));
    }
    //eslint-disable-next-line
  }, [user, practice]);

  useEffect(() => {
    dispatch(actionSetMessage());
    dispatch(actionSetBox('inbox'));
    dispatch(actionGetMessages());
  }, [dispatch]);

  return (
    <div className="d-flex inbox-page">
      <Card>
        <CardBody>
          <Card className="inbox-card">
            <CardHeader>
              <CardTitle className="d-flex align-content-center">
                <div className="general-card-title-img text-primary mr-2">
                  <i className="icon-inbox-header" />
                </div>
                <div className="general-card-title text-primary">
                  {t('title')}
                </div>
              </CardTitle>
            </CardHeader>
            <CardBody className="h-100 inbox-card-body">
              <Row>
                <Col
                  lg="4"
                  md="12"
                  className={`${
                    !mobile ? 'inbox-menu-col inbox-menu-list' : ''
                  }`}
                >
                  <Row>
                    <Col className="inbox-menu-container inbox-menu-col">
                      {mobile ? (
                        <InboxMenuMobile setShowForm={setShowForm} />
                      ) : (
                        <InboxMenu setShowForm={setShowForm} />
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col lg="8" md="12" className="inbox-body">
                  <InboxBody setShowForm={setShowForm} showForm={showForm} isDisaffected={isDisaffected} practice={practice}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </div>
  );
};

export default Inbox;
