export default {
  mimeTypes: [
    "application/msword",
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/x-rar-compressed",
    "application/zip",
    "audio/flac",
    "audio/mpeg",
    "audio/x-aiff",
    "audio/x-flac",
    "audio/x-wav",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/tiff",
    "image/x-citrix-jpg",
    "image/x-citrix-png",
    "image/x-png",
    "text/plain",
    "video/mp4",
    "video/x-msvideo",
  ],
  types: [
    '.txt',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.pdf',
    '.gif',
    '.png',
    '.jpg',
    '.jpeg',
    '.tiff',
    '.bmp',
    '.mp3',
    '.mp4',
    '.avi',
    '.wav',
    '.flac',
    '.aiff',
    '.zip',
    '.rar',
  ],
  maxSize: '50', //In MegaBytes
};
