import { eventChannel } from 'redux-saga';
import { fork, take, call, put, select, cancel } from 'redux-saga/effects';

//Translations
import utilTranslations from '../../helpers/utilTranslations';

//Service
import WsService from 'plugins/TS-lib-utils-public/services/WsService';

//Selectors
import CONSTANTS from '../CONSTANTS';
import { getUser, getMessages, getChats, getWebsocketLastAction, getPractices, getUserAuthorization } from 'store/selectors/selectors';
import { actionGetMessages } from 'store/actions/messagesActions';
import { actionGetNotifications } from 'store/actions/notificationsActions';
import { actionSetLastData } from 'store/actions/websocketActions';
import {
  actionChatMessagesRefresh,
  actionSetChatsOnline,
} from 'store/actions/chatsActions';
import { toast } from 'react-toastify';
import { actionResetApp } from 'store/actions/rootActions';
import AuthService from 'plugins/TS-lib-utils-public/services/AuthService';
import { actionUpdateChatInList } from 'store/actions/chatsActions';
import { actionGetPractices } from 'store/actions/practicesActions';
import { actionGetPractice } from 'store/actions/practicesActions';
import { actionGetUser } from 'store/actions/usersActions';
import { actionRefreshPermissions } from 'store/actions/usersActions';
import { actionChatsRefresh } from 'store/actions/chatsActions';
import { actionWsStatus } from 'store/actions/commonActions';
import { actionRefreshUser } from 'store/actions/usersActions';
import { actionDeleteLocal } from 'store/actions/chatsActions';
import { actionChatMessageRead } from 'store/actions/chatsActions';
import { deleteUrl } from 'helpers/urlsBlob';
import { actionChatMessageUpdate } from 'store/actions/chatsActions';
import { actionReadNotification } from 'store/actions/notificationsActions';
import { actionChatRecipientsSetFlags } from 'store/actions/chatsActions';
import { actionGetChatMessages, actionGetChats } from 'store/actions/chatsActions';
import { actionSyncChatsPractitioners } from 'store/actions/chatsActions';
import { actionGetActiveSessions } from 'store/actions/usersActions';
import { actionLogout } from 'store/actions/usersActions';
import { actionGetStudy } from 'store/actions/laboratoryActions';
//import { actionGetChats } from 'store/actions/chatsActions';

function subscribe(socket) {
  return eventChannel((emitter) => {
    socket.on('connect', () => {
      emitter('ONLINE');
    });
    socket.on('disconnect', () => {
      emitter('OFFLINE');
    });
    socket.on('messages', (message) => {
      if (message.data && message.data.action) {
        emitter(message.data);
      }
    });
    return () => {};
  });
}

function* listenAction(socket) {

  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');


  try {
    const channel = yield call(subscribe, socket);
    const { box } = yield select(getMessages);
    while (true) {
      const data = yield take(channel);

      //Local events
      if (data === 'ONLINE') {

        //Get the last saved action from the socket
        const last = yield select(getWebsocketLastAction);

        //If recovered from offline socket
        if (last === 'OFFLINE') {
          const chatReducer = yield select(getChats);

          yield put(actionGetNotifications('chats'));
          yield put(actionGetChats(false, false));
          yield take(CONSTANTS.CHATS_SET);

          if (chatReducer.chat._id) {
            yield put(actionGetChatMessages(chatReducer.chat._id));
          }
        }
        
        yield put(actionWsStatus('online'));
      }
      if (data === 'OFFLINE') {
        yield put(actionWsStatus('offline'));
      }

      //Save the last received action
      yield put(actionSetLastData(data));

      //Backend signals
      const { action } = data;
      if (CONSTANTS.hasOwnProperty(action)) {
        switch (action) {
          case CONSTANTS.USERS_FORCE_LOGOUT:
            yield put(actionLogout());
            yield put(actionResetApp());
            AuthService.removeToken();
            break;
          case CONSTANTS.USERS_ONLINE:
            yield put(actionSetChatsOnline(data.users_online));
            break;
          case CONSTANTS.WS_NOTIFICATION:
            const { notification } = data;
            switch (notification.module) {
              case 'laboratory':
                yield put(actionGetNotifications('laboratory'));
                break;
              case 'messages':
                yield put(actionGetNotifications('messages'));
                if (box === 'inbox') {
                  yield put(actionGetMessages());
                  toast.success( t("alert-new-message") ); //'New Message');
                }
                break;
              case 'chats':
                const { chat } = yield select(getChats);
                const practice = yield select(getUserAuthorization);
                const { data } = notification;
                /**
                 * If chat is equal to active chat
                 */
                if (data.chat.practice._id === practice._id) {
                  yield put(actionUpdateChatInList(data.chat, true));
                  yield put(actionChatMessagesRefresh(data.chat._id));
                  if (data.chat._id === chat._id && document.visibilityState === "visible" && ["chat", "dashboard"].includes(window.location.pathname)) {
                    yield put(actionReadNotification(notification._id, 1));
                    yield put(actionChatMessageRead(chat._id));
                  }
                }
                // if (data.chat) {
                //   if (data.chat._id === chat._id) {
                //     // yield put(actionReadNotification(notification._id, 1));
                //     yield put(actionUpdateChatInList(data.chat, true));
                //     // yield put(actionChatMessagesRefresh(data.chat._id));
                //     // yield put(actionChatMessageRead(chat._id));
                //   } else if (data.chat.practice._id === practice._id) {
                //     // Refresh actual list
                //     yield put(actionUpdateChatInList(data.chat, true));
                //     // yield put(actionChatMessagesRefresh(data.chat._id));
                //   }
                // }
                /**
                 * Get notifications
                 */
                yield put(actionGetNotifications('chats'));
                break;
              default:
                break;
            }
            break;
          case CONSTANTS.WS_ALERT:
            const { module, info } = data;
            // const { chat } = yield select(getChats);
            const practice = yield select(getUserAuthorization);
            const user = yield select(getUser);
            const { isDisaffected } = yield select(getPractices);
            if (module === 'chats' && !isDisaffected) {
              if (data.chat) {
                switch (info) {
                  case 'chat-deleted':
                    yield put(actionUpdateChatInList(data.chat, false));
                    break;
                  case 'chat-delete-user':
                    yield put(actionChatMessagesRefresh(data.chat._id));
                    yield put(actionUpdateChatInList(data.chat, false));
                    break;
                  case 'chat-add-user':
                    yield put(actionUpdateChatInList(data.chat, true));
                    const chatReducer = yield select(getChats);

                    //If chat is found in the list, update the messages.
                    //Else, an error ocurred or it belongs to another practice
                    if (chatReducer.chats.find(chat => chat._id === data.chat._id)) {
                      yield put(actionChatMessagesRefresh(data.chat._id));
                    }
                    break;
                  case 'chat-refresh':
                    yield put(actionUpdateChatInList(data.chat, true));
                    break;
                  case 'chat-edit':
                    yield put(actionUpdateChatInList(data.chat, true));
                    break;
                  case 'chat-message-sended':
                    yield put(actionChatMessageUpdate(data.message));
                    // yield put(actionUpdateChatInList(data.chat, true));
                    break;
                  case 'chat-message-received-all':
                    yield put(
                      actionChatRecipientsSetFlags(
                        data?.chat?._id,
                        data?.auth_user_id,
                        { received: true }
                      )
                    );
                    // yield put(actionChatMessageUpdate(data.message));
                    break;
                  case 'chat-message-read-all':
                    yield put(
                      actionChatRecipientsSetFlags(
                        data?.chat?._id,
                        data?.auth_user_id,
                        { received: true, readed: true }
                      )
                    );
                    // yield put(actionChatMessageUpdate(data.message));
                    break;
                  default:
                    if (data.chat && data.chat._id) {
                      yield put(actionUpdateChatInList(data.chat));
                    }
                    break;
                }
              }
              if (data.message) {
                switch (info) {
                  case 'chat-message-sended':
                    yield put(actionChatMessageUpdate(data.message, true));
                    break;
                  case 'chat-message-emoji-reaction':
                    yield put(actionChatMessageUpdate(data.message));
                    break;
                  case 'chat-message-received':
                    yield put(actionChatMessageUpdate(data.message));
                    break;
                  default:
                    break;
                }
              }
            }

            if (module === 'auth') {
              if (info === "new-login" || info === "logout") {
                yield put(actionGetActiveSessions());
              }
            }
             //when there are changes in the user profile
            if (module === 'profile') {
              //check data web socket
              let { practiceId, userId } = data.data;
              //gett id current user
              const { auth_user_id } = yield select(getUser);
              const chatReducer = yield select(getChats);

              //if the profile changes are from another user  and current practice
              if (
                info === 'profile-updated' &&
                practice._id === practiceId &&
                userId !==auth_user_id
                && !isDisaffected
              ) {
                deleteUrl(userId);
                yield put(actionGetPractices());
                yield take(CONSTANTS.PRACTICES_SET_LIST);
                yield put(actionGetPractice(practiceId));
                yield take(CONSTANTS.PRACTICES_SET);

                //check if the user is already in the chat list, if not, add it
                if (chatReducer.chats && chatReducer.chats.length) {
                  const found = chatReducer.chats.find(c => ["private", "group"].includes(c.type)
                  ? c.main_user?.auth_user_id === userId 
                  : c.auth_user_id === userId);

                  if (!found) {
                    yield put(actionGetChats(true));
                  } else {
                    yield put(actionChatsRefresh(userId));
                  }
                }
              }
               //if the profile changes are from current user and current practice
              if (userId === auth_user_id &&
                practice._id === practiceId) {
                deleteUrl(userId);
                yield put(actionRefreshUser());
                yield put(actionGetPractices());
                yield take(CONSTANTS.PRACTICES_SET_LIST);
                yield put(actionGetChats(true));
                //yield put(actionChatsRefresh(userId));
              }
            }

            if (module === "laboratory") {
              yield put(actionGetNotifications('laboratory'));
              if (info === "new-ai-review") {
                let { reportId, practice } = data.data;
                yield put(actionGetStudy(reportId, practice));
              }
            }

            if (module === 'practices' && !isDisaffected) {
              let { practiceId } = data.data;
              if (info === 'practice-removed') {
                yield put(actionGetUser());
                /*
                if (practice._id === practiceId) {
                  //Remove actual practice and set first about the list
                  yield put(actionRefreshPermissions(null, true));
                }
                */
              }

              if (info === 'practitioner-invitation-resolve') {
                yield put(actionGetPractice(practiceId));
              }

              if (info === 'practitioner-updated') {
                let { userId } = data.data;
                const { isDisaffected } = yield select(getPractices);
                if (!isDisaffected) {
                  if (user.auth_user_id.toString() !== userId.toString() && user._id.toString() !== userId.toString()) {
                    yield put(actionGetPractice(practiceId));
                    yield take(CONSTANTS.PRACTICES_SET);
                    yield put(actionGetChats(true));
                  } else {
                    yield put(actionRefreshPermissions());
                    yield put(actionGetChats(true));
                  }
                }
              }
              if (info === 'practitioner-new') {
                const practitioner = data.data;
                toast.info(
                  `Welcome ${practitioner.title ? practitioner.title : ''} ${
                    practitioner.firstName
                  } ${practitioner.lastName}`
                );
                yield put(actionGetPractices());
                yield take(CONSTANTS.PRACTICES_SET_LIST);
                yield put(actionGetPractice(practiceId));
                yield take(CONSTANTS.PRACTICES_SET);
                yield put(actionRefreshPermissions());
              }
            }
            break;
          default:
            break;
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

// function* emitterAction(socket) {
//   while (true) {
//     const { action } = yield take(CONSTANTS.WS_EMIT_ACTION);
//     console.log("saga", action);
//     socket.emit("message", action);
//   }
// }

function* flow() {
  try {
    while (true) {
      yield take(CONSTANTS.USERS_SET);
      const user = yield select(getUser);
      if (user._id) {
        const socket = yield call(WsService.connect);
        const task = yield fork(listenAction, socket);
        // yield fork(emitterAction, socket);
        console.log('* ===> WS Saga online');
        yield put(actionWsStatus('online'));
        yield take(CONSTANTS.USERS_LOGOUT);
        yield cancel(task);
        console.log('* <=== WS Saga offline');
        yield put(actionWsStatus('offline'));
        yield call(WsService.disconnect);
      }
    }
  } catch (error) {
    console.log('* <=== WS Saga ERROR: ' + error.message);
    yield put(actionWsStatus('offline'));
    yield call(WsService.disconnect);
  }
}

export function* wsSaga() {
  console.log('*Main WS Saga');
  yield fork(flow);
}
