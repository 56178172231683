import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import InvitationList from 'components/Practice/Invitations/InvitationList';
import PracticeTitle from 'components/Practice/PracticeTitle';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import { NavLink } from 'react-router-dom';

const PracticeSentInvitations = () => {
  const t = useTranslate('practices');

  const { practice_manage_select } = useSelector(state => state.practicesReducer);

  return (
    <Card className="profile">
      <CardBody>
        <Card className="mb-0">
          <CardHeader>
          <CardTitle className="d-flex align-content-center">
            <PracticeTitle title={practice_manage_select?.name}/>
          </CardTitle>

          <div className="d-flex align-content-end">
            <NavLink
              to="/admin/practice/users/invite"
              className="btn btn-info btn-sm ml-auto"
            >
              {t('subtitle-invite-user')}  <i className="icon-invitation" />
            </NavLink>
          </div>
          
          {/*
            <CardTitle className="d-flex align-content-center">

              <div className="general-card-title-img text-primary mr-2">
                <i className="icon-inbox title" />
              </div>
              <div className="general-card-title text-primary">
                {t('title-my-invitations')}
              </div>
            </CardTitle>
          */}
            
          </CardHeader>
          <CardBody className="mobile-x-overflow">
            <InvitationList />
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
};

export default PracticeSentInvitations;
