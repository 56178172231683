import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
// import { composeWithDevTools } from 'redux-devtools-extension';
//Sagas
import { appointmentsSaga } from './sagas/appointmentsSaga';
import { chatsSaga } from './sagas/chatsSaga';
import { commonSaga } from './sagas/commonSaga';
import { helpsSaga } from './sagas/helpsSaga';
import { messagesSaga } from './sagas/messagesSaga';
import { notificationsSaga } from './sagas/notificationsSaga';
import { practicesSaga } from './sagas/practicesSaga';
import { profileSaga } from './sagas/profileSagas';
import { patientsSaga } from './sagas/patientsSaga';
import { userDataSaga } from './sagas/userDataSaga';
import { usersSaga } from './sagas/usersSaga';
import { wsSaga } from './sagas/wsSaga';
import { doctorInfoSaga } from "./sagas/doctorInfoSaga";
import { laboratoriesSaga } from "./sagas/laboratoriesSaga";
import { kiLabSaga } from './sagas/kiLabSaga';
//Reducers
import appointmentsReducer from './reducers/appointmentsReducer';
import avatarsReducer from './reducers/avatarsReducer';
import categoryMenuReducer from './reducers/categoryMenuReducer';
import chatsReducer from './reducers/chatsReducer';
import commonReducer from './reducers/commonReducer';
import helpsReducer from './reducers/helpsReducer';
import loadingReducer from './reducers/loadingReducer';
import laboratoryReducer from './reducers/laboratoryReducer';
import messagesReducer from './reducers/messagesReducer';
import notificationsReducer from './reducers/notificationsReducer';
import patientsReducer from './reducers/patientsReducer';
import practicesReducer from './reducers/practicesReducer';
import responsesReducer from './reducers/responsesReducer';
import userAuthorizationReducer from './reducers/userAuthorizationReducer';
import userDataReducer from './reducers/userDataReducer';
import userReducer from './reducers/userReducer';
import websocketReducer from './reducers/websocketReducer';
import kiLabReducer from "./reducers/kiLabReducer";
import { compose } from 'redux';

//Persist part of the store
const baseConfig = {
  storage,
  stateReconciler: autoMergeLevel2
};

const allReducers = combineReducers({
  appointmentsReducer,
  avatarsReducer,
  categoryMenuReducer: persistReducer({ ...baseConfig, key: 'categoryMenu' }, categoryMenuReducer),
  chatsReducer,
  commonReducer,
  helpsReducer,
  loadingReducer,
  messagesReducer,
  notificationsReducer,
  patientsReducer,
  laboratoryReducer,
  practicesReducer: persistReducer({ ...baseConfig, key: 'practices' }, practicesReducer),
  responsesReducer,
  userAuthorizationReducer,
  userDataReducer,
  userReducer,
  websocketReducer,
  kiLabReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }
  return allReducers(state, action);
};

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

export const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(sagaMiddleware))
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

sagaMiddleware.run(appointmentsSaga);
sagaMiddleware.run(chatsSaga);
sagaMiddleware.run(commonSaga);
sagaMiddleware.run(helpsSaga);
sagaMiddleware.run(messagesSaga);
sagaMiddleware.run(notificationsSaga);
sagaMiddleware.run(practicesSaga);
sagaMiddleware.run(profileSaga);
sagaMiddleware.run(userDataSaga);
sagaMiddleware.run(usersSaga);
sagaMiddleware.run(wsSaga);
sagaMiddleware.run(patientsSaga);
sagaMiddleware.run(doctorInfoSaga);
sagaMiddleware.run(laboratoriesSaga);
sagaMiddleware.run(kiLabSaga);

export const persistor = persistStore(store);
