import { takeEvery, put, call } from "redux-saga/effects";
import CONSTANTS from "../CONSTANTS";
//Actions
import {
  actionSetDoctorInfo,
} from "../actions/userDataActions";
//Services
import DoctorsService from "services/DoctorsService";

function* sagaDoctorInfo() {
  try {
    const result = yield call(DoctorsService.getDoctorInfo);

    if (result.status >= 400) {
      throw new Error("Error retrieving patients list");
    }
    yield put(actionSetDoctorInfo(result.data));
  } catch (error) {
    console.log(error);
  }
}

export function* doctorInfoSaga() {
  console.log("*Main DoctorInfo Saga");
  yield takeEvery(CONSTANTS.DOCTOR_GET_INFO, sagaDoctorInfo);
}
