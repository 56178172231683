import React, { useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Row, Col, Label } from 'reactstrap';

// Include Translation tool
import { useTranslate } from "react-translate";

// Include a particular scss for this view
import 'assets/scss/custom/_kilab_list.scss';
import { useSelector, useDispatch } from 'react-redux';
import { actionSetContext, actionSendContext, actionGenerateAIComment } from 'store/actions/kiLabActions';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';

const KiLabEditor_data = ({id_lab_result}) => {

    const dispatch = useDispatch();
    const tEditor = useTranslate('kilab-editor');
    const study = useSelector(state => state.kiLabReducer.study);
    const isLoadingAI = useSelector((state) => state.kiLabReducer.loadingAiReview);
    const context = useSelector(state => state.kiLabReducer.context);
    const loaded = useRef(false);

    const [bornYear, setBornYear] = useState(context.birthYear);
    const [gender, setGender] = useState(context.gender);
    const [hormonYear, setHormonYear] = useState(context.hormoneYear);
    const [hormonStatus, setHormonStatus] = useState(context.hormoneStatus);
    const [additional, setAdditional] = useState(context.additionalInfo);
    const [edited, setEdited] = useState(false);

    useEffect(() => {
        if (Object.keys(study).length && loaded.current) {
            setEdited(false);
        }
    }, [study]);

    useEffect(() => {
        setBornYear(context.birthYear);
        setGender(context.gender);
        setHormonYear(context.hormoneYear);
        setHormonStatus(context.hormoneStatus);
        setAdditional(context.additionalInfo);
        loaded.current = true;
    }, [context]);
    
    useEffect(() => {
        if (Object.keys(study).length && loaded.current) {
            setEdited(true);
            dispatch(actionSetContext({
                gender: gender,
                birthYear: bornYear,
                hormoneYear: hormonYear,
                hormoneStatus: hormonStatus,
                additionalInfo: additional
            }));

            dispatch(actionSendContext(study._id, {
                gender: gender,
                birthYear: bornYear,
                hormoneYear: hormonYear,
                hormoneStatus: hormonStatus,
                additionalInfo: additional
            }))
        }
    }, [bornYear, gender, hormonYear, hormonStatus, additional]);

    //Hardcoded values
    const femaleHormonStatus = [
        { state:"Nichts zutreffend", value: "Nothing Applicable"},
        { state:"Schwangerschaft (Monat 1-3)", value: "pregnancy (months 1-3)"},
        { state:"Schwangerschaft (Monat 4-6)", value: "pregnancy (months 4-6)"},
        { state:"Schwangerschaft (Monat 7-9)", value: "pregnancy (months 7-9)"},
        { state:"Menarche", value: "menarche"},
        { state:"Prämenopause", value: "premenopause"},
        { state:"Perimenopause", value: "perimenopause"},
        { state:"Menopause", value: "Menopause"},
        { state:"Postmenopause", value: "Postmenopause"},
    ];

    const maleHormonStatus = [
        { state:"Blutentnahme bis 12:00 Uhr", value: "Blood sampling until 12:00"},
        { state:"Blutentnahme nach 12:00 Uhr", value: "Blood sampling after 12:00"},
        { state:"Vorpubertät", value: "prepuberty"},
        { state:"Postpubertät", value: "post-puberty"},
        { state:"Andropause", value: "andropause"},
    ];

    const handleGenerateClick = () => {
        dispatch(actionGenerateAIComment(study._id));
    }
    
    // Genera un array de años desde 1920 hasta el año vigente
    const years = [];
    const currentYear = new Date().getFullYear();

    for (let year = currentYear; year >= 1920; year--) {
        years.push(String(year));
    }

    if (!Object.keys(study).length || !study.metadata) {
        return <></>;
    }
    
    return (
            <Row>
                {/* Left side - Details in a table with custom border style */}
                <Col md={6}>
                    <Table borderless className="details-table">
                        <tbody>
                            <tr className="table-row">
                                <td><Label className="title">ID</Label></td>
                                <td><Label className="title">{tEditor("receipt-date")}</Label></td>
                                <td><Label className="title">{tEditor("born-date")}</Label></td>
                            </tr>
                            <tr className="table-row-space">
                                <td><p>{study.metadata.analysis_id.ext_ref}</p></td>
                                <td><p>22-08-2024</p></td>
                                <td>
                                    <Input type="select" disabled={isLoadingAI} name="bornYear" onChange={e => {setBornYear(e.target.value)}}>
                                        <option key="" value="">{tEditor("choose-year")}</option>
                                        {years.map((year) => (
                                            <option key={year} value={year} selected={bornYear === year}>
                                                {year}
                                            </option>
                                        ))}
                                    </Input>
                                </td>
                            </tr>
                            <tr className="table-row">
                                <td><Label className="title">{tEditor("gender")}</Label></td>
                                <td><Label className="title">{tEditor("hormone-status")}</Label></td>
                                <td><Label className="title">{tEditor("year")}</Label></td>
                            </tr>
                            <tr className="table-row-space">
                                <td>
                                    <Input type="select" disabled={isLoadingAI} onChange={e => {
                                        setHormonStatus("");
                                        setHormonYear("");
                                            setGender(e.target.value);
                                        }}>
                                        <option value="">Geschlecht</option>
                                        <option value="Male" selected={gender === "Male"}>Männlich</option>
                                        <option value="Female" selected={gender === "Female"}>Weiblich</option>
                                    </Input>
                                </td>
                                <td>
                                    <Input type="select" disabled={isLoadingAI} onChange={e => {setHormonStatus(e.target.value)}}>
                                        <option value="">-</option>
                                        {gender === "Female" ? femaleHormonStatus.map((o, i) => (
                                            <option 
                                                value={o.value} 
                                                key={`female-hormon-${i+1}`} 
                                                selected={hormonStatus === o.value}
                                            >
                                                {o.state}
                                            </option>
                                        )) : gender === "Male" ? maleHormonStatus.map((o,i) => (
                                            <option 
                                                value={o.value} 
                                                key={`male-hormon-${i+1}`} 
                                                selected={hormonStatus === o.value}>
                                                {o.state}
                                            </option>
                                        )) : (<></>)}
                                    </Input>
                                </td>
                                <td>
                                    <Input type="select" disabled={isLoadingAI} name="hormonYear" onChange={e => {setHormonYear(e.target.value)}}>
                                        <option key="" value="">{tEditor("choose-year")}</option>
                                        {years.map((year) => (
                                            <option key={year} value={year} selected={hormonYear === year}>{year}</option>
                                        ))}
                                    </Input>
                                </td>
                            </tr>
                            <tr className="table-row">
                                <td><Label className="title">{tEditor("practice")}</Label></td>
                                <td><Label className="title">{tEditor("lab-doctor")}</Label></td>
                                <td><Label className="title">{tEditor("status")}</Label></td>
                            </tr>
                            <tr className="table-row-space">
                                <td>
                                    <p>{study.metadata.lab_doctor.doctor_name}<br/><b>Praxis {study.practice_info.name}</b></p>
                                </td>
                                <td>
                                    <p>{study.metadata.lab_doctor.doctor_name}</p>
                                </td>
                                <td>
                                    <p>In Bearbeitung</p>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>

                {/* Right side - Additional Information */}
                <Col md={6} className="freeTextContentainer" >
                    <div>
                        <p className="freeTextTitle">
                            <b>{tEditor("extra-info")}</b>
                        </p>
                    </div>
                    <Input
                        type="textarea"
                        name="freeTextInput"
                        value={additional}
                        disabled={isLoadingAI}
                        onChange={e => {setAdditional(e.target.value)}}
                        placeholder={`Freitext hier eingeben, z.B.:\n- Relevante Medikamente\n- Begleiterkrankungen\n- Körpergröße\n- Gewicht`}
                    />

                    <Button 
                        className="mt-3 btn-success" 
                        disabled={isLoadingAI || !edited} 
                        onClick={handleGenerateClick}
                    >
                        {tEditor('btn-ai-analyze')}
                    </Button>
                </Col>

                {isLoadingAI && (<LoadingLocal/>)}
            </Row>
    );
}
export default KiLabEditor_data;