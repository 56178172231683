import React from 'react';
import PropTypes from 'prop-types';

const Icon = (props) => {
  return (
    <img
      className={'tp-icon ' + props.className ? props.className : ''}
      src={'/General/icons/' + props.file}
      width={props.width !== undefined ? props.width : '30px'}
      title={props.title}
      alt=""
    />
  );
};

Icon.propTypes = {
  file: PropTypes.string,
};

export default React.memo(Icon);
