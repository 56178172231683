import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { actionChatShowInbox, actionSetChat } from 'store/actions/chatsActions';
import ChatRoomAvatar from 'components/Chats/ChatRoomAvatar';
import useChatUtils from 'hooks/useChatUtils';
import useChatIsAdmin from 'hooks/useChatIsAdmin';
import { useTranslate } from 'react-translate';
import { Badge } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router';

const ChatHeaderMobile = ({ setEditChatMembers, editChatMembers, filter_select }) => {
  const history = useHistory();
  const location = useLocation();
  const t = useTranslate('chat');
  const dispatch = useDispatch();
  const chat = useSelector((state) => state.chatsReducer.chat);
  const { mobile } = useSelector((state) => state.commonReducer);
  const { isActive } = useChatUtils();
  const isAdmin = useChatIsAdmin();

  return (
    <div className="d-flex align-items-center chat-header-container">
      <div className="p-2 chat_img_inbox">
        <ChatRoomAvatar type={chat?.type} main_user={chat?.main_user ? chat?.main_user : chat} filter_select={filter_select} />
      </div>
      <div className="p-2 d-sm-flex ml-2 flex-column">
        <strong>
          {typeof chat.main_user !== 'undefined' &&
          typeof chat.main_user.title !== 'undefined'
            ? chat.main_user.title + ' '
            : chat.title ? chat.title + ' ' : ''}
          {['private', 'group'].includes(chat.type) 
          ? chat.name 
          : `${chat.firstName} ${chat.lastName}`}
        </strong>
        {!isActive(chat) ? (
          <Badge color="dark">{t('user-inactive')}</Badge>
        ) : (
          ''
        )}
      </div>

      {chat.type === 'group' && isAdmin && (
        <span
          className={`icon-edit-group icon-group`}
          onClick={() => {
            if (!isAdmin) {
              return;
            }
            if (chat && chat.type === 'group') {
              setEditChatMembers(!editChatMembers);
              dispatch(actionChatShowInbox(false));
            } else {
              toast.warning( t("invalid-option") ); //'Invalid option for this chat');
            }
          }}
          title={t('edit-group-tooltip')}
        ></span>
      )}

      <div className="ml-auto p-2">
        <Button
          className="btn-back"
          color="info"
          onClick={() => {
            if (mobile && location.pathname.includes("/chat")) {
              history.replace(`/admin/chats`);
            }
            dispatch(actionSetChat({}));
            dispatch(actionChatShowInbox(false));
          }}
        >
          <i className="fas fa-arrow-left"></i>
        </Button>
      </div>
    </div>
  );
};

export default ChatHeaderMobile;
