//Login with  atuhenticator..for example Google Auth

import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
//import { useFileUtils } from 'hooks';
import { useDispatch, useSelector } from "react-redux";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Col,
  FormGroup,
  Input,
} from "reactstrap";

// core components
import bgImage from "assets/img/bg13.jpg";
import { actionCheck2fa } from "store/actions/usersActions";
//hooks
import useInitialUrl from 'hooks/useInitialUrl';

const LockScreenPage = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);
  const urlDefault = useInitialUrl();

  /*
  const inputAvatar = document.getElementById("avatar-lock");
  const {
    getAvatar,
    defaultImage,
    setFile
  } = useFileUtils(inputAvatar);
  */

  // eslint-disable-next-line
  const [code, setCode] = useState(null);
  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setCode(value);
    if (value.length === 6) {
      dispatch(actionCheck2fa(value));
    }
  };

  /*
  useEffect(() => {
    if (user.auth_user_id) {
      const avatarResponse = getAvatar(user.auth_user_id);
      avatarResponse.then((avatar) => {
        if (avatar) {
          setFile({ file: avatar });
        }
      });
    }
  }, [user.auth_user_id, setFile]);
  */

  const isAuth = () => {
    if (user.isAuth && user.auth_2fa === true) {
      props.history.push(urlDefault);
    }
  };

  useEffect(() => {
    document.body.classList.add("lock-page");
    isAuth();
    return () => {
      document.body.classList.remove("lock-page");
    };
    // eslint-disable-next-line
  }, []);

  if (!user.auth_2fa) {
    return <Redirect to="/auth" />;
  } else if (user.auth_2fa === "true") {
    return <Redirect to="/admin" />;
  }

  return (
    <>
      <div className="content">
        <div className="login-page">
          <Container>
            <Col lg={4} md={8} xs={12} className="mr-auto ml-auto">
              <Card className="card-lock text-center">
                <CardHeader>
                  {/*<img src={defaultImage} alt="avatar-img" id="avatar-lock" />*/}
                </CardHeader>
                <CardBody>
                  <CardTitle tag="h4"><strong>{`${user.firstName} ${user.lastName}`}</strong></CardTitle>
                  <FormGroup>
                    <Input
                      className="text-center"
                      type="number"
                      placeholder="Enter code 2fa..."
                      onChange={handleChange}
                    />
                  </FormGroup>
                </CardBody>
                {/* <CardFooter>
                  <Button color="primary" size="lg" className="btn-round">
                    Unlock
                  </Button>
                </CardFooter> */}
              </Card>
            </Col>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{
          backgroundImage: "url(" + bgImage + ")",
        }}
      />
    </>
  );
};

export default LockScreenPage;
