import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Label,
  Row,
  Input,
} from 'reactstrap';
//actions
import {
  actionGetPractice,
  actionSendInvitation,
} from 'store/actions/practicesActions';
import { useTranslate } from 'react-translate';

const InvitationByEmail = () => {
  const t = useTranslate('practices');
  const emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const invitationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(emailRex, t('error-email-invalid'))
      .required(t('error-email-required')),
    custom_role_id: yup.string().required(t('error-custom-role-required')),
    text: yup.string().required(t('error-text-required')),
  });

  const { handleSubmit, errors, register } = useForm({
    validationSchema: invitationSchema,
    mode: 'onChange',
  });

  const dispatch = useDispatch();
  const practice = useSelector((state) => state.practicesReducer.practice_manage_select);

  const onSubmit = (data) => {
    const role = practice.custom_role.find(
      (r) => r._id === data.custom_role_id
    );
    dispatch(actionSendInvitation({ ...data, role_name: role.name }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h5 className="general-card-subtitle">
        {t('subtitle-invite-user-email')}
      </h5>
      <Card className="border-0 shadow-none">
        <CardBody>
          <Row>
            <Col md={6}>
              <FormGroup
                className={`has-label ${errors.email ? 'has-danger' : ''}`}
              >
                <Label for="email">{t('field-send-email')}</Label>
                <Input
                  innerRef={register}
                  type="email"
                  name="email"
                  id="email"
                  required
                  defaultValue=""
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                className={`has-label ${
                  errors.custom_role_id ? 'has-danger' : ''
                }`}
              >
                <Label for="role">{t('form-select-role')}</Label>
                <Input
                  type="select"
                  name="custom_role_id"
                  id="custom-role"
                  innerRef={register}
                >
                  {practice.custom_role?.length &&
                    practice.custom_role.map((role) => (
                      <option value={role._id} key={role._id}>
                        {role.name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup
                className={`has-label ${errors.text ? 'has-danger' : ''}`}
              >
                <Input
                  innerRef={register}
                  type="textarea"
                  name="text"
                  id="send-email-text"
                  required
                  defaultValue=""
                  placeholder={t('form-placeholder-text-invitation')}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="d-flex">
          <div className="ml-auto">
            <Button color="info" type="submit">
              {t('btn-send')}
            </Button>
            <NavLink
              to="/admin/practice/users/invitations"
              className="btn btn-primary ml-1"
            >
              {t('btn-cancel')}
            </NavLink>
          </div>
        </CardFooter>
      </Card>
    </form>
  );
};

export default InvitationByEmail;
