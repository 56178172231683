import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
} from 'reactstrap';
import LogoUpload from './LogoUpload';
import { actionSavePractice } from 'store/actions/practicesActions';
import { useTranslate } from 'react-translate';

const PracticeForm = ({ practice }) => {
  const t = useTranslate('practices');
  const dispatch = useDispatch();
  const id = practice ? practice._id : null;

  const { handleSubmit, reset, control, register } = useForm();
  const defaultValues = practice ? practice : {};
  const onSubmit = (data) => {
    dispatch(actionSavePractice(data));
  };

  useEffect(() => {
    if (id) reset(defaultValues);
    // eslint-disable-next-line
  }, [reset, id]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {practice && practice._id && (
        <Controller
          as={<Input />}
          control={control}
          defaultValue=""
          required
          type="hidden"
          name="_id"
          id="id"
        />
      )}
      <Row>
        <Col md="3">
          <FormGroup>
            <Label for="name" className="label-required">{t('praxis-name')}</Label>
            <Controller
              as={<Input />}
              control={control}
              defaultValue=""
              className="form-control"
              required
              type="text"
              name="name"
              id="name"
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label for="street" className="label-required">{t('praxis-street')}</Label>
            <Controller
              as={<Input />}
              control={control}
              defaultValue=""
              className="form-control"
              required
              type="text"
              name="street"
              id="street"
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label for="emailAccounting" className="label-required">{t('praxis-email')}</Label>
            <Controller
              as={<Input />}
              control={control}
              defaultValue=""
              className="form-control"
              required
              type="email"
              name="email_accounting"
              id="emailAccounting"
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label for="emailPatients">{t('praxis-email-patients')}</Label>
            <Controller
              as={<Input />}
              control={control}
              defaultValue=""
              className="form-control"
              
              type="email"
              name="email_patients"
              id="emailPatients"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="contactPerson" className="label-required">{t('praxis-contact')}</Label>
                <Controller
                  as={<Input />}
                  control={control}
                  defaultValue=""
                  className="form-control"
                  required
                  type="text"
                  name="contact_person"
                  id="contactPerson"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="appartment">{t('praxis-appartment')}</Label>
                <Controller
                  as={<Input />}
                  control={control}
                  defaultValue=""
                  className="form-control"
                  
                  type="text"
                  name="appartment"
                  id="appartment"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="exampleEmail" className="label-required">{t('praxis-phone')}</Label>
                <Controller
                  as={<Input />}
                  control={control}
                  defaultValue=""
                  className="form-control"
                  required
                  type="text"
                  name="telephone"
                  id="telephone"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="city" className="label-required">{t('praxis-city')}</Label>
                <Controller
                  as={<Input />}
                  control={control}
                  defaultValue=""
                  className="form-control"
                  required
                  type="text"
                  name="city"
                  id="city"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="postalCode" className="label-required">{t('praxis-posta-code')}</Label>
                <Controller
                  as={<Input />}
                  control={control}
                  defaultValue=""
                  className="form-control"
                  required
                  type="text"
                  name="postal_code"
                  id="postalCode"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="country">{t('praxis-country')}</Label>
                <Controller
                  as={<Input />}
                  control={control}
                  defaultValue=""
                  className="form-control"
                  
                  type="text"
                  name="country"
                  id="country"
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <LogoUpload
            register={register}
            fileId={practice._id ? practice._id : null}
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-right">
          <Button type="submit" size="sm" color="info">
            {practice._id ? t('btn-update') : t('btn-send-request')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default PracticeForm;
