import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import InvitationMyList from 'components/Practice/Invitations/InvitationMyList';
import { useTranslate } from 'react-translate';

const PracticeUserList = () => {
  const t = useTranslate('practices');
  return (
    <Card className="profile">
      <CardBody>
        <Card className="mb-0">
          <CardHeader>
            <CardTitle className="d-flex align-content-center">
              <div className="general-card-title-img text-primary mr-2">
                <i className="icon-inbox title" />
              </div>
              <div className="general-card-title text-primary">
                {t('title-my-invitations')}
              </div>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <InvitationMyList />
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
};

export default PracticeUserList;
