import React from 'react';
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { useTranslate } from 'react-translate';
import InvitationsForm from 'components/MedicalRep/InvitationsForm';
import moment from 'moment';
import { useSelector } from 'react-redux';

const InvitationsFormView = () => {
  const t = useTranslate('medical-rep');
  const user = useSelector(state => state.userReducer);
  return (
    <>
      <Card className="profile">
        <CardBody>
          <Card className="mb-0">
            <CardHeader>
              <CardTitle className="d-flex align-content-center">
                <div className="general-card-title-img text-primary mr-2">
                  <i className="icon-user-active" />
                </div>
                <div className="general-card-title text-primary">
                  {t('title')}
                </div>
              </CardTitle>
            </CardHeader>

            <CardBody>
              <h5 className="general-card-subtitle">{t('subtitle')}</h5>
              <InvitationsForm />

              <table border="0" className="metadata-table">
                <thead>
                    <tr>
                        <th colSpan={2}>
                            <b>Versanddatum der Einladung:</b> {moment(Date.now()).format(user.dateFormat)}
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr>    
                        <td><b>Einladung per:</b> mail@mail.com </td>
                    </tr>
                    <tr>    
                        <td><b>Status:</b> <span className="badge badge-success"> EINGETRAGEN </span> </td>
                    </tr>
                </tbody>
            </table>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </>
  );
};

export default InvitationsFormView;
