
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-translate';
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from 'reactstrap';
// core components
import { actionRecoverPassword } from 'store/actions/usersActions';

const PasswordRecovery = () => {
  const t = useTranslate('password-recovery');
  const dispatch = useDispatch();

  const [emailFocus, setEmailFocus] = useState(false);
  const [recoveryForm, setrecoveryForm] = useState({
    email: '',
    emailState: '',
  });

  const emailValidation = (e) => {
    let recovery = {
      email: null,
      emailState: null,
    };

    recovery.email = e.target.value.toLowerCase();
    if (e.target.value.length > 4) {
      recovery.emailState = 'has-success';
    } else {
      recovery.emailState = 'has-danger';
    }
    setrecoveryForm({ ...recoveryForm, ...recovery });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (recoveryForm.emailState === 'has-success') {
      await dispatch(
        actionRecoverPassword({
          email: recoveryForm.email,
          type: 'doctor',
        })
      );
    }
    //else
  };

  return (
    <Fragment>
      <div className="content">
        <picture>
          <source
            srcSet="/General/login/ilustracion.svg"
            media="(min-width: 720px)"
          />
          <img
            src="/General/login/illustration-mobile.svg"
            width="100%"
            alt="TelePraxen"
            className="mt-5"
          />
        </picture>

        {/* Keep the style from login page */}
        <div className="login-page">
          <Container>
            <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
              <Form onSubmit={handleSubmit}>
                <Card className="card-plain">
                  <CardHeader>
                    <img src={'/General/login/logo.svg'} width="100%" alt="" />
                    <CardTitle className="text-info">{t('title')}</CardTitle>
                  </CardHeader>

                  <CardBody>
                    <p>{t('description')}</p>
                    <InputGroup
                      className={
                        (emailFocus ? ' input-group-focus ' : '') +
                        recoveryForm.emailState
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <img
                            src={'/General/login/user.svg'}
                            width="15"
                            alt=""
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder={`${t('email-placeholder')}...`}
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        onChange={emailValidation}
                        name="forgot_email"
                      />
                    </InputGroup>
                  </CardBody>

                  <CardFooter>
                    <Button
                      block
                      type="submit"
                      color="info"
                      size="sm"
                      className="mb-3 mt-3"
                      name="forgot_buttton"
                    >
                      {t('send-btn')}
                    </Button>
                    <div className="pull-left">
                      <Link to="/auth/login-page" className="link footer-link">
                        {t('back')}
                      </Link>
                    </div>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default PasswordRecovery;
