import React from 'react';
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { useTranslate } from 'react-translate';
import SessionList from 'components/Users/SessionList';

const SessionManager = () => {
  const t = useTranslate('settings');
  return (
    <>
      {/*<PanelHeader size="sm" /> */}
      <Card className="profile">
        <CardBody>
          <Card className="mb-0">
            <CardHeader>
              <CardTitle className="d-flex align-content-center">
                <div className="general-card-title text-primary">
                  {t('session-title')}
                </div>
              </CardTitle>
            </CardHeader>

            <CardBody>
              <SessionList />
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </>
  );
};

export default SessionManager;
