import React from 'react';
import { useSelector } from 'react-redux';
import { CardTitle } from 'reactstrap';

const PracticeTitle = (props) => {
  const practiceName = useSelector(
    (state) => state.practicesReducer.practice_manage_select?.name
  );
  const title = props.title != '' ? props.title : practiceName;
  return (
    <>
      <div className="general-card-title-img text-primary mr-2">
        <i className="icon-practice-settings title" />
      </div>
      <div className="general-card-title text-primary">{title}</div>
    </>
  );
};

export default PracticeTitle;
