import React, { useState } from 'react';

import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from 'reactstrap';
import Icon from 'components/Icons/Icon';
import useHelpKey from '../../../hooks/useHelpKey';
import { useTranslate } from 'react-translate';

const HelpMenu = React.memo(({ activeRoute }) => {
  const [dropdownOpenHelp, setdropdownOpenHelp] = useState(false);
  const dropdownToggleHelp = () => {
    setdropdownOpenHelp(!dropdownOpenHelp);
  };
//obtain data Help sending current route of page
  const { title, key, text } = useHelpKey(activeRoute);
  const t = useTranslate('menu');

  return (
    <>
      <Dropdown
        nav
        isOpen={dropdownOpenHelp}
        toggle={dropdownToggleHelp}
        className="help-menu"
      >
        <DropdownToggle caret nav>
          <Icon file="help.svg" width="21px" title="Practices" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem className="help-info">
            <Card>
              <CardHeader>
                <CardTitle className="d-flex text-primary">
                  <div className="text-title">{t('help')}</div>
                  <div className="icon-times text-dark ml-auto">
                    <i className="fas fa-times"></i>
                  </div>
                </CardTitle>
                <div className="text-subtitle text-capitalize"></div>
              </CardHeader>
              <CardBody>
                <div className="text-help">
                  {/* <h6>{title}</h6> */}
                  {/* <h6 dangerouslySetInnerHTML={{__html: title}} />  */}
                  <div className="help_content" dangerouslySetInnerHTML={{__html: text}} /> 
                  {/* <p>{text}</p> */}
                </div>
              </CardBody>
            </Card>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
});

export default HelpMenu;
