// this page allow to view statics information about practice wich is owner and  enable option for
// delete, change role and invite users to the practice

import React, { Suspense, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

// reactstrap components
import { Card, CardHeader, CardBody } from 'reactstrap';

// core components
import { selectPractice } from 'store/selectors/selectors';
import { useTranslate } from 'react-translate';
import { actionSetCategoryMenu } from 'store/actions/categoryMenuActions';
import PracticeMetricsChats from '../../components/Practice/PracticeMetricsChats';
import CardTitle from 'reactstrap/lib/CardTitle';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import Button from 'reactstrap/lib/Button';
// const PractitionersMetricsChats = React.lazy(() =>
//   import('components/Practitioners/PractitionersMetricsChats')
// );
const PractitionersMetricsTable = React.lazy(() =>
  import('components/Practitioners/PractitionersMetricsTable')
);

const PracticeMetrics = () => {
  const dispatch = useDispatch();
  const [showOption, setShowOption] = useState('practices');
  const t = useTranslate('practices');
  const { id } = useParams();
  const practice = useSelector((state) => selectPractice(state, id));

  useEffect(() => {
    const container = document.querySelector('.main-panel');
    container.classList.add('overflow-hidden');
    dispatch(actionSetCategoryMenu(id ? 'practice' : 'default'));
    return () => {
      container.classList.remove('overflow-hidden');
    };
  }, [id, dispatch]);

  return (
    <>
      {/*<PanelHeader size="sm" /> */}
      <Card className="practice-metrics">
        <CardBody>
          <Card className="mb-0">
            <CardHeader>
              <CardTitle className="title-practice-button-options">
                <div className="d-flex align-content-center">
                  <div className="general-card-title-img text-primary mr-2">
                    <i className="icon-practice-settings title" />
                  </div>
                  <div className="general-card-title text-primary">
                    {t('title-practice-metrics')}
                    <div className="title-practice-subtitle-divisor">
                      |
                    </div>
                    <div className="title-practice-subtitle">
                      {practice.name}
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  {showOption === 'practices' && (
                    <Button
                      color="info"
                      size="sm"
                      onClick={() => setShowOption('practitioners')}
                      className="practice-header-btn"
                    >
                      {t('btn-show-practitioners-metrics')}
                    </Button>
                  )}
                  {showOption === 'practitioners' && (
                    <Button
                      color="info"
                      size="sm"
                      onClick={() => setShowOption('practices')}
                      className="practice-header-btn"
                    >
                      {t('btn-show-practices-metrics')}
                    </Button>
                  )}
                </div>

              </CardTitle>
            </CardHeader>
            <CardBody className="overflow-auto">
              {showOption === 'practices' && <PracticeMetricsChats />}
              <Suspense fallback={<LoadingLocal />}>
                {showOption === 'practitioners' && (
                  <div className="table-responsive">
                    <PractitionersMetricsTable />
                  </div>
                )}
              </Suspense>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </>
  );
};

export default PracticeMetrics;
