import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Media,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
//import PracticeItem from "./LogoUpload";
import Icon from "components/Icons/Icon";
import { useTranslate } from 'react-translate';
//import ImageLogoUpload from "/General/practice-management/photo-upload.svg";

const RoleDefinition = () => {
  const t = useTranslate('practice');
  return (
    <Card className="border-0 shadow-none">
      <CardHeader className="">
        <div className="practice-title">{t('role-definition')}</div>
      </CardHeader>
      <CardBody className="">
        <Media>
          <Media left href="#">
            {/*<Media object data-src="holder.js/64x64" alt="Generic placeholder image" />*/}
            <Icon file="photo-upload.svg" width="150px" className="mr-4" />
          </Media>
          <Media body>
            <FormGroup>
              <Label for="role">{t('role')}</Label>
              <Input type="select" name="role" id="role">
                <option>{t('owner')}</option>
                <option>{t('practice-administrator')}</option>
                <option>{t('physician-with-permission-modify')}</option>
                <option>{t('physician-without-permission-modify')}</option>
                <option>{t('receptionist')}</option>
              </Input>
            </FormGroup>
          </Media>
        </Media>
      </CardBody>
    </Card>
  );
};

export default RoleDefinition;
