import React from 'react';
import { Table, Input, Button, Row, Col, Label, TabPane } from 'reactstrap';

// Include Translation tool
import { useTranslate } from "react-translate";

const KiLabEditor_analytes = () => {

    const tEditor = useTranslate('kilab-editor');

    // Genera un array de años desde 1920 hasta el año vigente
    const years = [];
    const currentYear = new Date().getFullYear();

    for (let year = 1920; year <= currentYear; year++) {
        years.push(year);
    }
    
    // Data array for table rows
    const labResults = [
        { untersuchung: "ASAT (GOT) i.S. (enz.)", ergebnis: 25, einheit: "U/l", referenzbereich: "< 50", kommentar: "", highlight: false },
        { untersuchung: "ALAT (GPT) i.S. (enz.)", ergebnis: 31, einheit: "U/l", referenzbereich: "< 50", comentario: "", highlight: false },
        { untersuchung: "GGT i.S. (enz.)", ergebnis: 23, einheit: "U/l", referenzbereich: "11 - 59", comentario: "", highlight: false },
        { untersuchung: "Cholinesterase i.S. (enz.)", ergebnis: 13324, einheit: "U/l", referenzbereich: "4389 - 10928", comentario: "", highlight: true },
        { untersuchung: "ALAT (GPT) i.S. (enz.)", ergebnis: 31, einheit: "U/l", referenzbereich: "< 50", comentario: "", highlight: false },
        { untersuchung: "GGT i.S. (enz.)", ergebnis: 23, einheit: "U/l", referenzbereich: "11 - 59", comentario: "", highlight: false },
        { untersuchung: "ALAT (GPT) i.S. (enz.)", ergebnis: 31, einheit: "U/l", referenzbereich: "< 50", comentario: "", highlight: false },
        { untersuchung: "GGT i.S. (enz.)", ergebnis: 23, einheit: "U/l", referenzbereich: "11 - 59", comentario: "", highlight: false },
        { untersuchung: "Cholinesterase i.S. (enz.)", ergebnis: 13324, einheit: "U/l", referenzbereich: "4389 - 10928", comentario: "", highlight: true },
        { untersuchung: "ALAT (GPT) i.S. (enz.)", ergebnis: 31, einheit: "U/l", referenzbereich: "< 50", comentario: "", highlight: false },
        { untersuchung: "GGT i.S. (enz.)", ergebnis: 23, einheit: "U/l", referenzbereich: "11 - 59", comentario: "", highlight: false },
        { untersuchung: "ALAT (GPT) i.S. (enz.)", ergebnis: 31, einheit: "U/l", referenzbereich: "< 50", comentario: "", highlight: false },
        { untersuchung: "ALAT (GPT) i.S. (enz.)", ergebnis: 31, einheit: "U/l", referenzbereich: "< 50", comentario: "", highlight: false },
        { untersuchung: "GGT i.S. (enz.)", ergebnis: 23, einheit: "U/l", referenzbereich: "11 - 59", comentario: "", highlight: false },
        { untersuchung: "ALAT (GPT) i.S. (enz.)", ergebnis: 31, einheit: "U/l", referenzbereich: "< 50", comentario: "", highlight: false },
        { untersuchung: "GGT i.S. (enz.)", ergebnis: 23, einheit: "U/l", referenzbereich: "11 - 59", comentario: "", highlight: false },
        { untersuchung: "ALAT (GPT) i.S. (enz.)", ergebnis: 31, einheit: "U/l", referenzbereich: "< 50", comentario: "", highlight: false },
        { untersuchung: "GGT i.S. (enz.)", ergebnis: 23, einheit: "U/l", referenzbereich: "11 - 59", comentario: "", highlight: false },
        { untersuchung: "ALAT (GPT) i.S. (enz.)", ergebnis: 31, einheit: "U/l", referenzbereich: "< 50", comentario: "", highlight: false },
        { untersuchung: "Cholinesterase i.S. (enz.)", ergebnis: 13324, einheit: "U/l", referenzbereich: "4389 - 10928", comentario: "", highlight: true },
        { untersuchung: "GGT i.S. (enz.)", ergebnis: 23, einheit: "U/l", referenzbereich: "11 - 59", comentario: "", highlight: false },
        { untersuchung: "ALAT (GPT) i.S. (enz.)", ergebnis: 31, einheit: "U/l", referenzbereich: "< 50", comentario: "", highlight: false },
        { untersuchung: "GGT i.S. (enz.)", ergebnis: 23, einheit: "U/l", referenzbereich: "11 - 59", comentario: "", highlight: false },
    ];
    

    return (

            <TabPane tabId="tab-result">
                <Table >
                    <thead>
                        <tr>
                            <th>{tEditor('examination')}</th>
                            <th>{tEditor('result')}</th>
                            <th>{tEditor('unit')}</th>
                            <th>{tEditor('ref-range')}</th>
                            <th>{tEditor('comment')}</th>
                        </tr>
                    </thead>
                    <tbody> 
                        {labResults.map((result, index) => (
                            <tr key={index} className={result.highlight ? "bg-analyte-out-of-range" : ""}>
                                <td>{result.untersuchung}</td>
                                <td>{result.ergebnis}</td>
                                <td>{result.einheit}</td>
                                <td>{result.referenzbereich}</td>
                                <td>{result.comentario}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </TabPane>
    );
}
export default KiLabEditor_analytes;