import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardBody, Row, Col, ListGroup } from 'reactstrap';
import PracticeItem from './PracticeItem';
import PracticeActiveModal from './PracticeDeleteModal';

const Practices = ({ title, type }) => {
  const group = 'my-practices-group';
  const { practices, practice } = useSelector(
    (state) => state.practicesReducer
  );
  const user = useSelector((state) => state.userReducer);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const handleActive = useCallback((e) => {
    const id = e.target.getAttribute('parent');
    let els = document.getElementsByClassName(group);
    for (let i = 0; i < els.length; i++) {
      els[i].classList.remove('active');
    }
    const el = document.getElementById(id);
    if (el) {
      el.classList.add('active');
    }
  }, []);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="p-0">
          <div className="practice-title">{title}</div>
        </CardHeader>
        <CardBody className="p-0">
          <Row>
            <Col sm="12">
              <ListGroup>
                {practices &&
                  practices.map((item) => {
                    if (item.invisible) {
                      return null;
                    }
                    const role_key =
                      user.practices[item._id] &&
                      user.practices[item._id].key;
                    if (type === 'owner' && role_key === 'owner') {
                      return (
                        <PracticeItem
                          item={item}
                          key={item._id}
                          id={'my-practice-item-' + item._id}
                          group={group}
                          className="my-practice-item"
                          handleActive={handleActive}
                          toggleModal={toggleModal}
                          type={type}
                          practiceActive={practice?._id}
                        />
                      );
                    }
                    if (type === 'collaborator' && role_key !== 'owner') {
                      return (
                        <PracticeItem
                          item={item}
                          key={item._id}
                          id={'collaborator-item-' + item._id}
                          group={group}
                          className="collaborator-item"
                          handleActive={handleActive}
                          toggleModal={toggleModal}
                          type={type}
                          practiceActive={practice?._id}
                        />
                      );
                    }
                    return null;
                  })}
              </ListGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <PracticeActiveModal modal={modal} toggleModal={toggleModal} />
    </React.Fragment>
  );
};

Practices.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['owner', 'collaborator']).isRequired,
};

export default Practices;
