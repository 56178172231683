import CONSTANTS from '../CONSTANTS';

export const actionPracticeMetrics = (keyQuestion, practiceId, doctorAuthId) => ({

  //Tell to redux that must execut an action of this type
  type: CONSTANTS.LAB_EARLY_GET_PRACTICE_METRICS,
  practiceId,
  keyQuestion,
  doctorAuthId
});

//Metodo para guardar la info en el store
export const actionStorePracticeMetricsReducer = (data) => ({

  //Tell to redux that must execut an action of this type
  type: CONSTANTS.LAB_EARLY_STORE_PRACTICE_METRICS_REDUCER,
  data
});

