import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-translate';
import { Input, FormGroup, Form, Label, Button, Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useHistory } from 'react-router';

const InvitationsForm = () => {
    const t = useTranslate('medical-rep');
    const history = useHistory();

    const emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const userSchema = yup.object().shape({
        firstName: yup.string().required(t('error-name-required')),
        lastName: yup.string().required(t('error-lastname-required')),
        location: yup.string(),
        position: yup.string(),
        title: yup.string(),
        email: yup
          .string()
          .matches(emailRex, t('error-email-invalid'))
          .required(t('error-email-required')),
        phone: yup.string(),
        gender: yup.string().required(t('error-gender-required')),
      });

    const onEmailChange = (e) => {
    e.target.value = e.target.value.toLowerCase();
    }

    const { register, handleSubmit, errors } = useForm({
        validationSchema: userSchema,
        mode: 'onChange',
      });

    const onSubmit = (data) => {
        console.log(data);
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col sm="12" md="6">
                    <Label>{t('pronoun')}</Label>
                    <FormGroup
                    className={`has-label ${errors.pronoun ? 'has-danger' : ''}`}
                    >
                    <Input
                        type="select"
                        name="pronoun"
                        id="pronoun"
                        innerRef={register}
                    >
                        <option></option>
                        <option>{t('mr')}</option>
                        <option>{t('mrs')}</option>
                    </Input>
                    </FormGroup>
                </Col>
                <Col sm="12" md="6">
                    <Label>{t('profile-title')}</Label>
                    <FormGroup
                    className={`has-label ${errors.title ? 'has-danger' : ''}`}
                    >
                    <Input type="text" innerRef={register} name="title" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="6">
                    <Label>{t('first-name')}</Label>
                    <FormGroup
                    className={`has-label ${errors.firstName ? 'has-danger' : ''}`}
                    >
                    <Input
                        innerRef={register}
                        name="firstName"
                        id="firstName"
                        type="text"
                    />
                    </FormGroup>
                </Col>
                <Col sm="12" md="6">
                    <Label>{t('last-name')}</Label>
                    <FormGroup
                    className={`has-label ${errors.lastName ? 'has-danger' : ''}`}
                    >
                    <Input
                        type="text"
                        innerRef={register}
                        name="lastName"
                        id="lastName"
                    />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col sm="12" md="6">
                    <Label>{t('location')}</Label>
                    <FormGroup
                    className={`has-label ${errors.location ? 'has-danger' : ''}`}
                    >
                    <Input type="text" innerRef={register} name="location" />
                    </FormGroup>
                </Col>
                <Col sm="12" md="6">
                    <Label>{t('position')}</Label>
                    <FormGroup
                    className={`has-label  ${errors.position ? 'has-danger' : ''}`}
                    >
                    <Input type="text" innerRef={register} name="position" />
                    </FormGroup>
                </Col>
                </Row>
                <Row>
                <Col sm="12" md="6">
                    <Label>{t('email')}</Label>
                    <FormGroup
                    className={`has-label ${errors.email ? 'has-danger' : ''}`}
                    >
                    <Input 
                    type="email" 
                    innerRef={register} 
                    name="email"
                    onChange={(e) => {
                        onEmailChange(e);
                    }}
                    />
                    </FormGroup>
                </Col>
                <Col sm="12" md="6">
                    <Label>{t('gender')}</Label>
                    <FormGroup>
                    <Input
                        type="select"
                        name="gender"
                        id="gender"
                        innerRef={register}
                    >
                        <option value="Male">{t('gender-male')}</option>
                        <option value="Female">{t('gender-female')}</option>
                        <option value="Other">{t('gender-other')}</option>
                    </Input>
                    </FormGroup>
                </Col>
                </Row>
                <Row>
                <Col sm="12" md="6">
                    <Label>{t('phone')}</Label>
                    <FormGroup
                    className={`has-label ${errors.phone ? 'has-danger' : ''}`}
                    >
                    <Input type="tel" innerRef={register} name="phone" />
                    </FormGroup>
                </Col>
            </Row>

            <Row>

                <Col sm="9" md="6" className="mt-3">
                    <div className="btn-medical-rep">
                        <Button
                            className="mr-auto"
                            color="primary"
                            type="submit"
                            size="sm"
                            name="btn-save"
                        >
                            {t('save-button')}
                        </Button>

                        <Label>&nbsp;</Label>
                        <Button
                            color="info"
                            type="submit"
                            size="sm"
                            name="btn-save"
                        >
                            {t('save-button')}
                        </Button>
                    </div>
                </Col>

                <Col sm="3" className="mt-3">
                    <Button
                        color="primary"
                        type="cancel"
                        size="sm"
                        name="btn-cancel"
                        onClick={(e) => {
                        e.preventDefault();
                        history.goBack();
                        }}
                    >
                        {t('cancel-button')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );

}

export default InvitationsForm;