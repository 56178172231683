import React, { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import AsyncSelect from 'react-select/async';
import PracticesService from 'services/PracticesService';
import { actionSetUsersList } from 'store/actions/userDataActions';
import { actionGetPatients } from 'store/actions/patientsActions';
import DoctorsService from 'services/DoctorsService';
import Icon from 'components/Icons/Icon';

const InboxAsyncRecipients = ({ register, control, focus }) => {
  const t = useTranslate('messages');
  const dispatch = useDispatch();
  const recipientsField = useRef();
  const practiceId = useSelector((state) => state.userAuthorizationReducer._id);
  const practitioners = useSelector((state) => state.practicesReducer.practice.people);
  const patients = useSelector(state => state.patientsReducer.patients);
  const { auth_user_id } = useSelector(state => state.userReducer);

  const customStyles = {
    control: (provided, state) => {
      const borderColor = state.isFocused ? '#fd7e14 !important' : '#e3e3e3';
      return {
        ...provided,
        backgroundColor: 'transparent',
        border: `1px solid ${borderColor}`,
        borderRadius: '30px',
        color: '#2c2c2c',
        lineHeight: 'normal',
        height: 'auto',
        fontSize: '0.8571em',
        transition:
          'color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out',
        boxShadow: 'none',
      };
    },
    indicatorsContainer: (provided) => {
      return {
        ...provided,
        display: 'none',
      };
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  const loadRecipients = async (query) => {
    if (query.replaceAll(" ", "").length < 2) {
      return new Promise(resolve => {
        resolve([]);
      });
    }
    try {
      //Load user to check permissions
      const practiceUser = practitioners.find(p => String(p.auth_user_id) === String(auth_user_id));

      const result = await PracticesService.getContacts(practiceId, query);
      const filteredPatients = await DoctorsService.searchPatients(query, practiceId);
      let list = [];

      if ((result.data && result.data.length > 0)) {
        result.data.map((user) => {
          //If user can reply to patients or the recipients are doctors
          if (practiceUser.patient_reply_permission || user.type === 'doctor') {
            list.push( {
              label: `${user.title ? `${user.title} ` : ""}${user.firstName} ${user.lastName}`,
              value: user.auth_user_id,
              __score: user.score,
              __type: user.type
            });
          }
        });
      }
      
      //Append patients
      if ( (filteredPatients.data && filteredPatients.data.length > 0) ) {
        filteredPatients.data.map(patient => {
          list.push({
            label: `${patient.firstName} ${patient.lastName}`,
            value: patient.auth_user_id,
            __score: patient.score,
            __type: "patient"
          })
        });
      }

      if (!list.length) {
        throw new Error("No options");
      }

      list = list.sort((a,b) => {
        return b.__score - a.__score;
      })
      
      return new Promise((resolve) => {
        dispatch(actionSetUsersList([...practitioners, ...patients]));
        resolve(list);
      });
    } catch (error) {
      return new Promise((resolve) => {
        dispatch(actionSetUsersList([]));
        resolve([]);
      });
    }
  };

  useEffect(() => {
    dispatch(actionGetPatients(practiceId));
    if (focus) {
      recipientsField.current.focus();
    }
    //eslint-disable-next-line
  }, []);

  //Custom format for the options
  const formatOptionLabel = (props) => {
    const { label, __type } = props;
    return (
    <div className='d-flex'>
      <div className='mr-2'>
        <Icon file={`${__type === "doctor" ? "hospital" : "user-header"}.svg`} width="21px" title="Practices" />
      </div>
      <div className="self-center">{label}</div>
    </div>
    )
  };

  return (
    <>
      <Controller
        as={
          <AsyncSelect
            isMulti
            cacheOptions
            loadOptions={loadRecipients}
            styles={customStyles}
            isLoading={true}
            noOptionsMessage={() => t('recipients-not-found')}
            ref={recipientsField}
            formatOptionLabel={formatOptionLabel}
          />
        }
        control={control}
        name="to"
        placeholder={`${t('recipients')}`}
      />
    </>
  );
};

export default InboxAsyncRecipients;
