import React from 'react';
import moment from 'moment';
import { useTranslate } from 'react-translate';

const MsgSystem = ({ message }) => {
  const t = useTranslate('chat');
  
  let body = message.body;
  body = body.replace('was added', t('was-added'));
  body = body.replace('was removed', t('was-removed'));

  return (
    <div className="system_msg mt-1" data-id={message._id}>
      <div className="sent_msg">
        <p>{body || ''}</p>
        <span className="time_date">
          {moment(message.date).format('H:mm')} |{' '}
          {moment(message.date).format('MMMM D')}
        </span>
      </div>
    </div>
  );
};

export default MsgSystem;
