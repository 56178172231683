import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BtnNew from './BtnNew';
import BtnReply from './BtnReply';
import BtnDelete from './BtnDelete';
import BtnForward from './BtnForward';
// import BtnBack from './BtnBack';

import { actionSetMessage } from 'store/actions/messagesActions';

const Actions = ({ setShowForm, setResend, setReply, mobile ,isDisaffected }) => {
  const dispatch = useDispatch();

  const { message, box } = useSelector((state) => state.messagesReducer);

  const handleNewMessage = () => {
    dispatch(actionSetMessage());
  };


  return (
    <div className={mobile ? 'btn-group-inbox' : 'text-right'}>
      {box === 'inbox' && (
        <BtnNew
          mobile={mobile}
          setShowForm={setShowForm}
          handleNewMessage={handleNewMessage}
          setReply={setReply}
          setResend={setResend}
          isDisaffected={isDisaffected}
        />
      )}

      {true ?
        <BtnReply 
          setResend={setResend} 
          setReply={setReply} 
          mobile={mobile} 
          setShowForm={setShowForm} 
          disabled={isDisaffected === true ? isDisaffected : !message} 
        />
      : null}

      {true ?
        <BtnForward 
          setResend={setResend} 
          setReply={setReply} 
          mobile={mobile} 
          setShowForm={setShowForm} 
          disabled={isDisaffected === true ? isDisaffected : !message} 
        />
      : null}

      <BtnDelete mobile={mobile} setShowForm={setShowForm} disabled={isDisaffected}/>

      {/* <BtnBack
        setShowForm={setShowForm}
        mobile={mobile}
        disabled={!message && setShowForm !== 'inbox-form'}
      /> */}
    </div>
  );
};

export default Actions;
