import React from 'react';
import { useDispatch } from 'react-redux';
import { actionSetPriorityMessages } from 'store/actions/messagesActions';

const BtnPriority = ({ message }) => {
  const dispatch = useDispatch();

  const handleBtnTogglePriority = () => {
    dispatch(actionSetPriorityMessages({ messages: [message._id] }));
  };

  return (
    <span
      className={`text-info icon-star${
        message.priority ? '-active' : ''
      } ml-2 mr-2 btn-priority`}
      onClick={handleBtnTogglePriority}
    ></span>
  );
};

export default BtnPriority;
