import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';
import { useTranslate } from 'react-translate';
import { useSortBy, useTable } from 'react-table';
import { actionGetActiveSessions } from 'store/actions/usersActions';
import { actionDeleteSession } from 'store/actions/usersActions';
import { useHistory } from 'react-router';
import moment from "moment";

const SessionManager = () => {
  const t = useTranslate('settings');
  const history = useHistory();
  const dispatch = useDispatch();
  const sessions = useSelector(state => state.userReducer.sessions);

  useEffect(() => {
    dispatch(actionGetActiveSessions());
    //eslint-disable-next-line
  }, []);

  const handleClick = (e, sessionId) => {
    e.preventDefault();
    if (sessionId) {
      dispatch(actionDeleteSession(sessionId));  
    }
  }

  const data = useMemo(
    () => sessions && sessions.length ? sessions.map(p => {
      const deviceData = p.device.user_agent.match(/([^\(]+(?=\)))|([^\(\)\s]+)/g);
      console.log(p, deviceData);

      const sessionData = {
        c1: p.device.client_ip,
        c2: deviceData[1] ? deviceData[1].replace(";", ",") : deviceData[0],
        c3: deviceData[1] ? 
        `${deviceData[deviceData.length-2]} - ${deviceData[deviceData.length-1]}` : 
        deviceData[deviceData.length-1],
        c4: p.login_date ? moment(p.login_date).format("DD/MM/YYYY hh:mm:ss") : "",
      };

      const button = (
      <Button
        size="sm"
        color="danger"
        onClick={(e) => handleClick(e, p.session_token)}
      >
        {t('btn-delete')}
      </Button>
      );

      return { ...sessionData, c5: p.session_token ? button : "" };
    }) : [],
    //eslint-disable-next-line
    [sessions, t]
  );

  const columns = useMemo(
    () => [
      {
        Header: `${t('th-login-date')}`,
        accessor: "c4",
        defaultCanSort: true
      },
      {
        Header: "IP",
        accessor: "c1",
        disableSortBy: true,
      },
      {
        Header: `${t('th-platform')}`,
        accessor: "c2",
        disableSortBy: true,
      },
      {
        Header: `${t('th-browser')}`,
        accessor: "c3",
        disableSortBy: true,
      },
      {
        Header: `${t('th-actions')}`,
        accessor: "c5",
        disableSortBy: true
      }
    ], 
    //eslint-disable-next-line
    [sessions, t]
  );
  
  const {
    getTableProps,
   getTableBodyProps,
   headerGroups,
   rows,
   prepareRow,
  } = useTable({columns, data}, useSortBy);
  
  return (
    <>
      <Button
        size="sm"
        color="info"
        onClick={
          (e) => {
            e.preventDefault();
            history.push('/admin/settings');
          }
        }>
        {t('btn-back')}
      </Button>

      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                    className={`session-table-header ${column.id === "c4" ? "sortable-header" : ""}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}

                  {column.id === "c4" && (
                    <span>
                      <b className={`${column.isSorted ? column.isSortedDesc ?  "caret-desc" : "caret-asc" : "caret-default"}`}></b>
                    </span>
                  )}
                  
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  );
};

export default SessionManager;
