import CONSTANTS from "../CONSTANTS";

//Invitations
export const actionGetInvitation = () => ({
  type: CONSTANTS.INVITATION_GET,
});
export const actionGetInvitationList = () => ({
  type: CONSTANTS.INVITATION_GET_LIST,
});
export const actionSetInvitation = (invitation) => ({
  type: CONSTANTS.INVITATION_SET,
  invitation,
});
export const actionSetInvitationList = (invitations) => ({
  type: CONSTANTS.INVITATION_SET_LIST,
  invitations,
});
export const actionResolveInvitation = (practice_invitation_id, status) => ({
  type: CONSTANTS.INVITATION_RESOLVE,
  practice_invitation_id,
  status,
});
//MS-Users
export const actionGetUsersList = (query) => ({
  type: CONSTANTS.USERS_GET_LIST,
  query,
});
export const actionSetUsersList = (users) => ({
  type: CONSTANTS.USERS_SET_LIST,
  users,
});
export const actionGetDoctorInfo = () => ({
  type: CONSTANTS.DOCTOR_GET_INFO,
});
export const actionSetDoctorInfo = (doctorInfo) => ({
  type: CONSTANTS.DOCTOR_SET_INFO,
  doctorInfo,
});
