import React from 'react';
import { useTranslate } from 'react-translate';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import useChatMessageStatus from '../../hooks/useChatMessageStatus';

const ChatMessageStatusInfo = ({ message, modal, toggle }) => {
  const t = useTranslate('chat');
  const { getStatus, getIcon } = useChatMessageStatus();
  const statusList = getStatus(message);

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{t('chat-info')}</ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column">
          {statusList?.length &&
            statusList.map(
              (user) =>
                !user.global && (
                  <span
                    key={user.auth_user_id}
                    className="d-flex justify-content-start"
                  >
                    {getIcon(user.status, 'mr-2')} {user.user}
                  </span>
                )
            )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChatMessageStatusInfo;
