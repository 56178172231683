import CONSTANTS from '../CONSTANTS';
//INBOX
export const actionGetMessages = (page = 1, limit = 20, search = null) => ({
  type: CONSTANTS.MESSAGES_GET_INBOX,
  page,
  limit,
  search,
});
export const actionSetMessages = (messages) => ({
  type: CONSTANTS.MESSAGES_SET_INBOX,
  messages,
});
export const actionGetMessagesScroll = (search = null) => ({
  type: CONSTANTS.MESSAGES_GET_SCROLL,
  search,
});
export const actionGetMessageFromURL = (id, practiceId) => ({
  type: CONSTANTS.MESSAGES_GET_FROM_URL,
  id,
  practiceId,
});

export const actionSetMessagesScroll = (messages) => ({
  type: CONSTANTS.MESSAGES_SET_INBOX_SCROLL,
  messages,
});

//OUTBOX
export const actionOutboxGetMessages = (
  page = 1,
  limit = 20,
  search = null
) => ({
  type: CONSTANTS.MESSAGES_GET_OUTBOX,
  page,
  limit,
  search,
});
export const actionOutboxSetMessages = (messages) => ({
  type: CONSTANTS.MESSAGES_SET_OUTBOX,
  messages,
});
export const actionGetOutboxMessage = (id) => ({
  type: CONSTANTS.MESSAGES_GET_OUTBOX_MSG,
  id,
});
export const actionSetOutboxScroll = (messages) => ({
  type: CONSTANTS.MESSAGES_SET_OUTBOX_SCROLL,
  messages,
});

//PRIORITY BOX
export const actionGetPriorityMessages = (
  page = 1,
  limit = 20,
  search = null
) => ({
  type: CONSTANTS.MESSAGES_GET_PRIORITY,
  page,
  limit,
  search,
});
export const actionSetPriorityboxScroll = (messages) => ({
  type: CONSTANTS.MESSAGES_SET_PRIORITYBOX_SCROLL,
  messages,
});
/**
 * Set priority conditions to selected messages
 * @param {array} messages ids
 */
export const actionSetPriorityMessages = (messages) => ({
  type: CONSTANTS.MESSAGES_SET_PRIORITY,
  messages,
});
/**
 * Set messages in priority box
 * @param {array} messages ids
 */
export const actionSetPriorityBox = (messages) => ({
  type: CONSTANTS.MESSAGES_SET_PRIORITY_BOX,
  messages,
});
export const actionDeletePriorityMessages = (messages) => ({
  type: CONSTANTS.MESSAGES_DELETE_PRIORITY,
  messages,
});
//GENERAL
export const actionSetMessage = (message) => ({
  type: CONSTANTS.MESSAGES_SET_MSG,
  message,
});
export const actionGetMessage = (id) => ({
  type: CONSTANTS.MESSAGES_GET_MSG,
  id,
});
export const actionGetMessageNotification = (practiceId, messageId) => ({
  type: CONSTANTS.MESSAGES_GET_MSG_NOTIFICATION,
  practiceId,
  messageId,
});
//export const actionDelMessage = (id) => ({
//  type: CONSTANTS.MESSAGES_DEL_MSG,
//  id,
//});
export const actionDelMessages = (messages) => ({
  type: CONSTANTS.MESSAGES_DEL_MSG,
  messages,
});
export const actionSetBrowser = (browser) => ({
  type: CONSTANTS.MESSAGES_SET_BROWSER,
  browser,
});
export const actionSetLoadingInbox = (loading) => ({
  type: CONSTANTS.MESSAGES_LOADING_INBOX,
  loading,
});
export const actionSetLoadingMsg = (loading) => ({
  type: CONSTANTS.MESSAGES_LOADING_MSG,
  loading,
});
export const actionSendMessage = (message, refresh_outbox = true) => ({
  type: CONSTANTS.MESSAGES_SEND_MSG,
  message,
  refresh_outbox,
});
export const actionGetSelectedMessages = () => ({
  type: CONSTANTS.MESSAGES_GET_SELECTED,
});
export const actionSetSelectedMessages = (messages) => ({
  type: CONSTANTS.MESSAGES_SET_SELECTED,
  messages,
});
export const actionSetBox = (box) => ({
  type: CONSTANTS.MESSAGES_SET_BOX,
  box,
});
export const actionRefreshBoxes = () => ({
  type: CONSTANTS.MESSAGES_REFRESH_BOXES,
});
