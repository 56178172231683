import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ChatRoomAvatar from 'components/Chats/ChatRoomAvatar';
import ChatRoomTooltip from 'components/Chats/ChatRoomTooltip';
import TextToEmojis from 'components/Emojis/TextToEmojis';
import {
  actionSetChat,
  actionSetChatRecipients,
} from 'store/actions/chatsActions';
import { useDispatch } from 'react-redux';
import { actionChatMessagesRefresh } from 'store/actions/chatsActions';
import { actionChatMessageRead } from 'store/actions/chatsActions';
import { actionChatShowInbox } from 'store/actions/chatsActions';
import moment from 'moment';
import usePageVisibility from 'hooks/usePageVisibility';
import { actionReadNotificationsByMessages } from 'store/actions/notificationsActions';
import { actionReadNotification } from 'store/actions/notificationsActions';

const ChatRoom = (props) => {
  const { chat, vertical, filter_select } = props;
  const { isDisaffected, auth_user_id, status_account, type } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const chatActive = useSelector((state) => state.chatsReducer.chat);
  const { mobile, dashboardMobile } = useSelector(
    (state) => state.commonReducer
  );
  const lastMessage =
    Array.isArray(chat.messages) && chat.messages.length > 0
      ? chat.messages[chat.messages.length - 1]
      : {};
  const visibility = usePageVisibility();
  const notificationsState = useSelector(
    (state) => state.notificationsReducer["chats"]
  );
  //const noShowStatus= (isDisaffected === true  ? true : false);

  /**
   * Function to setup an active chat
   * @param {Object} chat Chat information
   * @param {Object} contact Contact information
   */
  const setChatActive = ({ chat }) => {
    //Set input focus on change
    const chatSendInput = document.getElementById('chat-send-input');
    const contact = chat.main_user ? chat.main_user : chat;
    const currentUser = chat.type === 'group' ? chat?.users?.find(u => String(u.auth_user_id) === String(auth_user_id)) : null;
    const chatIsActive = !(
      status_account === 'offline' || 
      contact.status === 'inactive' || 
      contact.disaffectedDate || 
      currentUser?.deleted
      );

    let recipients = [];
    dispatch(actionSetChat(chat));
    if (mobile || dashboardMobile) {
      dispatch(actionChatShowInbox(true));
    }
    if (chatSendInput && chatIsActive) {
      chatSendInput.disabled = false;
      chatSendInput.focus();
    } else if (!chatIsActive) {
      return dispatch(actionSetChatRecipients([]));
    }
    /**
     * Groups and privates
     */
    if (['private', 'group'].includes(chat.type)) {
      if (chat.users) {
        chat.users.map((user) => {
          recipients.push({
            user_id: user.auth_user_id,
            type: user.type,
          });
          return user;
        });
        dispatch(actionSetChatRecipients(recipients));
      }
    } else {
      //User without chat
      recipients.push({
        user_id: contact.auth_user_id,
        type: contact.type,
      });
      recipients.push({
        user_id: auth_user_id,
        type: type,
      });
      dispatch(actionSetChatRecipients(recipients));
    }
  };

  const chatName = (includeTitle = false) => {
    if (chat.type === 'group' && chat.name) {
      return chat.name;
    }
    const practitioner = chat.main_user ? chat.main_user : chat;
    if (practitioner) {
      return `${includeTitle && practitioner.title ? practitioner.title+' ' : ''}${
        practitioner.firstName
      } ${practitioner.lastName}`;
    }
    return '';
  };

  const chatActiveId = chatActive._id;
  useEffect(() => {
    if (chat && chatActiveId === chat._id && visibility === "visible" && ['private', 'group'].includes(chat.type)) {
      dispatch(actionChatMessageRead(chatActiveId));

      //Mark remaining notifications as readed for current chat
      notificationsState.notifications.forEach(m => {
        if (m.notification.data.chat._id === chat._id) {
          dispatch(actionReadNotification(m.notification._id, true));
        }
      });
    }
    //eslint-disable-next-line
  }, [chatActiveId, chatActive.date_last_message, visibility]);

  /*
  useEffect(() => {
    if (chat.unreaded > 0) {
      dispatch(actionChatMessagesRefresh(chat._id));
    }
    //eslint-disable-next-line
  }, [chat.unreaded]);
  */

  return (
    <>
      <ChatRoomTooltip
        chat={chat}
        context={vertical ? 'vertical-list' : 'horizontal-list'}
      />
      <div
        className={`chat_people text-center ${
          chatActive &&
          (chatActive._id === chat._id ||
            (chatActive.auth_user_id &&
              chatActive.auth_user_id === chat.auth_user_id))
            ? `active_chat_style${vertical ? '_vertical' : '_horizontal'}`
            : ''
        }`}
        id={chat._id}
        onClick={() => {
          setChatActive({ chat: chat });
        }}
      >
        <ChatRoomAvatar type={chat?.type} main_user={chat?.main_user ? chat?.main_user : chat} filter_select={filter_select} />
        <div
          className="chat-name"
          data-tip
          data-for={`${
            vertical ? 'vertical-list' : 'horizontal-list'
          }-tooltip-${chat._id}`}
        >
          <div className="d-flex justify-content-between">
            <div className="text-truncate text-left">
              {(chatActive && !isDisaffected && chat.unreaded > 0 && status_account === 'online') ||
              chatActive._id === chat._id ? (
                <strong>{chatName()}</strong>
              ) : (
                chatName()
              )}
            </div>
            {/* Show messages unreaded */}
            {chatActive && chatActive._id !== chat._id && !isDisaffected && chat.unreaded > 0 && status_account === 'online' && (
              <div className="messages-counter flex-1">{chat.unreaded}</div>
            )}
          </div>
          {/* Show last message */}
          {vertical && lastMessage.body && (
            <div className="d-flex justify-content-between text-left flex-column">
              <small className="text-truncate">
                {<TextToEmojis text={lastMessage.body} />}
              </small>
              <small className="time_date_list">
                {moment(lastMessage.date).format('MMMM D')}
              </small>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatRoom;
