import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

// reactstrap components
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';

// core components
import PracticeForm from 'components/Practice/Form/PracticeForm';
import { selectPractice } from 'store/selectors/selectors';
import { useTranslate } from 'react-translate';
import { actionSetCategoryMenu } from 'store/actions/categoryMenuActions';

const PracticeNew = () => {
  const dispatch = useDispatch();
  const t = useTranslate('practices');
  const { id } = useParams();
  const practice = useSelector((state) => selectPractice(state, id));

  useEffect(() => {
    dispatch(actionSetCategoryMenu(id ? 'practice' : 'default'));
  }, [id, dispatch]);

  return (
    <Card>
      <CardBody>
        <Card>
          <CardHeader>
            <CardTitle className="d-flex align-content-center">
              <div className="general-card-title-img text-primary mr-2">
                <i className="icon-practice-settings title" />
              </div>
              <div className="general-card-title text-primary">
                {practice._id
                  ? `${t('title-updating')} ${practice.name}`
                  : t('title-create-practice')}
              </div>
            </CardTitle>
            {!practice._id && <p style={{ color: "gray", userSelect: "none" }}>
              {t('price-info')}
            </p>}
          </CardHeader>
          <CardBody>

            <Row>
              <Col>
                <PracticeForm practice={practice} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
};

export default PracticeNew;
