import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslate } from 'react-translate';
import { Button, Tooltip } from 'reactstrap';
import fileRules from 'configs/fileRules';
import BtnAttachModal from './BtnAttachModal';
import MultimediaService from "services/MultimediaService";

const BtnAttach = ({ position, register, id, resend, attachmentList, setAttachmentList }) => {
  const t = useTranslate('messages');
  const [file, setFile] = useState(null);
  const [removed, setRemoved] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const getFileInfo = async (file) => {
    const info = await MultimediaService.getFileInfo(file);
    return info;
  }

  useEffect(() => {
    if (resend) {
      getFileInfo(id.replace("resend-", "")).then(data => {
        setFile(data.name);
        const blob = new File([], `${id}|resend-${data.name}`, {type:data.mimetype});
        let container = new DataTransfer(); 
        container.items.add(blob);
        document.getElementById(id).files = container.files;
      });
    }
  }, [resend]);

  const handleChange = (e) => {

    let file = e.target.files[0];
    /*
    if (!validateFileType(file)) {
      return toast.error(
        t('file-type-unsupported')
      );
    }
    */
    if (!validateFileSize(file, fileRules.maxSize)) {
      return toast.error(
        `${t('alert-error-size-file')} ${fileRules.maxSize} Mb `
      );
    }
    if (file && file.name) {
      setFile(file.name);
      setAttachmentList([...attachmentList, `attachment-${Date.now()}`]);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    const element = e.target.id;
    if (!element) {
      if (file && document.getElementById(id).value) {
        toggleModal();
        return;
        /* setRemoved(true);
        return document.getElementById(id).value; */
      }
      return document.getElementById(id).click();
    }
    // setRemoved(true);
    toggleModal();
    if (document.getElementById(id)) {
      /*  document.getElementById(id).value = ''; */
    }
  };

  /**
   *  Validate file type
   * @param {object} file selected
   */
  /*
  const validateFileType = (file) => {
    if (file && file.type) {
      return fileRules.mimeTypes.includes(file.type);
    }
    return false;
  };
  */

  /**
   *  Validate file size
   * @param {object} file selected
   * @param {number} size Mb
   */
  const validateFileSize = (file, size) => {
    if (file && file.size) {
      return file.size <= parseInt(size) * 1024 * 1024;
    }
    return false;
  };

  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const confirmDelete = () => {
    setRemoved(true);
    return document.getElementById(id).value;
  };

  return (
    <React.Fragment>
      {!removed && (
        <React.Fragment>
          <input
            type="file"
            name={`attachments[${position}]`}
            className={`d-none attach-group-${id}`}
            ref={register}
            id={id}
            onChange={handleChange}
            accept={fileRules.types.toString()}
          />
          <Button
            color={file ? 'info' : 'light'}
            onClick={handleClick}
            name="attach-file-message-btn"
            className={`attach-group-${id}`}
          >
            {file ? file : t('btn-add-file')}
            {file && <i className="fas fa-times ml-2" id={`remove-${id}`}></i>}
          </Button>
          <BtnAttachModal
            modal={modal}
            toggleModal={toggleModal}
            confirmDelete={confirmDelete}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default BtnAttach;
