import AxiosService from "plugins/TS-lib-utils-public/services/AxiosService";

class UsersService extends AxiosService {
  constructor() {
    super("users");
  }
  search = async (query) => {
    const response = await this.get(`/search/${query}`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };
  /**
   *
   * @param {string} query filter
   * @param {number} qty results quantity
   * @param {string} fields field's list separated by ,
   */
  getUsers = async (
    query,
    qty = 10,
    fields = "_id,email,firstName,lastName,type,auth_user_id"
  ) => {
    const response = await this.get(
      `/search/${query}?quantity=${qty}&fields=${fields}`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
    return response;
  };
  getInvitations = async (user_id) => {
    const response = await this.get(`/invitations/${user_id}`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };
  resolveInvitation = async (practice_invitation_id, status) => {
    const response = await this.post(
      `/invitations/resolve`,
      {
        practice_invitation_id,
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
    return response;
  };
}

export default new UsersService();
