//Page for edition user data

import React from 'react';
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { useTranslate } from 'react-translate';
import ProfileForm from 'components/Users/ProfileForm';
// core components
// import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
// import Form2fa from 'components/Users/Form2fa';

const Profile = () => {
  const t = useTranslate('profile');
  return (
    <>
      {/*<PanelHeader size="sm" /> */}
      <Card className="profile">
        <CardBody>
          <Card className="mb-0">
            <CardHeader>
              <CardTitle className="d-flex align-content-center">
                <div className="general-card-title-img text-primary mr-2">
                  <i className="icon-user-active" />
                </div>
                <div className="general-card-title text-primary">
                  {t('title')}
                </div>
              </CardTitle>
            </CardHeader>

            <CardBody>
              <h5 className="general-card-subtitle">{t('personal-info')}</h5>
              <ProfileForm />
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </>
  );
};

export default Profile;
