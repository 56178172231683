import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { translate } from 'react-translate';

const ChatSearchInput = (props) => {
  const { t, setChatSearch, filter_select } = props;
  const searchInputRef = useRef(null);
  const practice = useSelector((state) => state.userAuthorizationReducer);
  const practiceId = practice._id;
  const [searchText, setSearchText] = useState('');
  const { isDisaffected } = useSelector((state) => state.practicesReducer);
  //const disableSearch = (isDisaffected || filter_select) ? true : false;
  
  //Clear input and search
  const clearInput = useCallback(() => {
    setSearchText('');

    if (setChatSearch) {
      setChatSearch('');
    }

    document.getElementById('user_search_field').value = '';
  }, [setChatSearch]);
  
  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
      clearInput();
    }
  }, [practiceId, clearInput]);

  return (
    <div className="type_msg">
      <div className={`input_user_search ${(isDisaffected===true || filter_select === true)  ? 'disabled-inputs' : ''}`}>
        <input
          type="text"
          className="user_search"
          placeholder={t('search-contact')}
          id="user_search_field"
          disabled={ (isDisaffected===true || filter_select === true) ? true : false}
          onKeyUp={(e) => {
            setSearchText(e.target.value);

            if (setChatSearch) {
              setChatSearch(e.target.value);
            }
          }}
          ref={searchInputRef}
        />

        {searchText.length > 0 && (
          <button className="msg_send_btn" type="button" onClick={clearInput}>
            {/*TODO: change icon*/}
            <span className="icon-trash"></span>
          </button>
        )}
      </div>
    </div>
  );
};

export default translate('chat')(ChatSearchInput);
