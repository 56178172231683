import CONSTANTS from '../CONSTANTS';

const commonReducer = (
  state = {
    sweetAlert: null,
    collapse: 'WaitingRoom', //string with name component collapsable
    mobile: false,
    dashboardMobile: window.innerWidth < 1950,
    nativeWebView: false, //Native webview is different than mobile browser
    ws_status: 'offline',
  },
  action
) => {
  switch (action.type) {
    case CONSTANTS.SWEET_ALERT:
      return { ...state, sweetAlert: action.sweetAlert };
    case CONSTANTS.COLLAPSE:
      return { ...state, collapse: action.collapse };
    case CONSTANTS.MOBILE_VERSION:
      return { ...state, mobile: action.mobile };
    case CONSTANTS.DASHBOARD_MOBILE_VERSION:
      return { ...state, dashboardMobile: action.mobile };
    case CONSTANTS.NATIVE_WEBVIEW:
      return { ...state, nativeWebView: action.native };
    case CONSTANTS.WS_STATUS:
      return { ...state, ws_status: action.status };
    default:
      return state;
  }
};

export default commonReducer;
