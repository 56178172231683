import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Table,
} from "reactstrap";

import Icon from "components/Icons/Icon";
//import { NavLink } from "react-router-dom";

const hours = [
  "7 AM",
  "7:30 AM",
  "8 AM",
  "8:30 AM",
  "9 AM",
  "9:30 AM",
  "10 AM",
  "10:30 AM",
  "11 AM",
  "11:30 AM",
];

const PracticeSlots = () => {
  return (
    <div className="content">
      <Card>
        <CardBody>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader className="d-flex">
                  <div>
                    <h5 className="title">
                      <Icon
                        file="practice-settings-orange.svg"
                        className="mr-2"
                      />
                      Praxis Prof. Dr. Med. Max Müller
                    </h5>
                    <strong>Slots: Max Müller</strong>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Label>Type of insurance</Label>
                        <div>
                          <Button color="info">Private</Button>
                          <Button color="info">Self Payer</Button>
                          <Button color="default">Public</Button>
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup>
                        <Label>Place of encounter</Label>
                        <div>
                          <Button color="info">In Practice</Button>
                          <Button color="info">Telemedicine</Button>
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup>
                        <Label>Absences (Optional)</Label>
                        <div>
                          <Button color="primary">
                            Block this period of time
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <Table>
                        <tr>
                          <th>&nbsp;</th>
                          <th>Mon</th>
                          <th>Tue</th>
                          <th>Wed</th>
                          <th>Thu</th>
                          <th>Fri</th>
                          <th>Sat</th>
                          <th>Sun</th>
                        </tr>

                        {hours.map(function (item) {
                          return (
                            <tr>
                              <td>{item}</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          );
                        })}
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default PracticeSlots;
