import { call, select, put, cancelled, takeLatest } from 'redux-saga/effects';
import CONSTANTS from '../CONSTANTS';
import statsService from 'services/statsService';
import { actionStorePracticeMetricsReducer } from 'store/actions/statsActions';

//Selectors
import { getUser } from 'store/selectors/selectors';

//Helpers
import utilTranslations from '../../helpers/utilTranslations';

let t;

function* sagaGetPracticeMetrics({ keyQuestion, practiceId, doctorAuthId }) {

  const userActive = yield select(getUser);
  t = utilTranslations(userActive.language || 'de', 'laboratory-detail');

  const cancelToken = statsService.getSource();
  try {

    const res = yield call(

      statsService.getPracticeMetrics,
      keyQuestion,
      practiceId,
      doctorAuthId,
      { cancelToken: cancelToken.token }
    );

    if (res.status >= 400) {
      throw new Error("Error getting the study");
    }

    yield put( actionStorePracticeMetricsReducer( res.data ) );
    
  } catch (error) {

    console.log(error);
    //historyRouter.push("/admin/kilab/index");
  } finally {

    if (yield cancelled()) {

      cancelToken.cancel();
    }
  }
}

export function* statsSaga() {

  console.log('*Main Stats Saga');
  yield takeLatest(CONSTANTS.LAB_EARLY_GET_PRACTICE_METRICS, sagaGetPracticeMetrics);
}