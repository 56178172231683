
import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { TranslatorProvider } from 'react-translate';
import 'react-toastify/dist/ReactToastify.css';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// core components
import AuthNavbar from 'components/Navbars/AuthNavbar';
import Footer from 'components/Footer/Footer';
//Other
import routes from 'routes.js';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

var ps;

const Auth = (props) => {
  // const fullPagesRef = useRef(null);
  const { language } = useSelector((state) => state.userReducer);
  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      let fullPage = document.getElementById('full-page');
      ps = new PerfectScrollbar(fullPage);
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoutes = useCallback((routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.allowed || prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  }, []);

  return (
    <TranslatorProvider
      translations={require(`assets/i18n/${language || 'de'}.json`)}
    >
      <AuthNavbar {...props} authNavbar />
      <div className="wrapper wrapper-full-page animated fadeIn" id="full-page">
        <div className="full-page section-image" filter-color="blue">
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/auth" to="/auth/login-page" />
          </Switch>
          <Footer fluid />
        </div>
      </div>
    </TranslatorProvider>
  );
};

export default Auth;
