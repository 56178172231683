import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
} from "reactstrap";
import { useTranslate } from "react-translate";
import { actionSweetAlert } from 'store/actions/commonActions';
import SweetAlert from 'react-bootstrap-sweetalert';

import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import { 
    actionUpdateReview, 
    actionMarkAIComment, 
    actionSendReview, 
    actionDeleteDraft, 
    actionSetSentModal,
    actionSaveTemplateLocalData
} from "store/actions/laboratoryActions";
import Icon from "components/Icons/Icon";
import { toast } from "react-toastify";

const sanitizeHtmlReview = (html) => {
    //Decode HTMLEntities
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    //Delete HTML tags
    return txt.value.replace(/(?![^>]+\b(?:item|range)=)(<[/]*[a-z]+.*?>)/gi, "");
};

const DoctorReviewHistory = ({ study, reportId }) => {
    const user = useSelector(state => state.userReducer);
    const tMedicalReview = useTranslate('medical-review');
    const dispatch = useDispatch();
    const { practice } = useSelector(state => state.practicesReducer);

    return (
    <>
    {study.reviews.map((r, i) => {
        if (r.sent_at || r.is_draft) {
            return (
                <div className="review-card" key={`review_${i}`}>
                    <Row>
                            {r.is_draft ? (<span className="draft-icon"><Icon file="draft_.svg" /></span>) : <div style={{width: "50px"}}></div>}
                            <div className="review-left-header" style={{marginBottom: "15px"}}>

                                <div style={{display:"block"}}>
                                    <div className="d-flex">
                                        <div className="review-date">{moment(r.updated_at ? r.updated_at : r.created_at).format(`${user.dateFormat} HH:mm`)}</div>
                                            
                                        {r.ai_author ? (
                                            <span className={`review-ki${r.ai_modified ? "-edited": ""}`}>
                                                Ki-Befund {r.ai_modified ? `+ Text Arzt` : ""} 
                                            </span>
                                        ) : (<></>)}
                                    </div>

                                    <div>
                                        {study.patient.firstName} {study.patient.lastName} *{moment(study.patient.birthday, "YYYYMMDD").format(user.dateFormat)}
                                    </div>
                                </div>

                                

                                {!r.is_draft && (
                                    <div className="review-icon-section">
                                        <span className={r.isBilling ? "billing-green" : "billing-orange"}>
                                            {r.isBilling ? tMedicalReview("billed") : tMedicalReview("not-billed")}
                                        </span>
                                        <Icon className="euro-icon" file={`euro-currency-symbol_${r.isBilling ? "green" : "rot_crossed_out"}.svg`} width="20px" height="20px"/>
                                    </div>
                                )}

                            </div>
                        <Col sm={12} md={9} lg={9}>
                            <div className="review-content" dangerouslySetInnerHTML={{__html: r.content}}></div>
                        </Col>

                        {r.folgenValues && r.folgenValues.length ? (
                            <Col sm={12} md={3} lg={3} className="d-none d-md-block">
                                <div className="review-left-header">
                                    <div>
                                        <p>{tMedicalReview("folgen-title")}</p>
                                    </div>
                                </div>
                                <div className="folgen-section">
                                    {r.folgenValues.map((fv,i) => {
                                        let value = fv["Observation Identifier"].replace(/^([\s]*)\s/, '').split(' ');
                                        return (
                                        <div className="folgen-pill" key={`folgen-pill-${i}`}>
                                            {`${value[1]} ${value[2]}`}
                                        </div>
                                        )
                                    })}
                                </div>
                            </Col>
                        ) : (
                            <Col sm={12} md={3} lg={3} className="d-none d-md-block">
                                <div className="review-left-header">
                                    <p>{tMedicalReview("folgen-title")}</p>
                                </div>
                                <div className="folgen-section">
                                    <p>{tMedicalReview("all-folgen-values-processed")}</p>
                                </div>
                            </Col>
                        )}
                    </Row>


                    {r.is_draft && (
                        <div className="d-flex align-items-end flex-column">
                            <div className="d-flex align-items-end">

                                <span
                                    className="mr-3 pointer"
                                    onClick={(e) => {
                                        navigator.clipboard.writeText(sanitizeHtmlReview(r.content));
                                        toast.success("Text in die Zwischenablage kopiert");
                                    }}
                                > 
                                    <Icon file="copy_.svg" width="25px"/> 
                                </span>
                                
                                <span
                                    className="pointer"
                                    onClick={e => {
                                        dispatch(
                                            actionSweetAlert(
                                              <SweetAlert
                                                custom
                                                customIcon="/General/icons/warning-sign-orange.svg"
                                                title={tMedicalReview('are-you-sure')}
                                                onConfirm={() => {}}
                                                customButtons={
                                                  <>
                                                    {/*Custom buttons for more control*/}
                                                    <Button
                                                      className="mb-3"
                                                      color="info"
                                                      size="lg"
                                                      onClick={() => {
                                                        dispatch(actionSweetAlert(null));
                                                        dispatch(actionDeleteDraft(reportId, r._id, study.doctor_id, practice._id));
                                                      }}
                                                    >
                                                      {tMedicalReview('btn-ok')}
                                                    </Button>
                                      
                                                    <Button
                                                      className="mb-3 ml-2"
                                                      color="danger"
                                                      size="lg"
                                                      onClick={() => dispatch(actionSweetAlert(null))}
                                                    >
                                                      {tMedicalReview('btn-cancel')}
                                                    </Button>
                                                  </>
                                                }
                                              >
                                                {tMedicalReview("delete-draft-text")}
                                              </SweetAlert>
                                            )
                                          );
                                    }}
                                > 
                                    <Icon file="delete_.svg" width="25px"/> 
                                </span>
                            </div>
                        </div>
                    )}
                    
                </div>
            )
        }
    })}
    </>
    )
}

const DoctorReviewField = ({ study, reportId, review, setReview, aiFlag, setAiFlag, readAiComment, setReadAiComment, systemTemplates}) => {
    const tMedicalReview = useTranslate('medical-review');
    const tLabDetail = useTranslate('laboratory-detail');
    const dispatch = useDispatch();
    const { doctorInfo } = useSelector(state=>state.userDataReducer);
    const user = useSelector(state => state.userReducer);
    const { loadingAiReview, showSentModal } = useSelector(state => state.laboratoryReducer);
    const { practice } = useSelector(state => state.practicesReducer);
    const [backupReview, setBackupReview] = useState('');
    const [edited, setEdited] = useState(false);
    const [canSend, setCanSend] = useState(false);
    const [saving, setSaving] = useState(0);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    
    const aiTemplate = study?.ai_review?.content ? study?.ai_review?.content : "";
    const currentFolgenValues = study && study.metadata && study.metadata.folgenValues 
    ? JSON.parse(study?.metadata?.folgenValues) 
    : null;

    //constant to translate ai-comment translations to a base tag
    const aiBaseTag = "ai-comment";

    const timeoutHandle = useRef();
    const savingTimeoutHandle = useRef();
    const editedInfo = useRef();
    const isMounted = useRef(false);

    /** Modal logic */
    const [modal, setModal] = useState(false);
    const [aiModal, setAiModal] = useState(false);

    const readAI = () => {
        //Signal parent component to delete 'new review' text
        setReadAiComment(true);
        //Send signal to backend to read ai comment
        dispatch(actionMarkAIComment(reportId, study.doctor_id, practice._id));
    }

    //Helper to filter control keys
    const isValidInput = (e) => {
        let code = e.which ? e.which : e.keyCode;
        return !(
            code == 16 || 
            code == 17 ||
            code == 18 || 
            code == 19 || 
            code == 20 || 
            code == 27 ||
            code == 45 || 
            code == 91 || 
            code == 93 ||
            (code >= 35 && code <= 40) ||
            (code >= 33 && code <= 34 ) ||
            (code >= 112 && code <= 123) ||
            (code >= 144 && code <= 145 )
        );
    }

    const showTemporalSavedIcon = () => {
        setSaving(2);
        setEdited(false);
        savingTimeoutHandle.current = setTimeout(() => {
            setSaving(0);
            savingTimeoutHandle.current = null;
        }, 2400);
    }

    const toggleModal = (e) => {

        if (timeoutHandle.current) {
            //Save pending review with the previously selected template
            updateReview(backupReview, true, false, selectedTemplate);
            clearTimeout(timeoutHandle.current);
            timeoutHandle.current = null;
        }
        
        if (e && e.target.value) {
            setBackupReview(e.target.value);
        }

        const templateValue = getOptionsValue();
        const templateData = study?.templates?.find(te => te.name === templateValue);

        setAiModal(templateValue === aiBaseTag);
        if (!study?.ai_review?.readed && templateValue === aiBaseTag) {
            readAI();
        }

        if (edited) {
            clearTimeout(timeoutHandle.current);
            setModal(false);
            setReview(templateData ? templateData.content : e.target.value);
            setBackupReview(null);
            setEdited(false);
            editedInfo.current = null;
        } else {
            setReview(templateData ? templateData.content : e.target.value);
        }
    };

    const selectOption = (name) => {
        const t = document.getElementById("template-select");
        let index = 0;
        for (let i = 0; i < t.options.length; i++){
            t.options[i].selected = t.options[i].text === name;
            if (t.options[i].text === name) {
                index = i;
            }
        }
        setAiModal(name === aiBaseTag);
        
        if (name === aiBaseTag) {
            readAI();
        }
    }

    /** Main effects */
    useEffect(() => {
        setReview(!study.ai_review_error &&
            (!study?.reviews?.length || !study.reviews[0].content?.length) && 
            study?.ai_review?.content 
            ? study?.ai_review?.content 
            : study?.reviews?.length && study?.reviews[0]?.content?.length
            ? study?.reviews[0]?.content
            : review);

        if (!study.ai_review_error &&
            (!study?.reviews?.length || !study.reviews[0].content?.length) && 
            study?.ai_review?.content
        ) {
            selectOption("ai-comment");
        } else if (study?.reviews[0]?.template?.name)  {
            selectOption(study?.reviews[0]?.template?.name);
        }
        
        setAiModal(getOptionsValue() === aiBaseTag);

        return () => {
            if (timeoutHandle.current) {
                clearTimeout(timeoutHandle.current);
                updateReview(editedInfo.current, true);
            }

            if (savingTimeoutHandle.current) {
                clearTimeout(savingTimeoutHandle.current);
            }
        }
    }, []);

    useEffect(() => {
        //Select AI option and read when the button to see the ai review was pressed
        if (readAiComment === true && !study?.ai_review?.readed) {
            selectOption("ai-comment");
        }
    }, [readAiComment]);

    useEffect(() => {
        if (saving === 1) {
            //Set saving indicator at phase 2 if the saving timer ended
            showTemporalSavedIcon();
        }

        const templateValue = getOptionsValue();
        const templateData = study?.templates?.find(te => te.name === templateValue);

        if (!study?.ai_review?.readed && templateValue === aiBaseTag) {
            readAI();
        }

        if (!isMounted.current) {
            isMounted.current = true;
            setReview(!study.ai_review_error &&
                (!study?.reviews?.length || !study.reviews[0].content?.length) && 
                study?.ai_review?.content 
                ? study?.ai_review?.content 
                : study?.reviews?.length && study?.reviews[0]?.content?.length
                ? study?.reviews[0]?.content
                : review);
        } else {
            setReview(templateData?.content ? templateData.content 
                : !study.ai_review_error &&
                (!study?.reviews?.length || !study.reviews[0].content?.length) && 
                study?.ai_review?.content 
                ? study?.ai_review?.content 
                : study?.reviews?.length && study?.reviews[0]?.content?.length
                ? study?.reviews[0]?.content
                : review
            );
        }
        
    }, [study]);

    useEffect(() => {
        if (aiTemplate.length && !study.ai_review_error) {
            if (study?.ai_review?.content?.length && !study?.ai_review?.readed) {
                selectOption("ai-comment");
            }
        }
    }, [review, loadingAiReview]);

    useEffect(() => {
        if (edited) {
            if (timeoutHandle.current) {
                clearTimeout(timeoutHandle.current);
            }
            if (savingTimeoutHandle.current) {
                clearTimeout(savingTimeoutHandle.current);
            }

            //Set saving indicator at phase 1
            setSaving(1);
            setBackupReview(review);
            setSelectedTemplate(getOptionsValue());
    
            timeoutHandle.current = setTimeout(() => {
                updateReview(review, true);
                timeoutHandle.current = null;
            }, 1800);
        }
    }, [review, edited]);

    //Effect to change AI comment
    useEffect(() => {
        if (review) {
            let reviewSanitized = sanitizeHtmlReview(review);
            if (edited) {
                setAiFlag(!(reviewSanitized !== aiTemplate));
            } else if (reviewSanitized === aiTemplate) {
                setAiFlag(true);
            }
        }
    }, [edited, review]);

    const getOptionsValue = () => {
        const t = document.getElementById("template-select");
        return t.options[t.selectedIndex].text === tMedicalReview('phrase-ai-template') ? aiBaseTag : t.options[t.selectedIndex].text;
    };

    const updateReview = (review, skipModal = false, makeDraft = false, templateName = null) => {
        if (timeoutHandle.current) {
            clearTimeout(timeoutHandle.current);
        }
        const value = getOptionsValue();
        dispatch(actionSaveTemplateLocalData(templateName ? templateName : value, review));
        dispatch(actionUpdateReview(reportId, review, study.metadata._id, templateName ? templateName : value, study.doctor_id, practice._id, aiFlag, aiModal, skipModal, makeDraft));
    }

    const sendReview = (billing = false) => {
        if (timeoutHandle.current) {
            clearTimeout(timeoutHandle.current);
        }
        const value = getOptionsValue();
        dispatch(actionSendReview(reportId, review, study.metadata._id, value, study.doctor_id, practice._id, billing, aiFlag, aiModal));
    }

    const aiTemplateFunc = () => {
        let aiComment = study?.templates?.find(t => t.name === aiBaseTag);
        let aiReviewDate = new Date(study?.ai_review?.created_at).getTime()
        return study && study.ai_review ? 
            <option 
                value={new Date(aiComment?.updated_at).getTime() > aiReviewDate ? aiComment.content : study?.ai_review?.content} 
                key="ki-befund"
                selected={study.ai_review && study.reviews[0] && (!study.reviews[0].template || study.reviews[0].template && study.reviews[0].template.name === aiBaseTag)}
                >
                {tMedicalReview('phrase-ai-template')}
            </option>
        : <></>
    }

    return (
    <>
        <div className="template-container ml-1 mr-1 d-flex justify-content-between">
            <div className="template-picker">

                <div className="template-section">
                    <Input type="select" readOnly={loadingAiReview} onChange={toggleModal} id="template-select">

                        {aiTemplateFunc()}

                        <option value=" " key="empty-template">
                            {tMedicalReview('phrase-no-template')}
                        </option>

                        {systemTemplates && systemTemplates.length && systemTemplates.map(t => {
                            return (
                                <option value={t.text} key={t.name} selected={
                                    study?.reviews[0] && study?.reviews[0]?.template &&
                                    t.name === study?.reviews[0]?.template?.name}>
                                    {t.name}
                                </option>
                            )
                        })}
                        {doctorInfo?.personal_templates?.length && doctorInfo?.personal_templates.map(t => {
                            return (
                                <option value={t.text} key={t.name} selected={
                                    study?.reviews[0] && study?.reviews[0]?.template &&
                                    t.name === study?.reviews[0]?.template?.name}>
                                    {t.name}
                                </option>
                            )
                        })}
                    </Input>
                </div>

                <Modal isOpen={modal} toggle={toggleModal} >
                    <ModalHeader toggle={toggleModal}>{tMedicalReview('title-change-template')}</ModalHeader>
                    <ModalBody>
                        {tMedicalReview('notice-lose-data')}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" onClick={() => {
                                clearTimeout(timeoutHandle.current);
                                setModal(false);
                                setReview(backupReview);
                                setBackupReview(null);
                                setEdited(false);
                                updateReview(backupReview);
                                editedInfo.current = null;
                            }}>
                            {tMedicalReview('btn-ok')}
                        </Button>{' '}
                        <Button color="secondary" onClick={() => {setModal(false)}}>
                        {tMedicalReview('btn-cancel')}
                        </Button>
                    </ModalFooter>
                </Modal>


                <Modal isOpen={showSentModal} >
                    <ModalHeader toggle={() => {
                        dispatch(actionSetSentModal(false));
                        }}>
                            Info
                        </ModalHeader>
                    <ModalBody>
                    {tLabDetail("review-saved")}
                    </ModalBody>
                </Modal>

                {study?.reviews[0]?.updated_at && (
                    <span className="timestamp-text">
                        Stand: {moment(study?.reviews[0]?.updated_at).format(`${user.dateFormat} HH:mm`)}
                    </span>
                )}

                <span className={`autosave-container ${saving === 1 ? "info-color" : saving === 2 ? "success-color" : ""}`}> 
                    {edited && saving === 1 && (
                        <>
                            <i className="save-icon icon-hourglass-1"></i> 
                            <span>{tLabDetail('autosave-saving')}</span>
                        </>
                    )}
                    {saving === 2 && (
                        <>
                            <i className="save-icon icon-active-user"></i> 
                            <span>{tLabDetail('autosave-saved')}</span>
                        </>
                    )}
                </span>

            </div>
            
            
            {aiModal && (
                <div>
                    <div className={`ki-befund-${aiFlag? "full" : "edition"}`}>
                        <span>{`KI-Befund ${!aiFlag ? '+ Text Arzt' : ""} `}</span>
                        <Icon file={`TP_KI_Logo_${aiFlag ? "Blue" : "Orange"}_simple.svg`} width="40px"/>
                    </div>
                </div>
            )}
        </div>

        <Row>
            <Col lg={"12"}>
                <div className="review-field">
                    <ReactQuill id="editor"
                        readOnly={loadingAiReview}
                        disabled={loadingAiReview}
                        modules={{ toolbar: loadingAiReview ? [] : [
                            [{ size: [ 'small', false, 'large', 'huge' ]}],
                            ['bold', 'italic', 'underline'],
                            ['link'],
                            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                            ['blockquote'],
                            ['clean']
                        ]}}
                        style={{
                            height: "100%",
                            overflowY: "auto",
                            transitionDuration: "0.3s"
                        }}

                        onKeyDown={(e) => {
                            if (isValidInput(e)) {
                                setReview(e.target.innerHTML);
                                editedInfo.current = e.target.innerHTML;
                                setEdited(true);
                                setCanSend(false);
                            }
                        }}

                        onChange={(text) => {
                            setReview(text);
                            setCanSend(false);
                            editedInfo.current = text;
                        }}
                        value={review ? review : ""}
                    >
                    </ReactQuill>

                    <div className="folgen-values-main-section">
                        <div className="folgen-title">
                            <p>{tMedicalReview("folgen-title")}</p>
                        </div>
                        <div className="folgen-section">
                            {!loadingAiReview && currentFolgenValues && currentFolgenValues.length ? currentFolgenValues.map((fv,i) => {
                                let value = fv["Observation Identifier"].replace(/^([\s]*)\s/, '').split(' ');
                                return (
                                <div className="folgen-pill" key={`edition-folgen-pill-${i}`}>
                                    {`${value[1]} ${value[2]}`}
                                </div>
                                )
                            }) : loadingAiReview ? (<p>Loading...</p>) : (<p>{tMedicalReview("all-folgen-values-processed")}</p>)}
                        </div>
                    </div>

                    
                </div>
            </Col>
        </Row>

        <Button
            color="info"
            onClick={(e) => {
                if (review !== "<p><br></p>") {
                    e.preventDefault();
                    setEdited(false);
                    updateReview(review, true, true);
                    showTemporalSavedIcon();
                }
            }}
        >
        Speichern
        </Button>

        <Button
            color="info"
            onClick={(e) => {
                if (review !== "<p><br></p>") {
                    e.preventDefault();
                    setCanSend(true);
                    setEdited(false);
                    updateReview(review, true);
                    showTemporalSavedIcon();
                }
            }}
        >
        {tMedicalReview('btn-save')}
        </Button>

        <Button
            color="success"
            size="md"
            disabled={!canSend || edited}
            onClick={(e) => {
                e.target.blur();
                e.preventDefault();
                sendReview(true);
            }}
        >
            {tMedicalReview('btn-send-to-patient')}
        </Button>

        <Button
            className="btn-no-bill"
            size="md"
            disabled={!canSend || edited}
            onClick={(e) => {
                e.target.blur();
                e.preventDefault();
                sendReview();
            }}
        >
            {tMedicalReview('btn-send-to-patient-no-billing')}
        </Button>
    </>
    )
}

const DoctorReview = ({study, reportId, review, setReview, aiFlag, setAiFlag, readAiComment, setReadAiComment, systemTemplates}) => {
    return (
        <>
            <DoctorReviewField 
                study={study} 
                reportId={reportId} 
                review={review} 
                setReview={setReview} 
                aiFlag={aiFlag} 
                setAiFlag={setAiFlag}
                readAiComment={readAiComment}
                setReadAiComment={setReadAiComment}
                systemTemplates={systemTemplates}
            />
            <DoctorReviewHistory study={study} reportId={reportId} />
        </>
    )
}

export default React.memo(DoctorReview);