import React from 'react';

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';

// components
import PracticeTitle from 'components/Practice/PracticeTitle';
import InvitationByEmail from 'components/Practice/Invitations/InvitationByEmail';

const PracticeInviteUser = () => {
  return (
    <Card>
      <CardBody>
        <Card>
          <CardHeader>
            <CardTitle className="d-flex align-content-center">
              <PracticeTitle title ={''}/>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <InvitationByEmail />
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
};

export default PracticeInviteUser;
