import {
  takeEvery,
  takeLatest,
  put,
  call,
  cancelled,
  select,
} from 'redux-saga/effects';
//Redux Actions
import CONSTANTS from '../CONSTANTS';
import {
  actionGetNotifications,
  actionSetNotificationsChats,
  actionSetNotificationsMessages,
  actionSetNotificationsLaboratory,
} from '../actions/notificationsActions';
//Services
import NotificationsService from '../../services/NotificationsService.js';
import { actionGetMessages } from 'store/actions/messagesActions';
import { getUser } from 'store/selectors/selectors';
import PushNotificationsService from 'services/PushNotificationsService';

/**
 * Get notifications
 */
function* sagaGetNotifications({ module }) {
  const cancelToken = NotificationsService.getSource();
  const userActive = yield select(getUser);
  try {
    if (!userActive._id) {
      return;
    }
    const result = yield call(
      NotificationsService.getNotifications,
      module ? module : null,
      {
        cancelToken: cancelToken.token,
      }
    );
    if (result.status >= 400) {
      throw new Error('Get notifications failed');
    }
    if (module === 'messages') {
      yield put(actionSetNotificationsMessages(result.data));
    }
    if (module === 'chats') {
      yield put(actionSetNotificationsChats(result.data));
    }
    if (module === 'laboratory') {
      yield put(actionSetNotificationsLaboratory(result.data));
    }
    if (!module && Array.isArray(result.data)) {
      const messages = result.data.filter(
        (notification) => notification.module === 'messages'
      );
      yield put(actionSetNotificationsMessages(messages));
      const chats = result.data.filter(
        (notification) => notification.module === 'chats'
      );
      yield put(actionSetNotificationsChats(chats));
      const laboratory = result.data.filter(
        (notification) => notification.module === 'laboratory'
      );
      yield put(actionSetNotificationsLaboratory(laboratory));
    }
  } catch (error) {
    // toast.error(error.message || "Get notifications failed");
  } finally {
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}

/**
 * Read notifications
 */
function* sagaReadNotification({ id, read }) {
  try {
    const result = yield call(NotificationsService.readNotification, id, read);
    if (result.status >= 400) {
      throw new Error("Can't read notification");
    }
    const notification = result.data;
    if (notification.module === 'messages') {
      yield put(actionGetMessages(notification.module));
    }
    yield put(actionGetNotifications(notification.module));
  } catch (error) {
    // toast.error(error.message || 'Reading notification failed');
  }
}
/**
 * Read all notifications
 */
 function* sagaReadNotificationsByMessages({ module, messages }) {
  try {
    const result = yield call(NotificationsService.readNotificationsByMessages, messages, true);
    if (result.status >= 400) {
      throw new Error("Can't read notifications");
    }
    yield put(actionGetNotifications(module));
  } catch (error) {
    // toast.error(error.message || 'Reading notification failed');
  }
}
/**
 * Read all notifications
 */
 function* sagaReadAllNotification({ module }) {
  try {
    const result = yield call(NotificationsService.readAllNotification, module);
    if (result.status >= 400) {
      throw new Error("Can't read notifications");
    }

    //Clear notification tray
    switch (module) {
      case "chats": 
        yield put(actionSetNotificationsChats({ notifications: [] }));
        yield put(actionGetNotifications("chats"));
      break;

      case "messages": 
        yield put(actionSetNotificationsMessages({ notifications: [] }));
        yield put(actionGetNotifications("messages"));
      break;

      case "laboratory": 
        yield put(actionSetNotificationsLaboratory({ notifications: [] }));
        yield put(actionGetNotifications("laboratory"));
      break;

      default:
      break;
    }

  } catch (error) {
    // toast.error(error.message || 'Reading notification failed');
  }
}
/**
 * Subscribe to notifications
 */
function* sagaNotificationsSubscribe() {
  try {
    const subscription = yield call(
      PushNotificationsService.getUserSubscription
    );
    if (subscription) {
      yield call(PushNotificationsService.pushSusbcribe, subscription);
    }
  } catch (error) {
    console.log(error.messages);
  }
}

export function* notificationsSaga() {
  console.log('*Main Notifications Saga');
  yield takeLatest(CONSTANTS.NOTIFICATIONS_GET, sagaGetNotifications);
  yield takeEvery(CONSTANTS.NOTIFICATIONS_READ, sagaReadNotification);
  yield takeEvery(CONSTANTS.NOTIFICATIONS_READALL, sagaReadAllNotification);
  yield takeEvery(CONSTANTS.NOTIFICATIONS_READBYMESSAGES, sagaReadNotificationsByMessages);
  yield takeEvery(
    CONSTANTS.NOTIFICATIONS_SUBSCRIBE,
    sagaNotificationsSubscribe
  );
}
