import { useEffect, useState } from 'react';

/**
 * Hook to listen to changes in page visibility, using the Visibility API
 * 
 * @returns the current visibility state
 */
const usePageVisibility = () => {
  const [data, setData] = useState("visible");

  const updateData = () => {
    setData(document.visibilityState);
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", updateData);

    return () => {
        document.removeEventListener("visibilitychange", updateData);
    }
  }, []);

  return data;
}

export default usePageVisibility;