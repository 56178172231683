
import React, { Fragment } from "react";
//import PropTypes from "prop-types";
// reactstrap components
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
//import { AppName } from "configs/app.json";

// core components
// import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

const ErrorView = ({ code, message }) => {
  return (
    <Fragment>
      {/* <PanelHeader size="sm" /> */}
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Error {code}</h5>
              </CardHeader>
              <CardBody>{message}</CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

/** Props validation */
ErrorView.defaultProps = {
  code: 404,
  message: "Page not found",
};

export default ErrorView;
