import { useFileUtils } from 'hooks';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const ChatPeopleAvatar = ({ user, showName }) => {
  const isMounted = useRef(true);
  /**
   * Load avatar
   */
  const { getAvatar, defaultImage } = useFileUtils();
  useEffect(() => {
    if (isMounted.current && user.avatar) {
      getAvatar(user.auth_user_id, 'large');
    }
    return () => {
      isMounted.current = false;
    };
    //eslint-disable-next-line
  }, [user.avatar, getAvatar]);

  return (
    <div className="chat_people text-center" id={user._id}>
      <div className="chat_img">
        {defaultImage.includes('General') ? (
          <div className="avatar-name-large">
            <span>{user.nameInitials}</span>
          </div>
        ) : (
          <img src={defaultImage} alt="Contact" />
        )}
        <div className="text-truncate" id={`user-name-${user._id}`}>
          {showName && `${user.firstName} ${user.lastName}`}
        </div>
      </div>
    </div>
  );
};

ChatPeopleAvatar.propTypes = {
  showStatus: PropTypes.bool,
  showName: PropTypes.bool,
};

ChatPeopleAvatar.defaultProps = {
  showStatus: false,
  showName: false,
};

export default ChatPeopleAvatar;
