import React, { useCallback, useEffect, useRef } from 'react';
import { Form } from 'reactstrap';
import { translate } from 'react-translate';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { actionChatMessageSend } from 'store/actions/chatsActions';
import Linkify from 'react-linkify';

const ChatSendInput = (props) => {
  const { t } = props;
  const { replyData, setReplyData, setScroll } = props;
  const dispatch = useDispatch(null);
  const { auth_user_id } = useSelector((state) => state.userReducer);
  const { isDisaffected } = useSelector((state) => state.practicesReducer);
  const inputRef = useRef();
  const { register, handleSubmit, setValue } = useForm();

  const isInDasboard = window.location.pathname.includes('dashboard');
  const { mobile, dashboardMobile } = useSelector(
    (state) => state.commonReducer
  );
  const { chat, recipients } = useSelector((state) => state.chatsReducer);
  const currentUser = chat ?
  ["private", "group"].includes(chat.type) && chat.users ? chat?.users?.find(u => String(u.auth_user_id) === String(auth_user_id))
  : chat : null;

  /**
   * Check is this chat is writable
   * @returns boolean
   */
  const allowWrite = useCallback(() => {
    if (!chat) {
      return false;
    }
    if (recipients.length <= 0 && !chat._id) {
      return false;
    }
    if (
      chat.type !== 'group' &&
      (
        (chat.main_user && 
        (!chat.is_practitioner || chat.main_user.status === 'inactive' || chat.main_user.disaffectedDate)) || 
        chat.status === 'inactive'
      )
    ) {
      return false;
    }
    if (chat.type === 'group' && currentUser && currentUser.deleted) {
      return false;
    }

    if (currentUser && currentUser.status === 'offline') {
      return false;
    }

    return true;
  }, [chat, recipients?.length, currentUser?.deleted]);

  /**
   * Save the draft when the input changes
   */

  const saveDraft = () => {

    const el = inputRef.current;
    localStorage.setItem(`input-message-draft-${chat._id}`, el.value);

  };


  /**
   * Control if key  is not  'breakline' or  ""  then submit data
   */
  const ctrlTypeKey = () => {

    const el = inputRef.current;
    if (el && el.value !== '\n' && el.value !== "" && isDisaffected === false) {
      onSubmit({ body: el.value });
    }

  };

  /**
   * Control Shift + Enter in Textarea
   */
  const typeKey = (e) => {
    //control if press 'enter' and inputext is ''
    if (inputRef.current.value === '\n') {
      e.preventDefault()
      inputRef.current.value = '';
    }
    //control not input shft + enter + ''
    if ((e.keyCode === 13 && e.shiftKey) && (inputRef.current.value === '')) {
      e.preventDefault()
    }
    //control if input text has text and press 'enter' then send data
    if (!(e.keyCode === 13 && e.shiftKey) && (e.keyCode === 13)) {
      ctrlTypeKey();
      e.preventDefault()
    }

    if ((e.keyCode === 13 && e.shiftKey)) {

      // setScroll();
    }
  }

  /**
 *  when use the scroll: if hight is > 230px
 */
  const controlScrollTextarea = (hight) => {
    //230 is the hight limit (10 breack lines)
    if (hight > 230) {
      inputRef.current.style.overflow = 'scroll';
    }
    else {
      inputRef.current.style.overflow = 'hidden';
    }

  }
  /**
   *  auto hight of text rea
   */
  const onChangeHandler = (e) => {
    inputRef.current.style.height = "5px";
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    controlScrollTextarea(inputRef.current.scrollHeight);
    setScroll();
  };

  //default hight textarea
  const reseatStyleTextarea = () => {
    inputRef.current.style.height = "38px";
    controlScrollTextarea(38);
  }

  const onSubmit = (data) => {
    const isIOS = /iPhone|iPod|iPad/.test(navigator.userAgent);

    if (mobile && isIOS) {
      //iOS specific control
      //create dummy input to redirect the focus
      const dummy = document.createElement('input');
      dummy.setAttribute('type', 'text');
      dummy.style.position = 'absolute';
      dummy.style.opacity = 0;
      dummy.style.height = 0;
      document.body.append(dummy);
      dummy.focus();

      setTimeout(() => {
        //Clear input and redirect focus
        setValue('body', '');
        document.getElementById('chat-send-input').focus();
        dummy.remove();
      }, 100)
    } else {
      document.getElementById('chat-send-input').focus();
    }

    reseatStyleTextarea();
    let message = {
      recipients: JSON.stringify(recipients),
      ...data,
    };
    if (chat._id && ['private', 'group'].includes(chat.type)) {
      message = {
        ...message,
        chat: {
          _id: chat._id,
        },
        type: chat.type ? chat.type : 'private',
      };
    } else {
      message.type = 'private';
    }
    
    message.front_message_id = Date.now();
    if (Object.keys(replyData)?.length) {
      message.reply = replyData;
      setReplyData({});
    }
    dispatch(actionChatMessageSend(message));
    localStorage.removeItem(`input-message-draft-${chat._id}`);
    setValue('body', '');
  };


  // const addEmoji = (emoji) => {
  //   const text = getValues('body');
  //   setValue('body', `${text} ${emoji.native}`);
  // };

  //Get the saved draft for the current chat, or nothing if draft does not exists
  useEffect(() => {
    const temp = localStorage.getItem(`input-message-draft-${chat._id}`);
    const draft = temp === '\n' ? '' : temp;
    const el = inputRef.current;
    if (el) {
      el.value = draft ? draft : '';
      //update hight of textarea
      onChangeHandler();
    }
  //eslint-disable-next-line
  }, [chat]);

  useEffect(() => {
    const el = inputRef.current;
    if (!mobile && el && chat?.type === 'group') {
      return el.focus();
    }
    if (!mobile && el && (chat?.main_user?.status !== 'inactive' || chat?.status !== 'inactive')) {
      return el.focus();
    }
    if (el) {
      el.blur();
    }
  }, [chat, mobile]);

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={`form-send-input${mobile || (isInDasboard && dashboardMobile) ? '-mobile-new' : ''
        }`}
    >
      <div className='form-container'>
        <div className={`type_msg ${isDisaffected === true ? 'disabled-inputs' : ''}`}>
          {replyData && replyData.author && replyData.body && (
            <div className="current_quote">
              <div className="quote_author">
                <div className="quote_author_name">{replyData.author}</div>
                <div className="quote_cancel" onClick={() => setReplyData({})}>
                  X
                </div>
              </div>

              <div className="quote_body">{replyData.body}</div>
            </div>
          )}

          <div className="input_msg_write">
            <Linkify >
              <textarea
                type="text"
                name="body"
                className="write_msg text-area-control"
                id="chat-send-input"
                //rows={rowsTextArea}
                placeholder={allowWrite() ? t('type-message') : chat.type === 'group' ? '' : t('user-inactive')}
                ref={(e) => {
                  register(e);
                  inputRef.current = e;
                }}
                required
                disabled={!allowWrite()}
                autoComplete="off"
                onInput={saveDraft}
                onChange={onChangeHandler}
                onKeyDown={e => {
                  typeKey(e)
                }}

              />
            </Linkify>
            {
              isDisaffected === false && currentUser && !currentUser?.deleted ?
              <div>
                <button
                  className="msg_send_btn"
                  type="submit"
                  disabled={!allowWrite()}
                >
                  <span className="icon-send"></span>
                </button>
              </div>
              :
              <div>  </div>
            }

          </div>
        </div>
      </div>
    </Form>
  );
};

export default translate('chat')(ChatSendInput);
