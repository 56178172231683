
/*eslint-disable*/
import React from 'react';
import { Container } from 'reactstrap';
// used for making the prop types of this component
import PropTypes from 'prop-types';

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={
          'footer d-none' + (this.props.default ? ' footer-default' : '')
        }
      >
        <Container
          fluid={this.props.fluid}
          className="d-flex justify-content-md-between"
        >
          <nav>
            <ul>
              {/* <li>
                <a
                  href="https://www.creative-tim.com?ref=nudr-footer"
                  className="mr-4-px"
                  target="_blank"
                >
                  Creative Tim
                </a>
              </li>
              <li>
                <a
                  href="https://presentation.creative-tim.com?ref=nudr-footer"
                  className="mr-4-px"
                  target="_blank"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="https://blog.creative-tim.com?ref=nudr-footer"
                  target="_blank"
                >
                  Blog
                </a>
              </li> */}
            </ul>
          </nav>
          <div className="copyright">
            &copy; {1900 + new Date().getYear()}
            {/* , Designed by{" "}
            <a href="https://www.invisionapp.com" target="_blank">
              Invision
            </a>
            . Coded by{" "}
            <a
              href="https://www.creative-tim.com?ref=nudr-footer"
              target="_blank"
            >
              Creative Tim
            </a>
            . */}
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.defaultProps = {
  default: false,
  fluid: false,
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
