import React from 'react';

// reactstrap components
import { Card, CardHeader, CardTitle, CardBody, Row, Col } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Practices from 'components/Practice/Practices';
import { useTranslate } from 'react-translate';
import { useSelector } from 'react-redux';

const PracticeManage = () => {
  const t = useTranslate('practices');
  const user = useSelector((state) => state.userReducer);
  const { mobile } = useSelector((state) => state.commonReducer);

  return (
    <>
      {/*<PanelHeader size="sm" /> */}
      <Card className="profile">
        <CardBody>
          <Card className="mb-0">
            <CardHeader>
              <CardTitle className="d-flex align-content-center">
                <div className="general-card-title-img text-primary mr-2">
                  <i className="icon-practice-settings title" />
                </div>
                <div className="general-card-title text-primary">
                  {t('title-management')}
                </div>
                
                { user.membership === "full" && !mobile && 
                (
                <NavLink
                  to="/admin/practice/form"
                  className="btn btn-info btn-sm ml-auto"
                >
                  {t('btn-new-practice')} +
                </NavLink>
                )}

              </CardTitle>
              
              {/** Mobile button */}
              { user.membership === "full" && mobile && 
                (
                <NavLink
                  to="/admin/practice/form"
                  className="btn btn-info btn-sm ml-auto"
                >
                  {t('btn-new-practice')} +
                </NavLink>
                )}
            </CardHeader>

            <CardBody>
              <h5 className="general-card-subtitle">
                {t('title-all-practices')}
              </h5>
              <Row>
                <Col md="6" sm="12">
                  <Practices title={t('title-my-practices')} type="owner" />
                </Col>
                <Col md="6" sm="12">
                  <Practices
                    title={t('title-collaboration-practices')}
                    type="collaborator"
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </>
  );
};

export default PracticeManage;
