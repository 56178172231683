import CONSTANTS from '../CONSTANTS';

const initialState = {
  chats: [],
  chat: {},
  chats_online: [],
  recipients: [],
  showInbox: false,
  error: null,
  loadingDone: false,
  filter_select:false//users actives of preactice
};

const chatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.CHATS_SET_LOADING:
      return { ...state, loadingDone: action.status };
    case CONSTANTS.CHATS_SET:
      return { ...state, chats: action.chats };
    case CONSTANTS.CHATS_SET_CHAT:
      return { ...state, chat: action.chat };
    case CONSTANTS.CHATS_SET_ONLINE:
      return {
        ...state,
        chats_online: { ...state.chats_online, ...action.chats_online },
      };
    case CONSTANTS.CHATS_SET_RECIPIENTS:
      return { ...state, recipients: action.recipients };
    case CONSTANTS.CHATS_SHOW_INBOX:
      return { ...state, showInbox: action.show };
    case CONSTANTS.CHATS_FILTER:
      return { ...state, filter_select: action.filter_select };
    case CONSTANTS.CHATS_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};

export default chatsReducer;
