
const icons = [
  "arrows-1_cloud-download-93",
  "arrows-1_cloud-upload-94",
  "arrows-1_minimal-down",
  "arrows-1_minimal-left",
  "arrows-1_minimal-right",
  "arrows-1_minimal-up",
  "arrows-1_refresh-69",
  "arrows-1_share-66 ",
  "business_badge",
  "business_bank",
  "business_briefcase-24",
  "business_bulb-63",
  "business_chart-bar-32",
  "business_chart-pie-36",
  "business_globe",
  "business_money-coins",
  "clothes_tie-bow",
  "design_app",
  "design_bullet-list-67",
  "design_image",
  "design_palette",
  "design_scissors",
  "design_vector",
  "design-2_html5",
  "design-2_ruler-pencil",
  "emoticons_satisfied",
  "files_box",
  "files_paper",
  "files_single-copy-04",
  "health_ambulance",
  "loader_gear",
  "loader_refresh",
  "location_bookmark",
  "location_compass-05",
  "location_map-big",
  "location_pin",
  "location_world",
  "media-1_album",
  "media-1_button-pause",
  "media-1_button-play",
  "media-1_button-power",
  "media-1_camera-compact",
  "media-2_note-03",
  "media-2_sound-wave",
  "objects_diamond",
  "objects_globe",
  "objects_key-25",
  "objects_planet",
  "objects_spaceship",
  "objects_support-17",
  "objects_umbrella-13",
  "education_agenda-bookmark",
  "education_atom",
  "education_glasses",
  "education_hat",
  "education_paper",
  "shopping_bag-16",
  "shopping_basket",
  "shopping_box",
  "shopping_cart-simple",
  "shopping_credit-card",
  "shopping_delivery-fast",
  "shopping_shop",
  "shopping_tag-content",
  "sport_trophy",
  "sport_user-run",
  "tech_controller-modern",
  "tech_headphones",
  "tech_laptop",
  "tech_mobile",
  "tech_tablet",
  "tech_tv",
  "tech_watch-time",
  "text_align-center",
  "text_align-left",
  "text_bold",
  "text_caps-small",
  "gestures_tap-01",
  "transportation_air-baloon",
  "transportation_bus-front-12",
  "travel_info",
  "travel_istanbul",
  "ui-1_bell-53",
  "ui-1_check",
  "ui-1_calendar-60",
  "ui-1_lock-circle-open",
  "ui-1_send",
  "ui-1_settings-gear-63",
  "ui-1_simple-add",
  "ui-1_simple-delete",
  "ui-1_simple-remove",
  "ui-1_zoom-bold",
  "ui-2_chat-round",
  "ui-2_favourite-28",
  "ui-2_like",
  "ui-2_settings-90",
  "ui-2_time-alarm",
  "ui-1_email-85",
  "users_circle-08",
  "users_single-02"
];

export default icons;
