import React, { useRef, useState } from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    CardText,
    Collapse,
    Button,
    CardTitle,
} from "reactstrap";

import TermsCardContent from "./Terms/TermsCardContent";

const LegalContent = () => {
    // collapse states and functions
    const [collapses, setCollapses] = useState(false);

    const accordionHash = useRef(null);

    const changeCollapse = collapse => {
        collapses !== collapse ? setCollapses(collapse) : setCollapses(false);
    };

    const handleScroll = (ref) => {
        if (ref !== "<p />") {
            window.scrollTo({
                top: ref.getBoundingClientRect().top - 75,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    const onEntered = () => handleScroll(accordionHash.current)
    const onExited = () => {
        if (collapses === false) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
    }
    return (
        <>
            <Card className="card-nav-tabs card-plain" aria-multiselectable={true}>
                <CardHeader className="card-header-warning"  >

                    <Nav className="justify-content-center">
                        <p className="py-2 px-3 mr-5 m-0" ref={accordionHash}>Alpha Research Deutschland GmbH</p>
                        <div className="row md-row-12 justify-content-center">
                            <NavItem>
                                <NavLink
                                    data-toggle="collapse"
                                    className={collapses === 1 ? "active text-info" : "text-dark"}
                                    href="#privacy"
                                    onClick={e => {
                                        e.preventDefault();
                                        changeCollapse(1);
                                    }}
                                >
                                    Datenschutz
                                </NavLink>
                            </NavItem>
                            <p className="py-2 px-3 m-0">|</p>
                            <NavItem>
                                <NavLink
                                    data-toggle="collapse"
                                    className={collapses === 2 ? "active text-info" : "text-dark"}
                                    href="#myData"
                                    onClick={e => {
                                        e.preventDefault();
                                        changeCollapse(2);

                                    }}
                                >
                                    Meine Daten
                                </NavLink>
                            </NavItem>
                            <p className="py-2 px-3 m-0 d-none d-sm-block">|</p>
                            <p className="py-2 px-3 m-0 d-md-none"> </p>
                            <NavItem>
                                <NavLink
                                    data-toggle="collapse"
                                    className={collapses === 3 ? "active text-info" : "text-dark"}
                                    href="#terms"
                                    onClick={e => {
                                        e.preventDefault();
                                        changeCollapse(3);

                                    }}
                                >
                                    AGB's
                                </NavLink>
                            </NavItem>
                            <p className="py-2 px-3 m-0">|</p>
                            <NavItem>
                                <NavLink
                                    data-toggle="collapse"
                                    className={collapses === 4 ? "active text-info" : "text-dark"}
                                    href="#imprint"
                                    onClick={e => {
                                        e.preventDefault();
                                        changeCollapse(4);

                                    }}
                                >
                                    Impressum
                                </NavLink>
                            </NavItem>
                        </div>
                    </Nav>
                </CardHeader>
                <CardBody>
                    <Collapse isOpen={collapses === 1}
                        onEntered={onEntered}
                        onExited={onExited}
                    >
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Allgemeine Informationen
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Die TelePraxen Plattform, das Online-Portal für Medizin + Gesundheit, ist ein Service- und Informationsportal für Patienten und Gesundheitsdienstleister. Ärzte, Zahnärzte, Therapeuten und Gesundheitseinrichtungen stellen sich und Ihre Leistungen in Ihrer Online-Praxis vor.
                        </CardText>
                        <ul>
                            <li>Die TelePraxen APP dient dem sicheren Datenaustausch innerhalb und zwischen Gesundheitsdienstleistern, sowie zwischen Gesundheitsdienstleistern und Patienten.</li>
                            <li>Die Buchungs-Online-System (BOS) bietet Patienten die Möglichkeit, online-Termine mit Ärzten, Zahnärzten, Therapeuten oder Gesundheitseinrichtungen zu vereinbaren.</li>
                            <li>Das Ferndiagnostikmodul (FDM) bietet Patienten die Möglichkeit, ferndiagnostische Untersuchungen mit Ärzten, Zahnärzten, Therapeuten oder Gesundheitseinrichtungen zu vereinbaren.</li>
                        </ul>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Die TelePraxen sind ein Projekt der <br /><br />
                            Alpha Research Deutschland GmbH <br />
                            Goslarer Platz 7, 10589 Berlin <br />
                            Geschäftsführer: Priv.-Doz. Dr.med. Heinz R. Zurbrügg <br />
                            HRB-Nr.: AG Charlottenburg 58 0 57 <br />
                            USt.-ID-Nr.: DE 179 221 234 <br />
                            E-Mail: <a className="btn-link text-success p-0 m-0" href="mailto:info@telepraxen.de">info@telepraxen.de</a>
                            <br /><br />
                            Jegliche Erhebung, Verarbeitung und Nutzung von Daten dienen ausschließlich der Erbringung unserer Dienstleistungen. Eine Weitergabe der Daten zu Marketingzwecken oder einen Verkauf der Daten findet in keinem Fall statt. Wir stellen an den Datenschutz und die Datensicherheit stets höchste Anforderungen. Nur wenn Datenschutz und Datensicherheit zweifelsfrei gewährleistet sind, werden Kunden unser Angebot nachhaltig und mit Vertrauen nutzen. Wir gestalten unsere Dienste stets mit dem Ziel, so wenig personenbezogenen Daten wie möglich zu erheben, zu verarbeiten und zu nutzen.
                            <br /><br />
                            Die folgenden Ausführungen zum Datenschutz beschreiben, welche Arten von Daten wir von Ihnen erheben und zu welchem Zweck wir diese Daten verarbeiten, erheben und nutzen und wie wir kommunizieren. Mit der Nutzung unseres Internetangebotes und der Einwilligung in die Datenschutzerklärung willigen Sie in die hier genannte Erhebung, Verarbeitung und Nutzung der Daten ein. Sollten Sie Fragen hierzu haben, wenden Sie sich bitte an die unten genannte Adresse.
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Registrierung auf unserer Webseite / Personenbezogene Daten
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Soweit auf unseren Seiten personenbezogene Daten (zum Beispiel Name, Anschrift oder E-Mail-Adresse) über Sie als Nutzer erhoben werden, erfolgt dies im freiwilligen Einvernehmen und in Kenntnis mit Ihnen als Nutzer, wie wir sie von Ihnen erhalten haben.
                            <br /><br />
                            Die personenbezogenen Daten werden gemäß den gesetzlichen Bestimmungen der DSGVO, des Bundesdatenschutzgesetzes und des Telemediengesetzes behandelt. Eine Weitergabe der Daten an Dritte erfolgt ohne Ihre Einwilligung nur, wenn wir hierzu gesetzlich verpflichtet sind. Der Schutz der Daten erstreckt sich nicht auf Webseiten anderer Anbieter, die über Links von unserer Website erreicht werden können.
                            <br /><br />
                            Es wird darauf hingewiesen, dass die Datenübertragung im Internet (zum Beispiel bei der unverschlüsselten Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Sie sollten bedenken, dass die Vertraulichkeit der übermittelten Informationen per E-Mail nicht garantiert werden kann. E-Mails können von Dritten gelesen werden. Sie können jederzeit Informationen auch auf dem Postweg zu übermitteln.
                            <br /><br />
                            Wir bieten die Möglichkeit zur Registrierung für personalisierte Dienste an. Sind Sie bei uns registriert, können Sie auf Inhalte und Leistungen zugreifen, die wir nur registrierten Nutzern anbieten. Als Nutzer haben Sie die Möglichkeit, bei Bedarf die bei Registrierung angegebenen Daten zu ändern oder zu löschen. Wir erteilen Ihnen darüber hinaus jederzeit Auskunft über die von uns über Sie gespeicherten personenbezogenen Daten, soweit keine gesetzlichen Aufbewahrungspflichten vorliegen. Zur Kontaktaufnahme benutzen Sie bitte <a className="btn-link text-success p-0 m-0" href="mailto:datenschutz@telepraxen.de">datenschutz@telepraxen.de</a>.
                            <br /><br />
                            Wenn Sie eine Dienstleistung über unsere Internetseite oder APP buchen, erheben, verarbeiten und nutzen wir i.d.R. folgende Daten: Anrede, Titel, Vorname, Name, Geburtsdatum, E-Mail, Telefonnummer, Straße, Hausnummer, PLZ, Ort, Neu-/Bestandspatient, Kostenträger. Diese Daten dienen der Erbringung der Dienstleistung und der Verifizierung der Kommunikationswege. Die von Ihnen eingegebenen Daten werden von uns gespeichert, verarbeitet und an den von Ihnen gewählten Dienstleister zum Zwecke der Erfüllung und Rechnungsstellung übermittelt. Eine Datenweitergabe für Marketingzwecke oder zum Verkauf findet nicht statt. Die personenbezogenen Daten werden jedoch von uns für Marketing und Kundenbetreuung genutzt.
                            <br /><br />
                            Die Einwilligung des Nutzers zu den erhobenen Daten kann jederzeit widerrufen werden. Zudem kann jederzeit über diese Daten gemäß den gesetzlichen Vorgaben Auskunft vom Betroffenen (Nutzer) verlangt werden. Ebenso kann von diesem jederzeit eine Berichtigung oder Sperrung der Daten erfolgen, wenn dies gewünscht und mitgeteilt wird. In diesem Fall ist eine E-Mail an folgenden Adressen zu senden:  <a className="btn-link text-success p-0 m-0" href="mailto:datenschutz@telepraxen.de">datenschutz@telepraxen.de</a>
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Erfassung allgemeiner Informationen
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Beim Besuch auf unsere Webseite und bei jedem Abruf einer Datei werden automatisch von unserem Webserver über diese Vorgänge Protokollinformationen (z.B. Browsertyp, Datum und Uhrzeit des Abrufes, das verwendet Betriebssystem etc.) erfasst. Bei diesen Informationen (Server-Logfiles) handelt es sich um nicht personenbezogene Daten. Die Daten können von uns nicht einer bestimmten natürlichen Person zugeordnet werden. Diese Informationen sind technisch notwendig. Ohne diese ist es nicht möglich, die von Ihnen angeforderten Inhalte unserer Webseiten korrekt darzustellen. Sie fallen deshalb bei Nutzung des Internets automatisch an. Diese anonymen Informationen werden von uns statistisch ausgewertet, um den Internetauftritt und die dahinterstehende Technik zu optimieren.
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Cookies
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Wie viele andere Betreiber von Webseiten verwenden wir auch so genannte „Cookies“. Cookies sind kleine Textdateien, die von einem Webseitenserver auf die Festplatte Ihres Endgeräts übertragen werden. Hierdurch erhalten wir automatisch bestimmte Daten wie z. B. IP-Adresse, verwendeter Browser, Betriebssystem über Ihren Computer und Ihre Verbindung zum Internet.
                            <br /><br />
                            Cookies können nicht verwendet werden, um Programme zu starten oder Viren auf einen Computer zu übertragen. Anhand der in Cookies enthaltenen Informationen können wir Ihnen die Navigation erleichtern und die korrekte Anzeige unserer Webseiten ermöglichen.
                            <br /><br />
                            In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung mit personenbezogenen Daten hergestellt.
                            <br /><br />
                            Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich nutzen können.
                            <br /><br />
                            Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ist Art. 6 Abs. 1 Unterabs. 1 lit. f DS-GVO.
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Registrierung auf unserer Webseite
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Wir bieten die Möglichkeit zur Registrierung für personalisierte Dienste an. Dazu werden einige personenbezogene Daten erhoben wie Name, Anschrift, Kontakt- und Kommunikationsdaten wie Telefonnummer und E-Mail-Adresse. Sind Sie bei uns registriert, können Sie auf Inhalte und Leistungen zugreifen, die wir nur registrierten Nutzern anbieten. Als Nutzer haben Sie die Möglichkeit, bei Bedarf die bei Registrierung angegebenen Daten zu ändern oder zu löschen. Selbstverständlich erteilen wir Ihnen darüber hinaus jederzeit Auskunft über die von uns über Sie gespeicherten personenbezogenen Daten, soweit keine gesetzlichen Aufbewahrungspflichten vorliegen. Zur Kontaktaufnahme benutzen Sie bitte <a className="btn-link text-success p-0 m-0" href="mailto:datenschutz@telepraxen.de">datenschutz@telepraxen.de</a>.
                            <br /><br />
                            Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ist Art. 6 Abs. 1 Unterabs. 1 lit. f DS-GVO.
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            SSL-Verschlüsselung
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Dem aktuellen Stand der Technik entsprechend werden Verschlüsselungsverfahren (z.B. SSL) über HTTPS verwendet, um die Sicherheit Ihrer Daten bei der Übertragung zu gewährleisten.
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Kontaktaufnahme
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Treten Sie per E-Mail oder Kontaktformular mit uns in Kontakt, werden die von Ihnen gemachten Angaben gespeichert, damit bei Bedarf Anschlussfragen gestellt und Ihre Anfragen bearbeitet werden können. Sie sollten bedenken, dass die Vertraulichkeit der übermittelten Informationen per E-Mail nicht garantiert werden kann. E-Mails können von Dritten gelesen werden. Sie können jederzeit Informationen auch auf dem Postweg zu übermitteln.
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Löschung bzw. Sperrung der Daten
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Die Grundsätze der Datenvermeidung und Datensparsamkeit werden von uns eingehalten. Ihre personenbezogenen Daten werden nur so lange gespeichert, wie dies zur Erreichung der oben genannten Zwecke erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen Speicherfristen vorsehen. Sobald der jeweilige Zweck entfällt oder die Fristen ablaufen, werden die entsprechenden Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Auftragsverarbeitung
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Die Betreiber der Website haben mit Google Inc., vertreten in der EU durch die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, einen Vertrag zur Auftragsverarbeitung geschlossen und setzen hierbei die Vorschriften der deutschen und europäischen Datenschutzbehörden um.
                            <br /><br />
                            Die Rechtsgrundlage für die Nutzung dieses Dienstes erfolgt auf Grundlage von Art. 6 Abs. 1 Unterabs. 1 lit. f DS-GVO; Art. 49 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG.
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Google Analytics
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Diese Website verwendet Google Analytics, einen Werbeanalysedienst der Google Inc., vertreten in der EU durch die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.
                            <br /><br />
                            Google Analytics verwendet sogenannte „Cookies“. Diese Textdateien werden auf Ihrem Computer gespeichert und erlauben die Analyse Ihrer Nutzung unserer Websites. Die durch die Cookies erzeugten Informationen über Ihre Nutzung unserer Website (einschließlich Ihrer IP-Adresse) werden an einen Server der Google Inc. in den USA übertragen und dort gespeichert. Google verwendet diese Informationen, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen.
                            <br /><br />
                            Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
                            <br /><br />
                            Google wird diese Informationen gegebenenfalls auch an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag von Google bearbeiten.
                            <br /><br />
                            Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor genannten Zweck einverstanden.
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Verwendung von Google Maps
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Damit Sie die genannten Standorte einfach finden, wird Ihnen Google Maps API zur Verfügung gestellt. Bei der Nutzung von Google Maps werden von Google auch Daten über die Nutzung der Kartenfunktionen durch Besucher erhoben, verarbeitet und genutzt. Nähere Informationen über die Datenverarbeitung durch Google können Sie <a className="btn-link text-success p-0 m-0" href="http://www.google.com/privacypolicy.html" target='_blank'>den Google-Datenschutzhinweisen</a> entnehmen. Sie erhalten dort auch die Möglichkeiten Ihre persönlichen Datenschutz-Einstellungen zu verändern.
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Eingebettete YouTube-Videos
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Auf einigen unserer Webseiten bieten wir Ihnen die Möglichkeit sich die Praxen anzusehen oder den Ablauf von Behandlungen. Die entsprechenden Videos werden über Youtube betrieben. Wenn Sie eine Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern von Youtube hergestellt. Dabei wird Youtube mitgeteilt, welche Seiten Sie besuchen. Sobald Sie zusätzlich in Ihrem Youtube-Account eingeloggt sind, kann Youtube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Um dies zu verhindern müssen Sie sich vorher aus Ihrem Youtube-Account ausloggen.
                            <br /><br />
                            Wird ein Youtube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten sammeln.
                            <br /><br />
                            Haben Sie das Speichern von Cookies für das Google-Ad-Programm deaktiviert, müssen Sie auch beim Anschauen der Videos nicht mit solchen Cookies rechnen. Youtube legt aber auch in anderen Cookies nicht-personenbezogene Nutzungsinformationen ab. Möchten Sie dies verhindern, so müssen Sie das Speichern von Cookies im Browser blockieren.
                            <br /><br />
                            Weitere Informationen zum Datenschutz bei „Youtube“ finden Sie unter: <a className="btn-link text-success p-0 m-0" href="https://www.google.de/intl/de/policies/privacy/" target='_blank'>https://www.google.de/intl/de/policies/privacy/</a>
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Ihre Rechte auf Auskunft, Berichtigung, Sperre, Löschung und Widerspruch
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Sie haben das Recht, jederzeit Auskunft über Ihre bei uns gespeicherten personenbezogenen Daten zu erhalten. Ebenso haben Sie das Recht auf Berichtigung, Sperrung oder Löschung Ihrer personenbezogenen Daten. Ausgenommen sind Daten welche von Gesetzes wegen anderen Regelungen unterliegen. Bitte wenden Sie sich dazu an unseren Datenschutzbeauftragten. Die Kontaktdaten finden Sie ganz unten.
                            <br /><br />
                            Damit die Sperre von Daten jederzeit berücksichtigt werden kann, müssen diese Daten zu Kontrollzwecken in einer Sperrdatei vorgehalten werden. Sie können auch die Löschung der Daten verlangen, soweit keine gesetzliche Archivierungsverpflichtung besteht. Soweit eine solche Verpflichtung besteht, sperren wir Ihre Daten auf Wunsch.
                            <br /><br />
                            Sie können Änderungen oder den Widerruf einer Einwilligung durch entsprechende Mitteilung an uns mit Wirkung für die Zukunft vornehmen.
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Änderung unserer Datenschutzbestimmungen
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Diese Datenschutzerklärung wird bei Bedarf an neue gesetzliche Bestimmungen angepasst, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z. B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Fragen an den Datenschutzbeauftragten
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns unter <a className="btn-link text-success p-0 m-0" href="mailto:datenschutz@telepraxen.de">datenschutz@telepraxen.de</a>
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Recht auf Auskunft, Berichtigung, Löschung und Sperrung
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Sie haben das Recht auf Auskunft, Berichtigung, Löschung und Sperrung Ihrer Daten. Die Auskunft betrifft Daten, die zu Ihrer Person gespeichert wurden, die Herkunft der Daten, den Empfänger oder die Kategorien von Empfängern an die Daten übermittelt wurden und den Zweck der Speicherung. Zur Wahrnehmung dieser Rechte können Sie uns jederzeit kontaktieren. Die datenschutzrechtlich verantwortliche Stelle ist:
                        </CardText>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Alpha Research Deutschland GmbH<br />
                            Goslarer Platz 7, 10589 Berlin<br />
                            Geschäftsführer: Priv.-Doz. Dr.med. Heinz R. Zurbrügg<br />
                            HRB-Nr.: AG Charlottenburg 58 0 57<br />
                            USt.-ID-Nr.: DE 179 221 234<br />
                            E-Mail: <a className="btn-link text-success p-0 m-0" href="mailto:datenschutz@telepraxen.de">datenschutz@telepraxen.de</a><br />
                            Telefon: +49 30 32 79 80 90<br />
                            Telefax: +49 30 32 79 80 97<br /><br />
                            Gültig ab 24.11.2022
                        </CardText>
                        <Button
                            type="button"
                            color="info"
                            size="sm"
                            className="mb-3 btn-block"
                            onClick={() => changeCollapse(false)}
                        >
                            Close
                        </Button>
                    </Collapse>
                    <Collapse isOpen={collapses === 2}
                        onEntered={onEntered}
                        onExited={onExited}
                    >
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Einverständniserklärung zur Bearbeitung, Speicherung und zum Austausch personenbezogener Daten durch die Ärzte und Zahnärzte der TelePraxen
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Ich erkläre mich bis auf schriftlichen Widerruf einverstanden mit der Verarbeitung und Speicherung meiner Daten inkl. medizinischer Informationen bei den TelePraxen-Ärzten und -Zahnärzten nach den gesetzlichen Vorgaben zur Verwendung dieser innerhalb der TelePraxen oder durch Vertragspraxen der TelePraxen-Ärzte und Zahnärzte (z.B. CityPraxenBERLIN, Ärzte für Labormedizin, Radiologie, etc.) und dem Zugriff durch die TelePraxen-Ärzte, -Zahnärzte, -Therapeuten und -Vertragsärzte auf diese Daten.
                        </CardText>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Die TelePraxen-Ärzte und -Zahnärzte beauftragen die Alpha Research Deutschland GmbH mit der Speicherung und Archivierung personenbezogener Daten gemäß den gesetzlichen Bestimmungen der DSGVO und des Bundesdatenschutzgesetzes. Deren Mitarbeiter unterliegen der beruflichen Schweigepflicht und den Bestimmungen des Datenschutzes wie ein Arzt oder Zahnarzt, bzw. deren Praxispersonal.
                        </CardText>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Die TelePraxen oder Verrechnungsstellen werden die Abrechnung der ärztlichen/therapeutischen Leistungen vornehmen, die Rechnung erstellen und die Honorarforderung bei Ihnen einziehen.
                        </CardText>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Weiter erkläre ich mich einverstanden, dass Nachrichten inkl. medizinischer Befunde mir unverschlüsselt per E-Mail zugesandt werden können.
                        </CardText>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Weitergehende Informationen zum Thema Datenschutz bei den TelePraxen und zu Ihren Rechten erfahren Sie unter <a className="btn-link text-success p-0 m-0" href="https://www.telepraxen.de/datenschutz" target='_blank'>www.telepraxen.de/datenschutz</a>.
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Recht auf Auskunft, Berichtigung, Löschung und Sperrung
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Sie haben das Recht auf Auskunft, Berichtigung, Löschung und Sperrung Ihrer Daten: Nur dann, wenn das nicht den gesetzlichen Vorschriften widerspricht. <br />
                            Die Auskunft betrifft Daten, die zu Ihrer Person gespeichert wurden, die Herkunft der Daten, den Empfänger oder die Kategorien von Empfängern an die Daten übermittelt wurden und den Zweck der Speicherung. Zur Wahrnehmung dieser Rechte können Sie uns jederzeit kontaktieren. Die datenschutzrechtlich verantwortliche Stelle ist:
                        </CardText>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Alpha Research Deutschland GmbH<br />
                            Goslarer Platz 7, 10589 Berlin<br />
                            Geschäftsführer: Priv.-Doz. Dr. med. Heinz R. Zurbrügg<br />
                            HRB-Nr.: AG Charlottenburg 58 0 57<br />
                            USt.-ID-Nr.: DE 179 221 234<br />
                            E-Mail: <a className="btn-link text-success p-0 m-0" href="mailto:datenschutz@telepraxen.de">datenschutz@telepraxen.de</a><br />
                            Telefon: +49 30 32 79 80 90<br />
                            Telefax: +49 30 32 79 80 97<br /><br />
                            Gültig ab 24.11.2022
                        </CardText>
                        <Button
                            type="button"
                            color="info"
                            size="sm"
                            className="mb-3 btn-block"
                            onClick={() => changeCollapse(false)}
                        >
                            Close
                        </Button>
                    </Collapse>
                    <Collapse isOpen={collapses === 3}
                        onEntered={onEntered}
                        onExited={onExited}
                    >
                        <TermsCardContent />
                        <Button
                            type="button"
                            color="info"
                            size="sm"
                            className="mb-3 btn-block"
                            onClick={() => changeCollapse(false)}
                        >
                            Close
                        </Button>
                    </Collapse>
                    <Collapse isOpen={collapses === 4}
                        onEntered={onEntered}
                        onExited={onExited}
                    >
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Beauftragter für Medizinproduktesicherheit
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Priv.-Doz. Dr. med. Heinz R. Zurbrügg<br />
                            <br />
                            E-Mail: <a className="btn-link text-success p-0 m-0" href="mailto:info@telepraxen.de">info@telepraxen.de</a><br />
                            Telefon: +49 30 32 79 80 92 20<br />
                            Telefax: +49 30 32 79 80 97
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Verantwortlich gemäß TMG
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Alpha Research Deutschland GmbH<br />
                            Goslarer Platz 7<br />
                            10589 Berlin<br />
                            Geschäftsführer: Priv.-Doz. Dr.med. Heinz R. Zurbrügg<br />
                            HRB-Nr.: AG Charlottenburg 58 0 57<br />
                            USt.-ID-Nr.: DE 179 221 234<br />
                            <br />
                            E-Mail: <a className="btn-link text-success p-0 m-0" href="mailto:impressum@alpha-research.com">impressum@alpha-research.com</a><br />
                            Telefon: +49 30 32 79 80 90<br />
                            Telefax: +49 30 32 79 80 97
                        </CardText>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Die im Rahmen dieser Website benannten Ärzte, deren gesetzliche
                            Berufsbezeichnung Ärztin bzw. Arzt lautet, sind Mitglied der folgenden
                            Ärztekammern:<br />
                            Berlin – Ärztekammer Berlin
                        </CardText>
                        {/* esto fue comentado debido a que ya existe en meine daten */}
                        {/* <CardText className="text-wrap text-break text-dark paragraph">
                            Die Auskunft betrifft Daten, die zu Ihrer Person gespeichert wurden, die Herkunft der Daten, den Empfänger oder die Kategorien von Empfängern an die Daten übermittelt wurden und den Zweck der Speicherung. Zur Wahrnehmung dieser Rechte können Sie uns jederzeit kontaktieren. Die datenschutzrechtlich verantwortliche Stelle ist:
                        </CardText> */}
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Die im Rahmen dieser Website benannten Zahnärzte, deren gesetzliche Berufsbezeichnung Zahnärztin bzw. Zahnarzt lautet, sind Mitglieder der folgenden Zahnärztekammern:
                            <br />
                            Berlin – Zahnärztekammer Berlin
                        </CardText>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Für die Zahnärzte gelten folgende berufsrechtliche Regelungen: Zahnheilkundegesetz, Berufsordnungen der jeweiligen Zahnärztekammer, Sozialgesetzbuch V, Gebührenordnung für Zahnärzte.
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Bild-, Urheber- und Quellenangabe
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Unter dem nachfolgenden Link finden Sie alle Quellen, von denen wir Nutzungsrechte
                            bezogen und auf diesen Seiten verwendet haben. Bitte beachten Sie, dass nicht
                            auszuschließen ist, dass Quellen trotz entsprechender Lizenzrechte nicht oder nicht
                            mehr verwendet werden:
                            <a className="btn-link text-success p-0 m-0" href="https://telepraxen.de/quellenangabe/" target='_blank'>https://telepraxen.de/quellenangabe/</a>
                        </CardText>
                        <CardTitle tag="h5" className="d-flex justify-content-center text-info text-center">
                            Disclaimer / Haftungsausschluss
                        </CardTitle>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Es wird keine Garantie für die Vollständigkeit, die Richtigkeit und die Aktualität der auf dieser Webseite bereitgestellten Informationen sowie für die Inhalte sämtlicher von dieser Internetseite verlinkten Internetseiten übernommen.
                        </CardText>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Für die Inhalte externer Internetseiten, die durch einen Link von dieser Internetseite unmittelbar oder mittelbar erreicht werden können oder auf die verwiesen wird, wird keine Haftung übernommen. Für den Inhalt dieser externen Internetseiten sind ausschließlich deren jeweilige Betreiber verantwortlich. Zum Zeitpunkt der Setzung der Links waren keine illegalen Inhalte auf den verlinkten Internetseiten erkennbar.
                        </CardText>
                        <CardText className="text-wrap text-break text-dark paragraph">
                            Es gilt deutsches Recht. Gerichtsstand ist Berlin, Deutschland.<br /><br />
                            Copyright ©<br />
                            Alpha Research Deutschland<br />
                            GmbH<br />
                            Goslarer Platz 7<br />
                            10589 Berlin<br />
                            Alle Rechte vorbehalten
                        </CardText>
                        <Button
                            type="button"
                            color="info"
                            size="sm"
                            className="mb-3 btn-block"
                            onClick={() => changeCollapse(false)}
                        >
                            Close
                        </Button>
                    </Collapse>
                </CardBody>
            </Card >
        </>
    );
}

export default LegalContent;