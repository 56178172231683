import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// reactstrap components
import { Input, FormGroup, Form, Label, Button, Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import ProfileAvatar from './ProfileAvatar';
import { actionProfileUpdate } from 'store/actions/profileActions';
import { useHistory } from 'react-router';
import { useTranslate } from 'react-translate';

const ProfileForm = () => {
  const t = useTranslate('profile');
  const history = useHistory();
  const user = useSelector((state) => state.userReducer);
  const [avatar, setAvatar] = useState(null);
  const [shortname, setShortname] = useState(user.nameInitials);

  const dispatch = useDispatch();

  const emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const userSchema = yup.object().shape({
    firstName: yup.string().required(t('error-name-required')),
    lastName: yup.string().required(t('error-lastname-required')),
    location: yup.string(),
    position: yup.string(),
    title: yup.string(),
    email: yup
      .string()
      .matches(emailRex, t('error-email-invalid'))
      .required(t('error-email-required')),
    phone: yup.string(),
    gender: yup.string().required(t('error-gender-required')),
  });

  const { register, handleSubmit, errors } = useForm({
    validationSchema: userSchema,
    mode: 'onChange',
    defaultValues: user,
  });

  //Change shortname if either firstName or lastName changes
  const onChangeName = () => {
    const first = document.getElementById('firstName').value;
    const last = document.getElementById('lastName').value;

    setShortname(
      last.toUpperCase().substr(0, 2) + first.toUpperCase().substr(0, 1)
    );
  };

  const onEmailChange = (e) => {
    e.target.value = e.target.value.toLowerCase();
  }

  const onSubmit = (data) => {
    data.avatar_input = avatar;

    //Append custom initials if avatar is not set, and names remains the same
    if (
      !avatar &&
      (data.firstName === user.firstName || data.lastName === user.lastName)
    ) {
      data.shortname_input = shortname;
    }
    dispatch(actionProfileUpdate(data));
  };

  //Update name initials
  useEffect(() => {
    if (user.auth_user_id) {
      setShortname(user.nameInitials);
    }
    //eslint-disable-next-line
  }, [user.updated_at]);

  useEffect(() => {
    // const validationStyle = '';
    if (Object.keys(errors).length !== 0) {
      toast.error(t('update-failed'));
    }
    //eslint-disable-next-line
  }, [errors]);
  return (
    <Form className="d-flex profile-form" onSubmit={handleSubmit(onSubmit)}>
      {user._id && <input type="hidden" name="_id" ref={register} />}
      <input type="hidden" name="auth_user_id" ref={register} />
      <ProfileAvatar
        firstName={user.firstName}
        lastName={user.lastName}
        setAvatar={setAvatar}
        avatar={avatar}
        setShortname={setShortname}
        shortname={shortname}
      />
      <div className="form-col">
        <Row>
          <Col sm="12" md="6">
            <Label>{t('pronoun')}</Label>
            <FormGroup
              className={`has-label ${errors.pronoun ? 'has-danger' : ''}`}
            >
              <Input
                type="select"
                name="pronoun"
                id="pronoun"
                innerRef={register}
              >
                <option></option>
                <option>{t('mr')}</option>
                <option>{t('mrs')}</option>
              </Input>
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <Label>{t('profile-title')}</Label>
            <FormGroup
              className={`has-label ${errors.title ? 'has-danger' : ''}`}
            >
              <Input type="text" innerRef={register} name="title" />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6">
            <Label>{t('first-name')}</Label>
            <FormGroup
              className={`has-label ${errors.firstName ? 'has-danger' : ''}`}
            >
              <Input
                innerRef={register}
                name="firstName"
                id="firstName"
                type="text"
                onChange={onChangeName}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <Label>{t('last-name')}</Label>
            <FormGroup
              className={`has-label ${errors.lastName ? 'has-danger' : ''}`}
            >
              <Input
                type="text"
                innerRef={register}
                name="lastName"
                id="lastName"
                onChange={onChangeName}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6">
            <Label>{t('location')}</Label>
            <FormGroup
              className={`has-label ${errors.location ? 'has-danger' : ''}`}
            >
              <Input type="text" innerRef={register} name="location" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <Label>{t('position')}</Label>
            <FormGroup
              className={`has-label  ${errors.position ? 'has-danger' : ''}`}
            >
              <Input type="text" innerRef={register} name="position" />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6">
            <Label>{t('email')}</Label>
            <FormGroup
              className={`has-label ${errors.email ? 'has-danger' : ''}`}
            >
              <Input 
              type="email" 
              innerRef={register} 
              name="email"
              onChange={(e) => {
                onEmailChange(e);
              }}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <Label>{t('gender')}</Label>
            <FormGroup>
              <Input
                type="select"
                name="gender"
                id="gender"
                innerRef={register}
              >
                <option value="Male">{t('gender-male')}</option>
                <option value="Female">{t('gender-female')}</option>
                <option value="Other">{t('gender-other')}</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6">
            <Label>{t('phone')}</Label>
            <FormGroup
              className={`has-label ${errors.phone ? 'has-danger' : ''}`}
            >
              <Input type="tel" innerRef={register} name="phone" />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <div className="btn-profile">
              <Label>&nbsp;</Label>
              <Button
                className="ml-auto mr-2"
                color="info"
                type="submit"
                size="sm"
                name="btn-save"
              >
                {t('save-button')}
              </Button>

              <Button
                color="primary"
                type="cancel"
                size="sm"
                name="btn-cancel"
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                {t('cancel-button')}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default ProfileForm;
