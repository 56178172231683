import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PracticeTitle from 'components/Practice/PracticeTitle';
import PractitionerList from 'components/Practice/PractitionerList';
import InvitationList from 'components/Practice/Invitations/InvitationList';
import useAuthorizationValidator from 'plugins/TS-lib-utils-public/hooks/useAuthorizationValidator';
import { useTranslate } from 'react-translate';
// import { AppName } from "configs/app.json";

const PracticeUserList = () => {
  const t = useTranslate('practices');
  const dispatch = useDispatch();
  const { practices, practice_manage_select, practice } = useSelector(state => state.practicesReducer);
  const [dataPractice, setDataPractice] = useState([]);
  const [dataPracticioners, setDataPracticioners] = useState([]);
  const [titlePractice, setTitlePractice] = useState([]);
  const [list, setList] = useState('practitioners');
  const { checkAccess } = useAuthorizationValidator();
  const authInviteUser = checkAccess('practices-manager','invite-user');
  const authShowInvitations = checkAccess('practices-manager','show-invitations');
  const authShowPractitioners = checkAccess('practices-manager','practitioner-list');

  useEffect(() => {
    if (practices.length > 0 && Object.keys(practice_manage_select).length > 0) {
      var res = practice._id === practice_manage_select._id ?
      practice :
      practices.filter(function (pr) {
        return pr._id === practice_manage_select._id;
      })[0];

      setDataPractice(res);
      res.people = res.people.filter(p => p.type === 'doctor');
      setDataPracticioners(res.people);
      setTitlePractice(practice_manage_select.name);
    }

    //eslint-disable-next-line
  }, [practices, practice_manage_select, practice]);
  
  return (
    <Card>
      <CardBody>
        <Card>
          <CardHeader className="d-flex">

          { titlePractice && (
            <CardTitle className="d-flex align-content-center">
              <PracticeTitle title ={titlePractice}/>
            </CardTitle>
          )}

            <div className="ml-auto">
              {authInviteUser && (
                <NavLink
                  to="/admin/practice/users/invite"
                  className="btn btn-info btn-sm"
                >
                  {t('subtitle-invite-user')}
                </NavLink>
              )}
              {/* <NavLink
                        to="/admin/practice/users/add"
                        className="btn btn-info"
                      >
                        Add User
                      </NavLink> */}
              {list === 'practitioners' && authShowInvitations && (
                <Button size="sm" color="info" onClick={() => setList('invitations')}>
                  {t('btn-show-invitations')}
                </Button>
              )}
              {list === 'invitations' && authShowPractitioners && (
                <Button size="sm" color="info" onClick={() => setList('practitioners')}>
                  {t('btn-show-practitioners')}
                </Button>
              )}
            </div>
          </CardHeader>
          <CardBody>
            {list === 'practitioners'  && dataPracticioners && dataPractice && <PractitionerList practicioners = {dataPracticioners} practice ={dataPractice}/>}
            {list === 'invitations' && <InvitationList />}
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
};

export default PracticeUserList;
