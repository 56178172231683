import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetCategoryMenu } from 'store/actions/categoryMenuActions.js';
import routes from '../routes';
/**
 * For show sidebar links by context
 */
const useCategoryMenu = () => {
  const isMounted = useRef(null);
  const dispatch = useDispatch();

  const categoryMenu = useSelector(
    (state) => state.categoryMenuReducer.category
  );

  const showLink = (route) => {
    return route.sidebar || typeof route.category === "undefined" || route.category === categoryMenu;
  }

  const setCategory = (category) => {
    dispatch(actionSetCategoryMenu(category));
  }

  const path = window.location.pathname.split('/admin')[1];

  useEffect(() => {
    isMounted.current = true;
    const route = routes.find(
      (route) => path === route.path
    );
    if (isMounted.current && route && route.category !== categoryMenu) {
        //set default category
        dispatch(actionSetCategoryMenu('default'));
    }
    return () => {
      isMounted.current = false;
    };
  }, [categoryMenu, dispatch, path]);

  return {
    showLink,
    setCategory,
    categoryMenu,
  };
}

export default useCategoryMenu;