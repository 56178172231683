import React from 'react';
import { Table, Input, Button, Row, Col, Label, TabPane, Card, CardBody } from 'reactstrap';

// Include Translation tool
import { useTranslate } from "react-translate";

//Text editor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import classNames from 'classnames';

//Include the stored comments list
import KiLabEditor_stored_list from "components/KiLab/KiLabEditor_stored_list";

const KiLabEditor_analytes = () => {

    //Translation library
    const tEditor = useTranslate('kilab-editor');

    return (

<TabPane tabId="tab-comment">

        { /*  Comment Editor Secition */ }
        <Row>
            <Col lg={"12"}>
                <div className="review-field">
                    <ReactQuill id="editor"
                        //readOnly={loadingAiReview}
                        //disabled={loadingAiReview}
                        modules={{ toolbar:  [
                            [{ size: [ 'small', false, 'large', 'huge' ]}],
                            ['bold', 'italic', 'underline'],
                            ['link'],
                            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                            ['blockquote'],
                            ['clean']
                        ]}}
                        style={{
                            height: "100%",
                            overflowY: "auto",
                            transitionDuration: "0.3s"
                        }}
                        /*
                        onKeyDown={(e) => {
                            if (isValidInput(e)) {
                                setReview(e.target.innerHTML);
                                editedInfo.current = e.target.innerHTML;
                                setEdited(true);
                                setCanSend(false);
                            }
                        }}

                        onChange={(text) => {
                            setReview(text);
                            setCanSend(false);
                            editedInfo.current = text;
                        }}
                        */
                        //value={review ? review : ""}
                    >
                    </ReactQuill>

                    <div className="folgen-values-main-section">
                        <div className="folgen-title">
                            <p>{tEditor("folgen-title")}</p>
                        </div>
                        <div className="folgen-section">
                            {
                            /*
                            !loadingAiReview && currentFolgenValues && currentFolgenValues.length ? currentFolgenValues.map((fv,i) => {
                                let value = fv["Observation Identifier"].replace(/^([\s]*)\s/, '').split(' ');
                                return (
                                <div className="folgen-pill" key={`edition-folgen-pill-${i}`}>
                                    {`${value[1]} ${value[2]}`}
                                </div>
                                )
                            }) : loadingAiReview ? (<p>Loading...</p>) : (<p>{tMedicalReview("all-folgen-values-processed")}</p>)
                            */}
                        </div>
                    </div>                    
                </div>
            </Col>
        </Row>





       { /* Survey section */}
        <Row className="ki-report">
            <CardBody>
                {/* Preguntas de evaluación */}
                    <Row className="align-items-center mb-2">
                          
                        <Col md={9} className="">
                            <Row>
                                <div className='divSurvCont'>
                                            
                                            <Col md={6}>
                                                <Label className="survTitle">{tEditor("survey-comment-complete")}</Label>

                                                <Col md={12} className="text-left cont-surv-radio">
                                                    <Label className="mr-4 rad-surv">
                                                        <Input type="radio" name="completely" id="c_yes" />{tEditor("yes")}
                                                    </Label>
                                                    <Label className="mr-4 rad-surv">
                                                        <Input type="radio" name="completely" id="c_no" />{tEditor("no")}
                                                    </Label>
                                                    <Label className="rad-surv" >
                                                        <Input type="radio" name="completely" id="c_dont_know" />{tEditor("dont-know")}
                                                    </Label>
                                                </Col>
                                            </Col>

                                            <Col md={6}>
                                                <Label className="survTitle">{tEditor("survey-comment-correct")}</Label>

                                                <Col md={12} className="text-left  cont-surv-radio">
                                                    <Label className="mr-4 rad-surv">
                                                        <Input type="radio" name="right" id="r_yes" />{tEditor("yes")}
                                                    </Label>
                                                    <Label className="mr-4 rad-surv">
                                                        <Input type="radio" name="right" id="r_no" />{tEditor("no")}
                                                    </Label>
                                                    <Label className="rad-surv">
                                                        <Input type="radio" name="right" id="r_dont_know" />{tEditor("dont-know")}
                                                    </Label>
                                                </Col>
                                            </Col>
                                </div>
                            </Row>
                        </Col>
                        <Col md={3}>
                            {/* Action buttons */}
                            <Row className="btnAiContainter">
                                <Button className="btn btn-success mr-2 btn-ai-call">{tEditor("btn-save-store")}</Button>
                            </Row>
                        </Col>
                    </Row>
            </CardBody>
        </Row>


        { /*********************************
        Component to render de comment tab */ }
        <KiLabEditor_stored_list />


</TabPane>
);
}
export default KiLabEditor_analytes;