import AxiosService from 'plugins/TS-lib-utils-public/services/AxiosService';

class PracticesService extends AxiosService {
  constructor() {
    super('practices');
  }
  /**
   * Get all practices of the active user
   */
  getPractices = async () => {
    const response = await this.get(`/`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };
  /**
   * Get one practice of active user
   * chatStatus = set chat status for active practice
   * setPracticeActive = set the practice id as active practice in profile data
   */
  getPractice = async (
    practiceId,
    { chatStatus = undefined, practiceActive = undefined } = {}
  ) => {
    const url = `/${practiceId}?${
      practiceActive ? 'practice-active=' + practiceId : ''
    }${chatStatus ? '&chat-status=' + chatStatus : ''}`;
    const response = await this.get(url, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        practice_id: practiceId,
      },
    });
    return response;
  };
  /**
   * Get practice associated to the current session
   */
   getPracticeBySession = async () => {
    return await this.get('/practice_active', {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  };
  /**
   * Get practice's metrics
   */
  getPracticeMetrics = async (practiceId) => {
    const response = await this.get(`/${practiceId}/metrics`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };
  /**
   * Get practitioner's metrics
   */
  getPractitionersMetrics = async (practiceId) => {
    const response = await this.get(`/${practiceId}/practitioners-metrics`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };
  /**
   * Save practice
   */
  save = async (practice) => {
    let formData = new FormData();
    Object.keys(practice).forEach((key) => {
      if (practice[key] !== 'logo') {
        formData.append(key, practice[key]);
      }
    });
    /**
     * Attach file
     */
    if (practice.logo && practice.logo[0]) {
      formData.append('logo', practice.logo[0]);
    }
    if (practice._id) {
      // edit practice
      const response = await this.put(`/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getToken()}`,
          practice_id: practice._id,
        },
      });
      return response;
    }
    // else save a new practice
    const response = await this.post(`/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };

  /**
   * Delete a practice or activate the practice if it is inactive
   */
  deletePractice = async (password, practice_id) => {
    const response = await this.post(
      `/${practice_id}`,
      {
        password: password,
      },
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice_id: practice_id,
        },
      }
    );
    return response;
  };

  //Invitations
  /**
   * Save invitation
   */
  saveInvitation = async (practice_id, invitation) => {
    // send invitation from practice
    const response = await this.post(
      `/${practice_id}/invitations`,
      invitation,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice_id,
        },
      }
    );
    return response;
  };

  /**
   * Get contacts of the active practice
   */
  getContacts = async (practiceId, search) => {
    const response = await this.get(
      `/${practiceId}/practitioners/search/${search}`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice_id: practiceId,
        },
      }
    );
    return response;
  };
  /**
   * Get contacts of the active practice
   */
  getPractitioners = async (practiceId) => {
    const response = await this.get(`/${practiceId}/practitioners`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        practice_id: practiceId,
      },
    });
    return response;
  };
  /**
   * Delete a practitioner of active practice
   */
  deletePractitioner = async (practiceId, practitionerId) => {
    const response = await this.delete(
      `/${practiceId}/practitioners/${practitionerId}`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice_id: practiceId,
        },
      }
    );
    return response;
  };
  /**
   * Edit a practitioner of active practice
   */
  editPractitioner = async (practiceId, practitioner) => {
    const response = await this.put(
      `/${practiceId}/practitioners`,
      practitioner,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice_id: practiceId,
        },
      }
    );
    return response;
  };
  /**
   * Chat status in practice
   */
  chatStatusPractitioner = async (practiceId, practitionerId, status) => {
    const response = await this.put(
      `/${practiceId}/practitioners/${practitionerId}/chat-status/${
        status ? status : ''
      }`,
      {},
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice_id: practiceId,
        },
      }
    );
    return response;
  };
}

export default new PracticesService();
