import CONSTANTS from "../CONSTANTS";
const initialState = {
  category: 'default',
};

const categoryMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.CATEGORY_MENU_UPDATE:
      return {
          ...state,
          category: action.category
      };
    default:
      return state;
  }
};

export default categoryMenuReducer;
