import CONSTANTS from "../CONSTANTS";

const initialState = {
  invitations: [],
  invitation: {},
  users: [],
  doctorInfo: {},
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.INVITATION_SET_LIST:
      return { ...state, invitations: action.invitations };
    case CONSTANTS.INVITATION_SET:
      return { ...state, invitation: action.invitation };
    case CONSTANTS.USERS_SET_LIST:
      return { ...state, users: action.users };
    case CONSTANTS.DOCTOR_SET_INFO:
      return { ...state, doctorInfo: action.doctorInfo };
    default:
      return state;
  }
};

export default userDataReducer;
