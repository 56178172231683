import Dashboard from 'views/Dashboard/Dashboard.jsx';
import Buttons from 'views/Components/Buttons.jsx';
import GridSystem from 'views/Components/GridSystem.jsx';
import Panels from 'views/Components/Panels.jsx';
import SweetAlert from 'views/Components/SweetAlertPage.jsx';
import Icons from 'views/Components/Icons.jsx';
import Typography from 'views/Components/Typography.jsx';
import RegularForms from 'views/Forms/RegularForms.jsx';
import ExtendedForms from 'views/Forms/ExtendedForms.jsx';
import ValidationForms from 'views/Forms/ValidationForms.jsx';
import Wizard from 'views/Forms/Wizard/Wizard.jsx';
import GoogleMaps from 'views/Maps/GoogleMaps.jsx';
import FullScreenMap from 'views/Maps/FullScreenMap.jsx';
import Charts from 'views/Charts/Charts.jsx';
import CalendarX from 'views/Calendar/CalendarX.jsx';
import Widgets from 'views/Widgets/Widgets.jsx';
import Profile from 'views/Users/Profile.jsx';
import TimelinePage from 'views/Pages/TimelinePage.jsx';
import RTL from 'views/Pages/RTL.jsx';
import PricingPage from 'views/Pages/PricingPage.jsx';
import PasswordRecovery from 'views/Users/PasswordRecovery.jsx';
import LoginPage from 'views/Users/LoginPage.jsx';
import RegisterPage from 'views/Users/RegisterPage.jsx';
import LockScreenPage from 'views/Users/LockScreenPage.jsx';
import Welcome from 'views/Pages/Welcome';
import Schedule from 'views/Schedule/Schedule';
import ErrorView from 'views/Pages/ErrorView';
import SessionManager from 'views/Users/SessionManager';

import PracticeManage from 'views/Practices/PracticeManage.jsx';
import PracticeNew from 'views/Practices/PracticeNew.jsx';
import PracticeAddUser from 'views/Practices/PracticeAddUser.jsx';
import PracticeInviteUser from 'views/Practices/PracticeInviteUser.jsx';
import PracticeInviteBySearch from 'views/Practices/PracticeInviteBySearch.jsx';
import PracticeInviteByEmail from 'views/Practices/PracticeInviteByEmail.jsx';
import PracticeUserList from 'views/Practices/PracticeUserList.jsx';
import PracticeSlots from 'views/Practices/PracticeSlots.jsx';
import PracticeMyInvitations from 'views/Practices/PracticeMyInvitations.jsx';
import PracticeSentInvitations from 'views/Practices/PracticeSentInvitations';
import PracticeMetrics from 'views/Practices/PracticeMetrics.jsx';

import Settings from 'views/Users/Settings.jsx';
import Inbox from 'views/Messages/Inbox';
import RemoteDiagnostics from 'views/Remote/RemoteDiagnostics';
import Chats from 'views/Chat/Chats';
import PatientList from 'views/Patients/PatientList';
import RedirectionPage from 'views/Users/RedirectionPage';
import LaboratoryStudies from "views/Laboratories/LaboratoryStudies";
import LaboratoryDetail from 'views/Laboratories/LaboratoryDetail';
import UserDeleteConfirmation from "views/Users/UserDeleteConfirmation";
import UserDeletePreparation from "views/Users/UserDeletePreparation";

import KiLabIndex from 'views/KiLab/index';
import KiLabEditor from 'views/KiLab/editor';

import InvitationsFormView from 'views/MedicalRep/InvitationsFormView';
import InvitationsListView from 'views/MedicalRep/InvitationsListView';

/**
 * Routes description
 * 
 * path: Is the relative path to the view component
 * invisible: Is used to show/hide the component in the sidebar
 * icon: is the icon user fot the sidebar taked from awasomefonts
 * layout: is the layout applied to the view component
 * sidebar: ????
 */


let routes = [  
  
  { //KiLab Analysis list index
    _id : 49,
    path: '/kilab/index',

    name: 'KiLab Index',
    icon: 'icon-TP_KI_Logo_White',
    invisible: false,
    component: KiLabIndex,
    layout: '/admin',
    sidebar: false,
  },  
  { //KiLab Analysis editor
    _id : 50,
    path: '/kilab/editor/:id_lab_result?',

    name: 'KiLab Editor',
    icon: 'icon-TP_KI_Logo_White',
    invisible: true,
    component: KiLabEditor,
    layout: '/admin',
    sidebar: false,
  },

  // Practices
  {
    // key: 'practices-manager',
    _id: 1,
    path: '/practice/manage',
    name: 'Practice Manage',
    icon: 'now-ui-icons business_bank',
    invisible: true,
    component: PracticeManage,
    layout: '/admin',
    // category: 'practice-manage',
    sidebar: true,
  },
  {
    // key: 'practice-form',
    _id: 2,
    path: '/practice/form/:id?',
    name: 'Practice Form',
    icon: 'now-ui-icons business_bank',
    invisible: false,
    component: PracticeNew,
    layout: '/admin',
  },
  {
    _id: 3,
    path: '/practice/invitations',
    name: 'My Invitations',
    icon: 'now-ui-icons business_bank',
    invisible: true,
    component: PracticeMyInvitations,
    layout: '/admin',
    // category: 'practice-manage',
  },
  {
    _id: 4,
    key: 'practice-add-user',
    path: '/practice/users/add',
    name: 'Practice Add User',
    icon: 'now-ui-icons business_bank',
    invisible: true,
    component: PracticeAddUser,
    layout: '/admin',
    category: 'practice',
  },
  {
    // key: 'practice-invite-user',

    _id: 5,
    path: '/practice/users/invite',
    name: 'Practice Invite User',
    icon: 'now-ui-icons business_bank',
    invisible: true,
    component: PracticeInviteByEmail,
    layout: '/admin',
    category: 'practice',
  },
  {
    // key: 'practice-invite-user',

    _id: 48,
    path: '/practice/users/invitations',
    name: 'Practice Sent Invitations',
    icon: 'now-ui-icons business_bank',
    invisible: false,
    component: PracticeSentInvitations,
    layout: '/admin',
    category: 'practice',
  },
  {
    // key: 'practice-edit',

    _id: 6,
    path: '/practice/users/list',
    name: 'Practitioner List',
    icon: 'now-ui-icons business_bank',
    invisible: false,
    component: PracticeUserList,
    layout: '/admin',
    category: 'practice',
  },
  {

    _id: 7,
    key: 'practice-edit',
    path: '/practice/invitation/list',
    name: 'Invitation List',
    icon: 'now-ui-icons business_bank',
    invisible: true,
    component: PracticeUserList,
    layout: '/admin',
    category: 'practice',
  },
  {

    _id: 8,
    key: 'practice-invite-by-email',
    path: '/practice/users/invite-email',
    name: 'Practice - Invite user by email',
    icon: 'now-ui-icons business_bank',
    invisible: true,
    component: PracticeInviteByEmail,
    layout: '/admin',
    category: 'practice',
  },
  {

    _id: 9,
    key: 'practice-invite-by-search',
    path: '/practice/users/invite-search',
    name: 'Practice - Invite user by search',
    icon: 'now-ui-icons business_bank',
    invisible: true,
    component: PracticeInviteBySearch,
    layout: '/admin',
    category: 'practice',
  },
  {

    _id: 9,
    key: 'practice-slots',
    path: '/practice/slots',
    name: 'Practice - slots',
    icon: 'now-ui-icons business_bank',
    invisible: true,
    component: PracticeSlots,
    layout: '/admin',
    category: 'practice',
  },
  {
    //   key: 'practice-metrics',

    _id: 10,
    path: '/practice/metrics/:id',
    name: 'Practice - metrics',
    icon: 'now-ui-icons business_bank',
    invisible: true,
    component: PracticeMetrics,
    layout: '/admin',
    category: 'practice',
  },
  //End of practices
  {
    _id: 11,
    path: '/error',
    name: 'Error',
    component: ErrorView,
    layout: '/admin',
    invisible: true,
  },
  {
    path: "/delete-confirmation",
    name: "Delete confirmation",
    icon: "now-ui-icons business_bank",
    component: UserDeleteConfirmation,
    layout: "/admin",
    invisible: true,
  },
  {
    _id: 12,
    path: '/welcome',
    name: 'Welcome',
    icon: 'now-ui-icons business_bank',
    component: Welcome,
    layout: '/admin',
    invisible: true,
  },
  {
    _id: 12,
    key: 'dashboard',
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'now-ui-icons design_app',
    component: Dashboard,
    layout: '/admin',
  },
  {
    _id: 13,
    key: 'schedule',
    path: '/schedule',
    name: 'Schedule',
    icon: 'now-ui-icons  media-1_album',
    component: Schedule,
    layout: '/admin',
    invisible: true,
  },
  {
    _id: 14,
    collapse: true,
    path: '/pages',
    name: 'Pages',
    state: 'openPages',
    icon: 'now-ui-icons design_image',
    invisible: true,
    views: [
      {
        _id: 15,
        path: '/timeline-page',
        name: 'Timeline Page',
        mini: 'TP',
        component: TimelinePage,
        layout: '/admin',
      },
      {
        _id: 15,
        path: '/profile',
        name: 'User Profile',
        mini: 'UP',
        component: Profile,
        layout: '/admin',
      },
      {
        _id: 16,
        path: '/rtl-support',
        name: 'RTL Support',
        mini: 'RS',
        component: RTL,
        layout: '/admin',
      },
      {
        _id: 17,
        path: '/forgot-password',
        name: 'Password Recovery Page',
        short: 'Recovery',
        mini: 'PRP',
        component: PasswordRecovery,
        layout: '/auth',
      },
      {
        _id: 18,
        path: '/register-page',
        name: 'Register Page',
        short: 'Register',
        mini: 'RP',
        component: RegisterPage,
        layout: '/auth',
      },
      {
        _id: 19,
        path: '/login-page/:data?',
        name: 'Login Page',
        short: 'Login',
        mini: 'LP',
        component: LoginPage,
        layout: '/auth',
      },
      {
        path: "/delete-preparation/:token?",
        name: "Deletion Preparation",
        short: "Deletion",
        mini: "DC",
        component: UserDeletePreparation,
        layout: "/auth",
      },
      {
        _id: 20,
        path: '/pricing-page',
        name: 'Pricing Page',
        short: 'Pricing',
        mini: 'PP',
        component: PricingPage,
        layout: '/auth',
        invisible: true,
      },
      {
        _id: 21,
        path: '/lock-screen-page',
        name: 'Lock Screen Page',
        short: 'Lock',
        mini: 'LSP',
        component: LockScreenPage,
        layout: '/auth',
        invisible: true,
      },
      {
        path: '/redirect',
        name: 'Redirection Page',
        short: 'Redirection',
        mini: 'RDP',
        component: RedirectionPage,
        layout: '/auth',
        invisible: true,
      },
    ],
  },
  {
    _id: 22,
    collapse: true,
    path: '/components',
    name: 'Components',
    state: 'openComponents',
    icon: 'now-ui-icons education_atom',
    invisible: true,
    views: [
      {
        _id: 23,
        path: '/buttons',
        name: 'Buttons',
        mini: 'B',
        component: Buttons,
        layout: '/admin',
      },
      {
        _id: 24,
        path: '/grid-system',
        name: 'Grid System',
        mini: 'GS',
        component: GridSystem,
        layout: '/admin',
      },
      {
        _id: 25,
        path: '/panels',
        name: 'Panels',
        mini: 'P',
        component: Panels,
        layout: '/admin',
      },
      {
        _id: 26,
        path: '/sweet-alert',
        name: 'Sweet Alert',
        mini: 'SA',
        component: SweetAlert,
        layout: '/admin',
      },
      {
        _id: 27,
        path: '/icons',
        name: 'Icons',
        mini: 'I',
        component: Icons,
        layout: '/admin',
      },
      {
        _id: 28,
        path: '/typography',
        name: 'Typography',
        mini: 'T',
        component: Typography,
        layout: '/admin',
      },
    ],
  },
  {
    _id: 29,
    collapse: true,
    path: '/forms',
    name: 'Forms',
    state: 'openForms',
    icon: 'now-ui-icons design_bullet-list-67',
    invisible: true,
    views: [
      {
        _id: 30,
        path: '/regular-forms',
        name: 'Regular Forms',
        mini: 'RF',
        component: RegularForms,
        layout: '/admin',
      },
      {
        _id: 31,
        path: '/extended-forms',
        name: 'Extended Forms',
        mini: 'EF',
        component: ExtendedForms,
        layout: '/admin',
      },
      {
        _id: 32,
        path: '/validation-forms',
        name: 'Validation Forms',
        mini: 'VF',
        component: ValidationForms,
        layout: '/admin',
      },
      {
        _id: 33,
        path: '/wizard',
        name: 'Wizard',
        mini: 'W',
        component: Wizard,
        layout: '/admin',
      },
    ],
  },
  {
    _id: 34,
    collapse: true,
    path: '/maps',
    name: 'Maps',
    state: 'openMaps',
    icon: 'now-ui-icons location_pin',
    invisible: true,
    views: [
      {
        _id: 35,
        path: '/google-maps',
        name: 'Google Maps',
        mini: 'GM',
        component: GoogleMaps,
        layout: '/admin',
      },
      {
        _id: 36,
        path: '/full-screen-maps',
        name: 'Full Screen Map',
        mini: 'FSM',
        component: FullScreenMap,
        layout: '/admin',
      },
    ],
  },
  {
    _id: 37,
    path: '/widgets',
    name: 'Widgets',
    icon: 'now-ui-icons objects_diamond',
    invisible: true,
    component: Widgets,
    layout: '/admin',
  },
  {
    _id: 38,
    path: '/charts',
    name: 'Charts',
    icon: 'now-ui-icons business_chart-pie-36',
    invisible: true,
    component: Charts,
    layout: '/admin',
  },
  {
    _id: 39,
    key: 'calendar',
    path: '/calendar',
    name: 'Calendar',
    icon: 'now-ui-icons media-1_album',
    invisible: true,
    component: CalendarX,
    layout: '/admin',
  },
  {
    _id: 40,
    key: 'appointments',
    path: '/appointments',
    name: 'Appointments',
    icon: 'icon-appointments',
    invisible: false,
    component: Dashboard,
    layout: '/admin',
  },
  {
    _id: 42,
    key: 'chat',
    path: '/chats',
    name: 'Chat',
    icon: 'icon-chat',
    invisible: false,
    component: Chats,
    layout: '/admin',
  },
  {
    _id: 41,
    key: 'inbox',
    path: '/inbox/:id?',
    name: 'Inbox',
    icon: 'icon-inbox',
    invisible: false,
    component: Inbox,
    layout: '/admin',
  },
  {
    _id: 43,
    path: '/chat/:id?',
    name: 'Chat',
    icon: 'icon-chat',
    invisible: true,
    component: Chats,
    layout: '/admin',
  },
  {
    _id: 49,
    path: '/patients',
    name: 'Patients',
    icon: 'icon-user-active',
    invisible: false,
    component: PatientList,
    layout: '/admin',
  },
  {
    _id: 50,
    path: "/lab-studies",
    name: "Laboratory",
    icon: "icon-microscope",
    invisible: false,
    component: LaboratoryStudies,
    layout: "/admin",
  },
  {
    _id: 51,
    path: "/lab-studies-detail/:reportId?",
    name: "LaboratoryDetail",
    icon: "icon-microscope",
    invisible: true,
    component: LaboratoryDetail,
    layout: "/admin",
  },
  {
    _id: 44,
    path: '/profile',
    name: 'Profile',
    icon: 'icon-user-active',
    invisible: false,
    component: Profile,
    layout: '/admin',
  },
  {
    _id: 45,
    key: 'waiting-room',
    path: '/waiting-room',
    name: 'Waiting Room',
    icon: 'icon-waiting-room',
    invisible: false,
    component: Dashboard,
    layout: '/admin',
  },
  {
    _id: 46,
    path: '/settings',
    name: 'User Settings',
    icon: 'icon-settings1',
    invisible: false,
    component: Settings,
    layout: '/admin',
  },
  {
    _id: 47,
    key: 'remote-diagnostics',
    path: '/remote-diagnostics',
    name: 'Remote Diagnostics',
    icon: 'icon-remote-diagnostics-white-menu',
    invisible: false,
    component: RemoteDiagnostics,
    layout: '/admin',
  },
  {
    _id: 48,
    path: '/sessions',
    name: 'Sessions',
    icon: 'icon-user-active',
    invisible: true,
    component: SessionManager,
    layout: '/admin',
  },
  {
    _id: 48,
    path: '/medical-representative-form',
    key: 'medical-representative',
    name: 'MedRep Form',
    icon: 'icon-user-active',
    invisible: false,
    component: InvitationsFormView,
    layout: '/admin',
  },
  {
    _id: 48,
    path: '/medical-representative-list/',
    key: 'medical-representative',
    name: 'MedRep List',
    icon: 'icon-user-active',
    invisible: false,
    component: InvitationsListView,
    layout: '/admin',
  },

  
];

export default routes;