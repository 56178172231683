/**
 * Utility for translations outside of the react components
 * @param {string} lang language of the active user
 * @param {string} node module name translations
 * @returns function
 */
const utilTranslations = (lang = 'de', node = null) => {
  let langFile = require(`../assets/i18n/${lang}.json`);
  if (!langFile.hasOwnProperty(node)) {
    throw new Error('Invalid translation data');
  }
  const translate = (key) => {
    try {
      return langFile[node][key] ? langFile[node][key] : `${node}.${key}`;
    } catch (error) {
      return key;
    }
  };
  return translate;
}

export default utilTranslations;