/**
 * Check permissions
 * @param {Object} permissions full object
 * @param {string} instance id from react tree
 * @param {string} type of component
 * @returns
 */
export default function checkPermission({
  permissions,
  moduleKey,
  actionKey,
}) {
  if (!permissions || !moduleKey) {
    return false;
  }
  /**
   * Get module access
   */
  if (moduleKey && !actionKey) {
    const module = permissions?.modules?.findIndex((m) => m.key === moduleKey);
    return module >= 0;
  }
  /**
   * Get action access
   */
  if (moduleKey && actionKey) {
    const action = permissions?.modules?.findIndex((m) => {
      if (m.key === moduleKey) {
        return m?.actions?.includes(actionKey);
      }
      return false;
    });
    return action >= 0;
  }
  return false;
}
