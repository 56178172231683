import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ListGroupItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import history from 'historyRouter';
import PracticeAvatar from 'components/Practice/PracticeAvatar';
import { actionMetricPracticeSelect } from 'store/actions/practicesActions';
import { useTranslate } from 'react-translate';
import useAuthorizationValidator from 'plugins/TS-lib-utils-public/hooks/useAuthorizationValidator';

const PracticeItem = (props) => {
  const dispatch = useDispatch();
  const t = useTranslate('practices');
  const { item, id, group, toggleModal, type, practiceActive } = props;
  const { mobile } = useSelector((state) => state.commonReducer);
  const { isDisaffected, practices } = useSelector((state) => state.practicesReducer);
  const { auth_user_id } = useSelector((state) => state.userReducer);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  //Validations 
  //check if user can access to componets 
  const { checkAccess } = useAuthorizationValidator(item._id);
  const [allowedEdit, setAllowedEdit] = useState();
  const [allowedDelete, setAllowedDelete] = useState();
  const [allowedMetrics, setAllowedMetrics] = useState();
  const [role, setRole] = useState();

  //Update permissions on practice update
  useEffect(() => {
    const practice = practices.find(p => p._id === item._id);

    if (auth_user_id && practice && practice.people) {
      let res = practice.people.find((u) => String(u.auth_user_id) === String(auth_user_id) && u.type === "doctor");
      if (res) {
        const role = practice.custom_role.find((rol) => res.custom_role_id === rol._id);

        setRole(role);
        setAllowedEdit(res && !res.isDisaffected && checkAccess('practices-manager','practice-edit', item._id));
        setAllowedDelete(res && !res.isDisaffected && checkAccess('practices-manager','practice-delete', item._id));
        setAllowedMetrics(res && !res.isDisaffected && checkAccess('practices-manager','practice-metrics'));
      }
    }
  }, [isDisaffected, practices]);

  const handleEdit = () => {
    dispatch(actionMetricPracticeSelect(item));
    //redirect to page of form practice selected
    history.push(`/admin/practice/form/${item._id}`);
  };

  const handleMetrics = () => {
    dispatch(actionMetricPracticeSelect(item));
    history.push(`/admin/practice/metrics/${item._id}`);
  };

  return (
    <ListGroupItem
      tag="li"
      id={id}
      className={`practice-item d-flex p-4 ${
        practiceActive === item._id && 'practice-active'
      } ${group}`}
      onMouseDown={(e) => e.preventDefault()}
    >
      <span className="practice-item-text mr-2">
        <PracticeAvatar practice={item} width="25" />
      </span>
      <span className="practice-item-text text-left text-truncate">
        {item.name}
      </span>
      
      {role && role.name && (
        <span className="practice-item-role">
          {`(${role?.name})`}
        </span>
      )}
      

      
      <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="ml-auto"
      >
        {type !== 'collaborator' &&
          (allowedDelete || allowedEdit || allowedMetrics) && (
            <>
              <DropdownToggle tag="span">
                <i className="fas fa-ellipsis-h text-info cursor-pointer"></i>
              </DropdownToggle>
              <DropdownMenu right>
                {allowedEdit && (
                  <DropdownItem onClick={handleEdit}>
                    {t('btn-edit-practice')}
                  </DropdownItem>
                )}
                {allowedMetrics && (
                  <DropdownItem onClick={handleMetrics}>
                    {t('btn-metrics-practice')}
                  </DropdownItem>
                )}
                {allowedDelete && (
                  <DropdownItem onClick={toggleModal}>
                    {t('btn-delete-practice')}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </>
          )}
      </Dropdown>
      
    </ListGroupItem>
  );
};

export default PracticeItem;
