import { useFileUtils } from 'hooks';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectPractitionerUserId } from 'store/selectors/selectors';

const MsgAvatar = ({ user, avatarClass }) => {
  const isMounted = useRef();
  const { getAvatar, setFile, defaultImage } = useFileUtils();
  const practitioner = useSelector((state) =>
    selectPractitionerUserId(state, user.auth_user_id)
  );

  const updated_at =
    practitioner && practitioner.updated_at ? practitioner.updated_at : null;
  useEffect(() => {
    isMounted.current = true;
    if (user.avatar) {
      const avatarResponse = getAvatar(`${user.avatar}`, 'small');
      avatarResponse.then((avatar) => {
        if (isMounted.current && avatar) {
          setFile({ file: avatar });
        }
      });
    }
    return () => (isMounted.current = false);
    // eslint-disable-next-line
  }, [updated_at]);

  return (
    <div className={avatarClass}>
      {defaultImage.includes('General') ? (
        <span>{user.nameInitials}</span>
      ) : (
        <img src={defaultImage} alt="avatar"/>
      )}
    </div>
  );
};

export default MsgAvatar;
