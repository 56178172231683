import CONSTANTS from '../CONSTANTS';

const websocketReducer = (state = {}, action) => {
  switch (action.type) {
    case CONSTANTS.WS_SET_DATA:
      return action.data;
    default:
      return state;
  }
};

export default websocketReducer;
