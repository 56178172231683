import React, { Fragment } from 'react';
// import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
// import Dates from "helpers/Dates";

// reactstrap components
import { Button } from 'reactstrap';
import moment from 'moment';
import { useTranslate } from 'react-translate';
import {
  actionGetMessage,
  actionDelMessages,
  actionSetBrowser,
} from 'store/actions/messagesActions';
// import Loading from "plugins/TS-lib-utils-public/components/Loading/LoadingLocal";
import SweetAlert from 'react-bootstrap-sweetalert';
import { actionSweetAlert } from 'store/actions/commonActions';
import useAuthorizationValidator from 'plugins/TS-lib-utils-public/hooks/useAuthorizationValidator';

const InboxDetail = (props) => {
  const t = useTranslate('messages');
  const { showReplyForm, tagsLength, Idisaffected } = props;

  const { inbox, message, browser, box } = useSelector(
    (state) => state.messagesReducer
  );

  const dispatch = useDispatch();

  const { checkAccess } = useAuthorizationValidator();

  //Validations
  const allowInboxDetail = checkAccess('inbox', 'inbox-detail');
  const allowInboxReply = checkAccess('inbox', 'reply-message');
  const allowInboxDelete = checkAccess('inbox', 'delete-message');


  const showMessage = (key) => {
    if (typeof inbox.messages[key] !== 'undefined') {
      let browser = {
        prev: key - 1,
        next: key + 1,
      };
      dispatch(actionSetBrowser(browser));
      return dispatch(actionGetMessage(inbox.messages[key]._id));
    }
  };
  const deleteMessage = (id) => {
    let defaultKey = 0;
    inbox.messages.find((message, key) => {
      defaultKey = key;
      return message._id === id;
    });
    const message = inbox.messages[defaultKey + 1]
      ? inbox.messages[defaultKey + 1]
      : inbox.messages[defaultKey - 1]
        ? inbox.messages[defaultKey - 1]
        : null;
    dispatch(
      actionSweetAlert(
        <SweetAlert
          warning
          title={t('alert-delete-title')}
          onConfirm={() => {
            dispatch(actionDelMessages({ messages: [id] }));
            hideAlert();
            if (message) {
              dispatch(actionGetMessage(message._id));
            }
          }}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={t('alert-delete-btn-ok')}
          cancelBtnText={t('alert-delete-btn-cancel')}
          showCancel
        >
          {t('alert-delete-subtitle')}
        </SweetAlert>
      )
    );
  };

  const hideAlert = () => {
    dispatch(actionSweetAlert(null));
  };

  const formatText = (text) => {
    if (!text) {
      return '';
    }
    return text.split('\n').map((item, i) => {
      return (
        <React.Fragment key={i}>
          {item}
          <br />
        </React.Fragment>
      );
    });
  };

  if (tagsLength > 0) {
    return null;
  }

  return (
    <Fragment>
      {message && (
        <div
          className={
            'animated fast ' + (props.mobile ? 'fadeInRight' : 'fadeIn')
          }
        >
          <div className="row inbox-detail-header">
            <div className="col-8">
              <div className="h5">
                {message.from.firstName} {message.from.lastName}
              </div>
              {box === 'outbox' && (
                <React.Fragment>
                  <div>
                    <strong>{t('to')}: </strong>
                    {message.to.map(
                      (user_to, i) =>
                        `${user_to.firstName} ${user_to.lastName} ${i + 1 !== message.to.length ? ', ' : ''
                        }`
                    )}
                  </div>
                </React.Fragment>
              )}
              <div>{message.subject}</div>
            </div>
            <div className="col-4 text-right">
              {moment(message.date).format('L HH:mm')}
            </div>
          </div>
          <div className="row inbox-detail-body">
            <div className="col">
              <p className="text-justify">{formatText(message.body)}</p>
            </div>
          </div>
          <div className="row inbox-detail-footer">
            <div className="col">
              {/* Validator */}
              {allowInboxDetail && (
                <Fragment>
                  <Button
                    color="twitter"
                    className="btn-sm"
                    onClick={() => showMessage(browser.prev)}
                  >
                    <i className="now-ui-icons arrows-1_minimal-left" />
                    {t('btn-prev')}
                  </Button>
                  <Button
                    color="twitter"
                    className="btn-sm"
                    onClick={() => showMessage(browser.next)}
                  >
                    {t('btn-next')}
                    <i className="now-ui-icons arrows-1_minimal-right" />
                  </Button>
                </Fragment>
              )}
              {(allowInboxReply && !Idisaffected) ?
                <Button
                  color="twitter"
                  className="btn-sm"
                  onClick={() => {
                    showReplyForm([
                      {
                        id: message.from.user_id,
                        text:
                          message.from.firstName + ' ' + message.from.lastName,
                      },
                    ]);
                  }}
                >
                  {t('btn-reply')}&nbsp;&nbsp;&nbsp;
                  <i className="icon-message-header"></i>
                </Button>
                : null}
              {/* {(allowInboxReply && isDisaffected===false)(
                <Button
                  color="twitter"
                  className="btn-sm"
                  onClick={() => {
                    showReplyForm([
                      {
                        id: message.from.user_id,
                        text:
                          message.from.firstName + ' ' + message.from.lastName,
                      },
                    ]);
                  }}
                >
                  {t('btn-reply')}&nbsp;&nbsp;&nbsp;
                  <i className="icon-message-header"></i>
                </Button>
              )} */}
              {allowInboxDelete && !Idisaffected && (
                <Button
                  color="primary"
                  className="btn-sm"
                  onClick={() => deleteMessage(message._id)}
                >
                  {t('btn-delete')}&nbsp;
                  <i className="now-ui-icons ui-1_simple-remove" />
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default InboxDetail;
