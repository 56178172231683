import React from 'react';
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import SearchUser from 'components/Practice/SearchUser.jsx';
import { useSelector } from 'react-redux';

const PracticeInviteUser = () => {
  const practice = useSelector((state) => state.practicesReducer.practice_manage_select);
  return (
    <Card>
      <CardBody>
        <Card>
          <CardHeader>
            <CardTitle className="d-flex align-content-center">
              <div className="general-card-title-img text-primary mr-2">
                <i className="icon-practice-settings title" />
              </div>
              <div className="general-card-title text-primary">
                {practice.name}
              </div>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <SearchUser />
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
};

export default PracticeInviteUser;
