import React, { useState, useEffect, useRef } from 'react';
import Icon from 'components/Icons/Icon';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import history from 'historyRouter';

const PracticeHeader = () => {
  const { name } = useSelector((state) => state.userAuthorizationReducer);
  const location = useLocation();
  const pathname = location.pathname;

  const myRef = useRef(0);

  const [showPractice, setShowPractice] = useState(true);

  const notShowRoutes = ['/admin/settings', '/admin/profile'];

  const handleClick = () => {
    history.push(`/admin/chat`);
  };

  useEffect(() => {
    if (notShowRoutes.includes(pathname)) {
      setShowPractice(false);
    } else {
      setShowPractice(true);
    }
    // eslint-disable-next-line
  }, [pathname, setShowPractice]);

  const [scrollPractice, setScrollPractice] = useState(false);

  const handleScroll = () => {
    setScrollPractice(true);
    if (myRef.current.offsetTop > 70) {
      setScrollPractice(true);
    } else {
      setScrollPractice(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /*
  if (!name) {
    return <></>;
  }
  */

  return (
    <div
      className={`physician-header${
        pathname === '/admin/chat' ? '-fixed' : ''
      } ${showPractice ? '' : 'opacity-0'} ${
        scrollPractice ? 'opacity-header' : ''
      }
      `}
      id="physician-header"
      ref={myRef}
    >
      <button
        className="d-flex physician-title align-items-center"
        onClick={() => handleClick()}
      >
        <Icon file="hospital.svg" width="20" className="mr-2" />
        <span>{name}</span>
      </button>
    </div>
  );
};

export default PracticeHeader;
