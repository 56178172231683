import React from 'react';
import { useRef } from 'react';
import { translate } from 'react-translate';

const ChatNameInput = ({ t, chatNameDisplay, setChatNameDisplay }) => {
  const nameInputRef = useRef(null);

  return (
    <div className="type_msg">
      <div className="input_user_search">
        <input
          type="text"
          className="user_search"
          placeholder={"Group name..."}
          onChange={(e) => {
            setChatNameDisplay(e.target.value);
          }}
          ref={nameInputRef}
          defaultValue={chatNameDisplay}
        />
      </div>
    </div>
  );
};

export default translate('chat')(ChatNameInput);
