/*eslint-disable*/
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
// used for making the prop types of this component
import PropTypes from 'prop-types';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// reactstrap components
import { Nav, Collapse, Button } from 'reactstrap';

// import { actionLogout } from 'store/actions/usersActions';
import Icon from 'components/Icons/Icon';
import { useFileUtils } from 'hooks';
import { useTranslate } from 'react-translate';
import { useCategoryMenu } from 'hooks';
import useAuthorizationValidator from 'plugins/TS-lib-utils-public/hooks/useAuthorizationValidator';

var ps;

const Sidebar = (props) => {
  const [openAvatar, setOpenAvatar] = useState(false);
  const [openDebug, setOpenDebug] = useState(false);
  const [collapseState, setCollapseState] = useState(
    getCollapseStates(props.routes)
  );
  const sidebar = useRef();
  // const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);
  const practice = useSelector((state) => state.userAuthorizationReducer);
  //reducer para identificar si la ruta es una practica y no renderizar link si no lo es
  const practiceManage = useSelector((state) => state.practicesReducer.practice_manage_select);

  const { checkAccess } = useAuthorizationValidator(practice?._id);
  // const { categoryMenu } = useCategoryMenu();

  const { showLink } = useCategoryMenu();

  const t = useTranslate('sidebar');

  const { mobile } = useSelector((state) => state.commonReducer);

  const { minimizeSidebar, hideSidebar } = props;

  const handleClick = () => {
    if (mobile) {
      hideSidebar();
    }
    minimizeSidebar();
  };

  /**
   * Load avatar
   */
  const { getAvatar, defaultImage } = useFileUtils();
  useEffect(() => {
    getAvatar(user.avatar, 'large', true);
  }, [user.avatar, user.updated_at]);

  // const handleLogout = async (e) => {
  //   e.preventDefault;
  //   await dispatch(actionLogout());
  //   props.history.push("/auth/login-page");
  // };

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      // to stop the warning of calling setState of unmounted component
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, [Object.keys(user).length]);

  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  function getCollapseStates(routes) {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  }
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  function getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  function createLinks(routes) {
    return routes.map((prop, key) => {
      // if it's allowed to skip validation
      if (prop.invisible) {
        return null;
      }

      // Filter valid routes
      if (prop.key) {
        //TODO: Do something better for practices-manager
        if (!checkAccess(prop.key) && !checkAccess("practices-manager", prop.key)) {
          return null;
        }
      }

      //en la edicion de practices solo renderiza los links correspondientes
      if (prop._id > 10 && prop._id !== 48 && Object.keys(practiceManage).length > 0) {
        return null;
      }

      //evita que se renderice el link de la practice a editar cuando no se esta en modo edicion
      if (prop._id == 2 && Object.keys(practiceManage).length == 0) {
        return null;
      }

      //condicion provisoria para dejar de ver el link al listado de pacientes
      /*
      if (prop._id == 49 && (process.env.REACT_APP_ENV_NAME == "demo" || process.env.REACT_APP_ENV_NAME == "preprod")) {
        return null;
      }
      */

      if (mobile && prop._id === 12) {
        return null;
      }

      if (!showLink(prop)) {
        return null;
      }

      if (prop.collapse) {
        var st = {};
        st[prop['state']] = !collapseState[prop.state];
        return (
          <li
            className={getCollapseInitialState(prop.views) ? 'active' : ''}
            key={key}
          >
            <a
              href="#"
              data-toggle="collapse"
              aria-expanded={collapseState[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setCollapseState({ ...collapseState, ...st });
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={collapseState[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }

      return (
        <li
          className={`${activeRoute((practiceManage && prop._id == 2) ? '/admin/practice/form/' + practiceManage._id : (prop.layout + prop.path))}`}
          key={key}
          onClick={handleClick}
        >
          <NavLink to={(practiceManage && prop._id == 2) ? '/admin/practice/form/' + practiceManage._id : (prop.layout + prop.path)} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{t(prop.name.toLowerCase().replace(' ', '-'))}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  }

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    let route = routeName.replace(/(\:)\S{1,}/, "");
    return window.location.href.indexOf(route) > -1 ? 'active' : '';
  }

  return (
    <>
      <div className="sidebar siderbar-ts" data-color={props.backgroundColor}>
        <div className="logo">
          <div className="simple-text logo-mini">
            <div className="logo-img">
              <Icon file="logo_TP_initials.svg" alt="TP" width="25px" />
            </div>
          </div>

          {/*
          <a href="#" className="simple-text logo-normal">
            TelePraxen
          </a>
          */}

          <div className="navbar-minimize d-none">
            <Button
              outline
              className="btn-round btn-icon"
              color="neutral"
              id="minimizeSidebar"
              onClick={() => props.minimizeSidebar()}
            >
              <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
              <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
            </Button>
          </div>
        </div>

        <div className="sidebar-wrapper" ref={sidebar}>
          <div className="user">
            <div className="photo no-background">
              <img src={defaultImage} alt="Contact" id="user-avatar" />
            </div>
            <div className="info">
              <a
                // href="#"
                data-toggle="collapse"
                aria-expanded={openAvatar}
                onClick={() => setOpenAvatar(!openAvatar)}
              >
                <span>
                  {user.isAuth && user.firstName + ' ' + user.lastName}
                </span>
              </a>

              {/* <Collapse isOpen={openAvatar}>
                <StatusList />
              </Collapse> */}
              {/* <ul className={`nav`}> */}
              {/* <li>
                    <a href="#pablo" onClick={e => e.preventDefault}>
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">My Profile</span>
                    </a>
                  </li> */}

              {/* <li>
                    <a onClick={handleLogout}>
                      <span className="sidebar-mini-icon">LG</span>
                      <span className="sidebar-normal">Logout</span>
                    </a>
                  </li> */}
              {/* </ul> */}
            </div>
          </div>
          {!user.password_expired && !user.on_delete && (
            <>
              <Nav className="user">
                <li
                  className={`${window.location.pathname.includes('/admin/practice') &&
                    !window.location.pathname.includes(
                      '/admin/practice/invitations'
                    )
                    ? 'active'
                    : ''
                    }`}
                  onClick={handleClick}
                >
                  <NavLink
                    to="/admin/practice/manage"
                    activeClassName="active"
                  >
                    <i className="fa fa-clinic-medical" />
                    <p>{t('practice-manage')}</p>
                  </NavLink>
                </li>
                <li
                  className={activeRoute('/admin/practice/invitations')}
                  onClick={handleClick}
                >
                  <NavLink
                    to="/admin/practice/invitations"
                    activeClassName="active"
                  >
                    <i className="fa fa-inbox" />
                    <p>{t('my-invitations')}</p>
                  </NavLink>
                </li>
              </Nav>
            </>
          )}

          <Nav>{!user.password_expired && !user.on_delete && createLinks(props.routes)}</Nav>

          {/*Link to patients*/}
          {/* <div className={"patients-link"}>
            <div className="info">
              <a
                onClick={(e) => { window.location.href = process.env.REACT_APP_HOST_PATIENTS; }}
              >
                Go to patients
              </a>
            </div>
          </div> */}

          {/*hidden information about the environment*/}
          <div className={(process.env.REACT_APP_ENV_NAME == "demo") || (process.env.REACT_APP_ENV_NAME == "preprod") ? "debug-info d-none" : "debug-info"}>
            <a
              data-toggle="collapse"
              aria-expanded={openDebug}
              onClick={(e) => {
                e.preventDefault();
                setOpenDebug(!openDebug);
              }}
            >
              <span>
                <b className="caret" />
              </span>
            </a>

            <Collapse isOpen={openDebug}>
              <div className="debug-url">{process.env.REACT_APP_HOST}</div>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};

Sidebar.defaultProps = {
  routes: [],
  showNotification: false,
  backgroundColor: 'blue',
  minimizeSidebar: () => { },
};

Sidebar.propTypes = {
  // links that are rendered
  routes: PropTypes.arrayOf(PropTypes.object),
  // if you want to show a notification when switching between mini sidebar and normal
  showNotification: PropTypes.bool,
  // background color for the component
  backgroundColor: PropTypes.oneOf([
    'blue',
    'yellow',
    'green',
    'orange',
    'red',
  ]),
  // function that is called upon pressing the button near the logo
  minimizeSidebar: PropTypes.func,
};

export default React.memo(Sidebar);
