import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Button,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
//actions
import {
  actionGetPractice,
  actionSendInvitation,
} from 'store/actions/practicesActions';
import UserAvatar from 'components/Users/UserAvatar';
import { useTranslate } from 'react-translate';

const InvitationByUser = ({ user, setUser }) => {
  const t = useTranslate('practices');

  const emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const invitationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(emailRex, t('error-email-invalid'))
      .required(t('error-email-required')),
    custom_role_id: yup.string().required(t('error-custom-role-required')),
    text: yup.string().required(t('error-text-required')),
    user_name: yup.string().required(t('error-user-name-required')),
    title: yup.string().required(t('error-title-required')),
  });

  const { handleSubmit, errors, register } = useForm({
    validationSchema: invitationSchema,
    mode: 'onChange',
  });
  const dispatch = useDispatch();
  const practiceId = useSelector((state) => state.userAuthorizationReducer._id);
  const practice = useSelector((state) => state.practicesReducer.practice);

  const onSubmit = (data) => {
    const role = practice.custom_role.find(r => r._id === data.custom_role_id);
    dispatch(actionSendInvitation({...data, role_name: role.name}));
  };

  useEffect(() => {
    if (practiceId) {
      dispatch(actionGetPractice(practiceId));
    }
    // eslint-disable-next-line
  }, [dispatch, practiceId]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        innerRef={register}
        type="hidden"
        name="auth_user_id"
        defaultValue={user.auth_user_id}
      />
      <Input
        innerRef={register}
        type="hidden"
        name="email"
        defaultValue={user.email}
      />
      <Input
        innerRef={register}
        type="hidden"
        name="user_name"
        defaultValue={`${user.firstName} ${user.lastName}`}
      />
      <Card>
        <CardHeader className="">
          <div className="practice-title">{t('subtitle-invite-user')}</div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={2} className="text-center">
              <UserAvatar user={user} />
            </Col>
            <Col md={4}>
              <div>
                {user.firstName} {user.lastName}
              </div>
              <div>{user.speciality}</div>
            </Col>
            <Col md={6}>
              <Row>
                <Col>
                  <Label for="role">{t('form-select-role')}</Label>
                  <FormGroup
                    className={`has-label ${
                      errors.custom_role ? 'has-danger' : ''
                    }`}
                  >
                    <Input
                      type="select"
                      name="custom_role_id"
                      id="custom-role"
                      innerRef={register}
                    >
                      {practice.custom_role.length &&
                        practice.custom_role.map((role) => (
                          <option value={role._id} key={role._id}>
                            {role.name}
                          </option>
                        ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <Label for="title">{t('form-title')}</Label>
                  <FormGroup>
                    <Input
                      innerRef={register}
                      name="title"
                      id="title"
                      placeholder="Dr. Ms. Msc. Prof."
                      required
                      defaultValue={user.title}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <FormGroup>
                <Input
                  innerRef={register}
                  type="textarea"
                  name="text"
                  id="send-email-text"
                  placeholder={t('form-placeholder-text-invitation')}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="d-flex">
          <div className="ml-auto">
            <Button color="info">{t('btn-send')}</Button>
            <Button color="danger" className="ml-1" onClick={() => setUser({})}>
              {t('btn-cancel')}
            </Button>
          </div>
        </CardFooter>
      </Card>
    </form>
  );
};

export default InvitationByUser;
