import React, { useEffect, useState, useRef } from 'react';
import { Input, Button, Row, Col, Label, TabPane, CardBody } from 'reactstrap';

// Include Translation tool
import { useTranslate } from "react-translate";

//Text editor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//Include the stored comments list
import KiLabEditor_stored_list from "components/KiLab/KiLabEditor_stored_list";
import { useDispatch, useSelector } from 'react-redux';
import { actionSendEarlyReview, actionSaveEarlyDraft } from 'store/actions/kiLabActions';

const KiLabEditor_analytes = () => {

    //Translation library
    const tEditor = useTranslate('kilab-editor');
    const tMedicalReview = useTranslate('medical-review');
    const tLabDetail = useTranslate('laboratory-detail');
    const study = useSelector(state => state.kiLabReducer.study);
    const isLoadingAI = useSelector((state) => state.kiLabReducer.loadingAiReview);
    const context = useSelector(state => state.kiLabReducer.context);
    const practiceId = useSelector(state => state.practicesReducer.practice._id);
    const dispatch = useDispatch();
    const analytes = study && study.study_data ? JSON.parse(study.study_data.folgen_values) : [];

    const [review, setReview] = useState(
        study && study.draft
        ? study.draft.text
        : ""
    );

    const editedInfo = useRef();
    const timeoutHandle = useRef();
    const savingTimeoutHandle = useRef();
    const [canSend, setCanSend] = useState(false);
    const [edited, setEdited] = useState(false);
    const [saving, setSaving] = useState(0);
    const [commentType, setCommentType] = useState(Object.keys(study).length && study.draft ? study.draft.comment_type : 0);
    const [canVote, setCanVote] = useState(false);

    //Helper to filter control keys
    const isValidInput = (e) => {
        let code = e.which ? e.which : e.keyCode;
        return !(
            code == 16 || code == 17 || code == 18 || code == 19 || code == 20 || code == 27 || code == 45 || code == 91 || 
            code == 93 || (code >= 35 && code <= 40) || (code >= 33 && code <= 34 ) || (code >= 112 && code <= 123) || (code >= 144 && code <= 145 )
        );
    }

    const showTemporalSavedIcon = () => {
        setSaving(2);
        setEdited(false);
        savingTimeoutHandle.current = setTimeout(() => {
            setSaving(0);
            savingTimeoutHandle.current = null;
        }, 2400);
    }

    useEffect(() => {
        if (saving === 1) {
            showTemporalSavedIcon();
        }

        //Send if the study got updated and brought an AI draft
        if (study && study.draft && study.draft.comment_type === 1) {
            setCanSend(true);
            setCanVote(true);
        }

        setReview(study.draft ? study.draft.text : "");
        setCommentType(Object.keys(study).length && study.draft ? study.draft.comment_type : 0);
    }, [study]);

    useEffect(() => {
        if (edited) {
            if (timeoutHandle.current) {
                clearTimeout(timeoutHandle.current);
            }
            /*
            if (savingTimeoutHandle.current) {
                clearTimeout(savingTimeoutHandle.current);
            }
                */

            //Set saving indicator at phase 1
            setSaving(1);
    
            timeoutHandle.current = setTimeout(() => {
                saveDraft(review);
                setCanSend(true);
                timeoutHandle.current = null;
            }, 1800);
        }
    }, [review, edited]);

    //Get options from radio fields
    const getOptionsValue = () => {
        let option1, option2;

        for (let elem of document.getElementsByName("completely")) {
            if (elem.checked) {
                option1 = elem.value;
            }
        }

        for (let elem of document.getElementsByName("right")) {
            if (elem.checked) {
                option2 = elem.value;
            }
        }

        return [option1, option2];
    }

    const saveDraft = (review) => {
        if (timeoutHandle.current) {
            clearTimeout(timeoutHandle.current);
        }
        const [op1, op2] = getOptionsValue();

        dispatch(actionSaveEarlyDraft({
            studyId: study._id, 
            review: review, 
            hl7_id: study.study_data._id,
            practiceId, practiceId,
            type: commentType,
            context: {
                ...context,
                option_1: op1,
                option_2: op2
            }
        }));
    }

    const saveReview = () => {
        if (timeoutHandle.current) {
            clearTimeout(timeoutHandle.current);
        }
        const [op1, op2] = getOptionsValue();

        //const value = getOptionsValue();
        dispatch(actionSendEarlyReview({
            studyId: study._id, 
            review: review, 
            hl7_id: study.study_data._id,
            practiceId, practiceId,
            type: commentType,
            context: {
                ...context,
                option_1: op1,
                option_2: op2
            }
        }));
    }

    return (

<TabPane tabId="tab-comment">

        { /*  Comment Editor Secition */ }

        <span className={`autosave-container ${saving === 1 ? "info-color" : saving === 2 ? "success-color" : ""}`}> 
            {edited && saving === 1 ? (
                <>
                    <i className="save-icon icon-hourglass-1"></i> 
                    <span>{tLabDetail('autosave-saving')}</span>
                </>
            ) : saving === 2 ? (
                <>
                    <i className="save-icon icon-active-user"></i> 
                    <span>{tLabDetail('autosave-saved')}</span>
                </>
            ) : (
                <div style={{height: "21px"}}></div>
            )}
        </span>

        <Row>
            <Col lg={"12"}>
                <div className="review-field">
                    <ReactQuill id="editor"
                        readOnly={isLoadingAI}
                        disabled={isLoadingAI}
                        modules={{ toolbar:  [
                            [{ size: [ 'small', false, 'large', 'huge' ]}],
                            ['bold', 'italic', 'underline'],
                            ['link'],
                            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                            ['blockquote'],
                            ['clean']
                        ]}}
                        style={{
                            height: "100%",
                            overflowY: "auto",
                            transitionDuration: "0.3s"
                        }}

                        onKeyDown={(e) => {
                            if (isValidInput(e)) {

                                if (commentType === 1) {
                                    setCommentType(2);
                                }

                                setReview(e.target.innerHTML);
                                editedInfo.current = e.target.innerHTML;
                                setEdited(true);
                                setCanSend(false);
                            }
                        }}

                        onChange={(text) => {
                            
                            setReview(text);
                            editedInfo.current = text;
                        }}
                        value={review ? review : ""}
                    >
                    </ReactQuill>

                    <div className="folgen-values-main-section">
                        <div className="folgen-title">
                            <p>{tEditor("folgen-title")}</p>
                        </div>
                        <div className="folgen-section">
                            {
                            analytes && analytes.length ? analytes.map((fv,i) => {
                                let value = fv["Observation Identifier"].replace(/^([\s]*)\s/, '').split(' ');
                                return (
                                <div className="folgen-pill" key={`edition-folgen-pill-${i}`}>
                                    {`${value[1]} ${value[2]}`}
                                </div>
                                )
                            }) : (<p>{tMedicalReview("all-folgen-values-processed")}</p>)
                            }
                        </div>
                    </div>                    
                </div>
            </Col>
        </Row>


       { /* Survey section */}
        <Row className="ki-report">
            <CardBody>
                {/* Preguntas de evaluación */}
                    <Row className="align-items-center mb-2">
                          
                        <Col md={9} className="">
                            <Row>
                                <div className='divSurvCont'>
                                            
                                            <Col md={6}>
                                                <Label className="survTitle">{tEditor("survey-comment-complete")}</Label>

                                                <Col md={12} className="text-left cont-surv-radio">
                                                    <Label className="mr-4 rad-surv">
                                                        <Input type="radio" name="completely" value="c_yes" disabled={!canVote || isLoadingAI} />{tEditor("yes")}
                                                    </Label>
                                                    <Label className="mr-4 rad-surv">
                                                        <Input type="radio" name="completely" value="c_no" disabled={!canVote || isLoadingAI} />{tEditor("no")}
                                                    </Label>
                                                    <Label className="rad-surv" >
                                                        <Input type="radio" name="completely" value="c_dont_know" disabled={!canVote || isLoadingAI} />{tEditor("dont-know")}
                                                    </Label>
                                                </Col>
                                            </Col>

                                            <Col md={6}>
                                                <Label className="survTitle">{tEditor("survey-comment-correct")}</Label>

                                                <Col md={12} className="text-left  cont-surv-radio">
                                                    <Label className="mr-4 rad-surv">
                                                        <Input type="radio" name="right" value="r_yes" disabled={!canVote || isLoadingAI} />{tEditor("yes")}
                                                    </Label>
                                                    <Label className="mr-4 rad-surv">
                                                        <Input type="radio" name="right" value="r_no" disabled={!canVote || isLoadingAI} />{tEditor("no")}
                                                    </Label>
                                                    <Label className="rad-surv">
                                                        <Input type="radio" name="right" value="r_dont_know" disabled={!canVote || isLoadingAI} />{tEditor("dont-know")}
                                                    </Label>
                                                </Col>
                                            </Col>
                                </div>
                            </Row>
                        </Col>
                        <Col md={3}>
                            {/* Action buttons */}
                            <Row className="btnAiContainter">
                                <Button 
                                className="btn btn-success mr-2 btn-ai-call" 
                                onClick={saveReview}
                                disabled={!canSend || isLoadingAI}
                                >
                                    {tEditor("btn-save-store")}
                                </Button>
                            </Row>
                        </Col>
                    </Row>
            </CardBody>
        </Row>


        { /*********************************
        Component to render de comment tab */ }
        <KiLabEditor_stored_list />


</TabPane>
);
}
export default KiLabEditor_analytes;