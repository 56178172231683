import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslate } from 'react-translate';
//Components
import MsgIncoming from './MsgIncoming';
import MsgOutgoing from './MsgOutgoing';
import { actionGetChatMessagesScroll, actionChatMessagesRefresh } from 'store/actions/chatsActions';
import { selectChatMessages } from 'store/selectors/selectors';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';

 //Note: Always can see one message,after verify si the field total_message is major that 1,if is true, get tehe rest of messages

const ChatInbox = ({ parent, chatId, replyData, setReplyData, scrollSet }) => {
  const dispatch = useDispatch();
  const currentPos = useRef();
  const firstLoadRef = useRef(false);
  const shouldLoadMsgsRef = useRef(false);
  const historyRef = useRef();
  const lastMessageRef = useRef(null);
  const firstMessageRef = useRef();
  const t = useTranslate('chat');
  const messages = useSelector((state) => selectChatMessages(state, chatId));
  const chat = useSelector((state) => state.chatsReducer.chat);
  const { isDisaffected } = useSelector((state) => state.practicesReducer);
  const { mobile, dashboardMobile } = useSelector(
    (state) => state.commonReducer
  );
  const user = useSelector((state) => state.userReducer);
  const messagesQty = messages.length;

  let sameSender = false;
  let currentSender = null;

  let sameTime = false;
  let date = null;
  let time = null;

  const setScrollToBottom = () => {
    if (historyRef.current) {
      historyRef.current.scrollTop = historyRef.current.scrollHeight;
    }
  };

  //set scroll to bottom on window resize (showing the keyboard on mobile)
  useEffect(() => {
    if (mobile) {
      window.addEventListener('resize', setScrollToBottom);
    }
    
    return () => {
      window.removeEventListener('resize', setScrollToBottom);
    };
  }, []);  

  useEffect(() => {
    //Set scroll to bottom if chat changes
    if (historyRef.current && historyRef.current.scrollHeight > historyRef.current.clientHeight) {
      setScrollToBottom();
    }
    //eslint-disable-next-line
  }, [chatId]);

  useEffect(() => {
    // Set scroll in new message
    if ((setNewMessage() && firstMessageRef.current) || !shouldLoadMsgsRef.current) {
      shouldLoadMsgsRef.current = false;
      setScrollToBottom();
    }
    //eslint-disable-next-line
  }, [historyRef, messagesQty, messages, chatId]);

  useEffect(() => {
    // Set scroll to bottom if this is the first load of the chat, and if the scroll is visible
    if (firstLoadRef.current && historyRef.current && historyRef.current.scrollHeight > historyRef.current.clientHeight) {
      setScrollToBottom();
      firstLoadRef.current = false;
    }

    //Adjust scroll when new messages loads
    if (shouldLoadMsgsRef.current && historyRef.current) {
      const currentHeight = historyRef.current?.scrollHeight - historyRef.current?.clientHeight;
      const prevHeight = currentPos.current?.height - historyRef.current?.clientHeight;
      historyRef.current.scrollTop = !currentPos.current?.pos ? (currentHeight - prevHeight) : historyRef.current.scrollTop;
      shouldLoadMsgsRef.current = false;
    }
    //eslint-disable-next-line
  }, [messagesQty, messages]);

  useEffect(() => {
    //First load
    if (chat.messages_total > messagesQty) {
      if (setOldMessage()) {
        dispatch(actionGetChatMessagesScroll(chatId, lastMessageRef.current));
      }
      firstLoadRef.current = true;
    }
    //eslint-disable-next-line
  }, [chat, chatId]);


  const setOldMessage = () => {
    const last = messages && messages.length > 0 ? messages[0] : null;
    if (last && last._id && lastMessageRef.current !== last._id) {
      lastMessageRef.current = last._id;
      return true;
    }
    return false;
  }

  const setNewMessage = () => {
    const first = messagesQty > 0 ? messages[messagesQty - 1] : null;
    if (first && firstMessageRef.current?._id !== first._id) {
      firstMessageRef.current = first._id ? first : null;
      return true;
    }
    return false;
  }

  const updateOnScroll = () => {

    //Keep updating current position
    currentPos.current = {
      ...currentPos.current,
      pos: historyRef.current?.scrollTop
    };

    if (!shouldLoadMsgsRef.current && historyRef.current.scrollHeight > historyRef.current.clientHeight &&
      chatId &&
      !historyRef.current.scrollTop &&
      messagesQty < chat.messages_total) {
        shouldLoadMsgsRef.current = true;
      }
      
    if (!firstLoadRef.current &&
      historyRef.current &&
      historyRef.current.scrollHeight > historyRef.current.clientHeight &&
      chatId &&
      historyRef.current.scrollTop === 0 &&
      shouldLoadMsgsRef.current) {
      // Set scroll in old message
      if (setOldMessage()) {

        //Save the current height
        currentPos.current = {
          ...currentPos.current,
          height: historyRef.current?.scrollHeight
        };

        dispatch(actionGetChatMessagesScroll(chatId, lastMessageRef.current));
      }
    }
  };

  return (
    <>
      <div
        className={`inbox-history${mobile || (parent === 'dashboard' && dashboardMobile) ? '-mobile' : ''
          }${replyData && replyData.author && replyData.body ? '-resized' : ''
          }
        ${isDisaffected === true ? ' chat-inbox-disaffected' : ''}
        `}
        ref={historyRef}
        onScroll={() => {
          updateOnScroll();
        }}
      >
        {/* loading the rest of messages */}
        {messagesQty <= 1 && chat.messages_total > messagesQty ? (
          <LoadingLocal />
        ) : (
          <>
            {chat && (!messages.length) ?
              <div>
                <h5 className="d-flex align-items-center justify-content-center general-card-title text-primary">
                  <span>{t('no-chat-results')}</span>
                </h5>
              </div>
              :
              <div>
                 {messages.length > 0 &&
              messages.map((message) => {
                //only for temporal message
                if (message.temp_id) {
                  return (
                    <MsgOutgoing
                      key={message.temp_id}
                      message={message}
                      sameSender={true}
                      sameTime={true}
                    />
                  );
                }
                if (!message.sender) {
                  return null;
                }

                let messageTime = moment(message.date).format('H:mm A');
                let messageDate = moment(message.date).format('MMMM D');

                //Check if time has changed between messages
                if (time === messageTime && date === messageDate) {
                  sameTime = true;
                } else {
                  sameTime = false;
                  date = messageDate;
                  time = messageTime;
                }

                //Check if the previous sender is the same for the current message
                if (message.sender.auth_user_id === currentSender) {
                  sameSender = true;
                } else {
                  sameSender = false;
                  sameTime = false;
                  currentSender = message.sender.auth_user_id;
                  date = messageDate;
                  time = messageTime;
                }

                //Check if the message is a system message
                if (message.action !== 'default') {
                  sameSender = false;
                  sameTime = false;
                  currentSender = '';
                }

                if (message.sender.auth_user_id === user.auth_user_id) {
                  return (
                    <MsgOutgoing
                      key={message._id}
                      message={message}
                      sameSender={sameSender}
                      sameTime={sameTime}
                      setReplyData={setReplyData}
                    />
                  );
                }
                return (
                  <MsgIncoming
                    parent={parent}
                    key={message._id}
                    message={message}
                    sameSender={sameSender}
                    sameTime={sameTime}
                    setReplyData={setReplyData}
                  />
                );
              })}
              </div>
            }
           
          </>
        )}

      </div>
      {/* <ChatSendInput /> */}
    </>
  );
};

export default ChatInbox;
