import React, { useEffect } from 'react';
import history from 'historyRouter';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { tokenName } from 'configs/app.json';
import AdminLayout from 'layouts/Admin.jsx';
import AuthLayout from 'layouts/Auth.jsx';
import Loading from 'plugins/TS-lib-utils-public/components/Loading/LoadingGlobal';
//hooks
import useUrlParams from 'hooks/useUrlParams';
import useNativeReceiver from "hooks/useNativeReceiver";
import { actionGetUser, actionLogoutBase } from 'store/actions/usersActions';
import { useDispatch, useSelector } from 'react-redux';

// react plugin for creating notifications
import { toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Let toast plugin inject a ToastContainer automatically
toast.configure({
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  transition: Flip
})

const App = ({ persistor }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);
  const loading = useSelector((state) => state.loadingReducer);
  const appInfo = useNativeReceiver();

  useEffect(() => {
    const action = appInfo.action;
    
    switch (action) {
      case "LOGOUT_READY":
        dispatch(actionLogoutBase());
        break;
    }
  }, [appInfo]);

  /**
   * Get invitation from url
   */
  let invitation = useUrlParams('invitation');
  if (invitation) {
    localStorage.setItem('ts-invitation', invitation);
  }
  //Set url of request
  localStorage.setItem('tsd-url', window.location.pathname);

  let token = localStorage.getItem(tokenName);
  useEffect(() => {
    if (token && !user?._id) {
      dispatch(actionGetUser());
    }
  }, [dispatch, user?._id, token]);

  //Save store content before page unload
  useEffect(() => {
    window.addEventListener("beforeunload", saveReduxState);

    return () => {
      window.removeEventListener("beforeunload", saveReduxState);
    }
  }, []);

  const saveReduxState = () => {
    persistor.flush().catch(er => {
      console.log("Error while saving the state");
    });
  };

  if ((token && !user?._id) || loading) {
    return <Loading loading />;
  }
  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/admin"
          render={(props) => {
            return <AdminLayout {...props} />;
          }}
        />
        <Route
          path="/auth"
          render={(props) => {
            return <AuthLayout {...props} />;
          }}
        />
        {token && user?._id ? (
          <Redirect from="/" to="/admin" />
        ) : (
          <Redirect from="/" to="/auth/login-page" />
        )}
      </Switch>
    </Router>
  );
};

export default App;