import React, { useState, useEffect } from 'react';

//components
import Actions from '../Actions/Actions';
import InboxViewList from './InboxViewList';
import InboxViewDetail from './InboxViewDetail';
import InboxMobileDetail from './InboxMobileDetail';
import InboxViewForm from './InboxViewForm';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Button } from 'reactstrap';
//import InboxSearcher from '../InboxSearcher';
import { actionGetMessageFromURL } from 'store/actions/messagesActions';
import useUrlParams from 'hooks/useUrlParams';
import { useHistory, useParams } from 'react-router';
import { useTranslate } from 'react-translate';
//Actions
import { actionSetMessage } from 'store/actions/messagesActions';
import { actionSetBox } from 'store/actions/messagesActions';

const InboxBody = ({ showForm, setShowForm, isDisaffected, practice }) => {
  const t = useTranslate('messages');
  // eslint-disable-next-line
  const [replyTo, setReplyTo] = useState({
    tags: [],
  });
  const [resend, setResend] = useState(false);
  const [reply, setReply] = useState(false);
  //const user = useSelector((state) => state.userReducer);
  const { message, box } = useSelector((state) => state.messagesReducer);
  const { mobile } = useSelector((state) => state.commonReducer);
  const [contactIsDisaffected, setContactIsDisaffected ]= useState(false);
  const { id } = useParams();
  const history = useHistory();
  const practiceURL = useUrlParams('practice');
  const dispatch = useDispatch();

  const setReplyToTags = (to) => {
    setReplyTo({
      ...setReplyTo,
      tags: to,
    });
  }; 
  //set visibility of buttons if contact is an user disafected of the practice
  const setUserDisaffected = (status) =>{
    setContactIsDisaffected(status);
  }
  
  useEffect(() => {
    if (id && id !== ':id' && (!message || message?._id !== id)) {
      dispatch(actionGetMessageFromURL(id, practiceURL));
      history.replace(`/admin/inbox`);
    }
    //eslint-disable-next-line
  }, [message]);

  return (
    <>
      {/* <Actions showForm={showForm} setShowForm={setShowForm} mobile={mobile} /> */}
      <Row>
        {/*
        <Col sm={12} md={4}>
          <InboxSearcher box={box} />
        </Col>
        */}
        <Col sm={12}>
          <Actions 
          setShowForm={setShowForm} 
          mobile={mobile} 
          setResend={setResend} 
          setReply={setReply} 
          isDisaffected ={(isDisaffected === true || contactIsDisaffected=== true) ? true : false}/>
        </Col>
      </Row>
      {!message && !showForm && (
        <InboxViewList setShowForm={setShowForm} mobile={mobile} />
      )}
      {message &&
        !showForm &&
        (mobile ? (
          <>
            <InboxMobileDetail
              setReplyToTags={setReplyToTags}
              setShowForm={setShowForm}
              mobile={mobile}
              callback={setUserDisaffected}
              Idisafected={isDisaffected}
            />

          <hr />

          <Row>
              <Col>
                <Button
                  type="button"
                  size="lg"
                  color="info"
                  className="btn-msg-mobile-back"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(actionSetBox('inbox'));
                    dispatch(actionSetMessage());
                    setShowForm(false);
                  }}>
                <i className="fas fa-arrow-left"></i> {t('go-back')}
                </Button>
              </Col>
          </Row>

          </>
        ) : (
          <InboxViewDetail 
            key={0}
            setReplyToTags={setReplyToTags}
            mobile={mobile}
            callback={setUserDisaffected}
            Idisafected={isDisaffected}
          />
        ))}
        

      {showForm && (
        <InboxViewForm
          setReplyToTags={setReplyToTags}
          setShowForm={setShowForm}
          mobile={mobile}
          resend={resend}
          reply={reply}
          setReply={setReply}
        />
      )}
    </>
  );
};

export default InboxBody;
