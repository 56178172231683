import { sort } from 'fast-sort';

/**
 * Chats sorter by property
 */
export const chatsSorter = (chats) => {
  if (!chats || !Array.isArray(chats)) {
    return [];
  }

  //sort by chat name
  let chatsSorted = sort(chats).asc(chat => {
    if (chat.main_user || chat.type === 'group') {
      return chat.name;
    } else {
      return `${chat.firstName} ${chat.lastName}`;
    }
  });

  //Then, sort by chat status and type
  let finalChats = sort(chatsSorted).by({
    asc: true,
    comparer: (a, b) => {
      
      let aResult = 0;
      let bResult = 0;
      
      if (a.type === 'group') {
        aResult = 2;
      } else if (a.main_user || a.status) {
        const status = a.main_user ? a.main_user.status : a.status;

        switch (status) {
          case "online":
            aResult = 1;
          break;

          case "partial":
            aResult = 3;
          break;

          case "offline":
          case "active":
            aResult = 4;
          break;

          default:
            aResult = 5;
          break;
        }
      }

      if (b.type === 'group') {
        bResult = 2;
      } else if (b.main_user || b.status) {
        const status = b.main_user ? b.main_user.status : b.status;

        switch (status) {
          case "online":
            bResult = 1;
          break;

          case "partial":
            bResult = 3;
          break;

          case "offline":
          case "active":
            bResult = 4;
          break;

          default:
            bResult = 5;
          break;
        }
      }

      return aResult - bResult;
    }
  });

  return finalChats;
};