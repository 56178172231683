import { takeEvery, put, select, call } from 'redux-saga/effects';
import CONSTANTS from '../CONSTANTS';
//Services
import AppointmentsService from '../../services/AppointmentsService';
//Actions
import {
  actionSetAppointments,
  actionSetAppointment,
} from '../actions/appointmentsActions';
import { actionSetResponse } from '../actions/responsesActions';
// import { actionSetLoading } from "../actions/loadingActions";
import { getUser, getAppointments } from '../selectors/selectors';

function* sagaGetAppointment({ key }) {
  try {
    if (!isNaN(key)) {
      // disable
      const { appointments } = yield select(getAppointments);
      if (appointments.length > 0) {
        let appointment = appointments.filter(
          (data, index) => index === key ?? data
        );
        if (typeof appointment[0] !== 'undefined') {
          appointment[0].key = key;
          appointment[0].count = appointments.length;
          yield put(actionSetAppointment(appointment[0]));
        } else {
          yield put(actionSetAppointment({}));
        }
      }
    }
  } catch (error) {
    yield put(
      actionSetResponse({
        status: 'error',
        appointment: 'Cannot get data',
      })
    );
  }
}

function* sagaAppointments() {
  try {
    const user = yield select(getUser);

    const result = user._id
      ? yield call(AppointmentsService.appointments, user._id)
      : [];
    if (!result.isAxiosError) {
      yield put(actionSetAppointments(result));
    }
  } catch (error) {
    yield put(
      actionSetResponse({
        status: 'error',
        appointment: 'Cannot get data',
      })
    );
  }
}

export function* appointmentsSaga() {
  console.log('*Main Appointments Saga');
  yield takeEvery(CONSTANTS.APPOINTMENTS_GET, sagaAppointments);
  yield takeEvery(CONSTANTS.APPOINTMENTS_GET_AP, sagaGetAppointment);
}
