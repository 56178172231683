import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslate } from 'react-translate';
import { useDispatch, useSelector } from 'react-redux';
import { actionSweetAlert } from 'store/actions/commonActions';
import { actionDeleteAccountRequest } from 'store/actions/usersActions';
// reactstrap components
import { Button } from 'reactstrap';

const UserDeleteForm = (props) => {
  const t = useTranslate('user-delete-form');
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer);

  return (
    <div className="setting-section account-deletion-section">
      <h5 className="general-card-subtitle">{t('title')}</h5>
      {t('save-data-notice')}
      <br />
      <Button
        type="danger"
        color="danger"
        name="btn-delete-account"
        onClick={() => {
          dispatch(
            actionSweetAlert(
              <SweetAlert
                custom
                customIcon="/General/icons/warning-sign-orange.svg"
                title={t("phrase-are-you-sure")}
                onConfirm={() => { }}
                customButtons={
                  <>
                    {/*Custom buttons for more control*/}
                    <Button
                      className="mb-3"
                      color="danger"
                      size="lg"
                      onClick={() => {
                        dispatch(actionDeleteAccountRequest());
                        dispatch(actionSweetAlert(null));
                      }}
                    >
                      {t('btn-yes')}
                    </Button>
      
                    <Button
                      className="mb-3 ml-2"
                      color="info"
                      size="lg"
                      onClick={() => dispatch(actionSweetAlert(null))}
                    >
                      {t('btn-no')}
                    </Button>
                  </>
                }
              >
                {t('delete-account-disclaimer')}
              </SweetAlert>
            )
          );
        }}
      >
        {t('delete-account')}
      </Button>
    </div>
  );
};


export default UserDeleteForm;
