import CONSTANTS from '../CONSTANTS';

export const actionProfileUpdate = (profile) => ({
  type: CONSTANTS.PROFILE_SAVE,
  profile,
});

export const actionSetProfile = (profile) => ({
  type: CONSTANTS.PROFILE_SET,
  profile,
});