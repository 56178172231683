import React from 'react';
import { Emoji } from 'emoji-mart';

/**
 * Remplace emojis code by emojis
 * @returns text with emojis
 */
const TextToEmojis = ({ text }) => {
  if (!text) {
    return null;
  }
  const colons = `:[a-zA-Z0-9-_+]+:`;
  const skin = `:skin-tone-[2-6]:`;
  const colonsRegex = new RegExp(`(${colons}${skin}|${colons})`, 'g');
  return (
    <>
      {text
        .split(colonsRegex)
        .map(
          (emoji, idx) =>
            !!emoji && (
              <Emoji size={24} key={idx} emoji={emoji} fallback={() => emoji} />
            )
        )}
    </>
  );
};

export default TextToEmojis;
