import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
//Components
import InvitationByUser from 'components/Practice/Invitations/InvitationByUser';
// reactstrap components
import { Col, FormGroup, Input, Label, Row, Table } from 'reactstrap';

//Services
import UsersService from 'services/UsersService';
import { toast } from 'react-toastify';
import UserAvatar from 'components/Users/UserAvatar';
import { useTranslate } from 'react-translate';

const SearchUser = () => {
  const t = useTranslate('practices');
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);

  const { control, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    try {
      if (data.search) {
        const result = await UsersService.search(data.search);
        if (Array.isArray(result.data)) {
          setUsers(result.data);
          setUser({});
        }
        return result;
      }
      setUsers([]);
    } catch (error) {
      toast.error(error.message || 'Bad request');
    }
  };
  return (
    <React.Fragment>
      <h5 className="general-card-subtitle">
        {t('subtitle-invite-user')}
      </h5>
      <Row>
        <Col md={6}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="search-user">{t('field-search-user')}</Label>
              <div className="input-group mb-3">
                <Controller
                  as={Input}
                  type="search"
                  name="search"
                  id="search"
                  control={control}
                  defaultValue=""
                />
                <div className="input-group-append">
                  <button type="submit" className="input-group-text">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </FormGroup>
          </form>
        </Col>
      </Row>
      {typeof user._id !== 'undefined' ? (
        <InvitationByUser user={user} setUser={setUser} />
      ) : (
        ''
      )}
      <Row className={typeof user._id !== 'undefined' ? 'd-none' : ''}>
        <Col>
          {users && users.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <th colSpan="2">{t('th-name')}</th>
                  <th>{t('th-speciality')}</th>
                  <th>{t('th-location')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => {
                  return (
                    <tr
                      onClick={() => setUser(user)}
                      key={user._id}
                      className="search-user-tr"
                    >
                      <td style={{ width: '64px' }}>
                        <UserAvatar user={user} />
                      </td>
                      <td>
                        {user.firstName} {user.lastName}
                      </td>
                      <td></td>
                      <td>{user.location}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SearchUser;
