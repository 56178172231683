import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPractitionerUserId } from 'store/selectors/selectors';
/**
 * Verify if chat members are online
 * @param {array} users list
 * @param {string} type chat
 * @returns string
 */
const useChatIsOnline = (chat) => {
  const chats_online = useSelector((state) => state.chatsReducer.chats_online);
  const practice = useSelector((state) => state.userAuthorizationReducer);

  const isMounted = useRef(null);
  const [status, setStatus] = useState('offline');

  const practitioner = chat.main_user;
  //Data in the active practice
  const practitionerData = useSelector((state) =>
    selectPractitionerUserId(state, practitioner.auth_user_id)
  );

  // if (chat.name.includes('Lacey')) {
  //   console.log({ practitioner, practitionerData });
  // }

  const practitionerStatus =
    practitionerData && practitionerData.status
      ? practitionerData.status
      : 'inactive';
  const practitionerChatStatus =
    practitionerData && practitionerData.chat_status
      ? practitionerData.chat_status
      : 'offline';

  const checkStatus = (user) => {
    if (user.status === 'inactive') {
      return 'inactive';
    }
    if (user.deleted) {
      return 'offline';
    }
    if (
      chats_online.hasOwnProperty(practice._id) &&
      Array.isArray(chats_online[practice._id])
    ) {
      const online = chats_online[practice._id];
      const userIsOnline = online.find(
        (auth_user_id) => auth_user_id === user.auth_user_id
      );
      if (!userIsOnline) {
        return 'offline';
      }
      if (user.chat_status === 'available') {
        return 'online';
      }
      if (user.chat_status === 'busy') {
        return 'partial';
      }
    }
    return 'offline';
  };

  useEffect(() => {
    isMounted.current = true;
    if (chat.type !== 'group') {
      if (practitionerStatus === 'inactive' && isMounted.current) {
        setStatus('inactive');
      } else if (isMounted.current) {
        setStatus(checkStatus(practitionerData));
      }
    }
    return () => {
      isMounted.current = false;
    };
    //eslint-disable-next-line
  }, [
    practitionerStatus,
    practitionerChatStatus,
    setStatus,
    checkStatus,
    chat.type,
  ]);

  return status;
};

export default useChatIsOnline;
