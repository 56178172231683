import AxiosService from 'plugins/TS-lib-utils-public/services/AxiosService';

class LaboratoryService extends AxiosService {
    constructor() {
        super('doctor-user');
    }
    /**
  * Get all studies linked to the current doctor and practice
  */
    getStudiesList = async (practiceId, page = 0, count = 5, sortDate = 0, search = null, dateFrom = null, dateTo = null, { cancelToken = null } = {}) => {
        let query = `/laboratory/?page=${page}&limit=${count}&datesort=${sortDate}`;

        if (search) {
            query += `&search=${search}`
        }
        if (dateFrom) {
            query += `&from=${dateFrom}`;
        }
        if (dateTo) {
            query += `&to=${dateTo}`;
        }
        
        const response = await this.get(query, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                practice: practiceId,
            },
            cancelToken: cancelToken
        });
        return response;
    };
    /**
    * Get get study detail by reportId
    */
    getStudyDetail = async (reportId, practiceId) => {
        const response = await this.get(`/laboratory/${reportId}`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                practice: practiceId,
            },
        });
        return response;
    };

    /* Get get study reportFile by reportId
    */
    getStudyReportFile = async (reportId, doctorId, patientId) => {
        const response = await this.get(`/laboratory/getpdf/${reportId}`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                doctorid: doctorId,
                patientid: patientId
            },
        });
        return response;
    };

    updateStudyReview = async (reportId, review, hl7Id, templateName, doctorId, practiceId, billing = false, aiFlag = false, isAi = false, makeDraft = false) => {
        const response = await this.post(`/laboratory/${reportId}/review`, {
            review: review,
            aiModified: !aiFlag,
            aiAuthor: isAi,
            makeDraft, 
            templateName: templateName.length ? templateName : null,
            billing: billing,
            hl7Id
        },
        {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                doctorId,
                practice: practiceId,
            },
        });

        return response;
    };

    sendStudyReview = async (reportId, review, hl7Id, templateName, doctorId, practiceId, billing = false, aiFlag = false, isAi = false) => {
        const response = await this.post(`/laboratory/${reportId}/review/send`,
        { 
            review: review,
            aiModified: !aiFlag,
            aiAuthor: isAi,
            templateName: templateName.length ? templateName : null,
            billing: billing,
            hl7Id
        },
        {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                doctorId,
                practice: practiceId,
            },
        });

        return response;
    };

    readAiComment = async (reportId, doctorId, practiceId) => {
        return await this.get(`/laboratory/${reportId}/review/ai/read`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                doctorId,
                practice: practiceId,
            },
        })
    }

    checkAiComment = async (reportId, doctorId, patientId, practiceId, days) => {
        return await this.get(`/laboratory/${reportId}/review/ai/check?days=${days}`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                doctorId,
                patientId,
                practice: practiceId,
            },
        })
    }

    deleteDraft = async (reportId, reviewId, doctorId, practiceId) => {
        return await this.delete(`/laboratory/${reportId}/review/${reviewId}`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                doctorId,
                practice: practiceId,
            },
        })
    }
}

export default new LaboratoryService();

