import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
// import { actionGetChats } from 'store/actions/chatsActions';
import ChatRoom from './ChatRoom';

const ChatListVertical = (props) => {

  const { isDisaffected } = useSelector((state) => state.practicesReducer);
  const chatverticalListRef = useRef();
  //const dispatch = useDispatch();
  //eslint-disable-next-line
  const [chatBegin, setChatBegin] = useState(0); //Should also update to avoid overload
  const [chatLimit, setChatLimit] = useState(15);

  /**
   * Chat & contacts are join in saga
   */
  const { chats,filter_select } = useSelector((state) => state.chatsReducer);

  const updateOnScroll = () => {
    if (
      chatverticalListRef.current &&
      Math.ceil(chatverticalListRef.current.scrollTop) >=
        chatverticalListRef.current.scrollHeight -
          chatverticalListRef.current.clientHeight
    ) {
      setChatLimit(chatLimit + 15);
    }
  };

  return (
    <div
      className={`chat-list-vertical ${isDisaffected === true ? 'disaffected-list-vertical' : ''}`}
      onScroll={() => {
        updateOnScroll();
      }}
      ref={chatverticalListRef}
    >
      {chats.length > 0 ?
        chats.slice(chatBegin, chatLimit).map((chat) => {
          if (['private', 'group'].includes(chat.type)) {
            return (
              <ChatRoom
                parent="chat-list-vertical"
                key={`chat-vertical-${['private', 'group'].includes(chat.type)
                ? chat._id 
                : `${chat._id}-${chat.auth_user_id}-${chat.firstName}-${chat.lastName}`}`}
                chat={chat}
                vertical
                filter_select={filter_select}
              />
            );
          }
        })
        : 
        <div className="chat-list-vertical no-chat-recents"></div>
        }
    </div>
  );
};

export default React.memo(ChatListVertical);
