import CONSTANTS from '../CONSTANTS';

const initialState = {

  data: [],
}
const statsReducer = (

  state = initialState,
  action
) => {

  switch (action.type) {
    
    case CONSTANTS.LAB_EARLY_STORE_PRACTICE_METRICS_REDUCER: 
    /*  
    console.log('Acción recibida:', action);
    console.log('Nuevo estado:', { ...state, data: action.data });
    */
      return {...state, data: action.data}
    default:
      return state;
  }
};

export default statsReducer;