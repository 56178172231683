import React from 'react';
import { Emoji } from 'emoji-mart';
import { useDispatch } from 'react-redux';
import { actionChatMessageAddEmoji } from 'store/actions/chatsActions';

const emojisDefault = [
  ':heavy_check_mark:',
  ':+1:',
  ':-1:',
  ':slightly_smiling_face:',
  ':question:',
];

const EmojisReactions = (props) => {
  const dispatch = useDispatch();
  const { show, message, setShow } = props;

  const handleClick = (e) => {
    dispatch(actionChatMessageAddEmoji(message, e.colons));
    setShow(!show);
  };

  return (
    <>
      {show &&
        emojisDefault.map((emoji, i) => (
          <Emoji
            size={24}
            key={i}
            emoji={emoji}
            fallback={() => emoji}
            onClick={handleClick}
          />
        ))}
    </>
  );
};

export default EmojisReactions;
