import React, { useEffect, useState, useRef} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import { actionChatDeleteUsers } from 'store/actions/chatsActions';
import { actionChatRemoveActiveUser } from 'store/actions/chatsActions';
import { actionChatAddUser, actionSaveChat } from 'store/actions/chatsActions';
import ChatPeopleAvatar from './ChatPeopleAvatar';
import ChatSearchInput from './ChatSearchInput';
import ChatNameInput from './ChatNameInput';
import ChatBtnDelete from './ChatBtnDelete';
import { Button } from 'reactstrap';

const ChatPeopleInGroup = ({
  selected,
  setEditChatMembers,
  setChatSearch,
  chatName,
}) => {
  const t = useTranslate('chat');
  const dispatch = useDispatch();
  const userActive = useSelector((state) => state.userReducer);
  const [sweetAlert, setSweetAlert] = useState(null);

  const activePractice = useSelector((state) => state.practicesReducer.practice);
  const isMounted = useRef(false);

  const { chat } = useSelector((state) => state.chatsReducer);
  const [chatNameDisplay, setChatNameDisplay] = useState(
    chatName.current ? chatName.current : chat.name
  );
  const users = !Array.isArray(chat.users)
    ? []
    : chat.users.filter(
        (user) => !user.deleted && userActive.auth_user_id !== user.auth_user_id && !user.disaffectedDate
      );

  const removeUser = (e) => {
    e.preventDefault();
    const userIds = [e.target.getAttribute('data-user')];
    setSweetAlert(
      <SweetAlert
        question
        title={t('are-you-sure') + '?'}
        onConfirm={() => {
          /**
           * Delete active user
           */
          if (userIds.includes(userActive.auth_user_id)) {
            dispatch(actionChatRemoveActiveUser());
          } else {
            /**
             * Delete user members
             */
            dispatch(actionChatDeleteUsers(userIds));
          }
          setSweetAlert(null);
        }}
        onCancel={() => {
          setSweetAlert(null);
        }}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="info"
        confirmBtnText={t('btn-yes')}
        cancelBtnText={t('btn-no')}
        showCancel
      >
        {t('disable')}
      </SweetAlert>
    );
  };

  const handleSubmit = () => {
    if (chatName.current.trim().length) {
      //Save the new name of the chat
      if (chat.name !== chatName.current) {
        dispatch(
          actionSaveChat({
            _id: chat._id,
            name: chatName.current,
          })
        );
      }

      //Add users to the chat
      if (selected.current.length > 0) {
        dispatch(actionChatAddUser(selected.current));
      }

      setEditChatMembers(false);
    }
  };

  //Return to the main view when the practice changes
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else {
      if (activePractice){
        setEditChatMembers(false);
      }
    }
    //eslint-disable-next-line
  }, [activePractice]);

  useEffect(() => {
    chatName.current = chatNameDisplay;
    //eslint-disable-next-line
  }, [chatNameDisplay]);

  useEffect(() => {
    return () => {
      setChatSearch('');
      setChatNameDisplay('');
      selected.current = [];
      chatName.current = '';
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {sweetAlert}
      <div className="chat-add-members-text">
        {t('add-users-to-group')} <b>{chatNameDisplay}</b>
      </div>

      <div className="d-flex">
        <ChatNameInput
          chatNameDisplay={chatNameDisplay}
          setChatNameDisplay={setChatNameDisplay}
        />
        <div className="d-flex buttons-group">
          <Button
            className="mr-2"
            color="info"
            size="sm"
            onClick={handleSubmit}
          >
            {t('button-save')}
          </Button>
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setEditChatMembers(false);
            }}
          >
            {t('button-cancel')}
          </Button>
        </div>
      </div>

      <hr />

      <div className="d-flex">
        <ChatSearchInput setChatSearch={setChatSearch} />
        <div className="buttons-group">
          <ChatBtnDelete setEditChatMembers={setEditChatMembers} />
        </div>
      </div>

      <div className="chat_group_contacts d-flex align-items-center">
        <div className="chat_list">
          {users &&
            users.map((user) => {
              return (
                <div className="people-item" key={user._id}>
                  <ChatPeopleAvatar user={user} showName />

                  <div
                    className="remove"
                    title="remove"
                    data-user={user.auth_user_id}
                    onClick={removeUser}
                  >
                    X
                  </div>
                  {/* <div className="text-truncate">
                    {user.firstName + ' ' + user.lastName}
                  </div> */}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ChatPeopleInGroup;
