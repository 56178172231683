
import React from "react";
import { Link } from "react-router-dom";
import LanguageSwitch from "components/Languages/LanguageSwitchHeader";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Container
} from "reactstrap";
import { translate } from "react-translate";

class AuthNavbar extends React.Component {
  state = {
    isOpen: false
  };
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };
  render() {
    return (
      <Navbar
        expand="lg"
        className={
          this.props.authNavbar
            ? "auth-navbar"
            : "" + this.state.isOpen
              ? "bg-white navbar-absolute"
              : "navbar-transparent navbar-absolute"
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <NavbarToggler onClick={this.toggle}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>

            <Link to="/" className="navbar-brand">
              {"Professionals"}
            </Link>

          </div>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <LanguageSwitch />
            </Nav>
            <Nav navbar>
              <NavItem>
                <a
                  onClick={(e) => { window.location.href = process.env.REACT_APP_HOST_PATIENTS; }}
                  className="nav-link"
                >
                  {this.props.t("patienten")}
                </a>
              </NavItem>
              <NavItem className={this.activeRoute("/auth/register-page")}>
                <Link to="/auth/register-page" className="nav-link">
                  <i className="now-ui-icons tech_mobile" /> {this.props.t("registrieren")}
                </Link>
              </NavItem>

              <NavItem className={this.activeRoute("/auth/login-page")}>
                <Link to="/auth/login-page" className="nav-link">
                  <i className="now-ui-icons users_circle-08" /> {this.props.t("login")}
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default translate('menu')(AuthNavbar);
