import Icon from 'components/Icons/Icon';
import PracticeTitle from 'components/Practice/PracticeTitle';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslate } from 'react-translate';
// reactstrap components
import { Card, CardBody, CardHeader } from 'reactstrap';
import { actionMetricPrecticeSelect} from 'store/actions/practicesActions';


const PracticeInviteUser = () => {
  const t = useTranslate('practices');
  const { practices, practice_manage_select } = useSelector(state => state.practicesReducer);


  return (
    <Card>
      <CardBody>
        <Card>
          <CardHeader>
            <PracticeTitle title ={practice_manage_select?.name}/>
          </CardHeader>
          <CardBody>
            <h5 className="general-card-subtitle">
              {t('subtitle-invite-user')}
            </h5>
            <Card className="border-0 shadow-none">
              <CardBody className="d-flex justify-content-center">
                <NavLink to="/admin/practice/users/invite-search">
                  <Icon file="search-user.svg" className="mr-2" width="120px" />
                  <br />
                  {t('search-telepraxen')}
                </NavLink>

                <div className="border-left ml-4 pl-4"></div>

                <NavLink to="/admin/practice/users/invite-email">
                  <Icon file="invite-user.svg" className="mr-2" width="120px" />
                  <br />
                  {t('send-email-invitation')}
                </NavLink>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
};

export default PracticeInviteUser;
