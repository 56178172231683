import React, { useState, useEffect, useRef, Suspense } from 'react';
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import { useHistory, useParams } from 'react-router';
import ChatRecents from './ChatRecents';
import ChatStatus from './ChatStatus';
import { useTranslate } from 'react-translate';
import { useDispatch, useSelector } from 'react-redux';
import ChatHeader from './ChatHeader';
import ChatListVertical from './ChatListVertical';
import ChatSendInput from './ChatSendInput';
import { actionGetChats, actionGetChatsDisaffected, actionChatFilter, actionGetChatFromUrlParams } from 'store/actions/chatsActions';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import useUrlParams from 'hooks/useUrlParams';
import MenuOptions from 'components/Sidebar/MenuOptions';


const ChatInbox = React.lazy(() => import('./ChatInbox'));
const ChatCreateGroup = React.lazy(() => import('./ChatCreateGroup'));
const ChatPeopleInGroup = React.lazy(() => import('./ChatPeopleInGroup'));
const ChatPeopleListVertical = React.lazy(() =>
  import('./ChatPeopleListVertical')
);

const ChatBox = (props) => {
  const dispatch = useDispatch();
  const parent = props.view;
  //global state of current user 
  const { isDisaffected } = useSelector((state) => state.practicesReducer);
  const practice = props.practice;
  const userAuth = props.userAuth;
  const history = useHistory();
  const { id } = useParams();
  const practiceURL = useUrlParams('practice');

  const t = useTranslate('chat');
  const { chat, chats, filter_select, loadingDone } = useSelector((state) => state.chatsReducer);
  //parameter endpoint chatsDisaffected,
  const queryDisaffected = isDisaffected === true ? '1' : '0';

  const selected = useRef([]);
  const [isOpen, setIsOpen] = useState(true);
  const [scrollSet, setscrollSet] = useState('update');
  const [chatSearch, setChatSearch] = useState(null);
  const [showChatForm, setShowChatForm] = useState(false);
  const [editChatMembers, setEditChatMembers] = useState(false);
  const [contactDisaffected, setContactDisaffected] = useState(false);
  const [openAvatar, setOpenAvatar] = useState(false);
  const chatNameRef = useRef(chat.name);
  const toggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const [replyData, setReplyData] = useState({});

  const setScrollInbox = () => {
    let status = scrollSet === 'update' ? 'new update' : 'update';
    setscrollSet(status);
  }

  //function which receive value of filter select
  const setChatsList = (status) => {
    if (status === true) {
      dispatch(actionChatFilter(status));
      setContactDisaffected(true);
      dispatch(actionGetChatsDisaffected(true, userAuth, queryDisaffected));
    } else {
      dispatch(actionChatFilter(status));
      setContactDisaffected(false);
      dispatch(actionGetChats(true));
    }
  }

  useEffect(() => {
    if (chats && chats.length > 0) {
      if (id && id !== ':id') {
        dispatch(actionGetChatFromUrlParams(id, practiceURL));
        history.replace(`/admin/chats`);
      }
    }
    //eslint-disable-next-line
  }, [chats]);


  useEffect(() => {
    chatNameRef.current = chat.name;
    setReplyData({});
    //eslint-disable-next-line
  }, [chat, filter_select]);
  
  useEffect(() => {
    //'filter select' is the Select of Header that filter chats of users actives/disaffected
    dispatch(actionChatFilter(filter_select));

    if (!chats.length) {
      if ((isDisaffected || filter_select) && userAuth) {
        dispatch(actionGetChatsDisaffected(true, userAuth, filter_select ? 0 : 1));  
      } else if (!isDisaffected) {
        dispatch(actionGetChats());
      }
    }

    //eslint-disable-next-line
  }, [dispatch, userAuth, filter_select, isDisaffected]);

  return (
    <Card className="chat-box mb-0">
      <CardHeader className="text-left d-flex">
        <CardTitle className="text-primary d-flex w-100">
          <div className="mr-auto d-flex">
            <div className="general-card-title-img text-primary mr-2">
              <i className="icon-chat" />
            </div>
            <div className="general-card-title text-primary">{t('title')}</div>
          </div>

          <div className="chat-header-status" onClick={() => setOpenAvatar(!openAvatar)}>
            <ChatStatus />
            <MenuOptions setOpen={setOpenAvatar} isOpen={openAvatar}/>
          </div>
          
        </CardTitle>

        {/*Enable collapse button only on dashboard*/}
        {parent === 'dashboard' && (
          <span
            onClick={toggle}
            className="pull-right text-secondary chat-box-toggle"
          >
            {isOpen ? (
              <i className="fa fas fa-angle-up"></i>
            ) : (
              <i className="fa fas fa-angle-down"></i>
            )}
          </span>
        )}
      </CardHeader>

      <CardBody>
        {/* Show main components */}
        {!editChatMembers && !showChatForm && (
          <ChatHeader
            setChatSearch={setChatSearch}
            setShowChatForm={setShowChatForm}
            setEditChatMembers={setEditChatMembers}
            editChatMembers={editChatMembers}
            isDisaffected={isDisaffected}
            setChatsList={setChatsList}
            selectValue={filter_select}
          />

        )}



        {!editChatMembers && !showChatForm && (
          <>
            <ChatRecents chatSearch={chatSearch} filter_select={filter_select} />
            {
              !loadingDone ?
                <LoadingLocal />
              : loadingDone && !chats.length ?
                <h5 className="d-flex align-items-center justify-content-center general-card-title text-primary">
                  <span>{t('no-chat-results')}</span>
                  &nbsp;&nbsp;
                  <i className="far fa-frown-open"></i>
                </h5>
              :
                <div className="chat-body-container">
                  <ChatListVertical isDisaffected={isDisaffected} />
                  <div className={`new-chat-inbox-container ${isDisaffected === true ? ' chat-inbox-disaffected' : ''}`}>
                  <Suspense fallback={<LoadingLocal />}>
                    <ChatInbox parent="chat-page" chatId={chat._id} replyData={replyData} setReplyData={setReplyData} scrollSet={scrollSet} isDisaffected={isDisaffected} />
                    </Suspense>
                    {
                      (isDisaffected === true || contactDisaffected === true || filter_select === true) ?
                        <div className="input_msg_write textarea-disabled">
                          <textarea
                            type="text"
                            name="body"
                            className="write_msg text-area-control"
                            disabled={true}

                          />
                        </div>
                        :
                        //this component can be used if the current user is active or the filter is 'active'
                        <ChatSendInput 
                        replyData={replyData} 
                        setReplyData={setReplyData} 
                        setScroll={setScrollInbox} 
                        isDisaffected={(isDisaffected === true || contactDisaffected === true || filter_select === true) ? true : false} 
                        />
                    }
                  </div>
                </div>
            }




          </>
        )}
        {/* Create Group */}
        {showChatForm && (
          <Suspense fallback={<LoadingLocal />}>
            <ChatCreateGroup
              setShowChatForm={setShowChatForm}
              setEditChatMembers={setEditChatMembers}
              chatName={chatNameRef}
            />
          </Suspense>
        )}
        {/* Edit chat members */}
        {editChatMembers && (
          <>
            <Suspense fallback={<LoadingLocal />}>
              <ChatPeopleInGroup
                chatName={chatNameRef}
                setChatSearch={setChatSearch}
                setEditChatMembers={setEditChatMembers}
                selected={selected}
              />
            </Suspense>
            <Suspense fallback={<LoadingLocal />}>
              <ChatPeopleListVertical
                chatName={chatNameRef}
                chatSearch={chatSearch}
                setEditChatMembers={setEditChatMembers}
                selected={selected}
              />
            </Suspense>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default ChatBox;
