import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from "react-translate";
import { actionMetricPracticeSelect } from 'store/actions/practicesActions';
import { NavLink } from 'react-router-dom';

const PracticeManageHeader = () => {
    const t = useTranslate("practices");
    const dispatch = useDispatch();
    const practiceManage = useSelector((state) => state.practicesReducer.practice_manage_select);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(Object.keys(practiceManage).length > 0);
    }, [practiceManage]);

    useEffect(() => {
        //If no longer in practice edition pages
        if (!Object.keys(practiceManage).length || !
            (window.location.href.indexOf('practice/form') > -1 || 
            window.location.href.indexOf('practice/users') > -1 ||
            window.location.href.indexOf('practice/metrics') > -1)
            ) 
        {
            dispatch(actionMetricPracticeSelect({}));
        } else {
            setShow(window.location.href.indexOf('practice/metrics') > -1 ? false : true);
        }
    }, [window.location.href]);

    return (
        <>
            {show && (
            <div className="d-flex practice-edition-header">
                <div className="practice-edition-header-text">
                    <i className="icon-edit-settings" /> {t('edition-header')} <span className="practice-edition-name">{practiceManage.name}</span>
                </div>

                <NavLink
                    to="/admin/practice/manage"
                    className="practice-edition-header-exit"
                >
                    X
                </NavLink>
            </div>
            )}
        </>
    );
};

export default PracticeManageHeader;
