import React from 'react';
import { Table, Input, Button, Row, Col, Label } from 'reactstrap';

// Include Translation tool
import { useTranslate } from "react-translate";

// Include a particular scss for this view
import 'assets/scss/custom/_kilab_list.scss';

const KiLabEditor_data = ({id_lab_result}) => {


    const tEditor = useTranslate('kilab-editor');

    // Genera un array de años desde 1920 hasta el año vigente
    const years = [];
    const currentYear = new Date().getFullYear();

    for (let year = 1920; year <= currentYear; year++) {
        years.push(year);
    }
    

    return (
            <Row>
                {/* Left side - Details in a table with custom border style */}
                <Col md={6}>
                    <Table borderless className="details-table">
                        <tbody>
                            <tr className="table-row">
                                <td><Label className="title">ID</Label></td>
                                <td><Label className="title">{tEditor("receipt-date")}</Label></td>
                                <td><Label className="title">{tEditor("born-date")}</Label></td>
                            </tr>
                            <tr className="table-row-space">
                                <td><p>{id_lab_result}</p></td>
                                <td><p>22-08-2024</p></td>
                                <td>
                                    <Input type="select" name="bornYear">
                                    <option key="" value="">{tEditor("choose-year")}</option>
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                    </Input>
                                </td>
                            </tr>
                            <tr className="table-row">
                                <td><Label className="title">{tEditor("gender")}</Label></td>
                                <td><Label className="title">{tEditor("hormone-status")}</Label></td>
                                <td><Label className="title">{tEditor("year")}</Label></td>
                            </tr>
                            <tr className="table-row-space">
                                <td>
                                    <Input type="select" >
                                    <option>Geschlecht</option>
                                    <option>Männlich</option>
                                    <option>Weiblich</option>
                                    </Input>
                                </td>
                                <td>
                                    <Input type="select" >
                                        <option>Nichts zutreffend</option>
                                        <option>Schwangerschaft (Monat 1-3)</option>
                                        <option>Schwangerschaft (Monat 4-6)</option>
                                        <option>Schwangerschaft (Monat 7-9)</option>
                                        <option>Menarche</option>
                                        <option>Prämenopause</option>
                                        <option>Perimenopause</option>
                                        <option>Menopause</option>
                                        <option>Postmenopause</option>
                                    </Input>
                                </td>
                                <td>
                                    <Input type="select" name="hormonYear">
                                    <option key="" value="">{tEditor("choose-year")}</option>
                                    {years.map((year) => (
                                        <option key={year} value={year}>{year}</option>
                                    ))}
                                    </Input>
                                </td>
                            </tr>
                            <tr className="table-row">
                                <td><Label className="title">{tEditor("practice")}</Label></td>
                                <td><Label className="title">{tEditor("lab-doctor")}</Label></td>
                                <td><Label className="title">{tEditor("status")}</Label></td>
                            </tr>
                            <tr className="table-row-space">
                                <td>
                                    <p>Dr. Anna Bergmann<br/><b>Praxis Berlin MVZ</b></p>
                                </td>
                                <td>
                                    <p>Dr. med. Heinz Zurbrügg</p>
                                </td>
                                <td>
                                    <p>In Bearbeitung</p>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>

                {/* Right side - Additional Information */}
                <Col md={6} className="freeTextContentainer" >
                    <div>
                        <p className="freeTextTitle">
                            <b>{tEditor("extra-info")}</b>
                        </p>
                    </div>
                    <Input
                        type="textarea"
                        name="freeTextInput"
                        placeholder={`Freitext hier eingeben, z.B.:\n- Relevante Medikamente\n- Begleiterkrankungen\n- Körpergröße\n- Gewicht`}
                    />

                    <Button className="mt-3 btn-success">{tEditor('btn-ai-analyze')}</Button>
                </Col>
            </Row>
    );
}
export default KiLabEditor_data;