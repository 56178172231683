import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-translate';
import { Collapse } from 'reactstrap';
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Col,
  Button,
} from 'reactstrap';

const RedirectionPage = (props) => {
  const [openDebug, setOpenDebug] = useState(false);
  const t = useTranslate("redirection-page");
  return (
    <Fragment>
      <div className="content">
        <picture>
          <source
            srcSet="/General/login/ilustracion.svg"
            media="(min-width: 720px)"
          />
          <img
            src="/General/login/illustration-mobile.svg"
            width="100%"
            alt="TelePraxen"
            className="mt-5"
          />
        </picture>
        <div className="login-page">

          {/*hidden information about the environment*/}
          <div className="debug-info" style={{ color: "black" }}>
            <div
              onClick={(e) => {
                e.preventDefault();
                setOpenDebug(!openDebug);
              }
              }
            >
              <span>
                <b className="caret" />
              </span>
            </div>

            <Collapse isOpen={openDebug}>
              <div className="debug-url">
                {process.env.REACT_APP_HOST}
              </div>
            </Collapse>
          </div>

          <Container>
            <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
              <Card className="card-login card-plain">
                <CardHeader className="login-title">
                  <img src={'/General/login/logo.svg'} width="100%" alt="" />
                </CardHeader>
                <CardBody className="d-flex flex-column justify-content-center mt-4">
                  <Button
                    size="lg" color="info" className="d-block center mb-5"
                    style={{ fontWeight: 'bold' }}
                    onClick={((e) => {
                      window.location.href = process.env.REACT_APP_HOST_PATIENTS;
                    })}
                  >
                    {t("go-to-patients")}
                  </Button>
                  <Button
                    size="lg" color="secondary" className="d-block center mt-5"
                    style={{ fontWeight: 'bold' }}
                    onClick={((e) => {
                      window.location.href = process.env.REACT_APP_HOST_DOCTORS;
                    })}
                  >
                    {t("go-to-doctors")}
                  </Button>


                </CardBody>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
      {/* <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")" }}
      /> */}
    </Fragment>
  );
};

export default RedirectionPage;
