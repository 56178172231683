import React, { useEffect } from 'react';
import usePushNotifications from 'hooks/usePushNotifications';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

export default function PushNotificationAsk() {
  const user = useSelector((state) => state.userReducer);
  const {
    pushNotificationSupported,
    userSubscription,
    error,
    userConsent,
    loading,
  } = usePushNotifications();

  const handleSubscribe = () => {
    /*
    setAlert(
      
      <SweetAlert
        warning
        title="Subscribe to push notifications?"
        onConfirm={() => {
          askUserPermission();
          setAlert(null);
        }}
        confirmBtnBsStyle="info"
        confirmBtnText="Subscribe"
      >
        Push notifications subscribe
      </SweetAlert>
    );
    */
   
    toast.info("Rememeber to subscribe to push notifications!");
  };
  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (
      !loading &&
      user.status === 'success' &&
      !user.password_expired &&
      !userSubscription &&
      pushNotificationSupported &&
      userConsent === 'default'
    ) {
      handleSubscribe();
    }
    //eslint-disable-next-line
  }, [user.status, user.password_expired, userSubscription, loading]);

  return <></>;
}
