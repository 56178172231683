import { toast } from 'react-toastify';
import { takeEvery, put, select } from 'redux-saga/effects';
//Redux Actions
//Selectors
import { getAvatars } from 'store/selectors/selectors';
import { actionSetAvatars } from '../actions/avatarsActions';
import CONSTANTS from '../CONSTANTS';

/**
 * Remove avatar from store
 */
function* sagaRemoveAvatar({ userId }) {
  try {
    const avatars = yield select(getAvatars);
    if (avatars && typeof avatars[userId] !== 'undefined') {
      let newAvatars = { ...avatars };
      delete newAvatars[userId];
      yield put(actionSetAvatars(newAvatars));
    }
  } catch (error) {
    toast.error(error.message);
  }
}

export function* commonSaga() {
  console.log('*Main Common Saga');
  yield takeEvery(CONSTANTS.REMOVE_AVATARS, sagaRemoveAvatar);
}
