import React, { useState, useMemo } from 'react';
import { Table } from 'reactstrap';
import { useTranslate } from 'react-translate';
import { useSortBy, useTable } from 'react-table';
import moment from "moment";

const HistoryList = () => {
    const t = useTranslate('settings');

    const [dummyData, setDummyData] = useState((() => {
        let d = [];
        for (let a = 0; a < Math.floor(Math.random() * 12) + 1; a++) {
            d.push(a);
        }
        return d;
    })());

    const data = useMemo(
        () => dummyData && dummyData.length ? dummyData.map(p => {

            const oldDate = new Date();
            const editionDate = new Date();
            const changeVal = (Math.floor(Math.random() * 365)) + 1;

            oldDate.setDate(oldDate.getDate() - changeVal);
            editionDate.setDate(oldDate.getDate() + (Math.floor(changeVal / 2)));

            const sessionData = {
                c1: moment(oldDate).format("DD/MM/YYYY hh:mm:ss"),
                c2: moment(editionDate).format("DD/MM/YYYY hh:mm:ss"),
                c3: "Dr. John Doe",
                c4: "Gastroenterology",
                c5: Math.floor(Math.random() * 2) ? <span className="icon icon-attach ml-2"></span> : <></>
            };

            return { ...sessionData };
        }) : [],
        //eslint-disable-next-line
        [dummyData, t]
    );


    const columns = useMemo(
        () => [
            {
                Header: "Ordered",
                accessor: "c1",
                defaultCanSort: true
            },
            {
                Header: `Modified`,
                accessor: "c2",
                defaultCanSort: true
            },
            {
                Header: `Doctor`,
                accessor: "c3",
                defaultCanSort: true,
            },
            {
                Header: `Speciality`,
                accessor: "c4",
                defaultCanSort: true
            },
            {
                Header: `PDF`,
                accessor: "c5",
                disableSortBy: true
            }
        ],
        //eslint-disable-next-line
        [dummyData, t]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data }, useSortBy);

    return (
        <>
            <Table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    className={`session-table-header ${column.id === "c4" ? "sortable-header" : ""}`}
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                >
                                    {column.render('Header')}

                                    {column.id !== "c5" && (
                                        <span>
                                            <b className={`${column.isSorted ? column.isSortedDesc ? "caret-desc" : "caret-asc" : "caret-default"}`}></b>
                                        </span>
                                    )}

                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </>
    );
};

export default HistoryList;
