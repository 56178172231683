import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// import { actionGetChats } from 'store/actions/chatsActions';
import { chatsSorter } from 'helpers/chatsSorter';
import ChatRoom from './ChatRoom';
import { useTranslate } from 'react-translate';

const ChatRecents = ({ chatSearch, filter_select }) => {
  const t = useTranslate('chat');
  const { isDisaffected } = useSelector((state) => state.practicesReducer);
  //eslint-disable-next-line
  const [chatBegin, setChatBegin] = useState(0); //Should also update to avoid overload
  const [chatLimit, setChatLimit] = useState(15);

  const chatListRef = useRef();
  /**
   * Searcher
   */
  const searcher = (value, chatSearch) => {
    let searchList = chatSearch
      .toLowerCase()
      .replace(/[^\p{L}\s\d]/gu, ' ')
      .trim()
      .replace(/\s/g, '|');

    let regex = new RegExp(`${searchList}`, 'g');
    return value.toLowerCase().match(regex);
  };
  /**
   * Chat & contacts are join in saga
   */
  const { chats } = useSelector((state) => state.chatsReducer);

  const chatsSorted = useSelector((state) => {
    return chatsSorter(state.chatsReducer.chats);
  });

  const userActive = useSelector((state) => state.userReducer);

  const chatsSearch = (chatsData, user) => {
    if (!chatsData) {
      return [];
    }
    const chats = chatsData.filter(
      (chat) => chat.auth_user_id !== user.auth_user_id
    );
    if (!chatSearch) {
      return chats;
    }
    return chats.filter((chat) => {
      if (chat.type === 'group') {
        let value = chat.name + ' ' + chat.description;
        return searcher(value, chatSearch);
      }
      let value;
      const user = ['private', 'group'].includes(chat.type) ? chat.main_user : chat;
      value =
        user.firstName +
        ' ' +
        user.lastName +
        ' ' +
        user.nameInitials;
      return searcher(value, chatSearch);
    });
  };
  const chatsData = chatsSearch(
    chatSearch && chatSearch.length > 0
      ? chats
      : chatsSorted.splice(chatBegin, chatLimit),
    userActive
  );

  useEffect(() => {
    if (chatsData && chatsData.length) {
      updateOnScroll();
    }
    //eslint-disable-next-line
  }, [chats]);


  const updateOnScroll = () => {
    //One way displacement to the right
    if (
      chatListRef.current &&
      (!chatSearch || !chatSearch.length) &&
      Math.ceil(chatListRef.current.scrollLeft) >=
      chatListRef.current.scrollWidth - chatListRef.current.clientWidth
    ) {
      setChatLimit(chatLimit + 15);
    }

    /* 
    //Two way displacement 

    if (chatListRef.current) {

      //Displace to the right
      if (chatListRef.current.scrollLeft === chatListRef.current.scrollWidth - chatListRef.current.clientWidth) {
        setChatBegin(chatLimit);
        setChatLimit(chatLimit + 15);

        //Displace to the left
      } else if (chatListRef.current.scrollLeft === 0) {
        setChatBegin(chatLimit - 30 < 0 ? 0 : chatLimit - 30);
        setChatLimit(chatLimit - 15 < 15 ? 15 : chatLimit - 15);
      }
      
    }
    */
  };

  return (
    <>
      {isDisaffected === false ?
        <div
          className={`chat_list${chatsData.length === 0 ? ' no-chat-recents' : ''}`}
          onScroll={() => {
            updateOnScroll();
          }}
          ref={chatListRef}
        >
          {chatsData.length > 0 &&
            chatsData.map((chat) => {
              return <ChatRoom key={chat._id} chat={chat} filter_select={filter_select} />;
            })}
          {chatsData.length === 0 && chatSearch && (
            <h5 className="d-flex align-items-center justify-content-center general-card-title text-primary">
              <span>{t('no-search-results')}</span>
              &nbsp;&nbsp;
              <i className="far fa-frown-open"></i>
            </h5>
          )}
        </div>
        :
        <div className="no-chat-recents" />}</>

  );
};

export default React.memo(ChatRecents);
