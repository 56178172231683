import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { TranslatorProvider } from 'react-translate';
import { Collapse, Card, CardBody, CardFooter, Button, NavItem, NavLink, Nav } from "reactstrap";
import TermsCardContent from './Terms/TermsCardContent';

// import { useDispatch, useSelector } from 'react-redux';
//Terms and Conditions component
const Terms = ({ t, Label, register }) => {
    const { language } = useSelector((state) => state.userReducer);

    const [open, setOpen] = useState(false);

    const accordionHash = useRef(null);

    const toggle = (e) => {
        e.preventDefault();
        setOpen(!open)
    }

    const handleScroll = (ref) => {
        window.scrollTo({
            top: (ref.getBoundingClientRect().top - 75),
            left: 0,
            behavior: "smooth",
        });
    };

    const onEntered = () => handleScroll(accordionHash.current)
    const onExited = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })


    return (
        <>
            <div className="row justify-content-center" >
                <Label check className="mt-1" style={{ position: "relative", textDecoration: "none" }}>
                    <input
                        name="terms"
                        ref={register}
                        className="form-control"
                        type="checkbox"
                    />
                    {t('terms-1')}&nbsp;
                    <span className="form-check-sign" />
                </Label>
                <Nav className="justify-content-center p-0 m-0">
                    <NavItem className="p-0 m-0">
                        <NavLink className="register-term-url mt-0 mb-0 pb-0 pt-0 ml-0 mr-0 pl-0 pr-0"
                            data-toggle="collapse"
                            href="#terms"
                            onClick={toggle}
                        >
                            {t('terms-2')}
                        </NavLink>
                    </NavItem>
                </Nav>
                &nbsp;
                <span className="py-0 text-muted" ref={accordionHash}>{t('terms-3')}</span>
            </div>
            <Collapse
                role="tabpanel"
                isOpen={open}
                onEntered={onEntered}
                onExited={onExited}
            >
                <Card className="mt-5 mb-3 p-2 text-left text-dark">
                    <CardBody>
                        <TranslatorProvider
                            translations={require(`assets/i18n/terms/${language || 'de'}.json`)}
                        >
                            <TermsCardContent />
                        </TranslatorProvider>
                    </CardBody>
                    <CardFooter>
                        <Button
                            type="button"
                            color="info"
                            size="sm"
                            className="mb-3 btn-block"
                            onClick={toggle}
                        >
                            {t('hide-terms')}
                        </Button>
                    </CardFooter>
                </Card>
            </Collapse>
        </>
    );
}

export default Terms