import React, { Fragment } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import LogoUpload from 'components/Practice/Form/LogoUpload.jsx';
import RoleDefinition from 'components/Practice/Form/RoleDefinition.jsx';
import Icon from 'components/Icons/Icon';
import { Link } from 'react-router-dom';

const PracticeAddUser = () => {
  return (
    <Fragment>
      {/* <PanelHeader size="sm" /> */}
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">
                  <Icon file="practice-settings-orange.svg" className="mr-2" />
                  Praxis Prof. Dr. Med. Max Müller
                </h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <Card className="border-0 shadow-none">
                      <CardHeader className="">
                        <div className="practice-title">New User</div>
                      </CardHeader>
                      <CardBody className="">
                        <Form>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label for="firstName">Name</Label>
                                <Input
                                  type="text"
                                  name="first_name"
                                  id="firstName"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="lastName">Last Name</Label>
                                <Input
                                  type="text"
                                  name="last_name"
                                  id="lastName"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="office">Office</Label>
                                <Input type="text" name="office" id="office" />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="street">Street</Label>
                                <Input type="text" name="street" id="street" />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="city">City</Label>
                                <Input type="text" name="city" id="city" />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="telephone">Telephone Number</Label>
                                <Input
                                  type="text"
                                  name="telephone"
                                  id="telephone"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="country">Country</Label>
                                <Input
                                  type="text"
                                  name="country"
                                  id="country"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="postalCode">Postal Code</Label>
                                <Input
                                  type="text"
                                  name="postal_code"
                                  id="postalCode"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="section">Section</Label>
                                <Input
                                  type="text"
                                  name="section"
                                  id="section"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="username">Username</Label>
                                <Input
                                  type="text"
                                  name="username"
                                  id="username"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup>
                                <Label for="email">Email</Label>
                                <Input type="email" name="email" id="email" />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="6">
                    <RoleDefinition />
                    <LogoUpload />

                    <Link to="#" className="btn btn-info">
                      Add
                    </Link>
                    <Link to="#" className="btn btn-primary">
                      Cancel
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default PracticeAddUser;
