import React, { useState } from 'react';
import {
  Row,
  Col,
  FormGroup,
  CustomInput,
} from 'reactstrap';

import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionCreate2fa,
  actionInactivate2fa,
} from 'store/actions/usersActions';
import { actionSweetAlert } from 'store/actions/commonActions';
import Form2fa from './Form2fa';
import { translate } from 'react-translate';

const Main2fa = (props) => {
  const { t } = props;
  const user = useSelector((state) => state.userReducer);
  const [showForm, setShowForm] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(user.auth_2fa === true);

  const dispatch = useDispatch();

  const handleChange = () => {
    setSwitchChecked(!switchChecked);
    if (!switchChecked && !user.auth_2fa) {
      dispatch(actionCreate2fa());
      setShowForm(true);
    } else {
      setShowForm(false);
      if (user.auth_2fa === true) {
        dispatch(
          actionSweetAlert(
            <SweetAlert
              question
              input
              required
              inputType="password"
              placeHolder={t('password-placeholder')}
              title={t('are-you-sure') + '?'}
              onConfirm={(pass) => {
                dispatch(actionInactivate2fa(pass));
                hideAlert();
              }}
              onCancel={() => {
                setSwitchChecked(true);
                hideAlert();
              }}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="info"
              confirmBtnText={t('btn-yes')}
              cancelBtnText={t('btn-no')}
              showCancel
            >
              {t('disable')}
            </SweetAlert>
          )
        );
      }
    }
  };

  const hideAlert = () => {
    dispatch(actionSweetAlert(null));
  };

  return (
    <div className="setting-section">
      <h5 className="general-card-subtitle">{t('title')}</h5>
      <Row>
        <Col md="12">
          <FormGroup>
            <CustomInput
                type="switch"
                id="auth2fa_switch"
                className="custom-switch-settings"
                label=""
                checked={switchChecked}
                onChange={handleChange}
                inline
              />
            
              <label>{t('activate')}</label>
          </FormGroup>
        </Col>
        <Col md="12">
          {user.uri && showForm && (
            <Form2fa
              setShowForm={setShowForm}
              setSwitchChecked={setSwitchChecked}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default translate('2fa')(Main2fa);
