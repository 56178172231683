import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Row, Button, Input, Pagination, PaginationItem, PaginationLink, Form, Col, FormGroup } from 'reactstrap';
import { useTranslate } from 'react-translate';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useForm } from 'react-hook-form';
import moment from "moment";
import { actionGetPatients } from 'store/actions/patientsActions';
import PatientDetail from './PatientDetail';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import Dates from "helpers/Dates";

const PatientListComponent = () => {
  const t = useTranslate('patients');
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();
  const patients = useSelector(state => state.patientsReducer.patients);
  const practiceId = useSelector(state => state.practicesReducer.practice._id);
  const user = useSelector(state => state.userReducer);
  const pageLimit = useSelector(state => state.patientsReducer.lastPage);
  const loadingPatientList = useSelector(state => state.patientsReducer.loadingPatientList);
  const [querySearch, setQuerySearch] = useState("");
  const refreshTable = useRef(false);

  const [selectedPatient, setSelectedPatient] = useState(null);

  const handleClick = (patient) => {
    setSelectedPatient(patient)
  };

  // const handleStudiesCheckbox = (e, patient) => {
  //   dispatch(actionUpdatePatientCheck(practice._id, patient.auth_user_id, !patient.auto_forward_studies));
  // }

  const data = useMemo(
    () => patients && patients.length ? patients.map((p, i) => {

      const patientsData = {
        c2: p?.lastName,
        c3: p?.firstName,
        c4: moment(Dates.parseDate(p?.birthday)).format(user.dateFormat),
        c5: p?.address ? p?.address[0]?.city : "---",
        c6: p?.address ? isNaN(p?.address[0]?.country?.value) ? p?.address[0]?.country?.value : p?.address[0]?.country?.label : "---",
        c7: "---",
        // c8: <FormGroup check>
        //   <Label check>
        //     <input
        //       className="form-control"
        //       type="checkbox"
        //       data-id={i}
        //       checked={p.auto_forward_studies}
        //       onChange={(e) => handleStudiesCheckbox(e, p)}
        //     />
        //     <span className="form-check-sign" />
        //   </Label>
        // </FormGroup>,
        c9: <Button color="info" type="button" onClick={() => handleClick(p)}><i className="now-ui-icons users_single-02"></i></Button>
      };

      return patientsData;
    }) : [],
    //eslint-disable-next-line
    [patients, t]
  );

  const columns = useMemo(
    () => [
      {
        Header: `${t('last-name')}`,
        accessor: "c2",
        defaultCanSort: true
      },
      {
        Header: `${t('name')}`,
        accessor: "c3",
        defaultCanSort: true
      },
      {
        Header: `${t('birthday')}`,
        accessor: "c4",
        disableSortBy: true
      },
      {
        Header: `${t('city')}`,
        accessor: "c5",
        disableSortBy: true
      },
      {
        Header: `${t('country')}`,
        accessor: "c6",
        disableSortBy: true
      },
      {
        Header: `${t('observations')}`,
        accessor: "c7",
        disableSortBy: true,
      },
      // {
      //   Header: `${t('info-check')}`,
      //   accessor: "c8",
      //   disableSortBy: true
      // },
      {
        accessor: "c9",
        disableSortBy: true,
      }
    ],
    //eslint-disable-next-line
    [patients, t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable({ 
    columns,
    data,
    disableSortRemove: true,
    enableSortingRemoval: true,
    defaultCanSort: true,
    initialState: { 
      pageSize: 10,
      hiddenColumns: ["c7"] 
    },
    manualPagination: true,
    pageCount: pageLimit + 1,
    autoResetPage: !refreshTable.current
  }, useSortBy, usePagination);

  useEffect(() => {
    refreshTable.current = true;
    dispatch(actionGetPatients({
      practiceId,
      pageIndex,
      pageSize,
      querySearch
    }));
    //eslint-disable-next-line
  }, [pageIndex, pageSize, practiceId, querySearch]);

  useEffect(() => {
    if (selectedPatient !== null) {
      setSelectedPatient(patients.find(p => p.auth_user_id == selectedPatient.auth_user_id));
    }

  }, [patients]);

  useEffect(() => {
    refreshTable.current = false;
  }, []);

  const onSubmit = (data) => {
    setQuerySearch(data.query);
}

  useEffect(() => {
    gotoPage(0);
  }, [querySearch]);

  if (selectedPatient !== null) {
    return (
      <PatientDetail p={selectedPatient} setSelectedPatient={setSelectedPatient} />
    )
  };
  
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
            <Col xs={4} sm={6}>
                <FormGroup
                className="mt-2">
                    <Input 
                    className="form-control"
                        type="text"
                        innerRef={register}
                        name="query"
                        id="query"
                        placeholder={t('search')}
                        defaultValue={querySearch || ""}
                    />
                </FormGroup>
            </Col>

            <Col xs={1}>
                <Button
                    size="md"
                    color="info"
                    type="submit"
                    name="search-patient"
                >
                    {t("search-button")}
                </Button>
            </Col>
          </Row>
      </Form>

      <div className="mobile-x-overflow">
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    className={`session-table-header`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}

                    {["c1", "c2", "c3"].includes(column.id) && (
                      <span>
                        <b className={`${column.isSorted ? column.isSortedDesc ? "caret-desc" : "caret-asc" : "caret-default"}`}></b>
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            { loadingPatientList ? (<tr><td><LoadingLocal /></td></tr>) : (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr key={i}>
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })
            )
            }
          </tbody>
        </Table>
      </div>

      <Row className="text-center justify-content-center mt-3">
        <nav aria-label="Page navigation example">
            <Pagination
                className="pagination justify-content-end"
                listclassname="justify-content-end"
            >
                <PaginationItem disabled={!canPreviousPage}>
                    <PaginationLink
                        href="#previous-page"
                        onClick={e => {
                            e.preventDefault()
                            previousPage()
                        }}
                        tabIndex="-1"
                        className="bg-info text-white"
                    >
                        <i className='fa fa-angle-double-left'></i>
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={pageIndex < 2}>
                    <PaginationLink href="#minus-two" onClick={e => {
                        e.preventDefault();
                        gotoPage(pageIndex - 2);
                    }}>
                        {canPreviousPage && pageIndex > 1 ? pageIndex - 1 : " "}
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={pageIndex === 0}>
                    <PaginationLink href="#minus-one"
                        onClick={e => {
                            e.preventDefault();
                            previousPage()
                        }}>
                        {!canPreviousPage ? " " : pageIndex}
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem active>
                    <PaginationLink href="#actual-page" onClick={e => { e.preventDefault() }} className="bg-info">
                        {pageIndex + 1}
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={!canNextPage}>
                    <PaginationLink href="#plus-one" onClick={e => {
                        e.preventDefault();
                        nextPage();
                    }}>
                        {canNextPage ? pageIndex + 2 : " "}
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={!(pageCount - pageIndex > 2)}>
                    <PaginationLink href="#plus-two" onClick={e => {
                        e.preventDefault();
                        gotoPage(pageIndex + 2);
                    }}>
                        {(pageCount - pageIndex > 2) ? pageIndex + 3 : " "}
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={!canNextPage}>
                    <PaginationLink href="#next-page" onClick={e => {
                        e.preventDefault();
                        nextPage();
                    }}
                        className="bg-info text-white"
                    >
                        <i className='fa fa-angle-double-right'></i>
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </nav>
      </Row>
    </>
  );
};

export default PatientListComponent;