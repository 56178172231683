import React, { useState } from 'react';
import { useTranslate } from 'react-translate';
import Icon from 'components/Icons/Icon';
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from 'reactstrap';
import Inbox from 'components/RemoteDiagnostics/Inbox';
import DevicesOverview from 'components/RemoteDiagnostics/DevicesOverview';
import Studies from 'components/RemoteDiagnostics/Studies';

const RemoteDiagnostics = () => {
  const t = useTranslate('remote');

  const [state, setState] = useState({
    openedCollapses: ['collapseOne'],
    hTabs: 'inbox',
  });

  // const collapsesToggle = (collapse) => {
  //   let openedCollapses = state.openedCollapses;
  //   if (openedCollapses.includes(collapse)) {
  //     setState({
  //       openedCollapses: [],
  //     });
  //   } else {
  //     setState({
  //       openedCollapses: [collapse],
  //     });
  //   }
  // };
  return (
    <>
      <Card>
        <CardBody>
          <Card className="mb-0">


            <CardHeader>
              <CardTitle className="d-flex text-primary">
              
                <div className="mr-auto d-flex">
                  <div className="general-card-title-img mr-2">
                    <Icon file="remote-diagnostics-orange.svg" />
                  </div>
                  <div className="general-card-title">
                    {t("title")}
                  </div>
                </div>
              </CardTitle>
            </CardHeader>

            <CardBody>

              <div className="remote-diagnostics-nav">
                <div 
                  className={`nav-button ${state.hTabs === 'inbox' ? 'active-nav' : ''}`}
                  onClick={() => setState({ hTabs: 'inbox' })}
                  >
                  {t('inbox')}
                </div>
                <div
                  className={`nav-button ${state.hTabs === 'devices' ? 'active-nav' : ''}`}
                  onClick={() => setState({ hTabs: 'devices' })}
                  >
                  {t('devices-overview')}
                </div>
                <div
                  className={`nav-button ${state.hTabs === 'studies' ? 'active-nav' : ''}`}
                  onClick={() => setState({ hTabs: 'studies' })}
                  >
                {t('studies')}
                </div>
              </div>

              <TabContent activeTab={state.hTabs} className="tab-space">
                <TabPane tabId="inbox">
                  <Inbox />
                </TabPane>
                <TabPane tabId="devices">
                  <DevicesOverview />
                </TabPane>
                <TabPane tabId="studies">
                  <Studies />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </>
  );
};

export default RemoteDiagnostics;
