import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import nativeSender from 'helpers/nativeSender';
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  Container,
  Col,
  Button,
} from 'reactstrap';
import { actionDeleteAccountPrepare } from "store/actions/usersActions";
import { actionSetLoading } from "store/actions/loadingActions";
import { useTranslate } from 'react-translate';

const UserDeletePreparation = (props) => {
  const t = useTranslate('settings');
  const tDelForm = useTranslate('user-delete-form');
  const dispatch = useDispatch();
  const { token } = useParams();
  const [choice, setChoice] = useState(false);

  useEffect(() => {
    let credentials = null;
  
    try {
      credentials = token
    ? decodeURIComponent(escape(atob(token))).split('||')
    : [];
    credentials[0] = JSON.parse(credentials[0]);
    } catch (e) {
      credentials = null;
      dispatch(actionSetLoading(false));
    }

    if (credentials && choice){
      dispatch(actionDeleteAccountPrepare(credentials[0]));
    }
  }, [choice]);
  

  if (choice) {
    return <Redirect to="/" />;
  }

  return (
    <>
    <div className="content">
    <picture>
      <source
        srcSet="/General/login/ilustracion.svg"
        media="(min-width: 720px)"
      />
      <img
        src="/General/login/illustration-mobile.svg"
        width="100%"
        alt="TelePraxen"
        className="mt-5"
      />
    </picture>
    <div className="login-page">

      <Container>
        <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
            <Card className="card-login card-plain">
              <CardHeader className="login-title">
                <img src={'/General/login/logo.svg'} width="100%" alt="" />
                <CardTitle className="text-info">{tDelForm('title')}</CardTitle>
              </CardHeader>
              <CardBody>
                {tDelForm('phrase-delete-conditions')}
              </CardBody>
              <CardFooter>
                <Button
                  block
                  type="submit"
                  color="info"
                  size="sm"
                  className="mb-3 mt-3"
                  onClick={() => {
                    nativeSender.send("LOGOUT");
                    setChoice(true);
                  }}
                >
                  {tDelForm('phrase-understand-conditions')}
                </Button>
              </CardFooter>
            </Card>
        </Col>
      </Container>
    </div>
  </div>
  </>
  );
};

export default UserDeletePreparation;
