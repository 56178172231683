import AxiosService from 'plugins/TS-lib-utils-public/services/AxiosService';
import { setUrl, getUrl } from 'helpers/urlsBlob';
class MultimediaService extends AxiosService {
  constructor() {
    super('multimedia');
  }
  /**
   * Array buffer to base64
   * @returns base64
   */
  arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  /**
   * Get avatar
   * @param {string} fileId
   */
  getAvatar = async (fileId, thumb = 'small') => {
    try {
      const isTrueSet = fileId !== 'false' && fileId !== false;
      if (isTrueSet) {
        const url = `/public/doc-files/avatar/${fileId}${
          thumb ? '?thumbnail=' + thumb : ''
        }`;
        let urlBlob = getUrl(fileId);
        if (urlBlob) {
          return urlBlob;
        }
        const response = await this.get(url, {
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
            'Cache-Control': 'private, max-age=25920',
          },
        });
        if (response && response.data && response.data.byteLength > 0) {
          let blob = new Blob([response.data], {
            type: response.headers['content-type'],
          });
          return setUrl(fileId, blob);
        }
      }
      return null;
    } catch (error) {
      
    }
  };

  /**
   * Delete avatar
   * @param {string} fileId
   */
  deleteAvatar = async (fileId, thumb = null) => {
    const isTrueSet = fileId !== 'false' && fileId !== false;
    if (isTrueSet) {
      const response = await this.delete(
        `/public/doc-files/avatar/${fileId}${
          thumb ? '?thumbnail=' + thumb : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      );
      if (response.data) {
        return response.data;
      }
    }
    return null;
  };

  /**
   * Get file for download
   * @param {string} fileId
   */
  getFile = async (fileId, getBlob = true, thumb = null) => {
    const isTrueSet = fileId !== 'false' && fileId !== false;
    if (isTrueSet) {
      const response = await this.get(
        `/public/doc-files/${fileId}${thumb ? '/' + thumb : ''}`,
        {
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      );
      if (response.data && response.data.byteLength > 0) {

        if (getBlob) {
          let blob = new Blob([response.data], {
            type: response.headers['content-type'],
          });
          return URL.createObjectURL(blob);
        } else {
          return response.data;
        }
        
      }
    }
    return null;
  };

  /**
   * Get file's info
   * @param {string} fileId
   */
  getFileInfo = async (fileId) => {
    const isTrueSet = fileId !== 'false' && fileId !== false;
    if (isTrueSet) {
      const response = await this.get(`/public/doc-files/${fileId}/meta`, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      });
      return response.data;
    }
    return null;
  };
}

export default new MultimediaService();
