import React from 'react';
import { useTranslate } from 'react-translate';
import { Button } from 'reactstrap';
const BtnReply = ({ mobile, setShowForm, setResend, setReply, disabled }) => {
  const t = useTranslate('messages');
  return (
    <Button
      className="btn-reply"
      color="info"
      size="sm"
      name="reply-message-btn"
      onClick={() => {
        setShowForm(true);
        setResend(false);
        setReply(false);
      }}
      disabled={disabled}
    >
      {!mobile && t('btn-reply')} <span className="icon-reply-message"></span>
    </Button>
  );
};

export default BtnReply;
