import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Label, ListGroupItem } from 'reactstrap';
import BtnPriority from '../Actions/BtnPriority';

//Actions
import {
  actionGetMessage,
  actionGetOutboxMessage,
  actionSetSelectedMessages,
} from 'store/actions/messagesActions';
//Helpers
import Dates from 'helpers/Dates';

const InboxViewListItem = (props) => {
  const { item } = props;

  const dispatch = useDispatch();

  const { selectedMessages, box } = useSelector(
    (state) => state.messagesReducer
  );

  const recipientsTotal = item.to.length;

  const showMessage = (id) => {
    if (box === 'outbox') {
      dispatch(actionGetOutboxMessage(id));
    } else {
      dispatch(actionGetMessage(id));
    }
  };

  const handleCheckbox = (event, message_id) => {
    let newSelectedMessages = [];

    if (event.target.checked) {
      newSelectedMessages = [...selectedMessages, message_id];
    } else {
      newSelectedMessages = selectedMessages.filter((item) => {
        return item !== message_id;
      });
    }

    dispatch(actionSetSelectedMessages(newSelectedMessages));
  };

  let diff = Dates.dateDiff(item.date, moment());
  let dateCondition = diff.years === 0 && diff.months === 0 && diff.days <= 2;
  const key = item._id;

  const [attachments, setAttachments] = useState(false);

  useEffect(() => {
    if (item.files.length !== 0) {
      setAttachments(true);
    }
  }, [item]);

  return (
    <ListGroupItem
      id={`item-${key}`}
      data-id={key}
      className="inbox-message"
      onClick={(e) => {
        if (
          e.target.nodeName !== 'INPUT' &&
          e.target.nodeName !== 'SPAN' &&
          e.target.nodeName !== 'IMG'
        ) {
          showMessage(item._id, key);
        }
      }}
    >
      <div className="d-flex">
        <FormGroup check className="message-select">
          <Label check>
            <input
              className="form-control"
              type="checkbox"
              data-id={key}
              checked={selectedMessages.includes(item._id)}
              onChange={(e) => handleCheckbox(e, item._id)}
            />
            <span className="form-check-sign" />
          </Label>
        </FormGroup>

        {/* Toggle priority */}
        {box !== 'outbox' && (
          <React.Fragment>
            <div className="message-priority">
              <BtnPriority message={item} />
            </div>
            <div className={item.flags.readed ? 'readed' : 'not-readed'}></div>
            <div className={`message-name`}>
              {item.thread && item.thread.length && recipientsTotal > 1? (<>
                {item.from.firstName} {item.from.lastName} ... {item.to[recipientsTotal-1].firstName} {item.to[recipientsTotal-1].lastName} ({recipientsTotal})
              </>): (item.thread && item.thread.length ? (<>{item.thread[item.thread.length-1].from.firstName} {item.thread[item.thread.length-1].from.lastName}</>) : (<>{item.from.firstName} {item.from.lastName}</>))}
            </div>
          </React.Fragment>
        )}

        {box === 'outbox' && (
          <React.Fragment>
            <div className={`message-name ml-2`}>
              {item.to[0].firstName} {item.to[0].lastName}
            </div>
          </React.Fragment>
        )}

        <div className="message-subject">
          {item.subject} - 
          <div className="message-inner-subject">
            {item.thread.length ? `${item.thread[item.thread.length-1].body}` : `${item.body}`}
          </div>
        </div>

        {item.thread && item.thread.length > 0 && (
          <div className="inbox-thread-counter">{item.thread.length}</div>
        )}

        {attachments && <div className="ml-auto mr-2 icon-attach1"></div>}

        {dateCondition && (
          <div className="message-date ml-auto">
            {moment(item.date).fromNow()}
          </div>
        )}
        {!dateCondition && (
          <div className="message-date ml-auto">
            {moment().format('D') === moment(item.date).format('D')
              ? moment(item.date).format('HH:mm')
              : moment(item.date).format('D MMM')}
          </div>
        )}
      </div>

      {/*thread messages*/}
      {/*
      {item.thread && item.thread.length > 1 && (<div className="message-reply-item">...</div>)}
      {item.thread && item.thread.slice(-1).map(th => {
        return (
          <div key={th.date} className="message-reply-item">
            {th.subject} - {th.body.substr(0, 10)}{th.body.length > 10 && (<>...</>)} 
            {th.files.length ? <span className="icon-attach1"></span> : <></>}
          </div>
        )
      })}
    */}
    </ListGroupItem>
  );
};

export default InboxViewListItem;
