import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import ReactTable from 'react-table';

const Studies = () => {

  const [data, setData] = useState([]);

  useEffect(() => {
    let newData = data;

    for (let i = 0; i < 20; i++) {
      newData.push({
        condition: "Bitmark",
        method: "Photo Upload",
        typeOfStudy: "Photo diagnostic",
        deviceOrAnalysis: "Photo Upload",
        actions: (
          <>
            <Button
              className="btn-icon btn-neutral"
              color="success"
              size="md"
              type="button"
            >
              <i className="icon-activate fa-2x"></i>
            </Button>
            <Button
              className="btn-icon btn-neutral"
              color="info"
              size="md"
              type="button"
            >
              <i className="icon-remote-settings fa-2x"></i>
            </Button>
            <Button
              className="btn-icon btn-neutral"
              color="danger"
              size="md"
              type="button"
            >
              <i className="icon-graph fa-2x"></i>
            </Button>
          </>
        )
      });
    }

    setData(newData);
    //eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <ReactTable
        data={data}
        
        columns={[
          {
            Header: 'Condition',
            accessor: 'condition',
          },
          {
            Header: 'Method',
            accessor: 'method',
          },
          {
            Header: 'Type of study',
            accessor: 'typeOfStudy',
          },
          {
            Header: 'Device or analysis',
            accessor: 'deviceOrAnalysis',
          },
          {
            Header: 'Actions',
            accessor: 'actions',
          }
        ]}
        className="-striped -highlight"
        defaultPageSize={5}
      />

    </React.Fragment>
  );
};

export default Studies;
