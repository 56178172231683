import { useFileUtils } from 'hooks';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actionUpdatePatientCheck } from 'store/actions/patientsActions';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import { actionGetPatients } from 'store/actions/patientsActions';
import { useTranslate } from 'react-translate';

const PatientDetail = ({ p, setSelectedPatient }) => {

    const practice = useSelector(state => state.practicesReducer.practice);
    const dispatch = useDispatch();
    const t = useTranslate('patient-detail');

    const { getAvatar, defaultImage } = useFileUtils();

    useEffect(() => {
        getAvatar(p.avatar, 'large', true);
    }, [p.avatar]);

    const clearSelectedPatient = () => {
        setSelectedPatient(null)
    }

    const handleStudiesCheckbox = () => {
        dispatch(actionUpdatePatientCheck(practice._id, p.auth_user_id, !p.auto_forward_studies));
        dispatch(actionGetPatients(practice._id));
    }

    return (
        <>

            <Row className="justify-content-start">
                <Col sm={12} md={3} className="d-flex justify-content-center">
                    <div className="photo no-background">
                        <img src={defaultImage} alt="Contact" id="user-avatar" />
                    </div>
                </Col>
                <Col sm={12} md={6}>
                    <p className='text-info text-center text-md-left'><strong>{p.firstName} {p.lastName}</strong></p>
                    <p>{t('email')}: {p.email}</p>
                    <Button className="d-block mx-auto mx-md-0" color="info" type="button" onClick={clearSelectedPatient}>{t('btn-close')}</Button>

                </Col>
                <Col className="d-none">
                    <FormGroup check>
                        <Label check>
                            <input
                                className="form-control"
                                type="checkbox"
                                checked={p.auto_forward_studies}
                                onChange={() => handleStudiesCheckbox()}
                            />
                            <span className="form-check-sign" />
                            {t('can-receive')}?
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}

export default PatientDetail;