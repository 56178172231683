import { useFileUtils } from 'hooks';
import React, { useEffect, useRef } from 'react';

const PracticeAvatar = ({ practice, width }) => {
  const isMounted = useRef(true);
  /**
   * Load avatar
   */
  const { getAvatar, defaultImage, setDefaultImage } = useFileUtils();
  useEffect(() => {
    setDefaultImage('/General/icons/photo-upload.svg');
    if (isMounted.current && practice._id) {
      getAvatar(`practice-${practice._id}`, 'small').then((response) => {
        if (isMounted.current && response) {
          setDefaultImage(response);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line
  }, [practice._id]);
  return (
    <img src={defaultImage} alt={practice.name} width={width ? width : ''} />
  );
};

export default PracticeAvatar;
