import { takeEvery, call, select, put, cancelled, takeLatest } from 'redux-saga/effects';
import CONSTANTS from '../CONSTANTS';
import kiLabService from 'services/kiLabService';
import { toast } from 'react-toastify';
import { actionSetEarlyStudies, actionSetEarlyStudy } from 'store/actions/kiLabActions';
import historyRouter from 'historyRouter';

//Selectors
import { getUser, getStudy } from 'store/selectors/selectors';
//Helpers
import utilTranslations from '../../helpers/utilTranslations';
import { actionSetLoadingStudyList, actionSetSentModal } from 'store/actions/laboratoryActions';

let t;

function* sagaGetStudy({ reportId, practiceId }) {
  const userActive = yield select(getUser);
  t = utilTranslations(userActive.language || 'de', 'laboratory-detail');
  const cancelToken = kiLabService.getSource();
  try {
    const res = yield call(
      kiLabService.getStudyDetail, 
      reportId, 
      practiceId,
      { cancelToken: cancelToken.token }
    );

    if (res.status >= 400) {
      throw new Error("Error getting the study");
    }

    yield put(actionSetEarlyStudy(res.data));
    
  } catch (error) {
    console.log(error);
    historyRouter.push("/admin/kilab/index");
  } finally {
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}

/**
 * Method to call to the API to get the studies
 * 
 * @param {} param0 
 */
function* sagaGetLaboratoryStudies({ options }) {

  const userActive = yield select(getUser);

  t = utilTranslations(userActive.language || 'de', 'laboratory-detail');

  // Method to avoid the recurrent calls to stack request, only execute the last call
  const cancelToken = kiLabService.getSource();
  try {
    const res = yield call(
      kiLabService.getStudiesList, 
      options.practiceId, 
      options.pageIndex, 
      options.pageSize, 
      options.sortDate, 
      options.querySearch, 
      options.dateFrom,
      options.dateTo,
      { cancelToken: cancelToken.token }
    );

    if (res.status >= 400) {
      throw new Error("Error getting the studies");
    }

    yield put(actionSetEarlyStudies(res.data));

  } catch (error) {
    console.log(error);
  } finally {
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}

export function* kiLabSaga() {
  console.log('*Main KI Laboratories Saga');
  yield takeLatest(CONSTANTS.LAB_EARLY_GET_STUDY, sagaGetStudy);
  yield takeLatest(CONSTANTS.LAB_EARLY_GET_LIST, sagaGetLaboratoryStudies);
}
