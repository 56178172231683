import { createSelector } from 'reselect';
//Get reducer from store
export const getUser = (state) => state.userReducer;
export const getStudy = (state) => state.laboratoryReducer.study;
export const getMessages = (state) => state.messagesReducer;
export const getAppointments = (state) => state.appointmentsReducer;
export const getPractices = (state) => state.practicesReducer;
export const getUserAuthorization = (state) => state.userAuthorizationReducer;
export const getPracticeManage = (state) => state.practicesReducer.practice_manage_select;
export const getChats = (state) => state.chatsReducer;
export const getAvatars = (state) => state.avatarsReducer;
export const getInvitations = (state) => state.userDataReducer.invitations;
export const getMobile = (state) => state.commonReducer.mobile;
export const getNativeWebView = (state) => state.commonReducer.nativeWebView;
export const getWebsocketLastAction = (state) => state.websocketReducer;
/**
 * Custom Selectors
 */
//Select chat
export const selectChat = createSelector(
  (state) => state.chatsReducer,
  (_, chatId) => chatId,
  (chatsReducer, chatId) => {
    if (!chatsReducer.chat || !chatId) {
      return {};
    }
    const chat = chatsReducer.chats.find((chat) => chat._id === chatId);
    return chat ? chat : {};
  }
);
//Select chat messages
export const selectChatMessages = createSelector(
  (state) => state.chatsReducer,
  (_, chatId) => chatId,
  (chatsReducer, chatId) => {
    if (!Array.isArray(chatsReducer.chats) || !chatId) {
      return [];
    }
    const chat = chatsReducer.chats.find((chat) => chat._id === chatId);
    const current = chatsReducer.chat;
    return chat && current && chat.messages && current.messages ? 
    current.messages.length > chat.messages.length ? current.messages : chat.messages : [];
  }
);

//Select practice
export const selectPractice = createSelector(
  (state) => state.practicesReducer,
  (_, practiceId) => practiceId,
  (practicesReducer, practiceId) => {
    if (!Array.isArray(practicesReducer.practices) || !practiceId) {
      return {};
    }
    const practice = practicesReducer.practices.find((practice) => {
      return practice._id === practiceId;
    });
    return practice ? practice : {};
  }
);

//Select practitioners
export const selectPractitioners = createSelector(
  (state) => state.practicesReducer,
  (practicesReducer) => {
    if (!practicesReducer.practice) {
      return [];
    }
    const practitioners = practicesReducer.practice.people;
    return practitioners ? practitioners : [];
  }
);
//Select practitioners for chats
export const selectPractitionersChat = createSelector(
  (state) => state.practicesReducer,
  (_, chat) => chat,
  (practicesReducer, chat) => {
    if (!practicesReducer.practice || !chat) {
      return [];
    }
    const practice = practicesReducer.practice;
    const practitioners = practice.people.filter((practitioner) => {
      if (chat.users && chat.users.length > 0) {
        const memberIndex = chat.users.findIndex(
          (member) =>
            member.auth_user_id === practitioner.auth_user_id && !member.deleted
        );
        return memberIndex === -1;
      }
      return false;
    });
    return practitioners ? practitioners : [];
  }
);
//Select a practitioner for user active
export const selectPractitionerUserId = createSelector(
  (state) => state.practicesReducer,
  (_, auth_user_id) => auth_user_id,
  (practicesReducer, auth_user_id) => {
    if (practicesReducer.practice && practicesReducer.practice.people) {
      return practicesReducer.practice.people.find(
        (p) => p.auth_user_id === auth_user_id
      );
    }
    return {};
  }
);
//Select practitioners data
export const selectPractitionersData = createSelector(
  getUser,
  getPractices,
  (_, users) => users,
  (userReducer, practicesReducer, users) => {
    const userActive = userReducer;
    const practice = practicesReducer.practice;
    if (!practice || !practice.people) {
      return [];
    }
    const { people } = practice;
    const list = !Array.isArray(people)
      ? []
      : people.filter((practitioner) => {
          if (practitioner && userActive.email === practitioner.email) {
            return false;
          }
          const result = users.findIndex(
            (user) => user.email === practitioner.email
          );
          return result !== -1;
        });
    return list;
  }
);
