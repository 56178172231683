/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// reactstrap components
import { Form, FormGroup, Label, Button, Col, Row } from 'reactstrap';
import BtnAttach from 'components/Messages/Actions/BtnAttach';
import fileRules from 'configs/fileRules';

import moment from 'moment';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { actionSendMessage } from 'store/actions/messagesActions';
import { toast } from 'react-toastify';
import { useTranslate } from 'react-translate';
import InboxAsyncRecipients from './InboxAsyncRecipients';

const recipients = (to) => {
  let recipients = '';
  to.tags.forEach((user) => {
    recipients += user.text + ', ';
  });
  if (recipients.length > 0) {
    recipients = recipients.slice(recipients, recipients.length - 2);
  }
  return recipients;
};

const InboxViewForm = ({ setShowForm, resend, reply, setReply }) => {
  const t = useTranslate('messages');
  const dispatch = useDispatch();

  const box = useSelector((state) => state.messagesReducer.box);
  //const btnReplyRef = useRef(null);

  const [attachmentList, setAttachmentList] = useState([
    `attachment-${Date.now()}`,
  ]);

  const { message, loading_msg } = useSelector(
    (state) => state.messagesReducer
  );

  const users = useSelector((state) => state.userDataReducer.users);

  let tags = message
    ? [
        {
          id: message.from.auth_user_id,
          text: message.from.firstName + ' ' + message.from.lastName,
        },
      ]
    : [];

  useEffect(() => {
    if (resend) {
      setAttachmentList([...message.files.map(f => `resend-${f}`), ...attachmentList]);
    }
  }, []);

  const defaultValues = {
    to: message
      ? recipients({
          tags: tags,
          suggestions: [],
        })
      : '',
    subject: message ? `Re: ${message.subject}` : '',
    body: message
      ? message.thread.length && !resend
      ? '\n------' +
        '\nFrom: ' +
        (message.thread[message.thread.length-1].from.title ?  message.thread[message.thread.length-1].from.title : '') +
        ' ' +
        message.thread[message.thread.length-1].from.firstName +
        ' ' +
        message.thread[message.thread.length-1].from.lastName +
        '\nSubject: ' +
        message.thread[message.thread.length-1].subject +
        '\nDate: ' +
        moment(message.thread[message.thread.length-1].date).format('L HH:mm') +
        '\n' +
        message.thread[message.thread.length-1].body
      : '\n------' +
        '\nFrom: ' +
        (message.from.title ? message.from.title : '') +
        ' ' +
        message.from.firstName +
        ' ' +
        message.from.lastName +
        '\nSubject: ' +
        message.subject +
        '\nDate: ' +
        moment(message.date).format('L HH:mm') +
        '\n' +
        message.body
      : '',
  };

  const { register, handleSubmit, control, errors } = useForm({
    defaultValues,
  });

  if (loading_msg) {
    return <LoadingLocal />;
  }

  const onSubmit = (data) => {
    /**
     * Condition only for new message
     */
    if (data.hasOwnProperty('isNew')) {
      if (Array.isArray(data.to) && data.to.length === 1) {
        //For a single recipient
        let to = JSON.stringify([
          {
            user_id: data.to[0].value,
            type: data.to[0].__type,
          },
        ]);

        if (message && message.message_global_id) {
          dispatch(actionSendMessage({ ...data, to, original_id: message.message_global_id }));
        } else {
          dispatch(actionSendMessage({ ...data, to }));
        }
        
        setShowForm(false);
        return null;
      } else {
        //For each recipient (multi)
        let to = [];

        if (!data.to || !Array.isArray(data.to) || !data.to.length) {
          toast.error(t('no-recipient-selected'));
          return;
        }

        data.to.map(recip => {
          to.push({
            user_id: recip.value,
            type: recip.__type,
          });
        });

        dispatch(actionSendMessage({ ...data, to: JSON.stringify(to) }));
        setShowForm(false);
        return null;
      }
    }

    let to = [
      {
        user_id: message.from.auth_user_id,
        type: message.from.type,
      },
    ];

    if (reply && Array.isArray(data.to)) {
      //For each recipient (multi)
      if (!data.to || !data.to.length) {
        toast.error(t('no-recipient-selected'));
        return;
      }
      data.to.map(recip => {
        const _id = recip.value;
        const recipient = users.find((user) => _id === user.auth_user_id);
        if (!recipient) {
          return toast.error( t("recipients-not-found") ); //'Recipient invalid!');
        }
        to.push({
          user_id: recipient.auth_user_id,
          type: recipient.type,
        });
      });
    }

    to = JSON.stringify(to);
    let message_answered = JSON.stringify({
      _id: message.message_global_id,
      box,
      date: message.date,
    });
    dispatch(actionSendMessage({ ...data, to, message_answered }));
    setShowForm(false);
    //showReplyForm([]);
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label for="to">{t('recipients')}</Label>
          {(() => {
            if (message && !resend && !reply) {
              return (
                <Row>
                  <Col xs={7} md={9}>
                    <input
                      type="text"
                      className="form-control"
                      readOnly={true}
                      defaultValue={
                        message.from.firstName + ' ' + message.from.lastName
                      }
                    />
                  </Col>
                  <Col xs={5} md={3}>
                    <Button
                      size="md"
                      id="btn-cancel"
                      type="reset"
                      className="btn-info pull-right"
                      style={{marginTop: 0}}
                      onClick={e => {
                        setReply(true);
                      }}
                    >
                      Add recipient
                    </Button>
                  </Col>
                </Row>
              );
            } else {
              return (
                <>
                  {!reply && (<input type="hidden" name="isNew" ref={register} defaultValue={true} />)}
                  <InboxAsyncRecipients
                    register={register}
                    control={control}
                    focus
                  />
                </>
              );
            }
          })()}
        </FormGroup>
        <FormGroup>
          <Label for="to">{t('subject')}</Label>
          <input
            className="form-control"
            type="text"
            name="subject"
            id="subject"
            placeholder={`${t('subject')}`}
            ref={register({ required: true, maxLength: 255 })}
          />
          {errors.subject && (
            <p className="alert alert-danger mt-2 w-50">{t('error-subject')}</p>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="message">{t('message')}</Label>
          <textarea
            className="form-control inbox-msg-form-body"
            type="textarea"
            name="body"
            id="body"
            autoFocus={!!message}
            rows={6}
            ref={register(this?.value?.length ? { required: true } : { required: false })}
          ></textarea>
          {errors.body && (
            <div className="alert alert-danger mt-2 w-50">
              {t('error-message')}
            </div>
          )}
        </FormGroup>

        
        <FormGroup>
          <Label>{t('attachments')}</Label>
          <br />
          <div className="inbox_attachment_section">
            {attachmentList.map((value, i) => (
              <BtnAttach
                key={i}
                position={i}
                id={value}
                register={register}
                setAttachmentList={setAttachmentList}
                attachmentList={attachmentList}
                resend={value.includes("resend-")}
              />
            ))}
            <br />
          </div>
        </FormGroup>
      

        {/*<Label>{`${t('valid-attachments')}:${fileRules.types.map(t => ` ${t}`)}`}</Label>*/}
        <Label>{`${t('max-file-size')}: ${fileRules.maxSize} Megabytes (MB)`}</Label>

        <div>
          <Button id="btn-reply" size="sm" className="btn-info pull-right">
            {t('btn-send')} <span className="icon-send"></span>
          </Button>
          <Button
            size="sm"
            id="btn-cancel"
            type="reset"
            className="btn-primary pull-right mr-3"
            onClick={(e) => {
              e.preventDefault();
              setShowForm(false);
            }}
          >
            {t('btn-cancel')} <i className="fas fa-times" aria-hidden="true"></i>
          </Button>
        </div>
      </Form>
    </Fragment>
  );
};

export default InboxViewForm;
