import CONSTANTS from '../CONSTANTS';

export const actionGetEarlyStudies = (options) => ({

  //Tell to redux that must execut an action of this type
  type: CONSTANTS.LAB_EARLY_GET_LIST,
  options
});

export const actionSetEarlyStudies = (studies) => ({
  type: CONSTANTS.LAB_EARLY_SET_LIST,
  studies
});

export const actionGetEarlyStudy = (reportId, practiceId) => ({
  type: CONSTANTS.LAB_EARLY_GET_STUDY,
  reportId, practiceId
});

export const actionSetEarlyStudy = (study) => ({
  type: CONSTANTS.LAB_EARLY_SET_STUDY,
  study
});

export const actionSetSearchQueryEarly = (query) => ({
  type: CONSTANTS.LAB_EARLY_SET_QUERY,
  query
})