import React, { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import { useSelector } from 'react-redux';
//Services
import MultimediaService from "services/MultimediaService";
import nativeSender from 'helpers/nativeSender';

const BtnFile = ({ file }) => {
  const isMounted = useRef(true);
  const { nativeWebView } = useSelector(state => state.commonReducer);
  const [fileData, setFileData] = useState({});

  const downloadFile = async (file) => {
    const fileName = fileData.name ? fileData.name : "Attachment";

    if (nativeWebView) {
      return nativeSender.send('DOWNLOAD_BLOB', { id: file, name: fileName });
    }

    const data = await MultimediaService.getFile(file);
    console.log(data);
    if (!window.navigator.msSaveOrOpenBlob) {
      // BLOB NAVIGATOR
      const link = document.createElement("a");
      link.href = data;
      link.id = fileData._id ? fileData._id : Date.now();
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      // BLOB FOR EXPLORER 11
      window.navigator.msSaveOrOpenBlob(data, fileName);
    }
  };

  const getFileInfo = async (file) => {
    const data = await MultimediaService.getFileInfo(file);
    if (data) {
      setFileData(data);
    }
  };

  useEffect(() => {
    if (isMounted && !fileData._id) {
      getFileInfo(file);
    }
    return () => {
      isMounted.current = false;
    };
    //eslint-disable-next-line
  }, [getFileInfo, isMounted]);

  if (fileData.length === 0) {
    return <></>;
  }

  return (
    <Button color="info" onClick={() => downloadFile(file)}>
      <i className="fas fa-paperclip"></i> {fileData.name ? fileData.name : ""}
    </Button>
  );
};

export default BtnFile;
