import Icon from 'components/Icons/Icon';
import React from 'react';
import { useSelector } from 'react-redux';

const useChatMessageStatus = () => {
  const userActive = useSelector((state) => state.userReducer);
  const getStatus = (message) => {
    let aux = [
      {
        global: true,
        status: 'waiting',
      },
    ];
    let globalSended = true;
    let globalReceived = true;
    let globalReaded = true;
    if (Array.isArray(message?.recipients)) {
      message.recipients.forEach((user) => {
        if (
          userActive.auth_user_id.toString() === user.auth_user_id.toString()
        ) {
          return null;
        }
        let status = null;
        if (user.received) {
          status = 'received';
        } else {
          globalReceived = false;
        }
        if (user.readed) {
          status = 'readed';
        } else {
          globalReaded = false;
        }
        if (!user.readed && !user.readed) {
          status = 'received';
        }
        aux.push({
          user: `${user.title ? user.title + ' ' : ''}${user.firstName} ${
            user.lastName
          }`,
          auth_user_id: user.auth_user_id,
          status,
        });
      });
    } else {
      globalSended = false;
      globalReceived = false;
      globalReaded = false;
    }
    //set global state
    if (message?.temp_id?.includes('temp')) {
      aux[0].status = 'waiting';
    } else {
      if (globalSended) {
        aux[0].status = 'received';
      } else {
        aux[0].status = 'waiting';
      }
      if (globalReceived && !globalReaded) {
        aux[0].status = 'received';
      } else if (globalReaded) {
        aux[0].status = 'readed';
      }
    }
    return aux;
  };

  const getIcon = (status, className = null) => {
    let icon = null;
    if (!status) {
      return icon;
    }
    icon =
      status === 'waiting' ? (
        <Icon
          file="resend-chat.svg"
          title="Resend"
          className={`"chat-message-icon" ${className}`}
          width="17px"
        />
      ) : (
        icon
      );
    icon =
      status === 'sended' ? (
        <Icon
          file="sent-chat.svg"
          title="Sent"
          className={`"chat-message-icon" ${className}`}
          width="17px"
        />
      ) : (
        icon
      );
    icon =
      status === 'received' ? (
        <Icon
          file="received-chat.svg"
          title="Sent"
          className={`"chat-message-icon" ${className}`}
          width="17px"
        />
      ) : (
        icon
      );
    icon =
      status === 'readed' ? (
        <Icon
          file="view-chat.svg"
          title="Readed"
          className={`"chat-message-icon" ${className}`}
          width="17px"
        />
      ) : (
        icon
      );
    return icon;
  };

  return {
    getStatus,
    getIcon,
  };
};

export default useChatMessageStatus;
