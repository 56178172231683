import React, { useEffect, useState } from 'react';
import { useRef } from 'react';

import { useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import EmojisReactions from 'components/Emojis/EmojisReactions';
import { Row, Col } from 'reactstrap';
import ChatMessageStatusInfo from 'components/Chats/ChatMessageStatusInfo';

const MessageHoverModal = (props) => {
  const { show, setShow, id, message, type, setReplyData } = props;

  const { mobile } = useSelector((state) => state.commonReducer);
  const emojiContainer = useRef(null);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const t = useTranslate('chat');

  useEffect(() => {
    const calculatePosition = () => {
      const parent = document.getElementById(id);
      if (show && parent) {
        let msgPosition = parent.getBoundingClientRect();
        let containerPosition = null;
        if (mobile) {
          containerPosition = document.querySelector('.inbox-history-mobile');
          if (!containerPosition) {
            containerPosition = document.querySelector(
              '.inbox-history-mobile-resized'
            );
          }
        } else {
          containerPosition = document.querySelector('.new-chat-inbox-container');//

          if (!containerPosition) {
            containerPosition = document.querySelector(
              '.inbox-history-resized'
            );
          }
          containerPosition = containerPosition?.getBoundingClientRect();
        }
        if (emojiContainer.current) {
          let top = msgPosition.top - (mobile ? 115 : 160);
          let left = 0;
          if (type === 'out') {
            left = mobile ? 10 : 70;
            emojiContainer.current.style.top = Math.round(top) + 'px';
            emojiContainer.current.style.right = Math.round(left) + 'px';
          } else if (type === 'in') {
            left = mobile ? 12 : 350;
            emojiContainer.current.style.top = Math.round(top) + 'px';
            emojiContainer.current.style.left = Math.round(left) + 'px';
          }
        }
      }
    };
    if (show) {
      calculatePosition();
    }
  }, [show, id, mobile, type]);

  useEffect(() => {
    const handleScroll = (e) => {
      if (
        e.target.classList.toString().includes('inbox-history') ||
        e.target.classList.toString().includes('inbox-history-mobile') ||
        e.target.classList.toString().includes('incoming_msg') ||
        e.target.classList.toString().includes('outgoing_msg')
      ) {
        setShow(false);
      }
    };
    const container = mobile
      ? document.querySelector('.inbox-history-mobile')
      : document.querySelector('.inbox-history');
    if (container) {
      container.addEventListener('scroll', handleScroll);
      container.addEventListener('click', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
        container.removeEventListener('click', handleScroll);
      }
    };
  }, [setShow, id, mobile]);

  if (show) {
    const els = document.querySelectorAll('.emojis-custom-container');
    els.forEach((el) => el.classList.add('d-none'));
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 100000);
    return () => {
      clearTimeout(timer);
    };
  }, [setShow]);

  return (
    <>
      <ChatMessageStatusInfo message={message} modal={modal} toggle={toggle} />
      <div
        className={`emojis-custom-container ${!show ? 'd-none' : ''}`}
        ref={emojiContainer}
      >
        <Row>
          <Col className="d-flex justify-content-around">
            {show && (
              <EmojisReactions
                show={show}
                message={message}
                setShow={setShow}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex">
            <div
              className="btn-chat-message-info"
              onClick={() => setModal(true)}
            >
              {t('chat-info')}
            </div>
            <span className="reply-btn icon-inbox"></span>
          </Col>
          <Col className="d-flex">
            <div
              className="reply-btn"
              onClick={() => {
                const input = document.getElementById('chat-send-input');
                if (input) {
                  input.focus();
                }
                setReplyData({
                  _id: message._id,
                  author:
                    (message.sender.pronoun
                      ? `${message.sender.pronoun} `
                      : '') +
                    `${message.sender.firstName} ${message.sender.lastName}`,
                  body: message.body,
                });
              }}
            >
              {t('chat-reply-btn')}
            </div>
            <span className="reply-btn icon-message-header"></span>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MessageHoverModal;
