import CONSTANTS from '../CONSTANTS';

const initialState = {
  studies: [],
  page: 0,
  lastPage: 1,
  limit: 10,
  study: {},
  loadingAiReview: false,
  loadingStudy: false,
  loadingStudyList: false,
  showSentModal: false,
  querySearch: "",
}
const laboratoryReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CONSTANTS.LAB_LOADING_STUDY_LIST:
      return {
        ...state,
        loadingStudyList: action.value
      };

    case CONSTANTS.LAB_SHOW_SENT_MODAL:
      return {
        ...state,
        showSentModal: action.value
      };

    case CONSTANTS.LAB_LOADING_STUDY:
      return {
        ...state,
        loadingStudy: action.value
      };

    case CONSTANTS.LAB_SET_STUDIES:
      return { 
        ...state, 
        studies: [...action.studies.data],
        page: action.studies.page,
        lastPage: action.studies.lastPage,
        limit: action.studies.limit,
        loadingStudyList: false
      };

    case CONSTANTS.LAB_SET_STUDY:
      return {
        ...state,
        study: {...action.study},
        loadingStudy: false
      }

    case CONSTANTS.LAB_LOADING_AI_COMMENT:
      return {
        ...state,
        loadingAiReview: action.value
      }

    case CONSTANTS.LAB_SAVE_TEMPLATE_LOCAL_DATA: {
      //Save template and review data locally to avoid requesting the entire studio
      let newTemplates = [], newReviews = [];
      if (state.study.templates) {
        let templateIndex = state.study.templates.map(te => te.name).indexOf(action.name);
        newTemplates = [...state.study.templates];
        if (templateIndex !== -1 && newTemplates[templateIndex]) {
          newTemplates[templateIndex].content = action.content;
          newTemplates[templateIndex].updated_at = Date.now();
        } else {
          newTemplates.push({
            name: action.name,
            content: action.content,
            updated_at: Date.now()
          });
        }
      }

      if (state.study.reviews) {
        newReviews = [...state.study.reviews];

        if (newReviews[0]) {
          newReviews[0].content = action.content;
          newReviews[0].template = {name: action.name};
          newReviews[0].updated_at = Date.now();
        } else {
          newReviews[0] = {
            content: action.content,
            updated_at: Date.now(),
            template: {name: action.name}
          }
        }
      }
      return {
        ...state,
        study: {
          ...state.study, 
          templates: [...newTemplates],
          reviews: [...newReviews]
        }
      };
    }

    case CONSTANTS.LAB_SET_SEARCH_QUERY: 
      return {
        ...state,
        querySearch: action.search
      }

    default:
      return state;
  }
};

export default laboratoryReducer;
