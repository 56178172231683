import AxiosService from 'plugins/TS-lib-utils-public/services/AxiosService';

class PatientsService extends AxiosService {
    constructor() {
        super('patient-user');
    }
    /**
  * Get all studies linked to the current doctor and practice
  */
    sendManualInvitation = async (patientId, doctorId, studyId, { cancelToken = null } = {}) => {
        const response = await this.get(`/profile/invite/${patientId}`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                doctorId,
                studyId
            },
            cancelToken: cancelToken
        });
        return response;
    };
}

export default new PatientsService();

