import CONSTANTS from '../CONSTANTS';
const initialState = {
  _id: null,
  name: null,
  modules: [],
};
const userAuthorizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.USERS_AUTHORIZATION_SET:
      return { ...state, ...action.authorization };
    case CONSTANTS.USERS_AUTHORIZATION_RESET:
      return initialState;
    default:
      return state;
  }
};

export default userAuthorizationReducer;
