import AxiosService from 'plugins/TS-lib-utils-public/services/AxiosService';

class LaboratoryService extends AxiosService {
    constructor() {
        super('doctor-user');
    }
    /**
  * Get all studies linked to the current doctor and practice
  */
    getStudiesList = async (practiceId, page = 0, count = 5, sortDate = 0, search = null, dateFrom = null, dateTo = null, { cancelToken = null } = {}) => {
        let query = `/early-lab/?page=${page}&limit=${count}&datesort=${sortDate}`;

        if (search) {
            query += `&search=${search}`
        }
        if (dateFrom) {
            query += `&from=${dateFrom}`;
        }
        if (dateTo) {
            query += `&to=${dateTo}`;
        }
        
        const response = await this.get(query, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                practice: practiceId,
            },
            cancelToken: cancelToken
        });
        return response;
    };
    /**
    * Get get study detail by reportId
    */
    getStudyDetail = async (reportId, practiceId) => {
        const response = await this.get(`/early-lab/${reportId}`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                practice: practiceId,
            },
        });
        return response;
    };
}

export default new LaboratoryService();

