import CONSTANTS from '../CONSTANTS';

export const actionGetPractices = (setPractice) => ({
  type: CONSTANTS.PRACTICES_GET_LIST,
  setPractice
});

export const actionSetPractices = (practices) => ({
  type: CONSTANTS.PRACTICES_SET_LIST,
  practices,
});
/**
 * @deprecated
 */
export const actionGetPractitioners = (practiceId) => ({
  type: CONSTANTS.PRACTITIONERS_GET_LIST,
  practiceId,
});

export const actionSetPractitioners = (practitioners) => ({
  type: CONSTANTS.PRACTITIONERS_SET_LIST,
  practitioners,
});

export const actionGetPractice = (id, options) => ({
  type: CONSTANTS.PRACTICES_GET,
  id,
  options
});

export const actionSetPractice = (practice) => ({
  type: CONSTANTS.PRACTICES_SET,
  practice,
});

export const actionDeletePractice = (password, id) => ({
  type: CONSTANTS.PRACTICES_DELETE,
  password,
  id,
});

export const actionSavePractice = (practice) => ({
  type: CONSTANTS.PRACTICES_SAVE,
  practice,
});
//Invitations
export const actionSendInvitation = (invitation) => ({
  type: CONSTANTS.INVITATION_SAVE,
  invitation,
});
//Practitioners
export const actionDeletePractitioner = (id) => ({
  type: CONSTANTS.PRACTITIONER_DELETE,
  id,
});
export const actionEditPractitioner = (data) => ({
  type: CONSTANTS.PRACTITIONER_EDIT,
  data,
});

export const actionMetricPracticeSelect = (selected_practice) => ({
  type: CONSTANTS.PRACTICE_METRIC_SELECTED,
  selected_practice,
});

export const actionSetUserPracticecDisaffected = (isDisaffected) => ({
  type: CONSTANTS.PRACTICE_USERS_SET_DISAFFECTED,
  isDisaffected,
});