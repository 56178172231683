import React, { useState } from "react";
import {
    Card,    CardBody, CardHeader, CardTitle,  Button,
    Input,   Label,    Row,        Col,        Nav,
    NavItem, NavLink,  TabContent, TabPane,    Form
} from "reactstrap";
import classnames from 'classnames';

// Include params library
import { useParams, useHistory } from 'react-router';

// Include Translation tool
import { useTranslate } from "react-translate";

// Include the components
import KiLabEditor_data from "components/KiLab/KiLabEditor_data";
import KiLabEditor_analytes from "components/KiLab/KiLabEditor_analytes";
import KiLabEditor_comment from "components/KiLab/KiLabEditor_comment";

// Include a particular scss for this view
import 'assets/scss/custom/_kilab_editor.scss';



const KiLabEditor = () => {

    // Hook to redirect
    const history = useHistory();
    
    // Get AnalysisId from URL
    const { id_lab_result } = useParams("id_lab_result");
    

    // Invoke translation lib
    const t = useTranslate('kilab');
    const tEditor = useTranslate('kilab-editor');


    // State to manage active tab
    const [activeTab, setActiveTab] = useState('tab-result');

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    //Helper to filter control keys
    const isValidInput = (e) => {
        let code = e.which ? e.which : e.keyCode;
        return !(
            code == 16 || code == 17 || code == 18 || code == 19 || code == 20 || code == 27 || code == 45 || code == 91 || 
            code == 93 || (code >= 35 && code <= 40) || (code >= 33 && code <= 34 ) || (code >= 112 && code <= 123) || (code >= 144 && code <= 145 )
        );
    }

    return (
        <Card className="full-height">
            <CardBody>
                <Card name="body" className="expand-height">
                    <CardHeader>
                        <CardTitle className="d-flex align-content-center">
                            <div className="general-card-title-img text-primary mr-2">
                                <i className="icon-TP_KI_Logo_White" />
                            </div>
                            <div className="general-card-title text-primary">{t('title-view')}</div>
                        </CardTitle>
                        <Button className="btn btn-info btn-md" onClick={() => history.goBack()}>Zurück</Button>
                    </CardHeader>

                    <CardBody className="card-min-height">
                        <Form>

                            { /*************************************
                            Invoke the component to show the parameters for the AI question */ }
                            <KiLabEditor_data id_lab_result={id_lab_result} />

                            <Nav pills className="nav-pills-primary mb-4 mt-4">
                                <NavItem className="profile-item">
                                    <NavLink
                                        className={classnames({ active: activeTab === 'tab-result' })}
                                        onClick={() => { toggle('tab-result'); }}>
                                            {tEditor('tab-detail')}
                                    </NavLink>
                                </NavItem>                                
                                <NavItem className="profile-item">
                                    <NavLink
                                        className={classnames({ active: activeTab === 'tab-comment' })}
                                        onClick={() => { toggle('tab-comment'); }}>
                                            {tEditor('tab-doctor-review')}
                                    </NavLink>
                                </NavItem>
                            </Nav>


                            <TabContent activeTab={activeTab} className="mt-4">
                                
                                { /*********************************
                                Component to render de analytes tab */ }
                                <KiLabEditor_analytes />


                                { /*********************************
                                Component to render de comment tab */ }
                                <KiLabEditor_comment />
                               
                                
                            </TabContent>
                        </Form>
                    </CardBody>
                </Card>                
            </CardBody>
        </Card>
    );
};

export default KiLabEditor;