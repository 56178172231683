import AxiosService from 'plugins/TS-lib-utils-public/services/AxiosService';

class StatsService extends AxiosService {
    constructor() {
        //exiende el axiosservice, y hace referencia a la url del back   MS-Doctors-Users
        super('doctor-user');
    }
    
    /**
     * Metodo para recuperar las estadisticas genertales de las votaciones a KILAB
     * @param {*} practiceId 
     * @returns 
     */
    getPracticeMetrics = async (keyQuestion, practiceId, doctorAuthId) => {

        //console.log("doctorAuthId : " + doctorAuthId)
        const response = await this.post(`/early-lab/getAllMetrics`, {
            keyQuestion:keyQuestion,
            idPractice:practiceId,
            doctorAuthId:doctorAuthId
        }, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                practice: practiceId,
            },
        });
        return response;
    }
}

export default new StatsService();