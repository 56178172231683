import React, { useEffect } from 'react';
import { Form, Row, Col, FormGroup, Button, Input } from 'reactstrap';
import { useTranslate } from 'react-translate';
import { useForm } from 'react-hook-form';
import { actionSetSearchQueryEarly, actionSetFromDetails } from 'store/actions/kiLabActions';
import { useDispatch, useSelector } from 'react-redux';

const Searcher = () => {

    const dispatch = useDispatch();
    const t = useTranslate('kilab-searcher');
    const coming = useSelector(state => state.kiLabReducer.comingFromDetailView);
    const query = useSelector(state => state.kiLabReducer.querySearch);
    const { handleSubmit, register } = useForm();
    const onSubmit = (data) => {
        dispatch(actionSetSearchQueryEarly(data.query));
    }

    useEffect(() => {

        if (!coming) {
            dispatch(actionSetSearchQueryEarly(""));
        } else {
            dispatch(actionSetFromDetails(false));
        }
        
    }, []);

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={8} sm={6} lg={4} >
                        <FormGroup
                        className="mt-2">
                            <Input 
                                className="form-control"
                                type="text"
                                innerRef={register}
                                name="query"
                                id="query"
                                placeholder={t('input-placeholder')}
                                defaultValue={!coming ? "" : query}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={1}>
                        <Button
                            size="md"
                            color="info"
                            type="submit"
                            name="search-study"
                        >
                            {t("button-placeholder")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default Searcher;