import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import ChatBox from 'components/Chats/ChatBox';
import { useSelector, useDispatch } from 'react-redux';
import { actionSetUserPracticecDisaffected } from 'store/actions/practicesActions';
import ChatBoxMobile from 'components/Chats/Mobile/ChatBoxMobile';


const Chats = () => {
  const dispatch = useDispatch();
  const mobile = useSelector((state) => state.commonReducer.mobile);
  const user = useSelector((state) => state.userReducer);
  //state global current user
  const { practice, isDisaffected } = useSelector((state) => state.practicesReducer);
  const [userAuth, setuserAuth] = useState('');  
  const [ctrlDisaffected, ctrlIsDisaffected] = useState(null);
  
  useEffect(() => {
    //check if user looged is disaffected in te current practice
    if (user && practice && practice.people) {
      let res = practice.people.find(us => String(us.auth_user_id) === String(user.auth_user_id) && us.type === 'doctor');

      //check status
      setuserAuth(res ? res.auth_user_id : {});
      dispatch(actionSetUserPracticecDisaffected(res && res.isDisaffected));
      ctrlIsDisaffected(isDisaffected);
    }
    //eslint-disable-next-line
  }, [practice]);

 

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.getElementById('full-page').style.overflow = 'hidden';
    document.querySelector('html').scrollTop = 0;
    /*
    document.getElementById('physician-header').style.top = mobile
      ? '43px'
      : '23px';
      */
    return () => {
      document.body.style.overflow = null;
      document.getElementById('full-page').style.overflow = null;
      //document.getElementById('physician-header').style.top = null;
    };
  });

  return (
    <Card className={'chat-page'}>
      <CardBody>
        {mobile ? (
          <ChatBoxMobile view="chat-page" practice={practice} userAuth= {userAuth} isDisaffected = {ctrlDisaffected}/>
        ) : (
          <ChatBox view="chat-page" practice={practice} userAuth= {userAuth} isDisaffected = {ctrlDisaffected} />
        )}
      </CardBody>
    </Card>
  );
};

export default React.memo(Chats);
