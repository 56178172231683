import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import { Button } from 'reactstrap';
import { actionSweetAlert } from 'store/actions/commonActions';
import {
  actionSetSelectedMessages,
  actionDelMessages,
} from 'store/actions/messagesActions';

const BtnDelete = ({ mobile, disabled }) => {
  const t = useTranslate('messages');
  const dispatch = useDispatch();

  const { selectedMessages } = useSelector((state) => state.messagesReducer);

  const handleBtnDelete = () => {
    dispatch(
      actionSweetAlert(
        <SweetAlert
          warning
          title={t('alert-delete-title')}
          onConfirm={() => {
            dispatch(actionDelMessages({ messages: selectedMessages }));
            dispatch(actionSweetAlert(null));
            dispatch(actionSetSelectedMessages([]));
          }}
          onCancel={() => dispatch(actionSweetAlert(null))}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={t('alert-delete-btn-ok')}
          cancelBtnText={t('alert-delete-btn-cancel')}
          showCancel
        >
          {t('alert-delete-subtitle')}
        </SweetAlert>
      )
    );
  };

  return (
    <Button
      className="btn-delete"
      color="primary"
      size="sm"
      name="delete-message-btn"
      onClick={handleBtnDelete}
      disabled={selectedMessages.length <= 0 || disabled}
    >
      {!mobile && t('btn-delete')} <span className="icon-trash"></span>
    </Button>
  );
};

export default BtnDelete;
