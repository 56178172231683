import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslate } from 'react-translate';
import ProfilesService from 'services/ProfilesService';
import { actionSetUser } from 'store/actions/usersActions';

const StatusList = ({ setOpen }) => {
  const isMounted = useRef();
  const dispatch = useDispatch();
  const t = useTranslate('sidebar');
  const userActive = useSelector((state) => state.userReducer);
  const [status, setStatus] = useState('offline');

  const handleStatus = (status) => {
    const data = {
      _id: userActive._id,
      status,
    };
    const previusState = status === 'online' ? 'offline' : status;
    ProfilesService.edit(userActive.auth_user_id, data)
      .then(() => {
        setStatus(status);
        dispatch(actionSetUser({ ...userActive, status_account: status }));
      })
      .catch(() => {
        setStatus(previusState);
        dispatch(
          actionSetUser({ ...userActive, status_account: previusState })
        );
        toast.success(t('alert-error-profile-saved'));
      })
      .finally(() => {
        if (setOpen) {
          setOpen(false);
        }
      });
  };

  const updated_at =
    userActive && userActive.updated_at ? userActive.updated_at : null;
  const status_account =
    userActive && userActive.status_account
      ? userActive.status_account
      : 'offline';

  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current && updated_at) {
      setStatus(status_account);
    }
    return () => {
      isMounted.current = false;
    };
  }, [updated_at, status_account]);

  return (
    <ul className="nav">
      <li>
        <a
          href="#a"
          onClick={(e) => {
            e.preventDefault();
            handleStatus('online');
          }}
        >
          <span className="sidebar-mini-icon status">
            <i className={`online ${status === 'online' ? 'active' : ''}`}></i>
          </span>
          <span className="sidebar-normal">{t('available')}</span>
        </a>
      </li>
      <li>
        <a
          href="#a"
          onClick={(e) => {
            e.preventDefault();
            handleStatus('partial');
          }}
        >
          <span className="sidebar-mini-icon status">
            <i className={`partial ${status === 'partial' ? 'active' : ''}`}></i>
          </span>
          <span className="sidebar-normal">{t('busy')}</span>
        </a>
      </li>
      <li>
        <a
          href="#b"
          onClick={(e) => {
            e.preventDefault();
            handleStatus('offline');
          }}
        >
          <span className="sidebar-mini-icon status">
            <i
              className={`offline ${status === 'offline' ? 'active' : ''}`}
            ></i>
          </span>
          <span className="sidebar-normal">{t('unavailable')}</span>
        </a>
      </li>
    </ul>
  );
};

export default StatusList;
