import CONSTANTS from "../CONSTANTS";

export const actionDeletePatient = id => ({
  type: CONSTANTS.PATIENTS_DELETE,
  id
});

export const actionGetPatients = (options) => ({
  type: CONSTANTS.PATIENTS_GET_LIST,
  options
});

export const actionSetPatients = patients => ({
  type: CONSTANTS.PATIENTS_SET_LIST,
  patients
});

export const actionGetPatient = id => ({
  type: CONSTANTS.PATIENTS_GET,
  id
});

export const actionSetPatient = patient => ({
  type: CONSTANTS.PATIENTS_SET,
  patient
});

export const actionSavePatient = patient => ({
  type: CONSTANTS.PATIENTS_SAVE,
  patient
});

export const actionEditPatient = patient => ({
  type: CONSTANTS.PATIENTS_EDIT,
  patient
});

export const actionUpdatePatientCheck = (practiceId, patientId, status) => ({
  type: CONSTANTS.PATIENTS_EDIT_CHECK,
  practiceId,
  patientId,
  status
});

export const actionInvitePatient = (patientId, doctorId, studyId) => ({
  type: CONSTANTS.PATIENTS_MANUAL_INVITE,
  patientId,
  doctorId,
  studyId
});
