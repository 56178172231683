import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Table } from 'reactstrap';

import PractitionerStatus from 'components/Practice/PractitionerStatus';

//actions
import { actionGetPractice } from 'store/actions/practicesActions';
import { useTranslate } from 'react-translate';

const InvitationList = () => {
  const t = useTranslate('practices');
  const dispatch = useDispatch();

  const { auth_user_id } = useSelector((state) => state.userReducer);
  const practice = useSelector((state) => state.practicesReducer.practice_manage_select);
  const sentInvitations = Object.keys(practice).length ? practice.invitations.filter(i => String(i.from) === String(auth_user_id)) : [];

  const [invitationsData, setInvitationsData] = useState([...sentInvitations]);

  //Sort states
  const [roleSort, setRoleSort] = useState(0);
  const [statusSort, setStatusSort] = useState(0);
  const [dateSort, setDateSort] = useState(0);

  //Keys to sort by status
  const statusKeys = {
    active: t('practitioner-status-active'),
    online: t('practitioner-status-active'),
    offline: t('practitioner-status-active'),
    waiting: t('practitioner-status-waiting'),
    inactive: t('practitioner-status-inactive'),
    accepted: t('practitioner-status-accepted'),
    rejected: t('practitioner-status-rejected'),
    failed: t('practitioner-status-failed'),
    disaffected: t('practitioner-status-deleted'),
  };

  /**
   * Update invitation list, while maintaining order
   */
  useEffect(() => {
    if (invitationsData.length) {
      setInvitationsData([...invitationsData.map(i => {
        const index = sentInvitations.findIndex(si => String(i._id) === String(si._id));
        return sentInvitations[index];
      })]);
    } else {
      setInvitationsData([...invitationsData]);
    }
  }, [practice]);

  /**
   * Sort based on roles
   */
  useEffect(() => {
    if (roleSort) {
      setInvitationsData([...invitationsData.sort((l,r) => {
        const result = l.role_name.localeCompare(r.role_name);

        //Priorize results that matches the sort value, else, invert the result or return 0 (equal)
        return result ? result !== roleSort ? result * roleSort : result === roleSort : 0;
      })]);
    }

  //eslint-disable-next-line
  }, [roleSort]);

  /**
   * Sort based on status
   */
  useEffect(() => {
    if (statusSort) {
      setInvitationsData([...invitationsData.sort((l,r) => {
        const leftStatus = statusKeys[l.status ? l.status : 'waiting'];
        const rightStatus = statusKeys[r.status ? r.status : 'waiting'];
        const result = leftStatus.localeCompare(rightStatus);

        //Priorize results that matches the sort value, else, invert the result or return 0 (equal)
        return result ? result !== statusSort ? result * statusSort : result === statusSort : 0;
      })]);
    }

  //eslint-disable-next-line
  }, [statusSort]);

  /**
   * Sort based on dates
   */
   useEffect(() => {
    if (dateSort) {
      setInvitationsData([...invitationsData.sort((l,r) => {
        const result =  l.date.localeCompare(r.date);

        //Priorize results that matches the sort value, else, invert the result or return 0 (equal)
        return result ? result !== dateSort ? result * dateSort : result === dateSort : 0;
      })]);
    }

  //eslint-disable-next-line
  }, [dateSort]);

  /**
   * Revert to default order
   */
  useEffect(() => {
    if (!roleSort && !statusSort && !dateSort) {
      setInvitationsData([...invitationsData.sort((l,r) => {
        const compareRes = l.date.localeCompare(r.date);
        return compareRes ? compareRes : l.date.localeCompare(r.date);
      })]);
    }

  //eslint-disable-next-line
  }, [roleSort, statusSort, dateSort]);


  return (
    <>
      <h5 className="general-card-subtitle no-line">{t('invitation-list')}</h5>
      <Table>
        <tbody>
          <tr>
            <th>{t('th-email')}</th>
            <th>{t('th-text')}</th>

            <th className="sortable-header"
              onClick={(e) => {
                setDateSort(!dateSort ? 1 : dateSort === 1 ? -1: 0);
                setStatusSort(0);
                setRoleSort(0);
              }}>
              {t('th-date')}
              <span>
                <b className={`${!dateSort ? "caret-default" : dateSort === 1 ? "caret-asc" : "caret-desc"}`}></b>
              </span>
            </th>

            <th>{t('th-edited-date')}</th>

            <th className="sortable-header"
              onClick={(e) => {
                setRoleSort(!roleSort ? 1 : roleSort === 1 ? -1: 0);
                setStatusSort(0);
                setDateSort(0);
              }}>
              {t('th-role')}
              <span>
                <b className={`${!roleSort ? "caret-default" : roleSort === 1 ? "caret-asc" : "caret-desc"}`}></b>
              </span>
            </th>

            <th className="sortable-header"
              onClick={(e) => {
                setStatusSort(!statusSort ? 1 : statusSort === 1 ? -1: 0);
                setRoleSort(0);
                setDateSort(0);
              }}>
              {t('th-status')}
              <span>
                <b className={`${!statusSort ? "caret-default" : statusSort === 1 ? "caret-asc" : "caret-desc"}`}></b>
              </span>
            </th>

          </tr>
          {invitationsData &&
            invitationsData.map((invitation, i) => {
              return (
                <tr key={i}>
                  <td>{invitation.email}</td>
                  <td>{invitation.text}</td>
                  <td>{moment(invitation.date).format('L HH:mm') || ''}</td>
                  <td>{invitation.updated_at ? moment(invitation.updated_at).format('L HH:mm') || '' : '-'}</td>
                  <td>{invitation.role_name || ''}</td>
                  <td>
                    <PractitionerStatus
                      status={invitation.status ? invitation.status : 'waiting'}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

export default InvitationList;
