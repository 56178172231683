import { takeEvery, call, select, put, cancelled, takeLatest } from 'redux-saga/effects';
import CONSTANTS from '../CONSTANTS';
import LaboratoryService from 'services/LaboratoryService';
import { toast } from 'react-toastify';
import { actionSetLabStudies, actionSetStudy } from 'store/actions/laboratoryActions';
import historyRouter from 'historyRouter';

//Selectors
import { getUser, getStudy } from 'store/selectors/selectors';
//Helpers
import utilTranslations from '../../helpers/utilTranslations';
import { actionGetStudy } from 'store/actions/laboratoryActions';
import { actionSetLoadingAiComment } from 'store/actions/laboratoryActions';
import { actionSetLoadingStudy } from 'store/actions/laboratoryActions';
import { actionSetLoadingStudyList, actionSetSentModal } from 'store/actions/laboratoryActions';

let t;

function* sagaGetStudy({ reportId, practiceId, loadAiComment }) {
  
  const userActive = yield select(getUser);
  const currentStudy = yield select(getStudy);

  //For loading behavior
  if (!currentStudy || !Object.keys(currentStudy).length || currentStudy._id !== reportId) {

    yield put(actionSetLoadingStudy(true));
  }
  t = utilTranslations(userActive.language || 'de', 'laboratory-detail');
  const cancelToken = LaboratoryService.getSource();
  try {

    const res = yield call(

      LaboratoryService.getStudyDetail, 
      reportId, 
      practiceId,
      { cancelToken: cancelToken.token }
    );

    if (res.status >= 400) {

      throw new Error("Error getting the studies");
    }

    const { _id, hl7_lab_results_id, doctor_id, patient_id, hl7, reviews, patient, ai_review, ai_review_error, hl7Total, templates } = res.data;
    let medicalValues = JSON.parse(hl7[0].reportData);
    let metadata = hl7[0];
    const reportFile = hl7[0].reportFile ? hl7[0].reportFile : null;

    const study = {
      _id,
      hl7_lab_results_id,
      doctor_id,
      patient_id,
      medicalValues,
      metadata,
      patient,
      reportFile,
      reviews: [...reviews],
      ai_review: ai_review,
      ai_review_error: ai_review_error,
      templates
    }

    const days = 30;
    const currentDate = new Date();
    const fifteenDaysAgo = new Date(currentDate.getTime() - days * 24 * 60 * 60 * 1000);
    const formattedDate = fifteenDaysAgo.toISOString().slice(0, 19).replace(/[-T:]/g, '');

    //Check if the study is older than {days} and if AI is not up to date to set a flag
    if ((currentStudy.hl7_lab_results_id !== hl7_lab_results_id || currentStudy.hl7_lab_results_id === hl7_lab_results_id && !currentStudy.is_old) 
      && loadAiComment 
    && patient.isTelepraxen 
    && metadata.created_at < formattedDate 
    && metadata._id !== ai_review?.hl7_id) {
      study.is_old = true;
    }

    yield put(actionSetStudy(study));

    //Check if the ai comment needs an update
    if (loadAiComment && patient.isTelepraxen) {

      if (hl7Total > 1 && metadata.created_at >= formattedDate && metadata._id !== ai_review?.hl7_id) {

        yield put(actionSetLoadingAiComment(true));
        const aiReviewRes = yield call(LaboratoryService.checkAiComment, reportId, doctor_id, patient.patient_pifs_id, practiceId, days);
        study.ai_review = {...aiReviewRes.data};
        yield put(actionSetStudy(study));
      }
    }
    
  } catch (error) {

    console.log(error);
    historyRouter.push("/admin/lab-studies");
  } finally {

    //It's the signal to notice the filled data in the store
    if (yield cancelled()) {
      cancelToken.cancel();
    }
    yield put(actionSetLoadingStudy(false));
    yield put(actionSetLoadingAiComment(false));
  }
}

function* sagaGetLaboratoryStudies({ options }) {
  const userActive = yield select(getUser);
  t = utilTranslations(userActive.language || 'de', 'laboratory-detail');
  const cancelToken = LaboratoryService.getSource();
  try {
    yield put(actionSetLoadingStudyList(true));
    const res = yield call(
      LaboratoryService.getStudiesList, 
      options.practiceId, 
      options.pageIndex, 
      options.pageSize, 
      options.sortDate, 
      options.querySearch, 
      options.dateFrom,
      options.dateTo,
      { cancelToken: cancelToken.token }
    );

    if (res.status >= 400) {
      throw new Error("Error getting the studies");
    }

    yield put(actionSetLabStudies(res.data));

  } catch (error) {

    console.log(error);
  } finally {
    if (yield cancelled()) {

      cancelToken.cancel();
    }
    yield put(actionSetLoadingStudyList(false));
  }
}

function* sagaUpdateStudyReview({ reportId, review, hl7Id, templateName, doctorId, practiceId, aiFlag, isAi, skipModal, makeDraft }) {
  const userActive = yield select(getUser);
  t = utilTranslations(userActive.language || 'de', 'laboratory-detail');
  try {
    const res = yield call(LaboratoryService.updateStudyReview, 
      reportId, 
      review, 
      hl7Id,
      templateName, 
      doctorId, 
      practiceId, 
      false, 
      aiFlag, 
      isAi, 
      makeDraft);
    if (res.status >= 400) {
      throw new Error("Error saving the review");
    } else {
      yield put(actionSetSentModal(!skipModal));
      //toast.success(t("review-saved"));

      if (makeDraft) {
        yield put(actionGetStudy(reportId, practiceId));
      }
      
    }
  } catch (error) {
    console.log(error);
  }
}

function* sagaSendStudyReview({ reportId, review, hl7Id, templateName, doctorId, practiceId, billing, aiFlag, isAi }) {
  const userActive = yield select(getUser);
  t = utilTranslations(userActive.language || 'de', 'laboratory-detail');
  try {
    const res = yield call(LaboratoryService.sendStudyReview, reportId, review, hl7Id, templateName, doctorId, practiceId, billing, aiFlag, isAi);

    if (res.status >= 400) {
      throw new Error("Error Sending the review");
    } else {
      toast.success(t("review-sent"));
      yield put(actionGetStudy(reportId, practiceId));
      //historyRouter.push("/admin/lab-studies");
    }
  } catch (error) {
    console.log(error);
    toast.error(t('sending-review-error-notice'));
  }
}

function* sagaMarkAICommentAsRead({ reportId, doctorId, practiceId }) {
  const userActive = yield select(getUser);
  const currentStudy = yield select(getStudy);
  t = utilTranslations(userActive.language || 'de', 'laboratory-detail');
  try {
    const res = yield call(LaboratoryService.readAiComment, reportId, doctorId, practiceId);

    if (res.status >= 400) {
      throw new Error("Error reading the AI review");
    }

    //Save locally
    currentStudy.ai_review.readed = true;
    yield put(actionSetStudy({...currentStudy}));
  } catch (error) {
    console.log(error);
  }
}

function* sagaDeleteDraft({ reportId, reviewId, doctorId, practiceId }) {
  const userActive = yield select(getUser);
  t = utilTranslations(userActive.language || 'de', 'laboratory-detail');
  try {
    const res = yield call(LaboratoryService.deleteDraft, reportId, reviewId, doctorId, practiceId);

    if (res.status >= 400) {
      throw new Error("Error deleting the draft");
    } else {
      toast.success("Entwurf gelöscht");
      yield put(actionGetStudy(reportId, practiceId));
      //historyRouter.push("/admin/lab-studies");
    }
  } catch (error) {
    console.log(error);
  }
}

export function* laboratoriesSaga() {
  console.log('*Main Laboratories Saga');
  yield takeEvery(CONSTANTS.LAB_UPDATE_REVIEW, sagaUpdateStudyReview);
  yield takeEvery(CONSTANTS.LAB_SEND_REVIEW, sagaSendStudyReview);
  yield takeLatest(CONSTANTS.LAB_READ_AI_COMMENT, sagaMarkAICommentAsRead);
  yield takeLatest(CONSTANTS.LAB_GET_STUDY, sagaGetStudy);
  yield takeLatest(CONSTANTS.LAB_GET_STUDIES, sagaGetLaboratoryStudies);
  yield takeEvery(CONSTANTS.LAB_DELETE_DRAFT, sagaDeleteDraft);
}
