import AxiosService from 'plugins/TS-lib-utils-public/services/AxiosService';

class NotificationsService extends AxiosService {
  constructor() {
    super('notifications');
  }
  getNotifications = async (module = null, {cancelToken = null} = {}) => {
    const response = await this.get(
      `/?limit=5${module ? '&module=' + module : ''}`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        cancelToken: cancelToken
      },
    );
    return response;
  };

  readNotification = async (id, read) => {
    const response = await this.put(
      `/${id}/read/${read}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
    return response;
  };

  readNotificationsByMessages = async (messages, read) => {
    const response = await this.put(
      `/messages/read/${read}`,
      { messages },
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
    return response;
  };

  readAllNotification = async (module) => {
    const response = await this.get(
      `/${module}/readAll`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      },
    );
    return response;
  };
}

export default new NotificationsService();
