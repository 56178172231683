import { useSelector } from 'react-redux';

/**
 * Check is user active is administrator of the chat
 * @returns boolean
 */
const useChatIsAdmin = () => {
  const { chat } = useSelector((state) => state.chatsReducer);
  const userActive = useSelector((state) => state.userReducer);
  if (Object.keys(chat).length === 0) {
    return false;
  }
  if (!chat.users) {
    return false;
  }
  const users = chat ? chat.users : [];
  const isAdmin = users.findIndex(
    (user) =>
      user.administrator && user.auth_user_id === userActive.auth_user_id
  );
  return isAdmin >= 0;
};

export default useChatIsAdmin;
