import React from 'react';
import { Table, Badge, Row, Col, CardImg } from 'reactstrap';
import moment from "moment";

// Include Translation tool
import { useTranslate } from "react-translate";

//Import the Icons
import Icon from "components/Icons/Icon";

const KiLabEditor_stored_list = () => {
    // Translation library
    const tEditor = useTranslate('kilab-editor-stored-list');

    // Datos de ejemplo de los informes
    const study = [
        {
            date: "2024-11-02T23:49:02.771Z",
            title: "KI-BEFUND + TEXT ARZT",
            bornDate: "1978-11-09T23:49:02.771Z",
            aiPureResult: false,
            isAutoSaveDraft: true,
            description: `Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte...Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte, die auf eine stabile Stoffwechsellage hinweisen. Die Leberwerte (GOT, GPT, GGT) liegen im normalen Bereich, ebenso die Nierenfunktionsparameter (Kreatinin, eGFR). Der Calciumwert ist leicht erhöht, was auf eine mögliche Anpassung des Referenzbereichs im Mai 2024 hinweist. Der Hämoglobin A1c-Wert liegt im normalen Bereich, was keinen Hinweis auf Diabetes mellitus gibt. Der Nüchtern-Glukosewert ist jedoch leicht erhöht, was eine weitere Überwachung erfordert. Die Schilddrüsenparameter deuten auf eine euthyreote Stoffwechsellage hin. Der Rheumafaktor ist im normalen Bereich, und es gibt keine Hinweise auf eine akute Entzündung, da der CRP-Wert unter 1.0 mg/l liegt. Insgesamt deuten die Ergebnisse auf eine weitgehend normale Organfunktion hin, mit Ausnahme des leicht erhöhten Calcium- und Glukosewerts, die einer weiteren Beobachtung bedürfen.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte, die auf eine stabile Stoffwechsellage hinweisen. Die Leberwerte (GOT, GPT, GGT) liegen im normalen Bereich, ebenso die Nierenfunktionsparameter (Kreatinin, eGFR). Der Calciumwert ist leicht erhöht, was auf eine mögliche Anpassung des Referenzbereichs im Mai 2024 hinweist. Der Hämoglobin A1c-Wert liegt im normalen Bereich, was keinen Hinweis auf Diabetes mellitus gibt. Der Nüchtern-Glukosewert ist jedoch leicht erhöht, was eine weitere Überwachung erfordert. Die Schilddrüsenparameter deuten auf eine euthyreote Stoffwechsellage hin. Der Rheumafaktor ist im normalen Bereich, und es gibt keine Hinweise auf eine akute Entzündung, da der CRP-Wert unter 1.0 mg/l liegt. Insgesamt deuten die Ergebnisse auf eine weitgehend normale Organfunktion hin, mit Ausnahme des leicht erhöhten Calcium- und Glukosewerts, die einer weiteren Beobachtung bedürfen.`,
            status: "Nicht abgerechnet",
            pendingTests: ["ANA (anti-nukleäre)", "Anti-Streptodornase B-Reaktion", "Kolesterol HLD", "Parathormon,intakt i.EDTA-PL.", "Anti-Streptodornase B-Reaktion", "Kolesterol HLD", "Parathormon,intakt i.EDTA-PL.", "Anti-Streptodornase B-Reaktion", "Kolesterol HLD", "Parathormon,intakt i.EDTA-PL.", "Anti-Streptodornase B-Reaktion", "Kolesterol HLD", "Parathormon,intakt i.EDTA-PL.", "Anti-Streptodornase B-Reaktion", "Kolesterol HLD", "Parathormon,intakt i.EDTA-PL.", "Anti-Streptodornase B-Reaktion", "Kolesterol HLD", "Parathormon,intakt i.EDTA-PL."],
            statusColor: "danger"
        },
        {
            date: "2024-09-02T23:49:02.771Z",
            title: "KI-BEFUND",
            bornDate: "1965-07-04T23:49:02.771Z",
            aiPureResult:"true",
            isAutoSaveDraft: 0,
            description: `Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte. `,
            status: "Abgerechnet",
            pendingTests: ["ANA (anti-nukleäre)", "Anti-Streptodornase B-Reaktion", "Parathormon,intakt i.EDTA-PL.", "Parapitocina"],
            statusColor: "success"
        },
        {
            date: "2024-12-02T23:49:02.771Z",
            title: "KI-BEFUND",
            bornDate: "1994-12-02T23:49:02.771Z",
            aiPureResult:"true",
            isAutoSaveDraft: 1,
            description: `Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte. Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.Die vorliegenden Laborergebnisse zeigen überwiegend normale Werte.`,
            status: "Nicht abgerechnet",
            pendingTests: ["Parapitocina", "ANA (anti-nukleäre)", "Anti-Streptodornase B-Reaktion", "Kolesterol HLD", "Parathormon,intakt i.EDTA-PL.", "Kolesterol PHDFDS", "ANA (anti-nukleäre)", "Anti-Streptodornase B-Reaktion", "Kolesterol HLD", "Parathormon,intakt i.EDTA-PL.", "Kolesterol PHDFDS", "ANA (anti-nukleäre)", "Anti-Streptodornase B-Reaktion", "Kolesterol HLD", "Parathormon,intakt i.EDTA-PL.", "Kolesterol PHDFDS"],
            statusColor: "danger"
        }
    ];

    return (
    <>
        {
            study.map((result, index) => (
                <div className="review-card" key={`review_${index}`}>
                
                <Row>
                    <Col sm={6} md={6}  className="headerListRow">

                        <span className="review-date ">
                            {moment(result.date ? result.date : result.date).format(`DD-MM-YYYY HH:mm`)}
                        </span>
                        { 
                            result.aiPureResult==="true"?(
                                    <span className="review-ki ">{tEditor("dr-text")}</span>
                                ):(
                                    <span className="review-ki-edited ">{tEditor("dr-text-ai-result")}</span>
                            )
                        }

                    </Col>
                    <Col sm={6} md={6}>
                        <span className="store-info">{tEditor("saved")} <b>18-09-2024 15:02</b> {tEditor("byDr")} <b>Dr. med. Heinz Zurbrüegg</b></span>
                    </Col>
                    <Col md={1} className="align-right">
                        {/*
                            result.isAutoSaveDraft ? (

                                <span className="draft-icon"><Icon file="draft_.svg" /></span>
                            ):(

                                <span className="draft-icon"></span>
                            )
                            */
                        }  
                    </Col>
                </Row>                       
                <Row>
                    <Col lg={9} md={12} sm={12}>
                        <div className="review-content" >{result.description}</div>
                    </Col>
                    <Col lg={3} md={0} sm={0} className="containerFolgen">
                        <div className="review-left-header">
                            <p>{tEditor("pending")}</p>
                        </div>
                        <div className="folgen-section">
                            {result.pendingTests.map( (resultFV, indexFV) => (

                                        <p className="folgen-pill" key={`folgen-pill-${indexFV}`}>{resultFV}</p>
                            ))}
                        </div>
                    </Col>
                </Row>
            </div>
            ))
        }
    </>
    )
}
export default KiLabEditor_stored_list;