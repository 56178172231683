import React from 'react';
import { Table, Input, Button, Row, Col, Label, TabPane } from 'reactstrap';

// Include Translation tool
import { useTranslate } from "react-translate";
import { useSelector } from 'react-redux';

const KiLabEditor_analytes = () => {

    const tEditor = useTranslate('kilab-editor');
    const study = useSelector(state => state.kiLabReducer.study);
    const analytes = study && study.study_data ? JSON.parse(study.study_data.report_data) : [];

    // Genera un array de años desde 1920 hasta el año vigente
    const years = [];
    const currentYear = new Date().getFullYear();

    for (let year = 1920; year <= currentYear; year++) {
        years.push(year);
    }

    return (
            <TabPane tabId="tab-result">
                <Table >
                    <thead>
                        <tr>
                            <th>{tEditor('examination')}</th>
                            <th>{tEditor('result')}</th>
                            <th>{tEditor('unit')}</th>
                            <th>{tEditor('ref-range')}</th>
                            <th>{tEditor('comment')}</th>
                        </tr>
                    </thead>
                    <tbody> 
                        {analytes.map((result, index) => (
                            <tr key={index} className={result.highlight ? "bg-analyte-out-of-range" : ""}>
                                <td>{result['Observation Identifier'].replace(/^([\S]{1,})\s/, "")}</td>
                                <td>{result['Observation Value']}</td>
                                <td>{result['Units']}</td>
                                <td>{result['References Range']}</td>
                                <td>{result['Comment'].map((commentLine, index) => {
                                        return <><p key={index}>{commentLine}</p></>
                                    })}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </TabPane>
    );
}
export default KiLabEditor_analytes;