import moment from 'moment';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useTranslate } from 'react-translate';
import PracticesService from 'services/PracticesService';
/**
 * Example
 * const data = {
 *   labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
 *   datasets: [
 *     {
 *       label: '# of messages',
 *       data: [12, 19, 3, 5, 2, 3],
 *       backgroundColor: 'rgba(54, 162, 235, 0.2)',
 *       borderColor: 'rgba(54, 162, 235, 1)',
 *       borderWidth: 1,
 *     },
 *   ],
 * };
 */
const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  maintainAspectRatio: false,
};
const PracticeMetricsChats = () => {
  const { id } = useParams();
  const t = useTranslate('practices');
  const isMounted = useRef(null);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.userReducer);

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: t('of-messages'),
        data: [],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    isMounted.current = true;
    PracticesService.getPracticeMetrics(id)
      .then((res) => {
        const dataRes = res?.data;
        const buildData = () => {
          if (Array.isArray(dataRes) && dataRes.length > 0) {
            let x = [];
            let y = [];
            dataRes.map((item) => {
              let metric = item.metrics.chat_messages;
              x.push(moment(metric.date).format('MMMM D YYYY'));
              y.push(metric.count);
              return item;
            });
            let newData = { ...data };
            newData.labels = x;
            newData.datasets[0].data = y;
            if (isMounted.current) {
              setData(newData);
              setLoading(false);
            }
          }
        };
        buildData();
      })
      .catch((error) => {
        if (isMounted.current) {
          setLoading(false);
        }
        return toast.error(error.message);
      });
    //eslint-disable-next-line
  }, [id, user?.language]);

  return (
    <>
      <div className="header">
        <h5 className="general-card-subtitle">
          {t('title-practice-metrics-chat')}
        </h5>
      </div>
      <div className="chat-metrics">
        {loading ? <LoadingLocal /> : <Bar data={data} options={options} />}
      </div>
    </>
  );
};

export default React.memo(PracticeMetricsChats);
