import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormGroup, Label, Button } from 'reactstrap';
import { actionSendMessage } from 'store/actions/messagesActions';
import { useForm } from 'react-hook-form';
import { useTranslate } from 'react-translate';

const recipients = (to) => {
  let recipients = '';
  to.tags.forEach((user) => {
    recipients += user.text + ', ';
  });
  if (recipients.length > 0) {
    recipients = recipients.slice(recipients, recipients.length - 2);
  }
  return recipients;
};

const InboxForm = ({ to, showReplyForm, message }) => {
  const t = useTranslate('messages');
  const box = useSelector((state) => state.messagesReducer.box);
  const dispatch = useDispatch();
  const btnReplyRef = useRef(null);
  const defaultValues = {
    to: recipients(to),
    subject: `Re: ${message ? message.subject : ''}`,
    body: `\n\n --- \n\n ${message ? message.body : ''}`,
  };
  const { register, handleSubmit } = useForm({
    defaultValues,
  });
  if (to.tags.length === 0) {
    return null;
  }

  const onSubmit = (data) => {
    let to = JSON.stringify([
      {
        user_id: message.from.auth_user_id,
        type: message.from.type,
      },
    ]);
    let message_answered = JSON.stringify({
      _id: message._id,
      box,
      date: message.date,
    });
    dispatch(actionSendMessage({ ...data, to, message_answered }, false));
    showReplyForm([]);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label for="to">{t('recipients')}</Label>
        <input
          className="form-control"
          type="text"
          name="to"
          placeholder={t('recipients')}
          ref={register}
          readOnly
        />
      </FormGroup>
      <FormGroup>
        <Label for="to">{t('subject')}</Label>
        <input
          className="form-control"
          type="text"
          name="subject"
          id="subject"
          placeholder={t('subject')}
          ref={register}
        />
      </FormGroup>
      <FormGroup>
        <Label for="message">{t('message')}</Label>
        <textarea
          className="form-control"
          type="textarea"
          name="body"
          autoFocus={true}
          ref={register}
        ></textarea>
      </FormGroup>
      <Button id="btn-reply" size="sm" className="btn-info pull-right">
        {t('btn-reply')}
      </Button>
      <Button
        size="sm"
        id="btn-cancel"
        className="btn-danger pull-right"
        onClick={() => showReplyForm([])}
        ref={btnReplyRef}
      >
        {t('btn-cancel')} <i className="fas fa-times" aria-hidden="true"></i>
      </Button>
    </Form>
  );
};

export default InboxForm;
