import React, { useEffect, useRef, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useForm } from 'react-hook-form';
import { useTranslate } from 'react-translate';
import { useDispatch, useSelector } from 'react-redux';
import { actionSweetAlert } from 'store/actions/commonActions';
import { actionChangePassword } from 'store/actions/usersActions';
// reactstrap components
import { Form, Button, Row, Col, InputGroupAddon, InputGroup, Input, InputGroupText } from 'reactstrap';
import * as yup from 'yup';
import { toast } from 'react-toastify';

const FormChangePassword = () => {
  const t = useTranslate('settings');
  const dispatch = useDispatch();
  const isMounted = useRef(true);
  const user = useSelector(state => state.userReducer);
  //Schema
  const userSchema = yup.object().shape({
    oldPassword: yup.string().required(t('error-required-password')).min(6, t('error-required-length')),
    password: yup.string().required(t('error-required-password')).min(6, t('error-required-length')),
    rePassword: yup
      .string()
      .oneOf([yup.ref('password'), null], t('error-required-rePassword')),
  });

  const { register, handleSubmit, errors, formState, setValue, getValues } =
    useForm({
      validationSchema: userSchema,
      mode: "onChange"
    });
  const { touched } = formState;

  const [showCurrentPassword , setShowCurrentPassword] = useState(false);
  const [showOldPassword , setShowOldPassword] = useState(false);
  const [showRePassword , setShowRePassword] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      //Only show the first encountered error
      for (let er of Object.keys(errors)) {
        if (errors[er]) {
          toast.error(errors[er].message);
          break;
        }
      }
    }
    
    //eslint-disable-next-line
  }, [errors]);

  const onSubmit = async (data) => {
    dispatch(
      actionSweetAlert(
        <SweetAlert
          custom
          customIcon="/General/icons/warning-sign-orange.svg"
          title={t('are-you-sure')}
          onConfirm={() => {}}
          customButtons={
            <>
              {/*Custom buttons for more control*/}
              <Button
                className="mb-3"
                color="info"
                size="lg"
                onClick={() => {
                  dispatch(actionSweetAlert(null));
                  dispatch(actionChangePassword(data));
                  localStorage.removeItem('ts-temp-secret'); //Remove tmp password
                }}
              >
                {t('btn-yes')}
              </Button>

              <Button
                className="mb-3 ml-2"
                color="danger"
                size="lg"
                onClick={() => dispatch(actionSweetAlert(null))}
              >
                {t('btn-no')}
              </Button>
            </>
          }
        ></SweetAlert>
      )
    );
  };

  useEffect(() => {
    if (!getValues('oldPassword')) {
      const oldPassword = localStorage.getItem('ts-temp-secret');
      setValue('oldPassword', oldPassword);
    }
  });

  useEffect(() => {
    if (isMounted.current) {
      register({ name: 'oldPassword' });
      register({ name: 'rePassword' });
      register({ name: 'password' });
    }
    return () => {
      isMounted.current = false;
    };
  }, [register, dispatch]);

  return (
    <div className="setting-section">
      <h5 className="general-card-subtitle">{t('change-password')}</h5>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={`${user.password_expired ? "0" : "4"}`}>
            <div
              className={
                touched.oldPassword && errors.oldPassword ? 'has-danger' : ''
              }
            >
              {/*
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img
                      src={'/General/login/password.svg'}
                      width="15"
                      alt=""
                    />
                  </InputGroupText>
                </InputGroupAddon>
                */}
                {/* errors.oldPassword && <div style={{color: "#ff3636"}}>{errors.oldPassword.message}</div> */}

                <InputGroup>
                    <Input
                      type={`${user.password_expired ? "hidden" : showOldPassword ? "text" : "password"}`}
                      ref={register}
                      name="oldPassword"
                      className="form-control mb-0"
                      placeholder={t('old-password') + '...'}
                      onChange={(e) => {setValue("oldPassword", e.target.value)}}
                      readOnly={user.password_expired}
                    />

                  {!user.password_expired && (
                    <InputGroupAddon 
                    addonType="append" 
                    onClick={() => {setShowOldPassword(!showOldPassword)}}
                  >
                    <InputGroupText>
                      <span className={`icon-${showOldPassword ? "invisible" : "show"}`} />
                    </InputGroupText>
                  </InputGroupAddon>
                  )}
                  
                </InputGroup>
              
            </div>
          </Col>
          <Col md="4">
            <div
              className={
                touched.password && errors.password ? 'has-danger' : ''
              }
            >
              {/*
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img
                      src={'/General/login/password.svg'}
                      width="15"
                      alt=""
                    />
                  </InputGroupText>
                </InputGroupAddon>
                */}
                { /*errors.password && <div style={{color: "#ff3636"}}>{errors.password.message}</div>*/ }

              <InputGroup>
                  <Input
                    type={`${showCurrentPassword ? "text" : "password"}`}
                    ref={register}
                    name="password"
                    onChange={(e) => {setValue("password", e.target.value)}}
                    className="form-control mb-0"
                    placeholder={t('password') + '...'}
                  />

                <InputGroupAddon 
                  addonType="append" 
                  onClick={() => {setShowCurrentPassword(!showCurrentPassword)}}
                >
                  <InputGroupText>
                    <span className={`icon-${showCurrentPassword ? "invisible" : "show"}`} />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </Col>
          <Col md="4">
            <div
              className={
                touched.rePassword && errors.rePassword ? 'has-danger' : ''
              }
            >
              {/*
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons ui-1_lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                */}
                { /*errors.rePassword && <div style={{color: "#ff3636"}}>{errors.rePassword.message}</div>*/ }
                <InputGroup>
                  <Input
                    type={`${showRePassword ? "text" : "password"}`}
                    ref={register}
                    name="rePassword"
                    onChange={(e) => {setValue("rePassword", e.target.value)}}
                    className="form-control mb-0"
                    placeholder={t('re-password') + '...'}
                  />

                <InputGroupAddon 
                  addonType="append" 
                  onClick={() => {setShowRePassword(!showRePassword)}}
                >
                  <InputGroupText>
                    <span className={`icon-${showRePassword ? "invisible" : "show"}`} />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </Col>
        </Row>

        <div className="text-right pt-4">
          <Button type="submit" color="info" size="sm" className="mb-3">
            {t('change-password')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormChangePassword;
