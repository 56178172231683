import React, { useEffect, useState, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';

const ChatRoomStatus = ({ main_user , filter_select}) => {
  const isMounted = useRef();
  const [status, setStatus] = useState('offline');
  const practitioner = main_user;
  const [statusDisafected, setStatusDisafected] = useState(false);
  const { practice } = useSelector(
    (state) => state.practicesReducer
  );
  const user = useSelector((state) => state.userReducer);

  useEffect(() => {
  
    let status = practitioner?.status;
    isMounted.current = true;
    status = ['online', 'offline', 'partial', 'inactive'].includes(status)
      ? status
      : 'offline';
    if (isMounted.current) {
      setStatus(status);
    }
    return () => {
      isMounted.current = false;
    };
  //eslint-disable-next-line
  }, [practitioner?.updated_at, practitioner?.status, practitioner?.isDisaffected]);

  useEffect(() => {
    //check if user looged is disaffected in te current practice
    if (user && practice && practice.people) {
      var res = practice.people.filter(function (us) {
        return us.email === user.email && us.type === "doctor";

      });
      //check status
      if(res && res.length && res[0].isDisaffected === true){
        setStatusDisafected(true);
      }else{
        setStatusDisafected(false);
      }
    }
    //eslint-disable-next-line
  }, [user, practice]);

  return (
    <>
      <ReactTooltip
        id={`practitoner-status-${practitioner?._id}`}
        aria-haspopup="true"
        effect="float"
        type="dark"
        place="bottom"
        />
        {statusDisafected === false && filter_select === false ?
          <div
          className={`chat-status ${status}`}
          data-tip={status === 'partial' ? 'Busy' : status.charAt(0).toUpperCase() + status.slice(1)}
          data-for={`practitoner-status-${practitioner?._id}`}
        ></div>
        :
        <div/>
      }

     
    </>
  );
};

export default React.memo(ChatRoomStatus, (prevProps, nextProps) => {
  return prevProps?.main_user?.status === nextProps?.main_user?.status;
});

