import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';

const Inbox = () => {

  const [data, setData] = useState([]);

  useEffect(() => {
    let newData = data;

    for (let i = 0; i < 20; i++) {
      newData.push({
        status: "Not review",
        study: "Palpitation",
        received: "20.02.2021 14:23",
        patient: "Mustermann",
        carriedOn: "20.02.2021 13:05",
        sentOn: "20.02.2021 14:05"
      });
    }

    setData(newData);
    //eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <ReactTable
        data={data}
        
        columns={[
          {
            Header: 'Status',
            accessor: 'status',
          },
          {
            Header: 'Study',
            accessor: 'study',
          },
          {
            Header: 'Received on',
            accessor: 'received',
          },
          {
            Header: 'Patient',
            accessor: 'patient',
          },
          {
            Header: 'Carried out on',
            accessor: 'carriedOn',
          },
          {
            Header: 'Diagnostic sent on',
            accessor: 'sentOn',
          }
        ]}
        className="-striped -highlight"
        defaultPageSize={5}
      />

    </React.Fragment>
  );
};

export default Inbox;
