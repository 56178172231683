import CONSTANTS from '../CONSTANTS';

const avatarsReducer = (state = {}, action) => {
  switch (action.type) {
    case CONSTANTS.SET_AVATARS:
      return action.avatars;
    case CONSTANTS.PUSH_AVATAR:
      let newState = { ...state };
      newState[action.avatar] = action.response;
      return newState;
    default:
      return state;
  }
};

export default avatarsReducer;
