import React from 'react';
import ChatInbox from 'components/Chats/ChatInbox';
import ChatSendInput from 'components/Chats/ChatSendInput';
import ChatHeaderMobile from 'components/Chats/Mobile/ChatHeaderMobile';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionChatMessageRead, actionSetChat, actionChatShowInbox } from 'store/actions/chatsActions';
import { actionReadNotificationsByMessages } from 'store/actions/notificationsActions';
import usePageVisibility from 'hooks/usePageVisibility';
//import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';

const ChatInboxMobile = ({ parent, chatId, setEditChatMembers, editChatMembers, replyData, setReplyData, isDisaffected }) => {
  const dispatch = useDispatch();
  const chat = useSelector((state) => state.chatsReducer.chat);
  const visibility = usePageVisibility();
  const { practice } = useSelector(
    (state) => state.practicesReducer
  );

  const [scrollSet, setscrollSet] = useState('update');

  const setScrollInbox=() =>{
    let status=scrollSet === 'update' ? 'new update' : 'update';
    setscrollSet(status);
   }

  useEffect(() => {
    if (['private', 'group'].includes(chat.type) && chat && chatId === chat._id && visibility === "visible") {
      dispatch(actionChatMessageRead(chatId));

      //Mark all messages for current chat as readed
      const messageList = chat?.messages?.map(m => {
        return m._id;
      });
      dispatch(actionReadNotificationsByMessages("chats", messageList));
    }
    //eslint-disable-next-line
  }, [chatId, chat.date_last_message, visibility]);

  useEffect(() => {
    if (!chat?._id || (['private', 'group'].includes(chat.type) && chat.practice._id !== practice?._id)) {
      dispatch(actionSetChat({}));
      dispatch(actionChatShowInbox(false));
    }  
    //eslint-disable-next-line
  }, [chat, practice]);

  /*
  if (!chat?._id) {
    return <LoadingLocal />;
  }
  */

  return (
    <div className="chat-body-container">
      <div className="chat-inbox-container-mobile">
        <ChatHeaderMobile 
          setEditChatMembers={setEditChatMembers} 
          editChatMembers={editChatMembers}
          filter_select={isDisaffected}
        />
         <div className="new-mobile">
          <ChatInbox parent={parent} chatId={chatId} replyData={replyData} setReplyData={setReplyData} scrollSet={scrollSet}/>

          {!isDisaffected ? (
            <>
              <ChatSendInput replyData={replyData} setReplyData={setReplyData} setScroll={setScrollInbox} />
            </>
          ) : (
            <>
              <div className="input_msg_write textarea-disabled">
                <textarea
                  type="text"
                  name="body"
                  className="write_msg text-area-control"
                  disabled={true}

                />
              </div>
            </>
          )}
          
         </div>
         
      </div>
    </div>
  );
};

export default ChatInboxMobile;
