import React, { useEffect, useRef, useState } from 'react';
//import { useSelector } from 'react-redux';
import ChatRoomStatus from 'components/Chats/ChatRoomStatus';
import MultimediaService from 'services/MultimediaService';

const ChatRoomAvatar = (props) => {
  const { type, main_user, filter_select } = props;
  //const { isDisaffected } = useSelector((state) => state.userReducer);
  const isMounted = useRef();
  const [defaultAvatar, setDefaultAvatar] = useState(null);
  //const [defaultStatus, setDefaulStatus] = useState(null);

  const updated_at = main_user ? main_user?.updated_at : null;

  useEffect(() => {   
    isMounted.current = true;
    if (main_user?.avatar) {
      //setDefaulStatus(isDisaffected);
      MultimediaService.getAvatar(main_user.avatar, 'small')
        .then((response) => {
          if (isMounted.current && response) {
            setDefaultAvatar(response);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setDefaultAvatar(null);
    }
    return () => {
      isMounted.current = false;
    };
  //eslint-disable-next-line
  }, [updated_at, main_user?.avatar]);

  /* For private chats */
  if (type !== 'group') {
    return (
      <div className="chat_img">
        {!defaultAvatar ? (
          <div className="avatar-name-large">
            <span>
              {main_user?.nameInitials ? main_user.nameInitials : '...'}
            </span>
          </div>
        ) : (
          <img src={defaultAvatar} alt="Contact" />
        )}
      
        <ChatRoomStatus main_user={main_user} filter_select= {filter_select}/>
        
        
      </div>
    );
  }
  /* For group chats */
  return (
    <div className="chat_img">
      <img src={'/General/avatar-group.svg'} alt="Contact" />

      {/* Retain the padding and margin */}
      <div className="no-status"></div>
    </div>
  );
};

export default React.memo(ChatRoomAvatar, (prevProps, nextProps) => {
  return prevProps?.main_user?.updated_at === nextProps?.main_user?.updated_at;
});
