
import React, { useState, useEffect } from 'react';

import InboxList from './InboxList';

const Inbox = (props) => {

  const { practice } = props;
  const [mobile, setMobile] = useState(false);

  const [isOpen, setIsOpen] = useState(true);

  const updateMobile = () => {
    if (window.innerWidth < 992) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const toggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMobile);
    return () => window.removeEventListener('resize', updateMobile);
  }, [mobile]);

  return <InboxList isOpen={isOpen} toggle={toggle} practice={practice}/>;
};

export default Inbox;
