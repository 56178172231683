import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import { Label, FormGroup, Input } from 'reactstrap';
import ChatPeopleAvatar from './ChatPeopleAvatar';

const ChatPeopleListVertical = ({
  selected,
  chatSearch,
  setEditChatMembers,
}) => {
  const t = useTranslate('chat');
  const chat = useSelector((state) => state.chatsReducer.chat);
  const practice = useSelector((state) => state.practicesReducer.practice);

  //Temporal fix for live updates
  const practitioners = (!practice || !chat) ?
  [] :
  practice.people.filter((practitioner) => {
    if (!practitioner.type === "doctor") {
      return false;
    }
    if (chat.users && chat.users.length > 0) {
      const memberIndex = chat.users.findIndex(
        (member) =>
          member.auth_user_id === practitioner.auth_user_id && !member.deleted
      );
      return memberIndex === -1;
    }
    return false;
  }).sort((l, r) => {
    const compareRes = l.lastName.localeCompare(r.lastName);
    return compareRes ? compareRes : l.firstName.localeCompare(r.firstName);
  });


  let initialLetter = '';

  const handleSelect = (e) => {
    const el = e.target;
    const id = el.id.split('-')[1];
    if (!el.checked) {
      let exist = selected.current.findIndex((user) => user.user_id === id);
      if (exist > -1) {
        selected.current.splice(exist, 1);
        //setSelected(selected);
      }
      return null;
    }
    selected.current = [...selected.current, { user_id: id, type: el.value }];
  };

  /**
   * Searcher
   */
  const searcher = (value, chatSearch) => {
    if (chatSearch) {
      let searchList = chatSearch
        .toLowerCase()
        .replace(/\b\w{1,2}\b/g, ' ')
        .replace(/\s{2,}/g, ' ')
        .replace(/[^\p{L}\p{N}_\s\d]/gu, ' ')
        .trim()
        .replace(/\s/g, '|');

      let regex = new RegExp(`${searchList}`, 'g');
      return value.toLowerCase().match(regex);
    }
    return false;
  };

  const isChecked = (id) => {
    return selected.current.findIndex((user) => user.user_id === id) >= 0;
  };

  useEffect(() => {
    return () => {
      let checkboxes = document.getElementsByClassName('check-user-chat');
      Array.prototype.filter.call(checkboxes, (checkbox) => {
        checkbox.checked = false;
      });
      setEditChatMembers(false);
    };
  }, [setEditChatMembers]);

  return (
    <div className="inbox-container">
      <div className="chat-people-vertical">
        {practitioners &&
          practitioners.map((user) => {
            let initial = null;
            let value =
              user.title +
              ' ' +
              user.firstName +
              ' ' +
              user.lastName +
              ' ' +
              user.nameInitials;
            if ((chatSearch && !searcher(value, chatSearch)) || user.isDisaffected || user.type !== "doctor") {
              return null;
            }

            if (initialLetter !== user.lastName.substring(0, 1)) {
              initialLetter = user.lastName.substring(0, 1).toUpperCase();
              initial = (
                <div className="people-list-letter" key={user._id}>
                  {initialLetter}
                </div>
              );
            }
            return (
              <React.Fragment key={user.auth_user_id}>
                {initial ? initial : null}
                <label
                  className="d-flex align-items-center people-list-user justify-content-between"
                  htmlFor={`label-${user.auth_user_id}`}
                >
                  <div className="d-flex align-items-center">
                    <ChatPeopleAvatar user={user} showStatus />
                    <div className="ml-1 d-flex flex-column">
                      <span>
                        {user.lastName}, {user.firstName}
                      </span>
                      {user.status === 'inactive' && (
                        <span className="chat-label-inactive">
                          {t('user-inactive')}
                        </span>
                      )}
                    </div>
                  </div>
                  {user.status !== 'inactive' && (
                    <FormGroup className="ml-auto mr-5 mb-4" check>
                      <Label check>
                        <Input
                          defaultChecked={isChecked(user.auth_user_id)}
                          type="checkbox"
                          id={`label-${user.auth_user_id}`}
                          value={user.type}
                          className="check-user-chat"
                          onChange={handleSelect}
                        />
                        <span className="form-check-sign" />
                      </Label>
                    </FormGroup>
                  )}
                </label>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default ChatPeopleListVertical;
