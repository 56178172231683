
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch  } from 'react-redux';
// import SweetAlert from "react-bootstrap-sweetalert";
// reactstrap components
import { Alert, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import moment from 'moment';
// import { actionDelMessage } from "store/actions/messagesActions";
// import { actionSweetAlert } from "store/actions/commonActions";
import InboxFileList from 'components/Messages/View/InboxFileList';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import { Fragment } from 'react';
import { useTranslate } from 'react-translate';
import { actionReadNotificationsByMessages } from 'store/actions/notificationsActions';

const InboxMobileDetail = ({callback, Idisafected}) => {
  const t = useTranslate('messages');
  const dispatch = useDispatch();
  const { message, loading_msg } = useSelector(
    (state) => state.messagesReducer
  );
  const { practice } = useSelector((state) => state.practicesReducer);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  //Function to calculate the height of the message header and save it as a CSS var, for calculating
  const calcHeight = () => {
    let root = document.documentElement;
    const subject = document.getElementById("subject");
    const inbox_from = document.getElementById("inbox-message-from");

    if (subject && inbox_from) {
      let title = subject.clientHeight;
      let from = inbox_from.clientHeight;
      root.style.setProperty('--mth', `${title + from}px`);
    }
  }

  useEffect(() => {
     //check if my contact if an user disafected of the practice
    if (practice && practice.people && message && Idisafected === false) {    
      
      //var contact = box ==="outbox" ?  message.to[0].email : message.from.email ; 

      var res = practice.people.filter(function (us) {
        return us.email === message.from.email;
      });
      callback(res && res.length && res[0].isDisaffected === true);
    }
    //eslint-disable-next-line
  }, [ practice,message]);

  useEffect(() => {
    if (message) {
      dispatch(actionReadNotificationsByMessages("messages", [message._id]));
      calcHeight();
    }

    return () => {
      // hide:true;
    };
  }, [loading_msg, message]);

  if (loading_msg) {
    return <LoadingLocal />;
  }

  if (!loading_msg && !message) {
    return (
      <Row>
        <Col sm={12} className="p-5">
          <Alert color="info">Select a Message</Alert>
        </Col>
      </Row>
    );
  }

  const formatText = (text) => {
    return text?.split('\n').map((item, i) => {
      return (
        <React.Fragment key={i}>
          {item}
          <br />
        </React.Fragment>
      );
    });
  };

  return (
    <Fragment>

      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{t('attachments')}</ModalHeader>
        <ModalBody>
          <InboxFileList files={message.files ? message.files : []} />
        </ModalBody>
      </Modal>

      {message && (
        <div className="inbox-mobile-detail">
          <Row>
            <Col sm={12}>
              <div className="inbox-mobile-detail-header pt-3 pb-3">
                <div className="pt-1 pb-1 pl-2 pr-2">
                  <div className="inbox-message-from" id="inbox-message-from">
                    <span>{message.from.firstName} {message.from.lastName}</span>
                    <span className="message-date ml-5">
                      {`${moment(message.date).format('D MMM YYYY HH:mm')} (${moment(message.date).fromNow()})`}
                    </span>
                  </div>

                  <div className='d-flex'>
                    <div className="inbox-message-subject" id="subject">{message.subject}</div>
                    {message.files && message.files.length ? (
                      <div 
                      className="icon-attach1 msg-options"
                      onClick={toggle}
                      >
                      </div>
                    ) : <></>}

                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12}>
              <div className="inbox-message-text p-3 overflow-auto">
                <p className="text-justify mobile-inbox-detail">{formatText(message.body)}</p>

                {message.thread?.reverse().map((th) => {
                    return (
                    <Col sm={12} key={th.date} className="message-reply">
                        <Col sm={12}>
                          <div className="thread-message-header">
                            <span>{th.from.firstName} {th.from.lastName}</span>
                            <span className="message-date ml-5">
                              {`${moment(th.date).format('D MMM YYYY HH:mm')} (${moment(th.date).fromNow()})`}
                            </span>
                          </div>
                          <p className="text-justify">{formatText(th?.body)}</p>
                        </Col>
                        <Col sm={12}>
                        <InboxFileList files={th.files ? th.files : []} />
                        </Col>
                    </Col>
                    )
                })}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Fragment>
  );
};

export default InboxMobileDetail;
