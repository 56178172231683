// Page available when the user has permissions, this page allow view the chats and messages of inbox

// import Appointments from 'components/Appointments/Appointments';
// import AppointmentsYours from 'components/Appointments/AppointmentsYours';
// import ChatBox from 'components/Chats/ChatBox';
// core components
import Inbox from 'components/Messages/Inbox';
// import WaitingRoom from 'components/Physicians/WaitingRoom';
import React, { useEffect, useState } from 'react';
// reactstrap components
import { Card, CardBody, Col, Row } from 'reactstrap';
import useAuthorizationValidator from 'plugins/TS-lib-utils-public/hooks/useAuthorizationValidator';
import { useSelector, useDispatch } from 'react-redux';
import ChatBoxMobile from 'components/Chats/Mobile/ChatBoxMobile';
import { actionDasboardMobileVersion } from 'store/actions/commonActions';
import { actionSetUserPracticecDisaffected } from 'store/actions/practicesActions';
import {actionGetChatsDisaffected } from 'store/actions/chatsActions';

const Dashboard = () => {
  const dispatch = useDispatch();

    /*
  const { mobile, dashboardMobile } = useSelector(
    (state) => state.commonReducer
  );
  */

  const { checkAccess } = useAuthorizationValidator();
  const allowInbox = checkAccess('dashboard','show-inbox');
  const allowChat = checkAccess('dashboard', 'show-chat');
  // const allowWR = useAuthorizationValidator("waiting-room");
  // const allowAppY = useAuthorizationValidator("appointments-your");
  // const allowAppo = useAuthorizationValidator("appointments-dashboard");

  //eslint-disable-next-line 
  const user = useSelector((state) => state.userReducer);
    //state global current user
  const { practice, isDisaffected } = useSelector((state) => state.practicesReducer);
  const [userAuth, setuserAuth] = useState('');
  const [ctrlDisaffected, ctrlIsDisaffected] = useState(null);


  useEffect(() => {
    //check if user looged is disaffected in te current practice
    if (user && practice && practice.people) {
      let res = practice.people.find((us) => {
        return String(us.auth_user_id) === String(user.auth_user_id);
      });

      //check status
      setuserAuth(res ? res.auth_user_id : {});
      
      if(res && isDisaffected === true){
        dispatch(actionSetUserPracticecDisaffected(true));
        ctrlIsDisaffected(isDisaffected);
        dispatch(actionGetChatsDisaffected(true, res.auth_user_id, '1'));
      }else{
        dispatch(actionSetUserPracticecDisaffected(false));
        ctrlIsDisaffected(isDisaffected);
      }
    }
    //eslint-disable-next-line
  }, [practice]);

  useEffect(() => {
    //For load widget mobile version
    const handleWidth = (e) => {
      if (window.innerWidth < 1950) {
        return dispatch(actionDasboardMobileVersion(true));
      }
      return dispatch(actionDasboardMobileVersion(false));
    };
    window.addEventListener('resize', handleWidth);
    return () => window.removeEventListener('resize', handleWidth);
  });

  return (
    <Card>
      <CardBody>
        <Row>
          <Col sm="12" md="6">
            {/* {allowWR && <WaitingRoom />}
                  {allowAppY && <AppointmentsYours />} */}
            {/* <WaitingRoom />
              <AppointmentsYours /> */}
            {allowChat && (
              <div className={'chat-dashboard'}>
                {/* {mobile || dashboardMobile ? (
                  <ChatBoxMobile view="dashboard" isDisaffected={isDisaffected} practice={practice} userAuth= {userAuth} />
                ) : (
                  <ChatBox view="dashboard" isDisaffected={isDisaffected} practice={practice} userAuth= {userAuth} />
                )} */}

            {/* Now we will always use the mobile view, so we have good visibility of chats and inbox */}
            <ChatBoxMobile view="dashboard" practice={practice} userAuth= {userAuth} isDisaffected = {ctrlDisaffected}/>
                
              </div>
            )}
          </Col>
          <Col sm="12" md="6">
            {allowInbox && <Inbox practice={practice} />}
            {/* {allowChat && <ChatBox />}
                  {allowAppo && <Appointments />} */}
            {/* <Appointments /> */}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Dashboard;
