import React, { useEffect, useRef } from 'react';
import { useFileUtils } from 'hooks';

const UserAvatar = ({ user }) => {
  const isMounted = useRef();
  /**
   * Load avatar
   */
  const { getAvatar, setFile, defaultImage } = useFileUtils();

  useEffect(() => {
    isMounted.current = true;
    if (user.avatar) {
      const avatarResponse = getAvatar(`${user.avatar}`, 'small');
      avatarResponse.then((avatar) => {
        if (isMounted.current && avatar) {
          setFile({ file: avatar });
        }
      });
    }
    return () => isMounted.current = false;
    //eslint-disable-next-line
  }, []);


  if (defaultImage.includes('General')) {
    return (
      <div className="avatar-name-large">
        <span>{user.nameInitials}</span>
      </div>
    );
  }
  return (
    <div className="avatar-name-large no-background">
      <img src={defaultImage} alt="Contact" />
    </div>
  );
};

export default UserAvatar;
