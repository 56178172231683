import React, { useState } from 'react';
import moment from 'moment';
import MsgSystem from './MsgSystem';
import MsgAvatar from './MsgAvatar';
import { useSelector } from 'react-redux';
import MessageHoverModal from 'components/MessageHoverModal/MessageHoverModal';
import EmojisReactionsData from 'components/Emojis/EmojisReactionsData';
import { useTranslate } from 'react-translate';
import Linkify from 'react-linkify';

const MsgIncoming = ({
  parent,
  message,
  sameSender,
  sameTime,
  setReplyData,
}) => {
  const [showEmojiOptions, setShowEmojiOptions] = useState(false);
  const { mobile, dashboardMobile } = useSelector(
    (state) => state.commonReducer
  );
  const t = useTranslate('chat');
  const { auth_user_id } = useSelector((state) => state.userReducer);
  const { isDisaffected } = useSelector((state) => state.practicesReducer);
  const { chat } = useSelector((state) => state.chatsReducer);
  const currentUser = chat ? chat?.users?.find(u => String(u.auth_user_id) === String(auth_user_id)) : null;
  /**
   * For system messages
   */
  if (message.action !== 'default') {
    return <MsgSystem message={message} />;
  }

  return (
    <>
      <div
        className="incoming_msg animate__animated animate__fadeIn"
        data-id={message._id}
      >
        {!sameSender &&
          !(mobile || (parent === 'dashboard' && dashboardMobile)) && (
            <MsgAvatar user={message.sender} avatarClass={'avatar-incoming'} />
          )}
        <div className="received_msg">
          {showEmojiOptions && !isDisaffected && !currentUser?.deleted && (
            <MessageHoverModal
              show={showEmojiOptions}
              setShow={setShowEmojiOptions}
              id={`chat-message-${message._id}`}
              message={message}
              setReplyData={setReplyData}
              type="in"
            />
          )}
          <div className="message-header d-flex justify-content-between align-items-center">
            {!sameSender && (
              <span className="sender">
                <strong>
                  {message.sender.firstName + ' ' + message.sender.lastName}
                </strong>
              </span>
            )}
            <EmojisReactionsData message={message} />
          </div>

          {message.reply && (
            <>
              <div className="sent_quote_author">
                {`${message.reply.author} ${t('chat-said')}:`}
              </div>

              <div className="received_quote">{message.reply.body}</div>
            </>
          )}

          <div
            className={`received_container${message.reply ? '_reply' : ''}`}
            onClick={() => setShowEmojiOptions(!showEmojiOptions)}
          >
            {/* Linkfy allow send links */}
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            ><p className="break-line" id={`chat-message-${message._id}`}>{message.body || ''}</p>
            </Linkify>
          </div>

          {!sameTime && (
            <span className="time_date">
              {moment(message.date).format('H:mm')} |{' '}
              {moment(message.date).format('MMMM D')}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(MsgIncoming);
