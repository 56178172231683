import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTable } from 'react-table';

import PractitionerStatus from 'components/Practice/PractitionerStatus';

//actions
//import { actionGetPractice } from 'store/actions/practicesActions';
import { useTranslate } from 'react-translate';
import { actionDeletePractitioner } from 'store/actions/practicesActions';
import { actionEditPractitioner } from 'store/actions/practicesActions';

//options of User of the preactice

const UserOptions = (props) => {
  const t = useTranslate('practices');
  const customRoleIdRef = useRef(null);
  const userActive = useSelector(state => state.userReducer);
  const { practice } = useSelector(state => state.practicesReducer);
  const { practitioner } = props;
  
  const dispatch = useDispatch();
  const [dropdownOpen, setOpen] = useState(false);
  
  const [sweetAlert, setSweetAlert] = useState(null);

  const toggle = () => setOpen(!dropdownOpen);

 

  const removePractitioner = (e) => {
    e.preventDefault();
    setSweetAlert(
      <SweetAlert
        question
        title={`${t('sweet-alert-remove-to')} 
        ${practitioner.title} 
        ${practitioner.firstName} 
        ${practitioner.lastName} ?`}
        onConfirm={() => {
          dispatch(actionDeletePractitioner(practitioner._id));
          setSweetAlert(null);
        }}
        onCancel={() => {
          setSweetAlert(null);
        }}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="info"
        confirmBtnText={t('btn-yes')}
        cancelBtnText={t('btn-no')}
        showCancel
      >
        {t('sweet-alert-remove-user')}
      </SweetAlert>
    );
  };

  const changeRolePractitioner = (e) => {
    e.preventDefault();
    setSweetAlert(
      <SweetAlert
        question
        title={`${t('change-role-to')} 
        ${practitioner.title} 
        ${practitioner.firstName} 
        ${practitioner.lastName} ?`}
        onConfirm={() => {}}
        showCancel={false}
        showConfirm={false}
      >
        <FormGroup>
          <Label for="customRoleId">{t('select-custom-role')}</Label>
          <Input
            type="select"
            name="custom_role_id"
            id="customRoleId"
            defaultValue={practitioner.custom_role_id}
            innerRef={customRoleIdRef}
          >
            {Array.isArray(practice.custom_role) ? (
              practice.custom_role.map((role, index) => (
                <option value={role._id} key={index}>
                  {role.name}
                </option>
              ))
            ) : (
              <option>{t('no-options')}</option>
            )}
          </Input>
        </FormGroup>
        <Button
          className="mb-3"
          color="info"
          size="lg"
          onClick={() => {
            if (customRoleIdRef.current.value) {
              dispatch(
                actionEditPractitioner({
                  ...practitioner,
                  custom_role_id: customRoleIdRef.current.value,
                })
              );
            }
            setSweetAlert(null);
          }}
        >
          {t('btn-yes')}
        </Button>

        <Button
          className="mb-3 ml-2"
          color="danger"
          size="lg"
          onClick={() => setSweetAlert(null)}
        >
          {t('btn-no')}
        </Button>
      </SweetAlert>
    );
  };

  if (userActive.auth_user_id === practitioner.auth_user_id) {
    return <></>;
  }

  return (
    <>
      {sweetAlert}
      <ButtonDropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className={props.className}
      >
        <DropdownToggle caret color="link"></DropdownToggle>
        <DropdownMenu>
          {/* <DropdownItem>Edit User</DropdownItem> */}
          <DropdownItem onClick={changeRolePractitioner}>
            {t('option-change-role')}
          </DropdownItem>
          <DropdownItem onClick={removePractitioner}>
            {t('option-remove-user')}
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </>
  );
};

const PractitionerList = (props) => {
  const t = useTranslate('practices');
  const dispatch = useDispatch();
  //const dispatch = useDispatch();
  const searchInputRef = useRef(null);
  const initialRef = useRef(false);
  const practicioners = props.practicioners;
  const practice = props.practice;
  // const { practice, practices,  } = useSelector(state => state.practicesReducer);

  // const [datPractices, setDataPractices] = useState([]);
  const [dataPractitioners, setdataPractitioners] = useState( [...practicioners] );

  const [searchText, setSearchText] = useState('');
  const [roleSort, setRoleSort] = useState(0);
  const [statusSort, setStatusSort] = useState(0);
  const roles = practice.custom_role;

  //Keys to sort by status
  const statusKeys = {
    active: t('practitioner-status-active'),
    online: t('practitioner-status-active'),
    offline: t('practitioner-status-active'),
    waiting: t('practitioner-status-waiting'),
    inactive: t('practitioner-status-inactive'),
    accepted: t('practitioner-status-accepted'),
    rejected: t('practitioner-status-rejected'),
    failed: t('practitioner-status-failed'),
    disaffected: t('practitioner-status-deleted'),
  };
  
  const clearInput = () => {
    setSearchText('');
    document.getElementById('practitionerFilter').value = '';
  }

  const searcher = (value, search) => {
    const searchList = search
      .toLowerCase()
      .replace(/\b\w{1,2}\b/g, ' ')
      .replace(/\s{2,}/g, ' ')
      .replace(/[^\p{L}\p{N}_\s\d]/gu, ' ')
      .trim()
      .replace(/\s/g, '|');

    const regex = new RegExp(`${searchList}`, 'g');
    return value.toLowerCase().match(regex);
  };

  const getRole = (id) => {
    const role = roles.find((rol) => id === rol._id);

    return role;
  };

  const handlePermissionCheckbox = (e, practitioner) => {
    e.preventDefault();
    dispatch(actionEditPractitioner({
      ...practitioner,
      patient_reply_permission: !practitioner.patient_reply_permission,
    }));
  };

  const handleAIFeatureCheckbox = (e, practitioner) => {
    e.preventDefault();
    dispatch(actionEditPractitioner({
      ...practitioner,
      ai_feature_enabled: !practitioner.ai_feature_enabled,
    }));
  };

  const sortByRole = () => {
    if (roleSort) {
      setdataPractitioners([...dataPractitioners.sort((l,r) => {
        const leftRole = getRole(l.custom_role_id);
        const rightRole = getRole(r.custom_role_id);
        const result = leftRole.name.localeCompare(rightRole.name);

        //Priorize results that matches the sort value, else, invert the result or return 0 (equal)
        return result ? result !== roleSort ? result * roleSort : result === roleSort : 0;
      })]);
    }
  }

  const sortByStatus = () => {
    if (statusSort) {
      setdataPractitioners([...dataPractitioners.sort((l,r) => {
        const leftStatus = statusKeys[l.isDisaffected ? "disaffected" : l.status];
        const rightStatus = statusKeys[r.isDisaffected ? "disaffected" :r.status];
        const result = leftStatus ? leftStatus.localeCompare(rightStatus) : 0;

        //Priorize results that matches the sort value, else, invert the result or return 0 (equal)
        return result ? result !== statusSort ? result * statusSort : result === statusSort : 0;
      })]);
    }
  }

  const sortByText = () => {
    setdataPractitioners( searchText && searchText.length > 2 ?  
      [...practicioners.filter(p => { 
        const value =
        p.title +
        ' ' +
        p.firstName +
        ' ' +
        p.lastName +
        ' ' +
        p.nameInitials;
        
        return searcher(value, searchText);
    })] : [...practicioners] );
  }

  const sortByName = () => {
    if (!roleSort && !statusSort) {
      setdataPractitioners([...dataPractitioners.sort((l,r) => {
        const compareRes = l.lastName.localeCompare(r.lastName);
        return compareRes ? compareRes : l.firstName.localeCompare(r.firstName);
      })]);
    }
  }

  useEffect(() => {
    setdataPractitioners([...practicioners]);
    sortByName();
    sortByText();
    sortByRole();
    sortByStatus();
    
  }, [practicioners]);

  useEffect(() => {
    if (!initialRef.current) {
      clearInput();
    }
    return () => {
      initialRef.current = false;
    }
  }, []);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  //eslint-disable-next-line
  }, [practice]);

  useEffect(() => {
    sortByText();
  //eslint-disable-next-line
  }, [searchText]);

  //Sort based on roles
  useEffect(() => {
    sortByRole();
  //eslint-disable-next-line
  }, [roleSort]);

  //Sort based on status
  useEffect(() => {
    sortByStatus();
  //eslint-disable-next-line
  }, [statusSort]);

  useEffect(() => {
    sortByName();
  //eslint-disable-next-line
  }, [roleSort, statusSort]);
  

  /*
  const userAuthorization = useSelector(
    (state) => state.userAuthorizationReducer
  );
  const practiceId =
    typeof userAuthorization._id !== 'undefined' ? userAuthorization._id : null;
  useEffect(() => {
    if (practiceId) {
      dispatch(actionGetPractice(practiceId));
    }
  }, [dispatch, practiceId]);
  */

  // useEffect(() => {
  //   //check if user looged is disaffected in te current practice
  //   if (practices.length > 0) {
  //     var res = practices.filter(function (pr) {
  //       return pr._id === '000000000000000000000201'

  //     });
  //     setDataPractices(res[0]);
  //     setdataPractitioners(res[0].practitioners);
      
  //   }
  //   //eslint-disable-next-line
  // }, [practices]);

  return (
    <>
      <Row>
        <Col md={6}>
          <Input 
            type="text"
            id="practitionerFilter"
            placeholder={t('field-search-user')}
            onKeyUp={(e) => {
              setSearchText(e.target.value);
            }}
            ref={searchInputRef}
         />
        </Col>
      </Row>
      <br/>
      
      <div className="mobile-x-overflow">
        <Table>
          <tbody>
            <tr>
              <th>{t('th-name')}</th>
              {/* <th>Username</th> */}
              <th>{t('th-title')}</th>

              {/* <th>Speciality</th> */}
              <th
                className="sortable-header"
                onClick={(e) => {
                  setRoleSort(!roleSort ? 1 : roleSort === 1 ? -1: 0);
                  setStatusSort(0);
                }}
              >
                {t('th-role')}
                <span>
                  <b className={`${!roleSort ? "caret-default" : roleSort === 1 ? "caret-asc" : "caret-desc"}`}></b>
                </span>
              </th>

              <th
                className="sortable-header"
                onClick={(e) => {
                  setStatusSort(!statusSort ? 1 : statusSort === 1 ? -1: 0);
                  setRoleSort(0);
                }}
              >
                {t('th-status')}
                <span>
                  <b className={`${!statusSort ? "caret-default" : statusSort === 1 ? "caret-asc" : "caret-desc"}`}></b>
                </span>
              </th>

              <th>
                {t('th-reply-permission')}
              </th>

              <th>
                {t('ai-feature')}
              </th>

            </tr>
            {dataPractitioners &&
              dataPractitioners.map((practitioner, i) => {
                const role = getRole(practitioner.custom_role_id);
                return (
                  <React.Fragment key={i}>
                    <tr>
                      <td className="d-flex">

                        <div className="pr-name">
                          <span>{`${practitioner.lastName}, ${practitioner.firstName}` || ''}</span>
                          <span className="pr-email">{practitioner.email || ''}</span>
                        </div>

                        {!practitioner.isDisaffected && (<UserOptions className="ml-auto" practitioner={ practitioner }/>)}

                      </td>
                      <td className="text-capitalize">{practitioner.title || ''}</td>
                      {/* <td>{practitioner.speciality || ''}</td> */}
                      <td className="text-capitalize">
                        {role.name || ''}
                        </td>
                      <td>
                        <PractitionerStatus
                          status={practitioner.isDisaffected ? 'deleted' : practitioner.status || 'inactive'}
                          isDisaffected={practitioner.isDisaffected}
                        />
                      </td>

                      <td>
                        <FormGroup check>
                          <Label check>
                            <input
                              className="form-control"
                              type="checkbox"
                              data-id={i}
                              checked={practitioner.patient_reply_permission}
                              onChange={(e) => handlePermissionCheckbox(e, practitioner)}
                            />
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                      </td>

                      <td>
                        <FormGroup check>
                          <Label check>
                            <input
                              className="form-control"
                              type="checkbox"
                              data-id={i}
                              checked={practitioner.ai_feature_enabled}
                              onChange={(e) => handleAIFeatureCheckbox(e, practitioner)}
                            />
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                      </td>
                      
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default PractitionerList;
