import React, { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import ChatInboxMobile from './ChatInboxMobile';
import ChatRecents from '../ChatRecents';
import ChatStatus from '../ChatStatus';
import { useTranslate } from 'react-translate';
import { useDispatch, useSelector } from 'react-redux';
import ChatCreateGroup from '../ChatCreateGroup';
import ChatPeopleListVertical from '../ChatPeopleListVertical';
import ChatPeopleInGroup from '../ChatPeopleInGroup';
import ChatHeader from '../ChatHeader';
import ChatListVertical from '../ChatListVertical';
import { actionGetChats, actionChatShowInbox, actionSetChat, actionChatFilter, actionGetChatsDisaffected, actionGetChatFromUrlParams } from 'store/actions/chatsActions';
import { useHistory, useParams } from 'react-router';
import useUrlParams from 'hooks/useUrlParams';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import MenuOptions from 'components/Sidebar/MenuOptions';

const ChatBoxMobile = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const practiceURL = useUrlParams('practice');
  const parent = props.view;
  const isDisaffected = props.isDisaffected;
  const practice = props.practice;
  const userAuth = props.userAuth;
  const t = useTranslate('chat');
  const { chat, showInbox, chats, filter_select, loadingDone } = useSelector((state) => state.chatsReducer);
  const selected = useRef([]);
  const [isOpen, setIsOpen] = useState(true);
  const [chatSearch, setChatSearch] = useState(null);
  const [showChatForm, setShowChatForm] = useState(false);
  const [editChatMembers, setEditChatMembers] = useState(false);
  const [openAvatar, setOpenAvatar] = useState(false);
  const chatNameRef = useRef(chat.name);
  const [contactDisaffected, setContactDisaffected] = useState(false);
  //parameter endpoints chats,
  const queryDisaffected = isDisaffected === true ? '1' : '0';

  const { mobile, dashboardMobile } = useSelector(
    (state) => state.commonReducer
  );
  const [replyData, setReplyData] = useState({});
  const [scrollSet, setscrollSet] = useState('update');

  const setScrollInbox = () => {
    let status = scrollSet === 'update' ? 'new update' : 'update';
    setscrollSet(status);
  }
  //function which receive value of filter select active/disffected
  const setChatsList = (status) => {
    if (status === true) {
      dispatch(actionChatFilter(status));
      setContactDisaffected(true);
      dispatch(actionGetChatsDisaffected(true, userAuth, queryDisaffected));
    } else {
      dispatch(actionChatFilter(status));
      setContactDisaffected(false);
      dispatch(actionGetChats(true));
    }
  }

  useEffect(() => {
    if (chats && chats.length > 0) {
      if (id && id !== ':id') {
        dispatch(actionGetChatFromUrlParams(id, practiceURL));
        history.replace(`/admin/chats`);
      }
    }
    //eslint-disable-next-line
  }, [chats]);

  //check if my contact is disafected
  useEffect(() => {
    chatNameRef.current = ['private', 'group'].includes(chat.type) ? chat.name : `${chat.firstName} ${chat.lastName}`;
    setReplyData({});
    if (isDisaffected === false && chat && chat.main_user && practice && practice.people && chat.main_user.email) {
      let res = practice.people.find(us => us.email === chat.main_user.email);
      //check status in the practice
      setContactDisaffected(res && res.isDisaffected);
    }

  //eslint-disable-next-line
  }, [chat]);

  const toggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    //'filter select' is the Select of Header that filter chats of users actives/disaffected
    dispatch(actionChatFilter(filter_select));

    if (!chats.length) {
      if ((isDisaffected || filter_select) && userAuth) {
        dispatch(actionGetChatsDisaffected(true, userAuth, filter_select ? 0 : 1));  
      } else if (!isDisaffected) {
        dispatch(actionGetChats());
      }
    }

    //eslint-disable-next-line
  }, [dispatch, userAuth, filter_select, isDisaffected]);


  return (
    <Card className="chat-box mb-0">
      {!showInbox ? (
        <CardHeader className="text-left d-flex">
          <CardTitle className="text-primary d-flex w-100">
            <div className="mr-auto d-flex">
              <div className="general-card-title-img text-primary mr-2">
                <i className="icon-chat" />
              </div>
              <div className="general-card-title text-primary">
                {t('title')}
              </div>
            </div>

            <div className="chat-header-status" onClick={() => setOpenAvatar(!openAvatar)}>
              <ChatStatus />
              <MenuOptions setOpen={setOpenAvatar} isOpen={openAvatar}/>
            </div>
          </CardTitle>

          {/*Enable collapse button only on dashboard*/}
          {parent === 'dashboard' && (
            <span
              onClick={toggle}
              className="pull-right text-secondary chat-box-toggle"
            >
              {isOpen ? (
                <i className="fa fas fa-angle-up"></i>
              ) : (
                <i className="fa fas fa-angle-down"></i>
              )}
            </span>
          )}
        </CardHeader>
      ) : (
        ''
      )}

      <CardBody>
        {/* Show main components */}
        {(mobile || (parent === "dashboard" && dashboardMobile)) && showInbox ? (
          <ChatInboxMobile
            parent={parent}
            chatId={chat._id}
            setEditChatMembers={setEditChatMembers}
            editChatMembers={editChatMembers}
            replyData={replyData}
            setReplyData={setReplyData}
            setScroll={setScrollInbox}
            isDisaffected={(isDisaffected === true || contactDisaffected === true || filter_select === true) ? true : false}

          />
        ) : (
          <>
            {!editChatMembers && !showChatForm && (
              <>

                {isDisaffected === false &&
                  <div>
                    <ChatHeader
                      setChatSearch={setChatSearch}
                      setShowChatForm={setShowChatForm}
                      setEditChatMembers={setEditChatMembers}
                      editChatMembers={editChatMembers}
                      setChatsList={setChatsList}
                      selectValue={filter_select}
                    />
                    <ChatRecents chatSearch={chatSearch} filter_select={filter_select} />
                  </div>
                }
                {!loadingDone ?
                  <LoadingLocal />
                  : loadingDone && !chats.length ?
                  <h5 className="d-flex align-items-center justify-content-center general-card-title text-primary">
                    <span>{t('no-chat-results')}</span>
                    &nbsp;&nbsp;
                    <i className="far fa-frown-open"></i>
                  </h5>
                  :
                  <div className="chat-body-container">
                    <ChatListVertical />
                  </div>
                }

              </>
            )}
            {/* Create Group */}
            {showChatForm && (
              <ChatCreateGroup
                setShowChatForm={setShowChatForm}
                setEditChatMembers={setEditChatMembers}
                chatName={chatNameRef}
              />
            )}
            {/* Edit chat members */}
            {editChatMembers && (
              <>
                <ChatPeopleInGroup
                  chatName={chatNameRef}
                  setChatSearch={setChatSearch}
                  setEditChatMembers={setEditChatMembers}
                  selected={selected}
                />
                <ChatPeopleListVertical
                  chatName={chatNameRef}
                  chatSearch={chatSearch}
                  setEditChatMembers={setEditChatMembers}
                  selected={selected}
                />
              </>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default React.memo(ChatBoxMobile);
