import React from 'react';
import { useEffect,useState } from 'react';
import ChatSearchInput from './ChatSearchInput';
import { actionSetChat, actionChatFilter } from 'store/actions/chatsActions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useChatIsAdmin from 'hooks/useChatIsAdmin';
import { useTranslate } from 'react-translate';
import { Col, Input } from "reactstrap";

const ChatHeader = (props) => {
  const {
    setChatSearch,
    setEditChatMembers,
    setShowChatForm,
    editChatMembers,
    setChatsList

  } = props;
  const t = useTranslate('chat');
  const dispatch = useDispatch();

  const { isDisaffected } = useSelector((state) => state.practicesReducer);

  /**
   * Chat active
   */
  //var valueSelect = selectValue;
  const { chat, filter_select } = useSelector((state) => state.chatsReducer);
  const { auth_user_id } = useSelector((state) => state.userReducer)
  const currentUser = chat ? chat?.users?.find(u => String(u.auth_user_id) === String(auth_user_id)) : null;
  const { mobile } = useSelector(
    (state) => state.commonReducer
  );

  const chatType = chat.type;

  /**
   * Is admin
   */
  const isAdmin = useChatIsAdmin();

  const handleFilterSelect = (e) => {
    // setSingleSelect({ value: e.target.value});
    if (e.target.value === "Disaffected") {
      
      setChatsList(true);
      dispatch(actionChatFilter(true));
    } else {
      setChatsList(false);
      dispatch(actionChatFilter(false));
    }

  };



  return (
    <>
      <div className="d-flex icons-group ">
        <ChatSearchInput setChatSearch={setChatSearch} setChatsList={setChatsList} filter_select = {filter_select}/>
        <Col lg="3" md="6" sm="3">
          {
            isDisaffected === false ?
              <Input
                className={`select-filter ${isDisaffected === true ? 'disabled-inputs' : ''}`}
                type="select"
                name="filterUser"
                onChange={handleFilterSelect}
                defaultValue={filter_select === true ? 'Disaffected' : 'Actives'}
              >
                <option value="Actives">
                  {t('actives')}
                </option>
                <option value="Disaffected">
                  {t('disaffected')}
                </option>

              </Input>
              :
              <div />
          }

        </Col>
        {
          isDisaffected === false ?
            <div>
              <span
                className={`icon-add-group icon-group  ${isDisaffected === true ? 'disabled' : ''
                  }`}
                onClick={() => {
                  setShowChatForm(true);
                  setEditChatMembers(false);
                  dispatch(actionSetChat({}));
                }}
                title={t('new-group-tooltip')}
              ></span>

              {!(mobile) && (
                <span
                  className={`icon-edit-group icon-group ${((chatType === 'group' && isAdmin) && (isDisaffected === false) && (!currentUser?.deleted)) ? '' : 'disabled'
                    }`}
                  onClick={() => {
                    if (!isAdmin) {
                      return;
                    }
                    if (chat && chat.type === 'group' && !currentUser?.deleted) {
                      setEditChatMembers(!editChatMembers);
                    } else {
                      toast.warning( t("invalid-option") ); //'Invalid option for this chat');
                    }
                  }}
                  title={t('edit-group-tooltip')}
                ></span>
              )}
            </div>
            :
            <div />
        }

      </div>
    </>
  );
};

// export default ChatHeader;
export default React.memo(ChatHeader);
