import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
} from 'reactstrap';

import PractitionerStatus from 'components/Practice/PractitionerStatus';

//actions
import {
  actionGetInvitationList,
  actionResolveInvitation,
} from 'store/actions/userDataActions';
import { useTranslate } from 'react-translate';

const UserOptions = (props) => {
  const t = useTranslate('practices');
  const dispatch = useDispatch();
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  const handleInvitation = (practice_invitation_id, status) => {
    if (localStorage.getItem('ts-invitation')) {
      localStorage.removeItem('ts-invitation');
    }
    dispatch(actionResolveInvitation(practice_invitation_id, status));
  };

  return (
    <ButtonDropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className={props.className}
    >
      <DropdownToggle caret color="link"></DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() =>
            handleInvitation(props.practice_invitation_id, 'accepted')
          }
        >
          {t('btn-accept')}
        </DropdownItem>
        <DropdownItem
          onClick={() =>
            handleInvitation(props.practice_invitation_id, 'rejected')
          }
        >
          {t('btn-reject')}
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

const InvitationMyList = () => {
  const t = useTranslate('practices');
  const dispatch = useDispatch();

  const invitations = useSelector((state) => state.userDataReducer.invitations);

  const handleInvitation = useCallback(
    (practice_invitation_id, status) => {
      if (localStorage.getItem('ts-invitation')) {
        localStorage.removeItem('ts-invitation');
      }
      dispatch(actionResolveInvitation(practice_invitation_id, status));
    },
    [dispatch]
  );

  /**
   * Invitation by link
   */
  let invitation = localStorage.getItem('ts-invitation');

  /**
   * Accept invitation
   */
  useEffect(() => {
    if (invitation) {
      handleInvitation(invitation, 'accepted');
    }
  }, [invitation, handleInvitation]);

  useEffect(() => {
    dispatch(actionGetInvitationList());
  }, [dispatch]);

  return (
    <>
      <h5 className="general-card-subtitle no-line">{t('invitation-list')}</h5>
      <div className="mobile-x-overflow">
        <Table>
          <tbody>
            <tr>
              <th>{t('th-practice')}</th>
              <th>{t('th-text')}</th>
              <th>{t('th-role')}</th>
              <th>{t('th-date')}</th>
              <th>{t('th-status')}</th>
            </tr>
            {invitations &&
              invitations.map((invitation, i) => {
                return (
                  <tr key={i}>
                    <td className="d-flex">
                      {invitation.practice_name || ''}
                      {invitation.status === 'waiting' && (
                        <UserOptions
                          practice_invitation_id={
                            invitation.practice_invitation_id
                          }
                          className="ml-auto"
                        />
                      )}
                    </td>
                    <td>{invitation.text}</td>
                    <td>{invitation.role_name || ''}</td>
                    <td>{moment(invitation.date).format('L HH:mm') || ''}</td>
                    <td>
                      <PractitionerStatus
                        status={invitation.status ? invitation.status : 'waiting'}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default InvitationMyList;
