import React, { useEffect, useRef, useState } from 'react';
// reactstrap components
import { Button, Col, Row } from 'reactstrap';
import { useFileUtils } from 'hooks';
import ProfileInitials from './ProfileInitials';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import ImageCropModal from 'components/ImageCrop/ImageCropModal';
import { useDispatch } from 'react-redux';
import { actionRefreshUser } from 'store/actions/usersActions';

const ProfileAvatar = ({ setAvatar, avatar, setShortname, shortname }) => {
  const t = useTranslate('profile');
  const dispatch = useDispatch();
  const imagePrevRef = useRef();
  const user = useSelector((state) => state.userReducer);
  const inputAvatar = document.getElementById('profile-picture');

  const [showImageCrop, setShowImageCrop] = useState(false);
  const toggle = () => setShowImageCrop(!showImageCrop);

  const {
    getAvatar,
    deleteAvatar,
    defaultImage,
    resetInput,
    setFile,
    avatarExists,
  } = useFileUtils(inputAvatar);

  useEffect(() => {
    if (user.avatar) {
      const avatarResponse = getAvatar(user.avatar, 'large', true);
      avatarResponse.then((avatar) => {
        if (avatar) {
          setFile({ file: avatar });
        }
      });
    }
    //eslint-disable-next-line
  }, [user.avatar, setFile, user.updated_at]);

  useEffect(() => {
    if (defaultImage.includes('General')) {
      resetInput();
    }
    //eslint-disable-next-line
  }, [defaultImage]);

  const deleteAvatarEffect = () => {
    if (avatar) {
      setAvatar(null);
      inputAvatar.value = null;
      imagePrevRef.current.src = defaultImage;
      return;
    }
    const response = deleteAvatar(user.auth_user_id);
    response.then(() => {
      dispatch(actionRefreshUser());
      inputAvatar.value = null;
    });
  };

  return (
    <>
      <ImageCropModal
        toggle={toggle}
        showImageCrop={showImageCrop}
        imageInputId="profile-picture"
        imagePreviewId="avatar-preview"
        defaultImage={defaultImage}
        setAvatar={setAvatar}
      />

      <div className="profile-img-container mr-6">
        <div className="profile-img">
          <img
            src={defaultImage}
            id="avatar-preview"
            alt={t('profile-avatar')}
            ref={imagePrevRef}
            className={avatarExists || avatar ? '' : 'd-none'}
          />

          <div
            className={`initials-large ${
              !(avatarExists || avatar) ? '' : 'd-none'
            }`}
          >
            <span>{shortname}</span>
          </div>

          <div
            className={`text-center remove ${
              avatarExists || avatar ? '' : 'd-none'
            }`}
            title="remove"
            onClick={deleteAvatarEffect}
          >
            X
          </div>
        </div>
        <Row>
          <Col>
            <Button
              className="mt-3 btn-profile-photo"
              color="info"
              size="sm"
              name="btn-choose-avatar"
              onClick={() => {
                document.getElementById('profile-picture').click();
              }}
            >
              {t('avatar-upload')}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <ProfileInitials
              setShortname={setShortname}
              shortname={shortname}
            />
          </Col>
        </Row>

        <input
          type="file"
          name="avatar_input"
          id="profile-picture"
          accept="image/jpeg,image/png"
          className="d-none"
          defaultValue={null}
        />
      </div>
    </>
  );
};

export default ProfileAvatar;
