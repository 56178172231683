
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import InboxFileList from 'components/Messages/View/InboxFileList';
import { actionSetSelectedMessages } from 'store/actions/messagesActions';
import { useTranslate } from 'react-translate';
import { actionReadNotificationsByMessages } from 'store/actions/notificationsActions';

const InboxViewDetail = ({callback, Idisafected}) => {
  const t = useTranslate('messages');
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(1);

  const { message, loading_msg, box } = useSelector(
    (state) => state.messagesReducer
  );
  //message.from.email

  const { practice } = useSelector((state) => state.practicesReducer);

  useEffect(() => {
    //check if my contact is an user disaffected of the practice
    if (practice && practice.people && message && Idisafected === false) {     
      //get mail of my contact
      const contact = box ==="outbox" ?  message.to[0].email : message.from.email ; 
      const res = practice.people.find(us => us.email === contact);

      callback(res && res.isDisaffected);
    }
    //eslint-disable-next-line
  }, [practice, message]);

  useEffect(() => {
    if (message) {
      dispatch(actionSetSelectedMessages([message._id]));
      dispatch(actionReadNotificationsByMessages("messages", [message._id]));
    }

    return () => {
      // hide:true;
      //Restore buttons state
      callback(false);
    };
  }, [loading_msg, message, dispatch]);

  if (loading_msg) {
    return <LoadingLocal />;
  }

  if (!loading_msg && !message) {
    return (
      <Row>
        <Col sm={12} className="p-5">
          <Alert color="info">Select a Message</Alert>
        </Col>
      </Row>
    );
  }

  const formatText = (text) => {
    return text?.split('\n').map((item, i) => {
      return (
        <React.Fragment key={i}>
          {item}
          <br />
        </React.Fragment>
      );
    });
  };

  return (
    <Row className="inbox-view-detail">
      <Col sm={12}>
        <div className="inbox-view-detail-header pt-3 pb-3">
          <div className="pt-1 pb-1 pl-2 pr-2">
            <div className="inbox-message-subject">
              <strong>{t('subject')}: </strong>
              {message.subject}
            </div>
            <div className="inbox-message-from">
              <div>
                <strong>{t('from')}: </strong>
                {message?.from.firstName} {message?.from.lastName}
              </div>
              {box === 'outbox' && (
                <React.Fragment>
                  <div>
                    <strong>{t('to')}: </strong>
                    {message.to.map(
                      (user_to, i) =>
                        `${user_to.firstName} ${user_to.lastName} ${
                          i + 1 !== message.to.length ? ', ' : ''
                        }`
                    )}
                  </div>
                </React.Fragment>
              )}
              <div className="message-date">
                <strong>{t('date')}: </strong>
                {moment(message?.date).format('L HH:mm')}
              </div>
            </div>
            {message.priority ? (
              <div>
                <strong>{t('important')}</strong>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Col>
      <Col sm={12}>
        <div className="inbox-message-text p-3 overflow-auto">
          <p className="text-justify">{formatText(message?.body)}</p>
          <InboxFileList files={message.files ? message.files : []} />
        </div>
      </Col>

      {message.thread?.slice(limit*-1).reverse().map((th) => {
        return (
          <Col sm={12} key={th.date} className="message-reply">
            <Col sm={12}>
              <div className="thread-message-header">
                <span>{th.from.firstName} {th.from.lastName}</span>
                <span className="message-date ml-5">
                  {`${moment(th.date).format('D MMM YYYY HH:mm')} (${moment(th.date).fromNow()})`}
                </span>
              </div>
              <p className="text-justify">{formatText(th?.body)}</p>
            </Col>
            <Col sm={12}>
              <InboxFileList files={th.files ? th.files : []} />
            </Col>
          </Col>
        )
      })}

      {limit < message.thread?.length && (
        <div>
          <div className="inbox-detail-thread-counter">
            {message.thread?.length - limit}
          </div>
          <Button
            type="button"
            size="sm"
            color="info"
            onClick={(e) => {
              e.preventDefault();
              setLimit(limit+5);
            }}>
          Load {message.thread?.length - limit > 5 ? "5" : message.thread?.length - limit} messages
        </Button>
      </div>
      )}
    </Row>
  );
};

export default InboxViewDetail;
