import { takeEvery, call, select, put, cancelled, takeLatest } from 'redux-saga/effects';
import CONSTANTS from '../CONSTANTS';
import kiLabService from 'services/kiLabService';
import { toast } from 'react-toastify';
import { actionSetEarlyStudies, actionSetEarlyStudy } from 'store/actions/kiLabActions';
import historyRouter from 'historyRouter';

//Selectors
import { getUser, getEarlyStudy, getEarlyStudyList } from 'store/selectors/selectors';
//Helpers
import utilTranslations from '../../helpers/utilTranslations';
import { actionGetEarlyStudy } from 'store/actions/kiLabActions';
import { actionSetContext, actionSetLoadingEarlyStudy, actionSetLoadingAIReview } from 'store/actions/kiLabActions';

let t;

function* sagaGetStudy({ reportId, practiceId }) {
  const userActive = yield select(getUser);
  t = utilTranslations(userActive.language || 'de', 'laboratory-detail');

  const currentStudy = yield select(getEarlyStudy);
  if (!currentStudy || !Object.keys(currentStudy).length || currentStudy._id !== reportId) {
    yield put(actionSetLoadingEarlyStudy(true));
  }

  const cancelToken = kiLabService.getSource();
  try {
    const res = yield call(
      kiLabService.getStudyDetail, 
      reportId, 
      practiceId,
      { cancelToken: cancelToken.token }
    );

    if (res.status >= 400) {
      throw new Error("Error getting the study");
    }

    yield put(actionSetEarlyStudy(res.data));

    if (res.data.draft) {
      yield put(actionSetContext({
        ...res.data.draft.context
      }));
    }
    
    
  } catch (error) {
    console.log(error);
    historyRouter.push("/admin/kilab/index");
  } finally {
    if (yield cancelled()) {
      cancelToken.cancel();
    }
    yield put(actionSetLoadingEarlyStudy(false));
    yield put(actionSetLoadingAIReview(false));
  }
}

/**
 * Method to call to the API to get the studies
 * 
 * @param {} param0 
 */
function* sagaGetLaboratoryStudies({ options }) {

  const userActive = yield select(getUser);

  t = utilTranslations(userActive.language || 'de', 'laboratory-detail');

  // Method to avoid the recurrent calls to stack request, only execute the last call
  const cancelToken = kiLabService.getSource();
  try {
    const res = yield call(
      kiLabService.getStudiesList, 
      options.practiceId, 
      options.pageIndex, 
      options.pageSize, 
      options.sortDate, 
      options.querySearch, 
      options.dateFrom,
      options.dateTo,
      { cancelToken: cancelToken.token }
    );

    if (res.status >= 400) {
      throw new Error("Error getting the studies");
    }

    yield put(actionSetEarlyStudies(res.data));

  } catch (error) {
    console.log(error);
  } finally {
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}

function* sagaSendEarlyReview ({ data }) {
  const userActive = yield select(getUser);

  // Method to avoid the recurrent calls to stack request, only execute the last call
  const cancelToken = kiLabService.getSource();

  try {
    const res = yield call(
      kiLabService.sendReview, 
      data.studyId,
      data.review,
      data.practiceId,
      data.hl7_id,
      data.context,
      data.type,
      { cancelToken: cancelToken.token }
    );

    if (res.status >= 400) {
      throw new Error("Error saving the review");
    }

    yield put(actionGetEarlyStudy(data.studyId));

  } catch (error) {
    console.log(error);
  } finally {
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}


function* sagaSaveEarlyDraft ({ data }) {
  const userActive = yield select(getUser);

  // Method to avoid the recurrent calls to stack request, only execute the last call
  const cancelToken = kiLabService.getSource();

  try {
    const res = yield call(
      kiLabService.saveDraft, 
      data.studyId,
      data.review,
      data.practiceId,
      data.hl7_id,
      data.context,
      data.type,
      { cancelToken: cancelToken.token }
    );

    if (res.status >= 400) {
      throw new Error("Error saving the draft");
    }

    yield put(actionGetEarlyStudy(data.studyId));

  } catch (error) {
    console.log(error);
  } finally {
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}

function* sagaSaveContext ({ studyId, context }) {
  // Method to avoid the recurrent calls to stack request, only execute the last call
  const cancelToken = kiLabService.getSource();

  try {
    const res = yield call(
      kiLabService.saveContext, 
      studyId,
      context,
      { cancelToken: cancelToken.token }
    );

    if (res.status >= 400) {
      throw new Error("Error saving the context");
    }

  } catch (error) {
    console.log(error);
  } finally {
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}

function* sagaGenerateAIComment ({ studyId }) {
  // Method to avoid the recurrent calls to stack request, only execute the last call
  const cancelToken = kiLabService.getSource();

  yield put(actionSetLoadingAIReview(true));

  try {
    const res = yield call(
      kiLabService.generateAIComment, 
      studyId,
      { cancelToken: cancelToken.token }
    );

    if (res.status >= 400) {
      throw new Error("Error saving the context");
    }

    yield put(actionGetEarlyStudy(studyId));

  } catch (error) {
    console.log(error);
  } finally {
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}

function* sagaRemoveDoctorFromStudy ({ auth_user_id }) {
  // Method to avoid the recurrent calls to stack request, only execute the last call
  const cancelToken = kiLabService.getSource();

  try {
    const res = yield call(
      kiLabService.removeDoctor, 
      auth_user_id,
      { cancelToken: cancelToken.token }
    );

    if (res.status >= 400) {
      throw new Error("Error removing the doctor");
    }

  } catch (error) {
    console.log(error);
  } finally {
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}

export function* kiLabSaga() {
  console.log('*Main KI Laboratories Saga');
  yield takeLatest(CONSTANTS.LAB_EARLY_GET_STUDY, sagaGetStudy);
  yield takeLatest(CONSTANTS.LAB_EARLY_GET_LIST, sagaGetLaboratoryStudies);
  yield takeLatest(CONSTANTS.LAB_EARLY_SAVE_REVIEW, sagaSendEarlyReview);
  yield takeLatest(CONSTANTS.LAB_EARLY_SAVE_DRAFT, sagaSaveEarlyDraft);
  yield takeLatest(CONSTANTS.LAB_EARLY_SAVE_CONTEXT, sagaSaveContext);
  yield takeLatest(CONSTANTS.LAB_EARLY_GENERATE_AI_COMMENT, sagaGenerateAIComment);
  yield takeEvery(CONSTANTS.LAB_EARLY_REMOVE_DOCTOR_FROM_STUDY, sagaRemoveDoctorFromStudy);
}
