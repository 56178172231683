import React, { useEffect, useRef } from 'react';
import { Emoji } from 'emoji-mart';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { actionChatMessageRemoveEmoji } from 'store/actions/chatsActions';
import ReactTooltip from 'react-tooltip';

const EmojisReactionsData = (props) => {
  const dispatch = useDispatch();
  const [emojis, setEmojis] = useState({});
  const isMounted = useRef(null);
  const { message } = props;

  let emojisData = JSON.stringify(message);

  const handleClick = (e) => {
    dispatch(actionChatMessageRemoveEmoji(message, e.colons));
    getEmojis();
  };

  const getEmojis = () => {
    let aux = {};
    if (Array.isArray(message?.recipients)) {
      message.recipients.forEach((user) => {
        if (Array.isArray(user?.reactions)) {
          user.reactions.forEach((emoji) => {
            if (!aux[emoji]) {
              aux[emoji] = {
                users: [],
                quantity: 0,
              };
            }
            aux[emoji].users[user._id] = user;
            aux[emoji] = {
              ...aux[emoji],
              emoji,
              quantity: aux[emoji].quantity + 1,
            };
          });
        }
      });
    }
    if (isMounted.current) {
      emojisData = JSON.stringify(message);
      setEmojis(aux);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    getEmojis();
    return () => {
      isMounted.current = false;
    };
    //eslint-disable-next-line
  }, [emojisData]);

  return (
    <div className={`emojis-reactions-container ${false ? 'd-none' : ''}`}>
      {Object.keys(emojis).map((key) => {
        let emoji = emojis[key];
        const emojiImage = (
          <Emoji
            size={24}
            emoji={emoji.emoji}
            fallback={() => emoji}
            onClick={handleClick}
          />
        );
        return (
          <React.Fragment key={key}>
            <span data-tip data-for={`reactions-tooltip-${emoji.emoji}`}>
              {emojiImage}
              {emoji?.quantity > 1 && (
                <sup className="emoji-counter">{emoji?.quantity}</sup>
              )}
            </span>
            <ReactTooltip
              id={`reactions-tooltip-${emoji.emoji}`}
              aria-haspopup="true"
              effect="float"
              type="light"
              place="bottom"
            >
              {Object.keys(emoji.users).map((key) => {
                let u = emoji.users[key];
                return (
                  <div
                    key={u._id}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <span>
                      {`${u.title ? u.title + ' ' : ''}${u.firstName} ${
                        u.lastName
                      } `}
                    </span>
                    <span className="m-1"></span>
                    <span>{emojiImage}</span>
                  </div>
                );
              })}
            </ReactTooltip>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default EmojisReactionsData;
