import React, { useState, useMemo, useRef } from 'react';
import { Form, Table, Row, Button, Input, Pagination, PaginationItem, PaginationLink, FormGroup, Col, Label } from 'reactstrap';
import { useTranslate } from 'react-translate';
import { useSortBy, useTable, usePagination } from 'react-table';
import moment from "moment";
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';

const InvitationList = () => {
    const t = useTranslate('studies-list');
    const { handleSubmit, register } = useForm();
    const user = useSelector(state => state.userReducer);
    const invitationsList = [
        {
            firstName: "Niels",
            lastName: "Bohr",
            email: "niels@bohr.de",
            status: "pending",
            date: Date.now()
        },
        {
            firstName: "Niels",
            lastName: "Bohr",
            email: "niels@bohr.de",
            status: "accepted",
            date: Date.now()
        },
        {
            firstName: "Niels",
            lastName: "Bohr",
            email: "niels@bohr.de",
            status: "rejected",
            date: Date.now()
        }
    ]
    const refreshTable = useRef(false);
    const pageLimit = 0;
    const loadingStudyList = false;
    const [sortDate, setSortDate] = useState(-1);
    const querySearch = "";

    const onSubmit = (data) => {
        gotoPage(0);
    }

    // const handleInfoClick = (index) => {
    //     const updatedState = data[index];

    //     if (updatedState.other) {
    //         delete updatedState.other;
    //         setState((pre) => {
    //             return pre + 1;
    //         });
    //     } else {
    //         updatedState.other = {
    //             description: "Hello there" //or data from api
    //         };
    //         setState((pre) => {
    //             return pre + 1;
    //         });
    //     }
    // };

    const data = useMemo(
        () => invitationsList && invitationsList != "loading" ? invitationsList.map((p, i) => {
            const listData = {
                lastname: p.lastName,
                firstname: p.firstName,
                date: moment(p.date).format(user.dateFormat),
                email: p.email,
                status: <span className={`badge badge-${p.status === "pending" ? "info" : p.status === "accepted" ? "success" : "primary"}`}>{p.status}</span>,
                actions: <Button color="info">
                    offnen
                </Button>
            };

            return { ...listData };
        }) : [],
        //eslint-disable-next-line
        [t]
    );


    const columns = useMemo(
        () => [
            {
                Header: `Nachname`,
                accessor: "lastname",
                // defaultCanSort: false,
            },
            {
                Header: "Name",
                accessor: "firstname",
            },
            {
                Header: `Versanddatum`,
                accessor: "date",
            },
            {
                Header: `${t('status')}`,
                accessor: "status",
                // defaultCanSort: false,
                // disableSortBy: true
            },
            {
                Header: `Email`,
                accessor: "email",
                // defaultCanSort: false,
                // disableSortBy: true
            },
            {
                accessor: "actions",
                // defaultCanSort: false,
                // disableSortBy: true
            },
        ],
        //eslint-disable-next-line
        [t]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable({
        columns,
        data,
        disableSortRemove: true,
        enableSortingRemoval: true,
        defaultCanSort: true,
        initialState: {
            pageSize: 20,
            hiddenColumns: ["registerId", "additionalInfo"],
        },
        manualPagination: true,
        pageCount: pageLimit + 1,
        autoResetPage: !refreshTable.current
    },
        useSortBy,
        usePagination);

    useEffect(() => {
        refreshTable.current = true;
        document.getElementById("full-page").scroll(0,0);
    }, [pageIndex, pageSize, sortDate, querySearch]);


    useEffect(() => {
        refreshTable.current = false;
    }, []);


    return (
        <>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={4} sm={5}>
                        <FormGroup
                        className="mt-2">
                            <Input 
                            className="form-control"
                                type="text"
                                innerRef={register}
                                name="query"
                                id="query"
                                defaultValue={querySearch}
                            />

                            {querySearch.length ? (
                                <button 
                                className="msg_send_btn" 
                                type="button" 
                                onClick={() => {
                                    document.getElementById("query").value = "";
                                        
                                    }}>
                                    <span className="icon-trash"></span>
                              </button>
                            ) : (<></>)}
                        </FormGroup>
                    </Col>

                    <Col xs={1}>
                        <Button
                            size="md"
                            color="info"
                            type="submit"
                            name="search-study"
                        >
                            {t("search-button")}
                        </Button>
                    </Col>
                    
                </Row>
            </Form>
            

            {loadingStudyList ? <LoadingLocal/> : (
                <div className="table-responsive">
                    <Table {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            className={`session-table-header ${column.id === "c4" ? "sortable-header" : ""} ${column.id === "registerid" ? "d-none" : ""}`}
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            onClick={column.id === "berichtsdatum" ? e => {
                                                setSortDate(!sortDate ? 1 : sortDate === 1 ? -1 : 0);
                                            } : undefined}
                                        >
                                            {column.render('Header')}

                                            {column.id === "berichtsdatum" && (
                                                <span>
                                                    <b className={`${sortDate != 0 ? sortDate === -1 ? "caret-desc" : "caret-asc" : "caret-default"}`}></b>
                                                </span>
                                            )}

                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {invitationsList === "loading" ? (<tr><td><LoadingLocal /></td></tr>)
                                : (
                                    page.map((row, index) => {
                                        prepareRow(row)
                                        return (
                                            <React.Fragment key={`tbody-${index}`}>
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell, ci) => {
                                                        return (
                                                            <td
                                                                {...cell.getCellProps({ key: `tr_${index}_${ci}` })}
                                                                className={`${cell.column.id === "c1" ? "d-flex" : ""} ${cell.column.id === "registerid" ? "d-none" : ""}`}
                                                            >
                                                                {cell.column.id === "c1" && <div className={`dot-${cell.value === "Unavailable" ? "un" : ''}available`}></div>}
                                                                {cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                                </tr>

                                                <tr {...row.getRowProps({ key: `row_${index}_2` })}>
                                                    {/* <pre> */}
                                                    {
                                                        row.cells.map((cell, index) => {
                                                            {/* { console.log(cell.column.id) } */ }

                                                            {/* {
                                                                    if (cell.column.id == "registerid") {
                                                                        return (
                                                                            <pre>{cell.column.id}</pre>
                                                                        )
                                                                    } else {
                                                                        return (null)
                                                                    }
                                                                } */}
                                                            if (cell.column.id === "registerid") {

                                                                return (

                                                                    <td {...cell.getCellProps({ key: `cell_${index}_2` })} colSpan={9} style={{ borderTop: "none" }}>
                                                                        {/* 
                                                                        
                                                                        TODO - Resolve toggle hide/show aditional info
                                                                        
                                                                        <UncontrolledCollapse
                                                                            toggler={`#ButtonToggler${cell.value}`}>
                                                                            <>
                                                                                {Object.keys(cell.row.allCells[1].value).map((v,i) => {
                                                                                    return (
                                                                                        <p key={`p-${v}-${i}`}>{`${v}: ${cell.row.allCells[1].value[v]}`}</p>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        </UncontrolledCollapse>
                                                                            */}
                                                                    </td>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    {/* </pre> */}
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                )}
                        </tbody>
                    </Table>
                </div>
            )}
            

            <Row className="text-center justify-content-center mt-3">
                <nav aria-label="Page navigation example">
                    <Pagination
                        className="pagination justify-content-end"
                        listclassname="justify-content-end"
                    >
                        <PaginationItem disabled={!canPreviousPage}>
                            <PaginationLink
                                href="#previous-page"
                                onClick={e => {
                                    e.preventDefault()
                                    previousPage()
                                }}
                                tabIndex="-1"
                                className="bg-info text-white"
                            >
                                <i className='fa fa-angle-double-left'></i>
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={pageIndex < 2}>
                            <PaginationLink href="#minus-two" onClick={e => {
                                e.preventDefault();
                                gotoPage(pageIndex - 2);
                            }}>
                                {canPreviousPage && pageIndex > 1 ? pageIndex - 1 : " "}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={pageIndex === 0}>
                            <PaginationLink href="#minus-one"
                                onClick={e => {
                                    e.preventDefault();
                                    previousPage()
                                }}>
                                {!canPreviousPage ? " " : pageIndex}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem active>
                            <PaginationLink href="#actual-page" onClick={e => { e.preventDefault() }} className="bg-info">
                                {pageIndex + 1}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={!canNextPage}>
                            <PaginationLink href="#plus-one" onClick={e => {
                                e.preventDefault();
                                nextPage();
                            }}>
                                {canNextPage ? pageIndex + 2 : " "}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={!(pageCount - pageIndex > 2)}>
                            <PaginationLink href="#plus-two" onClick={e => {
                                e.preventDefault();
                                gotoPage(pageIndex + 2);
                            }}>
                                {(pageCount - pageIndex > 2) ? pageIndex + 3 : " "}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={!canNextPage}>
                            <PaginationLink href="#next-page" onClick={e => {
                                e.preventDefault();
                                nextPage();
                            }}
                                className="bg-info text-white"
                            >
                                <i className='fa fa-angle-double-right'></i>
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </nav>
            </Row>
        </>
    );
};

export default InvitationList;
