import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslate } from 'react-translate';

const BtnAttachModal = ({ modal, toggleModal, confirmDelete }) => {
  const t = useTranslate('messages');

  return (
    <Modal isOpen={modal} toggle={toggleModal}>
      <ModalBody>{t('attach-confirm-message')}</ModalBody>
      <ModalFooter>
        <Button color="info" name="attach-message-btn" onClick={confirmDelete}>
          {t('attach-confirm-remove')}
        </Button>{' '}
        <Button color="secondary" onClick={toggleModal}>
          {t('btn-cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BtnAttachModal;
