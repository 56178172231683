import AxiosService from 'plugins/TS-lib-utils-public/services/AxiosService';

class ChatsService extends AxiosService {
  constructor() {
    super('messages');
  }
  /**
   * Get all chats of the active user
   */
  getChats = async (practiceId, search = null, { cancelToken = null } = {}) => {
    const response = await this.get(
      `/chats${search ? '?search=' + search : ''}`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice: practiceId,
        },
        cancelToken: cancelToken,
      }
    );
    return response;
  };
   /**
   * Get all chats of the  user dissaffected
   */
    getChatsDisaffected = async (auth_user_id, queryDisaffected, practiceId, search = null, { cancelToken = null } = {}) => {
      const response = await this.get(
        `/chats/archive${search ? '?search=' + search : ''}/?userDisaffected=${queryDisaffected}`,
        {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
            practice: practiceId,
            auth_user_id
          },
          cancelToken: cancelToken,
        }
      );
      return response;
    };
  /**
   * Get one chat of active user
   */
  getChat = async (practiceId, chatId, { cancelToken = null } = {}) => {
    const response = await this.get(`/chats/${chatId}`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        practice: practiceId,
      },
      cancelToken: cancelToken,
    });
    return response;
  };
  /**
   * Save chat
   */
  saveChat = async (practiceId, chat) => {
    let formData = new FormData();
    Object.keys(chat).forEach((key) => {
      if (chat[key] !== 'avatar') {
        formData.append(key, chat[key]);
      }
    });
    /**
     * Attach file
     */
    if (chat.avatar && chat.avatar.length > 0) {
      formData.append('avatar', chat.avatar[0]);
    } else {
      formData.delete('avatar');
    }
    if (chat._id) {
      // edit chat
      const response = await this.put(`/chats/${chat._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getToken()}`,
          practice: practiceId,
        },
      });
      return response;
    }
    // else save a new chat
    const response = await this.post(`/chats`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.getToken()}`,
        practice: practiceId,
      },
    });
    return response;
  };

  /**
   * Add chat users
   */
  addChatUsers = async (practiceId, chatId, users) => {
    const response = await this.put(
      `/chats/${chatId}/users`,
      {
        users: JSON.stringify(users),
      },
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice: practiceId,
        },
      }
    );
    return response;
  };

  /**
   * Delete chat users
   */
  deleteChatUsers = async (practiceId, chatId, users) => {
    const response = await this.post(
      `/chats/${chatId}/users/delete`,
      { users },
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice: practiceId,
        },
      }
    );
    return response;
  };
  /**
   * Delete my user
   */
  deleteChatMyUser = async (practiceId, chatId, userId) => {
    const response = await this.delete(`/chats/${chatId}/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        practice: practiceId,
      },
    });
    return response;
  };
  /**
   * Stadistics by chat room
   */
  stadistics = async (practiceId, chatId) => {
    const response = await this.get(`/chats/${chatId}/stadistics`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        practice: practiceId,
      },
    });
    return response;
  };

  /**
   * Delete chat
   */
  deleteChat = async (practiceId, chatId) => {
    const response = await this.delete(`/chats/${chatId}`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        practice: practiceId,
      },
    });
    return response;
  };

  //MESSAGES
  /**
   * Get messages
   */
  getMessages = async (
    practiceId,
    chatId,
    { limit = 10, search = '', page = 1, last = null, first = null } = {}
  ) => {
    // send message
    const response = await this.get(
      `/chats-messages/chats/${chatId}/messages?page=${page}&limit=${limit}${
        search ? '&search=' + search : ''
      }${first ? '&first=' + first : ''}${last ? '&last=' + last : ''}`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice: practiceId,
        },
      }
    );
    return response;
  };
  /**
   * Get only new messages
   */
  getMessagesRefresh = async (
    practiceId,
    chatId,
    messageId,
    { cancelToken = null } = {}
  ) => {
    // get new messages
    const response = await this.get(
      `/chats-messages/chats/${chatId}/messages?first=${messageId}&page=1&limit=20`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice: practiceId,
        },
        cancelToken,
      }
    );
    return response;
  };
  /**
   * Send message
   */
  sendMessage = async (practiceId, message) => {
    // send message
    const response = await this.post(`/chats-messages`, message, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        practice: practiceId,
      },
    });
    return response;
  };
  /**
   * Read message
   */
  readMessage = async (practiceId, messageId) => {
    // send message
    const response = await this.put(`/chats-messages/${messageId}`, null, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        practice: practiceId,
      },
    });
    return response;
  };
  /**
   * Read all of the messages by chat id
   */
  readAll = async (practiceId, chatId) => {
    // send message
    const response = await this.put(
      `/chats-messages/${chatId}/read-all`,
      null,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice: practiceId,
        },
      }
    );
    return response;
  };
  /**
   * Send emoji reaction
   */
  addEmojiReaction = async (practiceId, messageId, emojiReaction) => {
    const response = await this.put(
      `/chats-messages/${messageId}/reaction`,
      {
        reaction: emojiReaction,
      },
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice: practiceId,
        },
      }
    );
    return response;
  };
  /**
   * Remove emoji reaction
   */
  removeEmojiReaction = async (practiceId, messageId, emojiReaction) => {
    const response = await this.delete(
      `/chats-messages/${messageId}/reaction/${emojiReaction}`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice: practiceId,
        },
      }
    );
    return response;
  };
}

export default new ChatsService();
