import { takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
//Actions
import CONSTANTS from '../CONSTANTS';
//Selectors
import { select } from 'redux-saga/effects';
import { getUser } from '../selectors/selectors';
//Services
import ProfileService from '../../services/ProfilesService';
import { actionRemoveAvatars } from 'store/actions/avatarsActions';
//Helpers
import { deleteUrl } from 'helpers/urlsBlob';
import utilTranslations from '../../helpers/utilTranslations';
import { actionRefreshUser } from 'store/actions/usersActions';
let t;
//Axios config
ProfileService.setBaseUrl('/');

function* sagaSaveProfile({ profile }) {
  try {
    //Translations
    const user = yield select(getUser);
    deleteUrl(user?.auth_user_id);
    t = utilTranslations(user.language || 'de', 'profile');
    //Saga's logic
    let result;
    if (profile?._id) {
      result = yield call(ProfileService.edit, user.auth_user_id, profile);
    } else {
      result = yield call(ProfileService.add, user.auth_user_id, profile);
    }
    if (result.status >= 200 || result.status <= 205) {
      yield put(actionRemoveAvatars(user.auth_user_id));
      yield put(actionRefreshUser());
      toast.success(t('alert-success-profile-saved'));
    } else {
      throw new Error(t('alert-error-profile-saved'));
    }
  } catch (error) {
    toast.error(error.message);
  }
}

export function* profileSaga() {
  console.log('*Main Profiles Saga');
  yield takeEvery(CONSTANTS.PROFILE_SAVE, sagaSaveProfile);
}
