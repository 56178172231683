import CONSTANTS from '../CONSTANTS';

const initialState = {
  practices: [],
  practice: {},
  practice_manage_select: {},
  isDisaffected: false,
};

const practicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.PRACTICES_SET_LIST:
      return { ...state, practices: action.practices };
    case CONSTANTS.PRACTICES_SET:
      return { ...state, practice: action.practice };
    case CONSTANTS.PRACTICE_METRIC_SELECTED:
      return { ...state,practice_manage_select: action.selected_practice };
    case CONSTANTS.PRACTICE_USERS_SET_DISAFFECTED:
     return {...state, isDisaffected: action.isDisaffected === undefined ? false : action.isDisaffected};
    default:
      return state;
  }
};

export default practicesReducer;
