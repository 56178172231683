import React, { useEffect, useRef } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import useSessionTimer from '../plugins/TS-lib-utils-public/hooks/useSessionTimer.jsx';

import routes from 'routes.js';
import { useSelector, useDispatch } from 'react-redux';
import { TranslatorProvider } from 'react-translate';
import momentjsI18n from 'helpers/momentjsI18n';
import { actionSetLang, actionSetDateFormat } from 'store/actions/usersActions';
import {
  actionMobileVersion,
  actionNativeWebView,
} from 'store/actions/commonActions.js';
import PushNotificationsAsk from 'components/Notifications/PushNotificationsAsk';
import PracticeHeader from 'components/Practice/PracticeHeader.jsx';
import PracticeManageHeader from 'components/Practice/PracticeManageHeader.jsx';
import useAuthorizationValidator from 'plugins/TS-lib-utils-public/hooks/useAuthorizationValidator.jsx';
import { actionGetDoctorInfo } from 'store/actions/userDataActions.js';
import history from 'historyRouter';

var ps;

const Admin = (props) => {
  const dispatch = useDispatch();
  const { sweetAlert } = useSelector((state) => state.commonReducer);
  const { language } = useSelector((state) => state.userReducer);
  const adminNavRef = useRef();
  const user = useSelector((state) => state.userReducer);
  const isMounted = useRef(true);
  const practice = useSelector((state) => state.userAuthorizationReducer);

  /**
   * Check permissions
   */
  const { checkAccess } = useAuthorizationValidator();
  /**
   * Controller of session time duration
   */
  //useSessionTimer(user);

  /**
   * Set mobile version
   */
  const updateMobile = () => {
    if (isMounted.current) {
      dispatch(actionMobileVersion(window.innerWidth < 992));
    }
  };

  useEffect(() => {
    isMounted.current = true;
    updateMobile();

    //Check if native webview special function exists
    dispatch(actionNativeWebView('ReactNativeSend' in window));

    window.addEventListener('resize', updateMobile);
    return () => {
      isMounted.current = false;
      window.removeEventListener('resize', updateMobile);
    };
    //eslint-disable-next-line
  }, []);

  /**
   * Search invitation on localstorage
   */
  let invitation = localStorage.getItem('ts-invitation');
  useEffect(() => {
    if (invitation) {
      props.history.push('/admin/invitations');
      localStorage.removeItem('ts-invitation');
    }
    //eslint-disable-next-line
  }, [invitation]);

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      let fullPage = document.getElementById('full-page');
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      // ps = new PerfectScrollbar(mainPanel.current);
      ps = new PerfectScrollbar(fullPage);
    }

    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.documentElement.className += ' perfect-scrollbar-off';
        document.documentElement.classList.remove('perfect-scrollbar-on');
      }
    };
  }, []);

  useEffect(() => {
    if (user._id) {
      momentjsI18n.setFormat(user.dateFormat);
      momentjsI18n.setLang(user.language);
      dispatch(actionSetLang(user.language));
      dispatch(actionSetDateFormat(user.dateFormat));
      //traigo la info del doctor logueado como pifid
      dispatch(actionGetDoctorInfo());
      if (user.on_delete) {
        history.push("/admin/delete-confirmation");
      }
    }
    return () => null;
    // eslint-disable-next-line
  }, [dispatch, language]);

  const getRoutes = (routes) => {
    if (!user._id) {
      return <Redirect to="/auth" />;
    } else if (user.auth_2fa && user.auth_2fa === 'pending' && !user.on_delete) {
      return <Redirect to="/auth/lock-screen-page" />;
    }
    /**
     * Password expired
     */
    if (user.password_expired) {
      const routeSettings = routes.find((route) => route.path === '/settings');
      if (!routeSettings) {
        throw new Error('Route no found!');
      }
      return [
        <Route
          path={routeSettings.layout + routeSettings.path}
          component={routeSettings.component}
          key={routeSettings.path}
        />,
      ];
    }

    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        if (prop.key) {
          //TODO: Do something better for practices-manager
          if (checkAccess(prop.key) || checkAccess("practices-manager", prop.key)) {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          }
        } else {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      }
      return null;
    });
  };

  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const hideSidebar = () => adminNavRef.current.hideSidebar();

  return (
    <TranslatorProvider
      translations={require(`assets/i18n/${language || 'de'}.json`)}
    >
      {/* Push Notifications */}
      <PushNotificationsAsk />
      {/* SweetAlert Global */}
      {sweetAlert}

      <div className="wrapper animated fadeIn">
        <Sidebar
          {...props}
          routes={routes}
          // minimizeSidebar={minimizeSidebar}
          backgroundColor="blue"
          hideSidebar={hideSidebar}
        />
        <AdminNavbar
          {...props}
          ref={adminNavRef}
          brandText={getActiveRoute(routes)}
        />
        <div className={`main-panel admin_layout`} id="full-page">
          <PracticeManageHeader />
          {/* mia ramos 23-11-2022: agregue la clase overflow-auto para evitar que se genere un scroll innecesario */}
          <div className='content-layer'>
            <div className="content" id="content">
              <PracticeHeader />

              <Switch>
                {getRoutes(routes)}
                {user.password_expired && <Redirect to="/admin/settings" />}
                {!practice._id && <Redirect to="/admin/welcome" />}
                {/* <Redirect from="/admin" to="/admin/dashboard" /> */}
                <Redirect from="/admin" to="/admin/chat" />
                {/* <Redirect from="/admin" to="/admin/inbox" /> */}
                <Redirect to="/admin/error" />
                {/* <Redirect from="/admin" to="/admin/welcome" /> */}
              </Switch>
            </div>
          </div>

          {
            // we don't want the Footer to be rendered on full screen maps page
            window.location.href.indexOf('full-screen-maps') !== -1 ? null : (
              <Footer fluid />
            )
          }
        </div>
      </div>
    </TranslatorProvider>
  );
};

export default React.memo(Admin, (prevProps, nextProps) => {
  return prevProps?.location?.key === nextProps?.location?.key;
});
