import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Table, Input, Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useHistory } from 'react-router';
import { useTranslate } from 'react-translate';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import moment from "moment";

// Include a particular scss for this view
import 'assets/scss/custom/_kilab_list.scss';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetEarlyStudies } from 'store/actions/kiLabActions';


const KiLabTable = () => {

    //Hook to redirect
    const history = useHistory();
    const dispatch = useDispatch();

    const kiLabList = useSelector(state => state.kiLabReducer.studies);
    const pageLimit = useSelector(state => state.kiLabReducer.lastPage);
    const practiceId = useSelector(state => state.practicesReducer.practice._id);
    const querySearch = useSelector(state => state.kiLabReducer.querySearch);
    const user = useSelector(state => state.userReducer);
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const refreshTable = useRef(false);

    //Translation library
    const t = useTranslate('kilab-list');
    const tStatusHL7 = useTranslate('hl7-status');

    //Bind the open Analysis action to a button
    const handleButtonClick = (param) => {

        if(param === undefined){

            alert("You can not open this Analysis.")
        }else{

            //Redirecto to Edit view
            history.push(`/admin/kilab/editor/${param}`);
        }
    };

    /** Execution on load component */
    useEffect(() => {
        //Reset the Scrollbar of the principal container
        document.getElementById("full-page").scroll(0,0);
         
        //Call to recieve the studies
        dispatch(actionGetEarlyStudies({
            practiceId, 
            pageIndex, 
            pageSize,
            querySearch,
            dateFrom,
            dateTo
        }))
        /* Update on some of thoese VAR change */
    }, [pageIndex, pageSize, querySearch]);

    const data = useMemo(
        () => 
            kiLabList && kiLabList.length ? kiLabList.map(ki => {

                const reportData = {
                    id: ki.id,
                    date: moment(ki.date/*, "YYYYMMDDhhmmss"*/).format(user.dateFormat),
                    //date: ki.date,
                    changed: ki.changed,
                    doctor: ki.doctor,
                    practice: <>
                        {ki.doctor} <br />
                        <strong>{ki.practice}</strong>
                    </>,
                    analysis_instance: ki.analysis,       //'Tailbefund',
                    ai_date: moment(ki.ai_date/*, "YYYYMMDDhhmmss"*/).format(user.dateFormat),
                    //ai_date: ki.ai_date,

                    statusId :
                            <>
                                <span className="orangeColor">{t('in-progress')}</span>
                                <br />
                                <span className="orangeColor"><b>{ki.responsible}</b></span>
                            </>,

                    responsible: ki.responsible,
                    action: <>
                        <Button size="md" color="info" type="button" name="search-study" onClick={ () => handleButtonClick(ki.id)}>
                            {t("open-analisys")}
                        </Button>
                    </>
                }

                return {...reportData};
            }) : [],
            [kiLabList, t]
    );

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id"
            },
            {
                Header: `${t("date-of-receipt")}`,
                accessor: "date"
            },
            {
                Header: `${t("changed")}`,
                accessor: "changed"
            },
            {
                Header: `${t("practice")}`,
                accessor: "practice"
            },
            {
                Header: `${t("analysis-instance")}`,
                accessor: "analysis_instance"
            },
            {
                Header: `${t("last-ai-result-Date")}`,
                accessor: "ai_date", //"last_ai_result_date"
            },
            {
                Header: `${t("status")}`,
                accessor: "statusId"
            },
            {
                Header: `${t("action")}`,
                accessor: "action"
            },

        ], [t]
    )

    //Array for columns to be hidded on mobile devices
    const hiddenColumnsOnMobile = ["date", "changed", "statusId", "changed", "ai_date"];


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable({
        columns,
        data,
        disableSortRemove: true,
        enableSortingRemoval: true,
        defaultCanSort: true,
        initialState: {
            pageSize: 20,
            hiddenColumns: ["registerId", "additionalInfo"],
        },
        manualPagination: true,
        pageCount: pageLimit + 1,
        autoResetPage: !refreshTable.current
    },
        usePagination);

    return (

        <div>
            {/*console.log(page)*/}
            <Table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()} >
                        {headerGroup.headers.map(column => (
                            <th className={`session-table-header no-wrap `}

                            className={
                                hiddenColumnsOnMobile.includes(column.id) 
                                    ? "hide-on-mobile"
                                    : ""
                            }
                                {...column.getHeaderProps()}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {kiLabList === "loading" ? (<tr><td><LoadingLocal /></td></tr>)
                    : (
                        page.map((row, index) => {
                            prepareRow(row)
                            return (
                                <React.Fragment key={`tbody-${index}`}>
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                        return (
                                            <td 
                                            {...cell.getCellProps()}

                                            /** Conditions to hide coplumns on the smartphones */
                                            //className={`${cell.column.id === "practice"?"hide-on-mobile ":""}`}

                                            className={
                                                hiddenColumnsOnMobile.includes(cell.column.id) 
                                                    ? "hide-on-mobile"
                                                    : ""
                                            }
                                            >
                                            {cell.column.id === "analysis_instance"?
                                                tStatusHL7(cell.value)
                                                : 
                                                cell.value
                                            }
                                            </td>
                                        )
                                        })}
                                    </tr>
                                </React.Fragment>
                            )
                        })
                    )}
            </tbody>
        </Table>
        
    </div>
    );
};

export default KiLabTable;