import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'react-translate';
import { actionSweetAlert } from 'store/actions/commonActions';
import { actionDeleteChat } from 'store/actions/chatsActions';
import Icon from 'components/Icons/Icon';

const ChatBtnDelete = ({ setEditChatMembers }) => {
  const t = useTranslate('chat');
  const dispatch = useDispatch();

  const handleBtnDelete = () => {
    dispatch(
      actionSweetAlert(
        <SweetAlert
          warning
          title={t('are-you-sure')}
          onConfirm={() => {
            dispatch(actionDeleteChat());
            dispatch(actionSweetAlert(null));
            setEditChatMembers(false);
          }}
          onCancel={() => dispatch(actionSweetAlert(null))}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={t('btn-yes')}
          cancelBtnText={t('btn-no')}
          showCancel
        >
        </SweetAlert>
      )
    );
  };

  return (
    <span className="delete-group-icon" onClick={handleBtnDelete} title={t('delete-group')} >
      <Icon file="delete-group.svg" width="40px" />
    </span>
  );
};

export default ChatBtnDelete;
