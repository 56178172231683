import React, { useEffect, useRef, useState } from 'react';

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Collapse,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionGetMessage,
  actionSetBrowser,
} from 'store/actions/messagesActions';
import { useTranslate } from 'react-translate';
// import Loading from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import {
  actionGetMessages,
  actionGetMessagesScroll,
} from 'store/actions/messagesActions';
import { actionSetUserPracticecDisaffected } from 'store/actions/practicesActions';
import InboxDetail from './InboxDetail';
import InboxForm from './InboxForm';
import history from 'historyRouter';
import { actionSetBox } from 'store/actions/messagesActions';
//Hooks
import useAuthorizationValidator from 'plugins/TS-lib-utils-public/hooks/useAuthorizationValidator';

const InboxList = (props) => {
  const t = useTranslate('messages');
  const { toggle, isOpen, practice } = props;
  const [replyTo, setReplyTo] = useState({
    tags: [],
    suggestions: [],
  });
  const inboxRef = useRef();

  const dispatch = useDispatch();
  const { isDisaffected } = useSelector((state) => state.practicesReducer);
  const user = useSelector((state) => state.userReducer);
  const { inbox, message } = useSelector((state) => state.messagesReducer);
  const [contactDisaffected, setContactDisaffected] = useState(false);

  //Validations
  const { checkAccess } = useAuthorizationValidator();
  const allowInboxDetail = checkAccess('inbox', 'inbox-detail',practice._id);
  //check if I can to see in dashboard
  const allowInboxRead = checkAccess('inbox', 'read-message',practice._id);

  const showReplyForm = (to) => {
    setReplyTo({ ...replyTo, tags: to });
    
  };

  const checkContactDissfected = (dataFrom) =>{
    //check if the contact is a user disafected
    if (dataFrom.from && practice && practice.people && isDisaffected === false) {
      //get current user
      let res = practice.people.find(us => us.email === dataFrom.from.email);
      //check status
      setContactDisaffected(res && res.isDisaffected);
    }
  }
  const showMessage = (id, key, dataFrom) => {
    checkContactDissfected(dataFrom);
    
    let browser = {
      prev: key - 1,
      next: key + 1,
    };
    dispatch(actionSetBrowser(browser));
    dispatch(actionGetMessage(id));
    let item = document.getElementById(`readed-${id}`);
    if (item && item.classList.toString().includes('not-readed')) {
      item.classList.remove('not-readed');
      item.classList.add('readed');
    }
  };

  useEffect(() => {
    const infinityScroll = (e) => {
      if (
        Math.round(e.target.offsetHeight + e.target.scrollTop) >=
        e.target.scrollHeight
      ) {
        dispatch(actionGetMessagesScroll());
      }
    };
    let inboxR = inboxRef;
    inboxR.current.addEventListener('scroll', infinityScroll);
    dispatch(actionGetMessages());
    return () => {
      inboxR.current.removeEventListener('scroll', infinityScroll);
    };
    //eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    //check if user looged is disaffected in te current practice
    if (user && practice && practice.people) {
      let res = practice.people.find(us => us.email === user.email);

      //check status
      dispatch(actionSetUserPracticecDisaffected(res && res.isDisaffected));
    }
    //eslint-disable-next-line
  }, [practice, message]);

  //number of messages
  const messages_qty = inbox.messages.length ? inbox.messages.length : 0;

  useEffect(() => {
    if(inbox.messages.length>0){
      //check  first message
      checkContactDissfected(inbox.messages[0]);
    }
  
    if (messages_qty > 0 && inbox.messages && inbox.messages[0] && !message) {
      dispatch(actionGetMessage(inbox.messages[0]._id));
    }
   
 
    //eslint-disable-next-line
  }, [messages_qty, dispatch]);

  const handleClickLink = () => {
    dispatch(actionGetMessage());
    history.push(`/admin/inbox`);
    dispatch(actionSetBox('inbox'));
  };

  return (
    <Card
      className={`inbox-list h-100 ${props.mobile ? 'animated fast fadeInLeft' : ''
        }`}
    >
      <CardHeader>
        <span onClick={toggle} className="pull-right text-secondary">
          {isOpen ? (
            <i className="fa fas fa-angle-up"></i>
          ) : (
            <i className="fa fas fa-angle-down"></i>
          )}
        </span>

        <CardTitle className="text-primary">
          <div
            className="dashboard-inbox-url d-flex"
            onClick={() => handleClickLink()}
          >
            <div className="general-card-title-img text-primary mr-2">
              <i className="icon-inbox" />
            </div>
            <div className="general-card-title text-primary">{t('title')}</div>
          </div>
        </CardTitle>
      </CardHeader>
      <CardBody>
        {/* {loading_inbox && <Loading />} */}
        {inbox && inbox.length === 0 && (
          <div className="alert alert-danger">You don't have messages</div>
        )}
        <Collapse isOpen={isOpen}>
          <div ref={inboxRef} className="inbox-collase">
            <ListGroup>
              {inbox &&
                inbox.messages &&
                inbox.messages.length > 0 &&
                inbox.messages.map((item, key) => {
                  return (
                    <ListGroupItem
                      key={key}
                      id={`item-${key}`}
                      active={message && item._id === message._id}
                      className="d-flex justify-content-between"
                      onClick={(e) => {
                        let els =
                          document.getElementsByClassName('list-group-item');
                        for (let i = 0; i < els.length; i++) {
                          els[i].classList.remove('active');
                        }
                        document
                          .getElementById(`item-${key}`)
                          .classList.add('active');
                        if (props.mobile) {
                          props.setInboxSwitch('detail');
                        }
                        showReplyForm([]);
                        //Validator
                        if (allowInboxDetail || allowInboxRead) {
                          showMessage(item._id, key, item);
                        }
                      }}
                    >
                      <div
                        id={`readed-${item._id}`}
                        className={!item.flags.readed ? 'not-readed' : 'readed'}
                      ></div>

                      <div className="text-left text-truncate w-50">
                        {/* <i className="now-ui-icons files_paper" /> &nbsp; */}
                        <strong>
                          {item.from.firstName} {item.from.lastName}
                        </strong>
                      </div>
                      <div className="text-justify text-truncate w-50">
                        {item.subject}
                      </div>
                    </ListGroupItem>
                  );
                })}
            </ListGroup>
          </div>
          <InboxDetail
            showReplyForm={showReplyForm}
            tagsLength={replyTo.tags.length}
            //User Disafected can't see some components and when my contact is disffected,for example I can't see button 'reply'
            Idisaffected={(isDisaffected === true || contactDisaffected === true)}
          />
          {replyTo.tags.length > 0 && (
            <InboxForm
              showReplyForm={showReplyForm}
              to={replyTo}
              isOpen={isOpen}
              message={message}
            />
          )}
        </Collapse>
      </CardBody>
    </Card>
  );
};

export default InboxList;
