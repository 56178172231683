import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-translate";
import { Modal, ModalBody, Button, FormGroup, Label, Input } from "reactstrap";
import { actionDeletePractice } from "store/actions/practicesActions";

const PracticeDeleteModal = ({ modal, toggleModal }) => {
  const t = useTranslate('practices');
  const dispatch = useDispatch();
  const passwordRef = useRef(null);
  const practice = useSelector((state) => state.practicesReducer.practice);

  const onSubmit = () => {
    const password = passwordRef.current.value;
    if (password && practice._id) {
      dispatch(actionDeletePractice(password, practice._id));
      toggleModal();
    }
    passwordRef.current.value = "";
  };

  return (
    <Modal isOpen={modal} toggle={toggleModal}>
      <ModalBody>
        <strong>
          {practice.active
              ? t('alert-question-delete-practice')
              : t('alert-question-restore-practice')}
          <span className="text-primary"> {practice.name} </span>?
        </strong>
        <hr />
        <FormGroup>
          <Label for="confirmPassword">{t('alert-question-password-confirm')}</Label>
          <Input
            type="password"
            name="password"
            innerRef={passwordRef}
            required
          />
        </FormGroup>
        <div className="d-flex justify-content-center">
          <Button color="danger" onClick={onSubmit} className="ml-2">
            {t('btn-yes')}
          </Button>
          <Button color="info" onClick={toggleModal} className="ml-2">
            {t('btn-no')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PracticeDeleteModal;
