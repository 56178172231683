import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, CardHeader, CardBody, Media, CustomInput } from 'reactstrap';
import FileValidator from 'helpers/FileValidator';
import { toast } from 'react-toastify';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import { useFileUtils } from 'hooks';
import { useTranslate } from 'react-translate';

const LogoUpload = ({ register, fileId }) => {
  const t = useTranslate('practices');
  const isMounted = useRef(true);
  const [file, setFile] = useState({
    file: '/General/icons/photo-upload.svg',
    label: 'Attach file',
  });
  const [defaultImage, setDefaultImage] = useState(
    '/General/icons/photo-upload.svg'
  );
  const [loading, setLoading] = useState(false);

  /**
   * Avatar
   */
  const inputAvatar = document.getElementById('practice-avatar');
  const { getAvatar } = useFileUtils(inputAvatar);
  /**
   * Show image when changing input file
   */
  const handleSelectFile = (e) => {
    const file = e.target.files[0];
    if (!file) {
      document.getElementById('logo').value = '';
      return setFile({ file: defaultImage, label: '' });
    }
    /**
     * File validation
     */
    const validator = new FileValidator(file);
    let src = URL.createObjectURL(file);
    let { name } = file;
    /**
     * For resolution's validation
     */
    validator
      .isValidImageResolution(150, 150)
      .then((isValid) => {
        if (!validator.isValidWeight(1024) || !isValid) {
          toast.error(t('validation-invalid-file'));
          document.getElementById('logo').value = '';
          return setFile({ file: defaultImage, label: '' });
        }
        setFile({ file: src, label: name });
      })
      .catch(() => toast.error(t('validation-error-in-validation')));
  };

  const getFile = useCallback(async (fileId = null) => {
    if (!fileId) return null;
    setLoading(true);
    const response = await getAvatar(`practice-${fileId}`);
    setLoading(false);
    setDefaultImage(response);
    setFile({ file: response });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getFile(fileId);
    }
    return () => {
      isMounted.current = false;
    };
  }, [getFile, fileId]);

  const handleSelectDispath = () => {
    document.getElementById('logo').click();
  };

  return (
    <Card className="border-0 shadow-none">
      <CardHeader className="">
        <div className="practice-title">{t('logo-upload')}</div>
      </CardHeader>
      <CardBody className="">
        <Media>
          <Media left href="#" onClick={() => handleSelectDispath()}>
            {loading && <LoadingLocal />}
            {!loading && (
              <img
                className={'tp-icon mr-4'}
                id="practice-avatar"
                src={file.file}
                width="150px"
                title="Logo"
                alt=""
              />
            )}
          </Media>
          <Media body>
            {t('logo-dimensions')}
            <br />
            {t('logo-size')}
            <br />
            {t('logo-image-type')}
            <br />
            <br />
            {/*<Button color="info">Attach file</Button>*/}
            <CustomInput
              id="logo"
              type="file"
              name="logo"
              label={t('attach-file')}
              innerRef={register}
              onChange={handleSelectFile}
              accept="image/jpeg,image/png"
            />
          </Media>
        </Media>
      </CardBody>
    </Card>
  );
};

export default LogoUpload;
