import React, { useEffect, useRef } from 'react';
import useChatMessageStatus from '../../hooks/useChatMessageStatus';

const ChatMessageStatus = (props) => {
  const isMounted = useRef(null);
  const { message } = props;

  const { getStatus, getIcon } = useChatMessageStatus();
  const statusList = getStatus(message);

  const global = statusList && statusList[0] ? statusList[0] : null;

  useEffect(() => {
    isMounted.current = true;
    getStatus();
    return () => {
      isMounted.current = false;
    };
    //eslint-disable-next-line
  }, [message]);

  return (
    <>
      <div
        className={`chat-message-status`}
        data-tip
        data-for={`chat-message-status-${message?._id}`}
      >
        {getIcon(global?.status)}
      </div>
    </>
  );
};

export default ChatMessageStatus;
