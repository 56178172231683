import React from 'react';
import ReactTooltip from 'react-tooltip';

const ChatRoomTooltip = ({ chat, context }) => {
  const chatName = (includeTitle = false) => {
    if (chat.type === 'group' && chat.name) {
      return chat.name;
    }
    const practitioner = chat.main_user ? chat.main_user : chat;
    if (practitioner) {
      return `${includeTitle && practitioner.title ? practitioner.title : ''} ${
        practitioner.firstName
      } ${practitioner.lastName}`;
    }
    return '';
  };

  const usersList = () => {
    if (chat && chat.type === 'group' && chat.users) {
      return (
        <div className="d-flex flex-column">
          {chat.users.map((user) => {
            if (!user.deleted && !user.disaffectedDate) {
              return (
                <span key={`tooltip-user-${user.auth_user_id}-${user._id}`}>{`
                ${user.title ? user.title : ''} ${user.firstName} ${
                  user.lastName
                }`}</span>
              );
            }
          })}
        </div>
      );
    }
    return null;
  };

  if (!chat) {
    return <></>;
  }

  return (
    <>
      <ReactTooltip
        id={`${context ? context + '-' : ''}tooltip-${chat._id}`}
        aria-haspopup="true"
        effect="float"
        type="dark"
        place="bottom"
      >
        {chat.type && chat.type.includes('group') ? (
          <strong>{`${chatName()}:`}</strong>
        ) : (
          chatName(true)
        )}

        {/*Show the list of users in the group */}
        {usersList()}
      </ReactTooltip>
    </>
  );
};

export default ChatRoomTooltip;
