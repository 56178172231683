
import React, { Fragment } from "react";

// reactstrap components
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { AppName } from "configs/app.json";
import { useTranslate } from 'react-translate';

// core components
// import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

const Welcome = () => {
  const t = useTranslate('welcome');
  return (
    <Fragment>
      {/* <PanelHeader size="sm" /> */}
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                {/*<h5 className="title">{t('welcome-to-site')}</h5>*/}
                
                {/*Todo: add support for translations"*/}
                <p style={{fontWeight: "bold"}}>
                  Ihre Praxis über "PRAXISMANAGEMENT" anmelden <br />
                  Ihrer Einladung über "MEINE EINLADUNGEN" folgen
                </p>

                <p>
                  Richten Sie hier Ihre Praxis ein. <br />
                  Wurden Sie Eingeladen? Folgen Sie doch der Einladung.
                </p>

              </CardHeader>
              <CardBody></CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Welcome;
