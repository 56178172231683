import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { translate } from 'react-translate';
import { useFileUtils } from 'hooks';

const ChatStatus = () => {
  const user = useSelector((state) => state.userReducer);
  const { getAvatar, defaultImage } = useFileUtils();
  useEffect(() => {
    getAvatar(user.avatar, 'large', true);
    //eslint-disable-next-line
  }, [user.avatar, user.updated_at]);
  return (
    <div className="chat_profile d-flex align-items-center justify-content-end pull-right">
      {user.avatar ? (
        <img src={defaultImage} alt="Contact" id="user-avatar" />
      ) : (
        <span className="avatar-name">{user.nameInitials.toUpperCase()}</span>
      )}
      <div className={`avatar-status ${user.status_account}`}></div>
    </div>
  );
};

export default translate('chat')(React.memo(ChatStatus));
