import React, { useState } from 'react';
import moment from 'moment';
import { useTranslate } from 'react-translate';
import MsgSystem from './MsgSystem';
import MessageHoverModal from 'components/MessageHoverModal/MessageHoverModal';
import EmojisReactionsData from 'components/Emojis/EmojisReactionsData';
import ChatMessageStatus from './ChatMessageStatus';
import Linkify from 'react-linkify';
import { useSelector } from 'react-redux';


const MsgOutgoing = ({ message, sameSender, sameTime, setReplyData }) => {
  const [showEmojiOptions, setShowEmojiOptions] = useState(false);
  const t = useTranslate('chat');
  const { auth_user_id } = useSelector((state) => state.userReducer);
  const { isDisaffected } = useSelector((state) => state.practicesReducer);
  const { chat } = useSelector((state) => state.chatsReducer);
  const currentUser = chat ? chat?.users?.find(u => String(u.auth_user_id) === String(auth_user_id)) : null;
  /**
   * For system messages
   */
  if (message.action !== 'default') {
    return <MsgSystem message={message} />;
  }

  return (
    <>
      {showEmojiOptions && !isDisaffected && !message?._id?.includes('temp') && !currentUser?.deleted && (
        <MessageHoverModal
          show={showEmojiOptions}
          setShow={setShowEmojiOptions}
          id={`chat-message-${message._id}`}
          message={message}
          type="out"
          setReplyData={setReplyData}
        />
      )}
      <div
        className={`outgoing_msg animate__animated animate__fadeIn`}
        data-id={message._id}
      >
        <div className="sent_msg">
          <div className="message-header d-flex justify-content-between align-items-center">
            {!sameSender && (
              <strong>
                <span className="sender">{t('me')}</span>
              </strong>
            )}
            <EmojisReactionsData message={message} />
          </div>

          {message.reply && (
            <>
              <div className="sent_quote_author">
                {`${message.reply.author} ${t('chat-said')}:`}
              </div>

              <div className="sent_quote">{message.reply.body}</div>
            </>
          )}

          <div
            className={`sent_container${message.reply ? '_reply' : ''}`}
            onClick={() => setShowEmojiOptions(!showEmojiOptions)}
          >
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              <p className="break-line" id={`chat-message-${message._id}`}>{message.body || ''} </p>
            </Linkify>
            <ChatMessageStatus message={message} />
          </div>

          {!sameTime && (
            <span className="time_date">
              {moment(message.date).format('H:mm')} |{' '}
              {moment(message.date).format('MMMM D')}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default MsgOutgoing;
