import CONSTANTS from "../CONSTANTS";

const initialState = {
  patients: [],
  patient: {},
  page: 0,
  lastPage: 1,
  limit: 10,
  loadingPatientList: false
};

const patientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.PATIENTS_SET_LIST:
      return { ...state, 
        patients: [...action.patients.data],
        page: action.patients.page,
        lastPage: action.patients.lastPage,
        limit: action.patients.limit,
        loadingPatientList: false
      };
    case CONSTANTS.SET_PATIENT:
      return { ...state, patient: action.patient };
    default:
      return state;
  }
};

export default patientsReducer;
