import React, { useState } from 'react';

import StatusList from 'components/Sidebar/StatusList';

const MenuOptions = React.memo(({ setOpen, isOpen }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <>
        <div
          className={`menu-status-container animation-fill-mode text-center remove-button ${
            isOpen ? 'animate__bounceIn' : 'animate__bounceOut'
          }`}
          title="remove"
          onClick={() => setOpen(!isOpen)}
        >
          x
        </div>
      <div
        className={`menu-status-container animation-fill-mode ${
          isOpen ? 'animate__bounceIn' : 'animate__bounceOut'
        }`}
      >
        <StatusList setOpen={setOpen} />
      </div>
    </>
  );
});

export default MenuOptions;
