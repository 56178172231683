import CONSTANTS from "../CONSTANTS";
//SweetAlert
export const actionSweetAlert = (sweetAlert) => ({
  type: CONSTANTS.SWEET_ALERT,
  sweetAlert,
});
//Collapse
export const actionSetCollapse = (collapse) => ({
  type: CONSTANTS.COLLAPSE,
  collapse,
});
//Mobile views
export const actionMobileVersion = (mobile) => ({
  type: CONSTANTS.MOBILE_VERSION,
  mobile,
});
//Mobile version only for dashboard's widgets
export const actionDasboardMobileVersion = (mobile) => ({
  type: CONSTANTS.DASHBOARD_MOBILE_VERSION,
  mobile,
});
//Native webview is different that mobile view (users can access the site through any mobile navigator)
export const actionNativeWebView = (native) => ({
  type: CONSTANTS.NATIVE_WEBVIEW,
  native,
});
//Set web socket status
export const actionWsStatus = (status) => ({
  type: CONSTANTS.WS_STATUS,
  status,
});
