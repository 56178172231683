import AxiosService from 'plugins/TS-lib-utils-public/services/AxiosService';

class DoctorsService extends AxiosService {
  constructor() {
    super('doctor-user');
  }
  /**
   * Get all patients linked to the current doctor
   */
  getPatients = async (practiceId, page = 0, count = 5, search = null) => {
    let query = `/patients/?page=${page}&limit=${count}`;

    if (search) {
      query += `&search=${search}`
    }

    const response = await this.get(query, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        practice: practiceId
      },
    });
    return response;
  };
  /**
   * Update patient check
   * @param {String} practiceId practice Id
   * @param {String} patientId patient Id (either pifs_id or auth_user_id)
   * @param {Boolean} status New status for the check
   * @returns 
   */
  updatePatientCheck = async (practiceId, patientId, status) => {
    return await this.put(`/patients/${patientId}/check`, { auto_forward_studies: status }, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        practice: practiceId
      },
    });
  }
  /**
   * Search for patients matching the query
   */
  searchPatients = async (query, practiceId) => {
    try {
      const response = await this.get(`/patients/search/${query}`, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          practice: practiceId
        },
      });
      return response;
    } catch (e) {
      return {
        data: []
      }
    }
  };
  /**
 * Get all patients linked to the current doctor
 */
  getDoctorInfo = async () => {
    const response = await this.get(`/doctors/`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };

  /**
 * Get all system templates
 */
  getSystemTemplates = async () => {
    try {
      const response = await this.get(`/system-templates/`, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      });
      return response;
    } catch (e) {
      console.log(e);
      return [];
    }
  };
}

export default new DoctorsService();
