import {
  takeEvery,
  put,
  all,
  call,
  select,
  take,
  takeLatest,
  cancelled,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
//Redux Actions
import CONSTANTS from '../CONSTANTS';
//Actions
import {
  actionSetChats,
  actionGetChatMessages,
  actionSetChat,
  actionGetChat,
  actionChatsLoadingStatus,
} from 'store/actions/chatsActions';
//Services
import ChatsService from '../../services/ChatsService.js';
import {
  getUserAuthorization,
  getUser,
  getChats
} from 'store/selectors/selectors';
import { actionUpdateChatInList } from 'store/actions/chatsActions';
import { actionChatMessagesRefresh, actionChatShowInbox } from 'store/actions/chatsActions';
import { actionGetNotifications } from 'store/actions/notificationsActions';
import { getPractices } from 'store/selectors/selectors';
import nativeSender from 'helpers/nativeSender';
//Helpers
import utilTranslations from '../../helpers/utilTranslations';
import { getMobile } from 'store/selectors/selectors';
//import { actionDeleteLocal } from 'store/actions/chatsActions';
//import { actionChatMessagePushTemp } from 'store/actions/chatsActions';
import { actionChatMessageUpdate } from 'store/actions/chatsActions';
import { selectChat } from 'store/selectors/selectors';
import { actionChatMessagePushTemp } from 'store/actions/chatsActions';
import { actionGetChats } from 'store/actions/chatsActions';
import { actionGetPractice } from 'store/actions/practicesActions';
import { actionRefreshPermissions } from 'store/actions/usersActions';
import { actionSyncChatsPractitioners } from 'store/actions/chatsActions';
import { actionGetChatMessagesScroll } from 'store/actions/chatsActions';
import PracticesService from 'services/PracticesService';
let t;
//MISCELLNEOUS
/**
 * Check user administrator in active chat
 * @returns boolean
 */
const isAdministrator = (userActive, users) => {
  const result = users.findIndex((user) => {
    return user.administrator && user.auth_user_id === userActive.auth_user_id;
  });
  return result >= 0;
};
/**
 * Update chat list
 */
const buildChatList = (chats = [], chat = {}, setFirst = false) => {
  let chatsTmp = chats;
  // delete chat.messages;
  if (setFirst) {
    // Set chat in first position
    let updatedChat = {};
    chatsTmp = chats.filter((chatTmp) => {
      if (chatTmp._id === chat._id) {
        updatedChat = Object.assign(chatTmp, chat);
      }
      if (!updatedChat._id) {
        updatedChat = chat;
      }
      return chatTmp._id !== chat._id;
    });
    chatsTmp.unshift(updatedChat);
  } else {
    // Preserve order
    chatsTmp = chats.map((chatTmp) => {
      if (chatTmp._id === chat._id) {
        return Object.assign(chatTmp, chat);
      }
      return chatTmp;
    });
  }
  return chatsTmp;
};
//CHATS
/**
 * Get chat list
 */
function* sagaGetChats({ loadMessages, preserveMessages }) {
  console.log("sagaGetChats");
  const cancelToken = ChatsService.getSource();
  const userActive = yield select(getUser);
  const mobile = yield select(getMobile);

  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    yield put(actionChatsLoadingStatus(false));
    const practice = yield select(getUserAuthorization);
    const { chat } = yield select(getChats);
    const PracticeReducer = yield select(getPractices);
    const result = yield call(ChatsService.getChats, practice._id, null, {
      cancelToken: cancelToken.token,
    });
    if (result.status >= 400) {
      throw new Error(t('alert-error-get-chats'));
    }
    let chatsList = result.data;

    if (Array.isArray(PracticeReducer.practice.people)) {
      let contacts = PracticeReducer.practice.people.filter((practitioner) => {

        const isChat = chatsList.findIndex(
          (chat) =>
            chat.type === 'private' &&
            chat.users.findIndex(
              (user) =>
                user.auth_user_id.toString() ===
                practitioner.auth_user_id.toString()
            ) > -1
        );
        return !practitioner.isDisaffected && isChat === -1 && practitioner.type === "doctor";
      });

      chatsList = chatsList.concat(contacts);
    }

    /**
     * Set chats
     */
    yield put(actionSetChats(chatsList));

    /**
     * Load messages for the first chat
     */
    if (false) {
      if (
        !mobile &&
        typeof chatsList[0] !== 'undefined' &&
        ['private', 'group'].includes(chatsList[0].type)
      ) {
        if (chat._id === chatsList[0]._id) {
          const lastM = chatsList[0].messages[chatsList[0].messages.length-1];
          if (lastM) {
            yield put(actionGetChatMessagesScroll(chat._id, lastM._id));
          }
        }
        yield put(actionSetChat(chatsList[0]));
      }
    }
    yield put(actionSyncChatsPractitioners());
  } catch (error) {
    console.log(error);
    toast.error(t('alert-error-get-chats'));
  } finally {
    yield put(actionChatsLoadingStatus(true));
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}
/**
 * Get chat list disaffected
 */
 function* sagaGetChatsDisaffected({ loadMessages, auth_user_id, queryDisaffected }) {
  console.log("sagaGetChatsDisaffected");
  const cancelToken = ChatsService.getSource();
  const userActive = yield select(getUser);
  const mobile = yield select(getMobile);

  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    yield put(actionChatsLoadingStatus(false));
    const practice = yield select(getUserAuthorization);
    const { chat } = yield select(getChats);
    const result = yield call(ChatsService.getChatsDisaffected, auth_user_id, queryDisaffected, practice._id, null, {
      cancelToken: cancelToken.token,
    });
    if (result.status >= 400) {
      throw new Error(t('alert-error-get-chats'));
    }
     let chatsList = result.data;
    // let chats = result.data.splice(1,5);
    /**
     * Set chats
     */
    yield put(actionSetChats(chatsList));
    /**
     * Load messages for the first chat
     */
    if (loadMessages) {
      if (
        !mobile &&
        typeof chatsList[0] !== 'undefined' &&
        ['private', 'group'].includes(chatsList[0].type)
      ) {
        yield put(actionSetChat(chatsList[0]));
      } else if (chat._id) {
        yield put(actionSetChat(chat));
      }
    }
  } catch (error) {
    toast.error(t('alert-error-get-chats'));
  } finally {
    yield put(actionChatsLoadingStatus(true));
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}

/**
 * Get chat from url params
 */
function* sagaGetChatFromUrlParams({ chatId, practiceId }) {
  try {
    let practice_active = yield call(PracticesService.getPracticeBySession);

    if (practice_active.status >= 400) {
      throw new Error("Session not found");
    }

    practice_active = practice_active.data.id;

    if (practiceId !== '' && practice_active !== practiceId) {
      yield put(actionGetPractice(practiceId));
      yield put(actionRefreshPermissions(practiceId));
      yield take(CONSTANTS.PRACTICES_SET);
      yield put(actionGetChats());
      yield take(CONSTANTS.CHATS_SET);
    }
    
    const { chats } = yield select(getChats);

    let notifChat = chats.find(chat => chat._id === chatId);
    if (notifChat) {
      yield put(actionSetChat(notifChat));
      yield put(actionChatShowInbox(true));
    }
  } catch (error) {
    console.log(error);
    toast.error(t("chat-not-located"));
  }
}
/**
 * Sync chat list with practitioners
 * 
 */
function* sagaSyncChatsPractitioners() {
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    const { practice } = yield select(getPractices);
    const { people } = practice;
    const { chats } = yield select(getChats);
    let chatsData = chats.length ? [...chats] : null;

    if (!chatsData) {
      return null;
    }

    if (Array.isArray(people) && chatsData.length) {

      people.map((practitioner) => {
        const chatIndex = chatsData.findIndex(
          (chat) => {
            return chat && !['private', 'group'].includes(chat.type) &&
            chat.auth_user_id.toString() ===
            practitioner.auth_user_id.toString();
          }
        );

        if (chatIndex > -1) {
          const isChat = chatsData.findIndex(
            (chat) =>
            chat &&
            chat.type === 'private' &&
            chat.users.findIndex(
                (user) =>
                  user.auth_user_id.toString() ===
                  practitioner.auth_user_id.toString()
              ) > -1
          );

          if (isChat > -1) {
            delete chatsData[chatIndex];
          } else {
            chatsData[chatIndex] = practitioner;
          }
        }
      });
    }

    //Check for disaffected/inactive users/chats and delete them
    chatsData = chatsData 
    ? chatsData.filter(c => {
      if (['private', 'group'].includes(c.type)) {
        return c && !c.main_user.disaffectedDate;
      } else {
        return c && !c.isDisaffected && c.status !== 'inactive';
      }
    })
    : [];
    /**
     * Set chats
     */
    yield put(actionSetChats(chatsData));
  } catch (error) {
    console.log(error);
    toast.error(t('alert-error-get-chats'));
  }
}
/**
 * Get chat
 * @param {string} chat_id
 * @param {boolean} makeRequest true for search in api and false for search in store
 * @param {boolean} setFirst show chat in fisrt position
 * @returns null
 */
function* sagaGetChat({ id, makeRequest, setFirst }) {
  console.log("sagaGetChat");
  const cancelToken = ChatsService.getSource();
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    const { chats } = yield select(getChats);
    const practice = yield select(getUserAuthorization);
    /**
     * Search in api
     */
    if (makeRequest) {
      const result = yield call(ChatsService.getChat, practice._id, id, {
        cancelToken: cancelToken.token,
      });
      if (result.status >= 400) {
        throw new Error(t('alert-error-get-chats'));
      }
      const chat = result.data;
      yield put(actionGetChatMessages(chat._id));
      yield put(actionUpdateChatInList(chat, setFirst, true));
      return null;
    }
    /**
     * Search in store
     */
    if (chats.length > 0) {
      let chat = chats.find((chatTmp) => chatTmp._id === id);
      if (chat._id) {
        yield put(actionSetChat(chat));
        yield put(actionChatMessagesRefresh(chat._id));
      } else {
        yield put(actionGetChat(id, true));
      }
    }
  } catch (error) {
    // toast.error('Get chat failed');
  } finally {
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}

/**
 * Update chat in store
 * @param {object} chat with full data
 * @param {boolean} param0 setFirst show chat in first position
 */
function* sagaUpdateChatInList({ chat, setFirst, setChat }) {
  const cancelToken = ChatsService.getSource();
  const userActive = yield select(getUser);
  //const practice = yield select(getUserAuthorization);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    // if (['group', 'private'].includes(chat.type)) {
    const practice = yield select(getUserAuthorization);
    const { chats } = yield select(getChats);
    if (chats.length > 0) {
      // Temporal chats array
      let chatsTmp = chats;
      // Search only in chat rooms with users
      if (chat.users && chat.users.length > 0) {
        chatsTmp = buildChatList(chatsTmp, chat, setFirst);

        if (setChat) {
          // Active chat in first position
          yield put(actionSetChat(chatsTmp[0]));
        }

      } else {
        // Chat not found in store
        // Get chat from api
        const result = yield call(
          ChatsService.getChat,
          practice._id,
          chat._id,
          { cancelToken: cancelToken.token }
        );
        if (result.status >= 400) {
          throw new Error(t('alert-error-get-chats'));
        }
        const newChat = result.data;

        //Abort if the new chat belongs to another practice
        if (newChat.practice._id !== practice._id) {
          return null;
        }

        if (!newChat.messages && chat.messages) {
          newChat.messages = chat.messages;
        }

        // Build list and Set chat in first position
        chatsTmp = buildChatList(chatsTmp, newChat, setFirst);
        // Remove user recipient from chat list in store
        if (['private'].includes(newChat.type)) {
          // Remove user fron chat list
          chatsTmp = chatsTmp.filter((chatTmp) => {
            if (['private', 'group'].includes(chatTmp.type)) {
              return true;
            }
            return (
              chatTmp.auth_user_id.toString() !== newChat.main_user.auth_user_id.toString()
            );
          });
        }
        if (setChat) {
          // Active chat in first position
          yield put(actionSetChat(newChat));
        }
      }
      // Update chat list in store
      yield put(actionSetChats(chatsTmp));
    }
    // }
  } catch (error) {
    console.log(error);
  } finally {
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}

/**
 * Get chat
 */
function* sagaSaveChat({ data }) {
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    const practice = yield select(getUserAuthorization);
    const result = yield call(ChatsService.saveChat, practice._id, data);
    if (result.status >= 400) {
      throw new Error(t('alert-error-save-chat'));
    }
    const chat = result.data;
    delete chat.messages;
    yield put(actionUpdateChatInList(chat, true));
    yield put(actionSetChat(chat));
    // yield put(actionGetChatMessages(chat._id));
  } catch (error) {
    // toast.error('Get chat failed');
  }
}

/**
 * Refresh all chats of one user
 */
function* sagaChatsRefresh({ auth_user_id, updateOwn }) {
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    const practice = yield select(getUserAuthorization);
    const { chats } = yield select(getChats);
    yield put(actionSyncChatsPractitioners());
    yield take(CONSTANTS.CHATS_SET);
    for (let index in chats) {
      const chat = chats[index];
      if (
        (userActive.auth_user_id !== auth_user_id &&
        chat.users.find((user) => user.auth_user_id === auth_user_id)) ||
        updateOwn
      ) {
        const result = yield call(ChatsService.getChat, practice._id, chat._id);
        if (result.status >= 400) {
          throw new Error(t('alert-error-get-chats'));
        }
        const chatUpdated = result.data;
        yield put(actionUpdateChatInList(chatUpdated));
      }
    }
  } catch (error) {
    // toast.error('Get chat failed');
  }
}

/**
 * Delete users from chat
 */
function* sagaDeleteActiveUserFromChat() {
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    const practice = yield select(getUserAuthorization);
    const { chat } = yield select(getChats);
    const result = yield call(
      ChatsService.deleteChatMyUser,
      practice._id,
      chat._id,
      userActive.auth_user_id
    );
    if (result.status >= 400) {
      throw new Error(t('alert-error-delete-chat'));
    }
    return yield put(actionSetChat(result.data));
  } catch (error) {
    toast.error(error);
  }
}

/**
 * Delete users to chat
 */
function* sagaDeleteUsersFromChat({ users }) {
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    const practice = yield select(getUserAuthorization);
    const { chat } = yield select(getChats);
    if (!isAdministrator(userActive, chat.users)) {
      throw new Error(t('alert-error-unauthorized'));
    }
    const result = yield call(
      ChatsService.deleteChatUsers,
      practice._id,
      chat._id,
      users
    );
    if (result.status >= 400) {
      throw new Error(t('alert-error-remove-users'));
    }
    let newChat = result.data;
    delete newChat.messages;
    yield put(actionUpdateChatInList(newChat, true));
    yield put(actionChatMessagesRefresh(chat._id));
    //yield put(actionGetPractice(practice._id));
  } catch (error) {
    toast.error(error);
  }
}
/**
 * Add users to chat
 */
function* sagaAddUsersToChat({ users }) {
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    const practice = yield select(getUserAuthorization);
    const { chat } = yield select(getChats);
    if (!isAdministrator(userActive, chat.users)) {
      throw new Error(t('alert-error-unauthorized'));
    }
    const result = yield call(
      ChatsService.addChatUsers,
      practice._id,
      chat._id,
      users
    );
    if (result.status >= 400) {
      throw new Error(t('alert-error-add-users'));
    }
    let newChat = result.data.chatRoom;
    delete newChat.messages;
    yield put(actionUpdateChatInList(newChat, true));
    yield put(actionChatMessagesRefresh(chat._id));
  } catch (error) {
    toast.error(error);
  }
}
/**
 * Delete  chat
 */
function* sagaDeleteChat() {
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    const practice = yield select(getUserAuthorization);
    const { chat } = yield select(getChats);
    if (!isAdministrator(userActive, chat.users)) {
      throw new Error(t('alert-error-unauthorized'));
    }
    const result = yield call(ChatsService.deleteChat, practice._id, chat._id);
    if (result.status >= 400) {
      throw new Error(t('alert-error-delete-chat'));
    }
    /*
    //Load another chat
    let chatLoad = chats.length && chats[0];
    if (chatLoad?._id === chat._id) {
      chatLoad = chats.length && chats[1];
    }
    if (chatLoad) {
      yield put(actionSetChat(chatLoad));
    }
    */
    //yield put(actionDeleteLocal(chat._id));
    // return yield put(actionGetChats(true));
  } catch (error) {
    toast.error(error);
  }
}
/**
 * Delete chat in store without make a request
 * @param {string} id chat id
 */
function* sagaDeleteChatLocal({ id }) {
  const { chats, chat } = yield select(getChats);
  if (chat._id === id) {
    yield put(actionSetChat({}));
  }
  if (Array.isArray(chats)) {
    const chatsFiltered = chats.filter((c) => c._id !== id);
    yield put(actionSetChats(chatsFiltered));
  }
}
//RECIPIENTS
/**
 *
 * @param {string} chat_id
 * @param {object} {flag: value}
 */
function* sagaChatRecipientsSetFlags({ chat_id, auth_user_id, options }) {
  try {
    const chat = yield select(selectChat, chat_id);
    if (chat && Array.isArray(chat?.messages)) {
      chat.messages = chat.messages.map((message) => {
        let index = null;
        if (Array.isArray(message?.recipients)) {
          index = message.recipients.findIndex(
            (u) => u.auth_user_id === auth_user_id
          );
        }
        let newMessage = { ...message };
        if (index >= 0 && newMessage?.recipients[index]) {
          Object.assign(message.recipients[index], options);
        }
        return newMessage;
      });
      yield put(actionUpdateChatInList(chat));
    }
  } catch (error) {
    console.log(error);
  }
}
/**
 * Get chat status
 */
//MESSAGES
/**
 * Get messages from chat
 */
function* sagaGetChatMessages({ chatId, options }) {
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    const practice = yield select(getUserAuthorization);
    const chatsReducer = yield select(getChats);
    const chat =
      chatsReducer.chat._id === chatId 
      ? chatsReducer.chat
      : chatsReducer.chats.length > 0
        ? chatsReducer.chats.find((c) => c._id === chatId)
        : {};
    const result = yield call(
      ChatsService.getMessages,
      practice._id,
      chatId,
      options
    );
    if (result.status >= 400) {
      throw new Error(t('alert-error-get-messages'));
    }
    let newMessages = result.data;
    // Set messages in chat list
    yield put(actionUpdateChatInList({ ...chat, messages: newMessages }, false, true));
  } catch (error) {
    toast.error(t('alert-error-get-messages'));
  }
}
/**
 * Get messages from chat with scroll
 * chatId Chat Id
 * last Id of the last message
 */
function* sagaGetChatMessagesScroll({ chatId, last }) {
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    const practice = yield select(getUserAuthorization);
    const chatsReducer = yield select(getChats);
    const chat =
      chatsReducer.chats.length > 0
        ? chatsReducer.chats.find((c) => c._id === chatId)
        : {};
    const messages = typeof chat.messages !== 'undefined' ? chat.messages : [];
    if (last && chat._id) {
      const result = yield call(
        ChatsService.getMessages,
        practice._id,
        chatId,
        { last }
      );
      if (result.status >= 400) {
        throw new Error(t('alert-error-get-messages-scroll'));
      }
      let newMessages = [...result.data, ...messages];
      // Update messages in chat list
      yield put(actionUpdateChatInList({ ...chat, messages: newMessages }));
    }
  } catch (error) {
    toast.error(error);
  }
}
/**
 * Get messages from chat
 */
function* sagaChatMessagesRefresh({ chatId, setFirst }) {
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  const cancelToken = ChatsService.getSource();
  try {
    const practice = yield select(getUserAuthorization);
    const chatsReducer = yield select(getChats);
    const chat =
      chatsReducer.chat._id === chatId 
      ? chatsReducer.chat
      : chatsReducer.chats.length > 0
        ? chatsReducer.chats.find((c) => c._id === chatId)
        : {};

    if (!chat) {
      // When is a new chat
      return yield put(actionGetChat(chatId, true, true));
    }

    // Get messages from chat list in store
    let messages = chat && chat.messages ? chat.messages : [];
    // Get only new messages
    const lastMessage =
      messages && messages.length > 0 ? messages[messages.length - 1] : {};
    if (lastMessage && lastMessage._id) {
      const result = yield call(
        ChatsService.getMessagesRefresh,
        practice._id,
        chat._id,
        lastMessage._id,
        { cancelToken: cancelToken.token }
      );
      if (result.status >= 400) {
        throw new Error(t('alert-error-chat-refresh'));
      }
      // Add new messages to global state
      const newMessages = messages
      .concat(result.data)
      .filter((message, i, self) => 
      self.findIndex(message2 => message2._id === message._id) === i);
      // Set new messages in chat active
      const lastMessageIncomming = newMessages[newMessages.length - 1];
      yield put(
        actionUpdateChatInList(
          {
            ...chat,
            messages: newMessages,
            date_last_message: lastMessageIncomming.date,
          },
          setFirst
        )
      );
    } else if (['group', 'private'].includes(chat.type)) {
      yield put(actionGetChat(chat._id, true));
    }
  } catch (error) {
    toast.error(error || t('alert-error-chat-refresh'));
  } finally {
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}

/**
 * Send message
 */
function* sagaChatMessagesSend({ message }) {
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    yield put(actionChatMessagePushTemp(message));
    if (message.chat) {
      yield take(CONSTANTS.CHATS_SET_CHAT);
    }
    const practice = yield select(getUserAuthorization);
    const result = yield call(ChatsService.sendMessage, practice._id, message);

    //If the message has been sent to a practitioner instead of a chat
    if (!message.chat) {
      result.data.chat.messages = [message];
      yield put(actionUpdateChatInList(result.data.chat, true, true));
      yield take(CONSTANTS.CHATS_SET);
    }
    yield put(actionChatMessagesRefresh(result.data.chat._id, true));
  } catch (error) {
    toast.error(error || t('alert-error-send-message'));
  }
}
/**
 * Push tmp chat
 */
function* sagaChatMessagePushTemp({ message }) {
  try {
    const { chat } = yield select(getChats);
    message.temp_id = `temp-${message.front_message_id}`;
    message.action = 'default';
    if (!Array.isArray(chat.messages)) {
      chat.messages = [];
    }
    if (['private', 'group'].includes(chat.type)){
      chat.messages.push(message);
      yield put(actionSetChat(chat));
      yield put(actionUpdateChatInList(chat, true));
    }
  } catch (error) {
    console.log({ error });
    toast.error(error || t('alert-error-send-message'));
  }
}

/**
 * Update message
 */
function* sagaChatMessageUpdate({ message, sent }) {
  try {
    let newMessage = { ...message };
    const chat = yield select(selectChat, message?.chat?._id);
    if (chat) {
      if (chat && Array.isArray(chat?.messages)) {
        const messageIndex = chat.messages
        .findIndex((m) => m.front_message_id && m.front_message_id === newMessage.front_message_id || m._id === newMessage._id);

        if (messageIndex > -1) {
          chat.messages[messageIndex] = newMessage;
        } else if (sent) {
          //If the sent message could not be found, add it
          chat.messages.push(newMessage);
        }
        
        yield put(actionUpdateChatInList(chat));
      }
    }
  } catch (error) {
    console.log({ error });
    toast.error(error || t('alert-error-send-message'));
  }
}

/**
 * Read messages by chat id
 */
function* sagaChatMessagesRead({ id }) {
  if (!id) {
    return;
  }
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    const practice = yield select(getUserAuthorization);
    const { chat } = yield select(getChats);
    const result = yield call(ChatsService.readAll, practice._id, id);
    if (id === chat._id) {
      chat.unreaded = 0;
      yield call(nativeSender.send, 'READED', { id: chat._id });
      yield put(actionUpdateChatInList(chat));
      yield put(actionGetNotifications('chats'));
    }
    if (result.status >= 400) {
      throw new Error(t('alert-error-read-message'));
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * Add emojis
 */
function* sagaChatMessageAddEmoji({ message, emoji }) {
  if (!message?.recipients) {
    return;
  }
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    const practice = yield select(getUserAuthorization);
    let index = null;
    if (Array.isArray(message?.recipients)) {
      index = message.recipients.findIndex(
        (u) => u.auth_user_id === userActive.auth_user_id
      );
    }
    if (index >= 0) {
      let emojiIndex = message?.recipients[index]?.reactions?.findIndex(
        (e) => e === emoji
      );
      if (typeof emojiIndex === 'undefined' || emojiIndex === -1) {
        //add
        if (!message?.recipients[index]?.reactions) {
          message.recipients[index]['reactions'] = [];
        }
        message.recipients[index].reactions.push(emoji);
        yield call(
          ChatsService.addEmojiReaction,
          practice._id,
          message._id,
          emoji
        );
      } else {
        //remove
        message.recipients[index].reactions.splice(emojiIndex, 1);
        yield call(
          ChatsService.removeEmojiReaction,
          practice._id,
          message._id,
          emoji
        );
      }
      yield put(actionChatMessageUpdate(message));
    }
  } catch (error) {
    console.log(error);
  }
}
/**
 * Remove emojis
 */
function* sagaChatMessageRemoveEmoji({ message, emoji }) {
  if (!message?.recipients) {
    return;
  }
  const userActive = yield select(getUser);
  //Translations
  t = utilTranslations(userActive.language || 'de', 'chat');
  try {
    const practice = yield select(getUserAuthorization);
    let index = null;
    if (message?.recipients) {
      index = message.recipients.findIndex(
        (u) => u.auth_user_id === userActive.auth_user_id
      );
    }
    if (index >= 0) {
      let emojiIndex = message?.recipients[index]?.reactions?.findIndex(
        (e) => e === emoji
      );
      if (emojiIndex >= 0) {
        //remove
        message.recipients[index].reactions.splice(emojiIndex, 1);
        yield call(
          ChatsService.removeEmojiReaction,
          practice._id,
          message._id,
          emoji
        );
        yield put(actionChatMessageUpdate(message));
      }
    }
  } catch (error) {
    console.log(error);
  }
}
export function* chatsSaga() {
  console.log('*Main Chats Saga');
  yield takeLatest(CONSTANTS.CHATS_GET, sagaGetChats);
  yield takeLatest(CONSTANTS.CHATS_GET_CHAT, sagaGetChat);
  yield takeLatest(CONSTANTS.CHATS_GET_CHAT_DISAFFECTED, sagaGetChatsDisaffected);
  yield takeEvery(CONSTANTS.CHATS_UPDATE_CHAT_IN_LIST, sagaUpdateChatInList);
  yield takeEvery(CONSTANTS.CHATS_SAVE, sagaSaveChat);
  yield takeEvery(CONSTANTS.CHATS_ADD_USER, sagaAddUsersToChat);
  yield takeEvery(CONSTANTS.CHATS_DELETE_USER, sagaDeleteUsersFromChat);
  yield takeEvery(CONSTANTS.CHATS_DELETE, sagaDeleteChat);
  yield takeEvery(CONSTANTS.CHATS_DELETE_LOCAL, sagaDeleteChatLocal);
  yield takeEvery(
    CONSTANTS.CHATS_RECIPIENTS_SET_FLAGS,
    sagaChatRecipientsSetFlags
  );
  yield takeEvery(
    CONSTANTS.CHATS_PRACTITIONERS_SYNC,
    sagaSyncChatsPractitioners
  );
  yield takeEvery(
    CONSTANTS.CHATS_REMOVE_ACTIVE_USER,
    sagaDeleteActiveUserFromChat
  );
  yield takeEvery(CONSTANTS.CHATS_GET_BY_URL, sagaGetChatFromUrlParams);
  yield takeEvery(CONSTANTS.CHATS_REFRESH_CHATS, sagaChatsRefresh);
  yield takeLatest(CONSTANTS.CHATS_M_REFRESH_CHAT, sagaChatMessagesRefresh);
  yield takeEvery(CONSTANTS.CHATS_M_GET, sagaGetChatMessages);
  yield takeEvery(CONSTANTS.CHATS_M_SEND, sagaChatMessagesSend);
  yield takeEvery(CONSTANTS.CHATS_M_READ, sagaChatMessagesRead);
  yield takeEvery(CONSTANTS.CHATS_M_SCROLL, sagaGetChatMessagesScroll);
  yield takeEvery(CONSTANTS.CHATS_M_PUSH_TEMP, sagaChatMessagePushTemp);
  yield takeEvery(CONSTANTS.CHATS_M_UPDATE, sagaChatMessageUpdate);
  yield takeEvery(CONSTANTS.CHATS_M_ADD_EMOJI, sagaChatMessageAddEmoji);
  yield takeEvery(CONSTANTS.CHATS_M_REMOVE_EMOJI, sagaChatMessageRemoveEmoji);
}
