/*!

Component to show values for a Topic.
It must recieve 2 parameters, 'data' and it's an array, and 'title' and it is a string

Data have 4 fields
label : Is the string for the value.
value : The integer number who represent the amount.
bgColor : The color of the area in the circle.
hoverColor : The color of the action hover, and the color of the box behind the doughnut.

title = "Title of the topic"
data = [
        {
            label: "Weiss nicht",
            value:10,
            bgColor: "#86aef3",
            hoverColor: "#e6f0ff",
        },
        {
            label: "Ja",
            value: 85,
            bgColor: "#3ace00",
            hoverColor: "#dafec0",
        }]

*/

import React from "react";
// react plugin used to create switch buttons
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";

// reactstrap components
import { Card } from "reactstrap";

//Include a particular scss for this component
import 'assets/scss/custom/widgets/widgetDoughnut.scss';






const WidgetDoughnut = (props) => {

    //get the parameters
    const { data, dataTitle } = props;  

    const DoughnutsOptions = {
        cutoutPercentage: 60, //the thickness of the ring
        legend: {
            display: false,
            position: "bottom"
        },
        maintainAspectRatio: true,
        aspectRatio: true,
        responsive: true,
        
        rotation: -90,
        elements: {
            arc: {
                borderWidth: 0,
                hoverBorderWidth: 2,
                hoverBorderColor: '#fff', 
            }
        },
        
        title: {
            display:false,
            text: "Title over the circle"
        },
      };

    /**
     * Construction of the array to send to base widget dougnhut.
     */
    const DoughnutsData = {

        labels: data.map((item) => item.label ),
        datasets:   [{
                        data: data.map((item, index) => item.value ),
                        backgroundColor: data.map((item, index) => item.bgColor ),
                        hoverBackgroundColor: data.map((item, index) => item.hoverColor )
                    }]
    };

    // Rendering the component
    return (
        <>
            <Card className="col-lg-6 col-sm-12 col-md-6">
                <h6 class="widDoughnutTitle">{dataTitle}</h6>
                    <div className="card-stats">
                        <Doughnut data={ DoughnutsData } options={ DoughnutsOptions } />
                        <div className="row contLabelsVals">
                            {   //Render the box with the values
                                data.map((item, index)  => (

                                        <div className=" labelBoxValue" style={{ backgroundColor : item.hoverColor }}>
                                            <p className="lb-text">{item.label}</p>
                                            <p className="lb-value"><b>{item.value}%</b></p>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </div>
            </Card>
        </>
    );
}

WidgetDoughnut.defaultProps = {
    data : [],
    title: ''
};

WidgetDoughnut.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string
};

export default WidgetDoughnut;