import CONSTANTS from '../CONSTANTS';
//Misellaneous
export const actionChatShowInbox = (show) => ({
  type: CONSTANTS.CHATS_SHOW_INBOX,
  show,
});

export const actionGetChatFromUrlParams = (chatId, practiceId) => ({
  type: CONSTANTS.CHATS_GET_BY_URL,
  chatId,
  practiceId
});
//End Misellaneous
//Chats
export const actionChatFilter= (filter_select) => ({
  type: CONSTANTS.CHATS_FILTER,
  filter_select,
});
export const actionChatsLoadingStatus = (status) => ({
  type: CONSTANTS.CHATS_SET_LOADING,
  status,
});
export const actionSaveChat = (data) => ({
  type: CONSTANTS.CHATS_SAVE,
  data,
});
export const actionGetChats = (loadMessages = true, preserveMessages = true) => ({
  type: CONSTANTS.CHATS_GET,
  loadMessages,
  preserveMessages,
});

export const actionGetChatsDisaffected = (loadMessages = true, auth_user_id = '', queryDisaffected = 0) => ({
  type: CONSTANTS.CHATS_GET_CHAT_DISAFFECTED,
  loadMessages,
  auth_user_id,
  queryDisaffected
});
export const actionChatsRefresh = (auth_user_id, updateOwn = false) => ({
  type: CONSTANTS.CHATS_REFRESH_CHATS,
  auth_user_id,
  updateOwn,
});
export const actionSetChats = (chats) => ({
  type: CONSTANTS.CHATS_SET,
  chats,
});
export const actionSetChatsOnline = (chats_online) => ({
  type: CONSTANTS.CHATS_SET_ONLINE,
  chats_online,
});
export const actionGetChat = (id, makeRequest = false, setFirst = false) => ({
  type: CONSTANTS.CHATS_GET_CHAT,
  id,
  makeRequest,
  setFirst,
});
export const actionSetChat = (chat) => ({
  type: CONSTANTS.CHATS_SET_CHAT,
  chat,
});
export const actionChatAddUser = (users) => ({
  type: CONSTANTS.CHATS_ADD_USER,
  users,
});
export const actionChatDeleteUsers = (users) => ({
  type: CONSTANTS.CHATS_DELETE_USER,
  users,
});
export const actionChatRemoveActiveUser = (user_id) => ({
  type: CONSTANTS.CHATS_REMOVE_ACTIVE_USER,
  user_id,
});
export const actionGetChatStadistics = (id) => ({
  type: CONSTANTS.CHATS_STADISTICS,
  id,
});
export const actionDeleteChat = (id) => ({
  type: CONSTANTS.CHATS_DELETE,
  id,
});
export const actionDeleteLocal = (id) => ({
  type: CONSTANTS.CHATS_DELETE_LOCAL,
  id,
});
export const actionUpdateChatInList = (
  chat,
  setFirst = false,
  setChat = false
) => ({
  type: CONSTANTS.CHATS_UPDATE_CHAT_IN_LIST,
  chat,
  setFirst,
  setChat,
});
export const actionSyncChatsPractitioners = () => ({
  type: CONSTANTS.CHATS_PRACTITIONERS_SYNC,
});
export const actionChatsError = (error) => ({
  type: CONSTANTS.CHATS_ERROR,
  error,
});
//RECIPIENTS
export const actionSetChatRecipients = (recipients) => ({
  type: CONSTANTS.CHATS_SET_RECIPIENTS,
  recipients,
});
export const actionChatRecipientsSetFlags = (chat_id, auth_user_id, options) => ({
  type: CONSTANTS.CHATS_RECIPIENTS_SET_FLAGS,
  chat_id,
  auth_user_id,
  options,
});

//Messages
export const actionChatMessagesRefresh = (chatId, setFirst = false) => ({
  type: CONSTANTS.CHATS_M_REFRESH_CHAT,
  chatId,
  setFirst,
});
export const actionChatMessageSend = (message) => ({
  type: CONSTANTS.CHATS_M_SEND,
  message,
});
export const actionGetChatMessages = (
  chatId,
  { page = 1, limit = 20, search = null, first = null, last = null } = {}
) => ({
  type: CONSTANTS.CHATS_M_GET,
  chatId,
  options: {
    page,
    limit,
    search,
    first,
    last,
  },
});
export const actionGetChatMessagesScroll = (chatId, last) => ({
  type: CONSTANTS.CHATS_M_SCROLL,
  chatId,
  last,
});
export const actionSetChatMessages = (messages) => ({
  type: CONSTANTS.CHATS_M_SET,
  messages,
});
export const actionChatMessageDelete = (id) => ({
  type: CONSTANTS.CHATS_M_SEND,
  id,
});
export const actionChatMessageRead = (id) => ({
  type: CONSTANTS.CHATS_M_READ,
  id,
});
export const actionChatMessagePushTemp = (message) => ({
  type: CONSTANTS.CHATS_M_PUSH_TEMP,
  message,
});
export const actionChatMessageUpdate = (message, sent = false) => ({
  type: CONSTANTS.CHATS_M_UPDATE,
  message,
  sent,
});
export const actionChatMessageAddEmoji = (message, emoji) => ({
  type: CONSTANTS.CHATS_M_ADD_EMOJI,
  message,
  emoji,
});
export const actionChatMessageRemoveEmoji = (message, emoji) => ({
  type: CONSTANTS.CHATS_M_REMOVE_EMOJI,
  message,
  emoji,
});