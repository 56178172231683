import { takeEvery, put, call, select, takeLatest, take } from 'redux-saga/effects';
import CONSTANTS from "../CONSTANTS";
import momentjsI18n from 'helpers/momentjsI18n';
//Actions
import {
  actionGetPatients,
  actionSetPatients,
} from "../actions/patientsActions";
//Services
import DoctorsService from "services/DoctorsService";
import { toast } from "react-toastify";

//Translations
import utilTranslations from '../../helpers/utilTranslations';
import { getUser, getNativeWebView, getMobile } from 'store/selectors/selectors';
import PatientsService from 'services/PatientsService';

function* sagaPatients({ options }) {

  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');

  try {
    const result = yield call(
      DoctorsService.getPatients, 
      options.practiceId,
      options.pageIndex,
      options.pageSize,
      options.querySearch
    );

    if (result.status >= 400) {
      throw new Error("Error retrieving patients list");
    }

    yield put(actionSetPatients(result.data));
  } catch (error) {
    console.log(error);
  }
}

function* sagaUpdatePatientCheck({ practiceId, patientId, status }) {

  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');

  try {
    const result = yield call(DoctorsService.updatePatientCheck, practiceId, patientId, status);

    if (result.status >= 400) {
      throw new Error("Error updating patient");
    }

    yield put(actionGetPatients(practiceId));
    toast.success(t("alert-patient-updated")); //"Patient updated"
  } catch (error) {
    console.log(error);
  }
}

function* sagaSendManualInvitation({ patientId, doctorId, studyId }) {

  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');

  try {
    const result = yield call(PatientsService.sendManualInvitation, patientId, doctorId, studyId);

    if (result.status >= 400) {
      throw new Error("Error inviting patient");
    }

    toast.success(t("alert-patient-invitation-sent")); //"Patient updated"
  } catch (error) {
    console.log(error);
    toast.warning(t("alert-patient-invitation-already-sent"));
  }
}

export function* patientsSaga() {
  console.log("*Main Patients Saga");
  yield takeEvery(CONSTANTS.PATIENTS_GET_LIST, sagaPatients);
  yield takeEvery(CONSTANTS.PATIENTS_EDIT_CHECK, sagaUpdatePatientCheck);
  yield takeEvery(CONSTANTS.PATIENTS_MANUAL_INVITE, sagaSendManualInvitation);
}
