
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Button,
} from 'reactstrap';

// core components
import bgImage from 'assets/img/bg16.jpg';
import { actionRegister } from 'store/actions/usersActions';
import Terms from 'views/Components/Terms';

const RegisterPage = (props) => {
  const t = useTranslate('register-page');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);
  const emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //Schema
  const userSchema = yup.object().shape({
    firstName: yup.string().min(2).required(t('error-required-firstName')),
    lastName: yup.string().min(2).required(t('error-required-lastName')),
    username: yup.string().min(6).matches(emailRex, t('error-required-username')).required(t('error-required-username')),
    password: yup.string().min(6, t('error-required-password')),
    rePassword: yup
      .string()
      .oneOf([yup.ref('password'), null], t('error-required-rePassword')),
    terms: yup.boolean().required(t('error-required-terms')),
  });

  const { register, handleSubmit, errors, formState } = useForm({
    validationSchema: userSchema,
  });
  const { touched } = formState;

  const [firstNameFocus, setFirstNameFocus] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [usernameFocus, setUsernameFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [rePasswordFocus, setRePasswordFocus] = useState(false);

  useEffect(() => {
    document.body.classList.add('register-page');
    return () => {
      return document.body.classList.remove('register-page');
    };
  }, []);

  const onSubmit = async (data) => {
    if (!data.terms) {
      return toast.warning( t("accept-terms") ); //'You must accept the terms of service');
    }
    dispatch(actionRegister(data));
  };

  const isAuth = () => {
    if (user.isAuth) {
      props.history.push('/admin/dashboard');
    }
  };

  const onEmailChange = (e) => {
    e.target.value = e.target.value.toLowerCase();
  }

  Object.keys(touched).map((key) => {
    if (errors[key] && !toast.isActive(key)) {
      toast.error(errors[key].message, {
        toastId: key,
      });
    }
    return null;
  });

  useEffect(() => {
    isAuth();
    return () => null;
    // eslint-disable-next-line
  }, [user.isAuth]);

  return (
    <Fragment>
      <div className="content">
        <img
          src="/General/login/ilustracion.svg"
          width="100%"
          alt=""
          className="mt-5"
        />
        <div className="register-page">
          <Container>
            <Row className="justify-content-center">
              <Col lg={12} md={8} xs={12}>
                <Card className="card-signup card-plain">
                  <Col lg={4} md={8} xs={12} className="mx-auto">
                    <CardHeader className="text-center">
                      <img src={'/General/login/logo.svg'} width="100%" alt="" />
                      <CardTitle className="text-info">{t('register')}</CardTitle>
                      {/* <div className="social btns-mr-5">
                      <Button className="btn-icon btn-round" color="twitter">
                        <i className="fab fa-twitter" />
                      </Button>
                      <Button className="btn-icon btn-round" color="dribbble">
                        <i className="fab fa-dribbble" />
                      </Button>
                      <Button className="btn-icon btn-round" color="facebook">
                        <i className="fab fa-facebook-f" />
                      </Button>
                      <h5 className="card-description">
                        {t("register-classic")}
                      </h5>
                    </div> */}
                    </CardHeader>
                  </Col>
                  <CardBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Col lg={4} md={8} xs={12} className="mx-auto">
                        <InputGroup
                          className={
                            (firstNameFocus ? 'input-group-focus' : '') +
                              touched.firstName && errors.firstName
                              ? 'has-danger'
                              : ''
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_circle-08" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <input
                            ref={register}
                            className="form-control"
                            name="firstName"
                            type="text"
                            placeholder={t('first-name')}
                            onFocus={(e) => setFirstNameFocus(true)}
                            onBlur={(e) => setFirstNameFocus(false)}
                          />
                        </InputGroup>
                        <InputGroup
                          className={
                            (lastNameFocus ? 'input-group-focus' : '') +
                              touched.lastName && errors.lastName
                              ? 'has-danger'
                              : ''
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <img
                                src={'/General/login/user.svg'}
                                width="15"
                                alt=""
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <input
                            ref={register}
                            className="form-control"
                            name="lastName"
                            type="text"
                            placeholder={t('last-name')}
                            onFocus={(e) => setLastNameFocus(true)}
                            onBlur={(e) => setLastNameFocus(false)}
                          />
                        </InputGroup>
                        <InputGroup
                          className={
                            (usernameFocus ? 'input-group-focus' : '') +
                              touched.username && errors.username
                              ? 'has-danger'
                              : ''
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-inbox" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <input
                            ref={register}
                            className="form-control"
                            name="username"
                            type="text"
                            placeholder={t('email')}
                            onChange={(e) => { onEmailChange(e); }}
                            onFocus={(e) => setUsernameFocus(true)}
                            onBlur={(e) => setUsernameFocus(false)}
                          />
                        </InputGroup>
                        <InputGroup
                          className={
                            (passwordFocus ? 'input-group-focus' : '') +
                              touched.password && errors.password
                              ? 'has-danger'
                              : ''
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <img
                                src={'/General/login/password.svg'}
                                width="15"
                                alt=""
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <input
                            ref={register}
                            className="form-control"
                            name="password"
                            type="password"
                            placeholder={t('password') + '...'}
                            onFocus={(e) => setPasswordFocus(true)}
                            onBlur={(e) => setPasswordFocus(false)}
                          />
                        </InputGroup>
                        <InputGroup
                          className={
                            (rePasswordFocus ? 'input-group-focus' : '') +
                              touched.rePassword && errors.rePassword
                              ? 'has-danger'
                              : ''
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <input
                            ref={register}
                            className="form-control"
                            name="rePassword"
                            type="password"
                            placeholder={t('re-password') + '...'}
                            onFocus={(e) => setRePasswordFocus(true)}
                            onBlur={(e) => setRePasswordFocus(false)}
                          />
                        </InputGroup>
                      </Col>
                      <FormGroup check className="m-0">
                        {/* 07112022 todo este FormGroup se paso al componente Terms por Mia Ramos */}
                        <Terms t={t} Label={Label} register={register} />
                      </FormGroup>
                      {/* se puso un z-index provisorio para que funcione en safari
                          TODO: hacerlo con reactstrap
                        */}
                      <Col lg={4} md={8} xs={12} className="mx-auto" >
                        <CardFooter className="text-center pt-4">
                          <Button
                            block
                            type="submit"
                            color="info"
                            size="sm"
                            className="mb-3 btn-block"
                            name="register_button"
                          >
                            {t('get-started')}
                          </Button>
                        </CardFooter>
                      </Col>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundImage: 'url(' + bgImage + ')' }}
      />
    </Fragment>
  );
};

export default RegisterPage;
