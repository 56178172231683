import PropTypes from 'prop-types';
import React from 'react';
import { useTranslate } from 'react-translate';

const PractitionerStatus = ({ status, isDisaffected }) => {
  const t = useTranslate('practices');
  const statusKeys = {
    active: {
      text: t('practitioner-status-active'),
      icon: 'icon-active-user',
    },
    online: {
      text: t('practitioner-status-active'),
      icon: 'icon-active-user',
    },
    offline: {
      text: t('practitioner-status-active'),
      icon: 'icon-active-user',
    },
    waiting: {
      text: t('practitioner-status-waiting'),
      icon: 'icon-hourglass-1',
    },
    inactive: {
      text: t('practitioner-status-inactive'),
      icon: 'icon-inactive-user',
    },
    accepted: {
      text: t('practitioner-status-accepted'),
      icon: 'icon-accepted',
    },
    rejected: {
      text: t('practitioner-status-rejected'),
      icon: 'icon-inactive-user',
    },
    failed: {
      text: t('practitioner-status-failed'),
      icon: 'icon-inactive-user',
    },
  };

  return (
    <div className={`collaborator-status ${status}`}>
      <span className={isDisaffected ? 'icon-inactive-user' : statusKeys[status]?.icon}></span>{' '}
      {isDisaffected ? t('practitioner-status-deleted') : statusKeys[status]?.text}
    </div>
  );
};

PractitionerStatus.propTypes = {
  status: PropTypes.oneOf([
    'active',
    'waiting',
    'accepted',
    'inactive',
    'rejected',
    'online',
    'offline',
    'deleted',
    'failed',
  ]).isRequired,
};

export default PractitionerStatus;
